import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  TextField,
} from "@mui/material";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const buttonsType = [
  {
    name: "link url",
    value: "link_url",
    icon: "https://fly.linkcdn.cc/images/back/button_link_icon.png",
    p: "Add a new link to your link page",
  },
  {
    name: "video",
    value: "video",
    icon: "https://fly.linkcdn.cc/images/back/button_video_icon.png",
    p: "Add videos right to your Fast World",
  },
  {
    name: "google map",
    value: "google_map",
    icon: "https://fly.linkcdn.cc/images/back/button_google-map_icon.png",
    p: "Add Google Maps right to your Fast World",
  },
  {
    name: "contact details",
    value: "contact_details",
    icon: "https://fly.linkcdn.cc/images/back/button_contact_icon.png",
    p: "Add your contact details",
  },
  {
    name: "form",
    value: "form",
    icon: "https://fly.linkcdn.cc/images/back/button_form_icon.png",
    p: "Collect your customer's message",
  },
];
const PopUpChooseButtonType = ({ show, setShow, handleChooseType }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //   const [allinputType, setallInputType] = React.useState([
  //     'text','number','date','time','textarea','select','checkbox','file'

  //   ]);
  const handleClose = () => setShow(false);
  const handleDelete = () => {};

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">Choose Button </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "red" }}>
            <div
              style={{
                width: "500px",
                height: "350px",
                alignItems: "center",

                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "450px",
                  height: "350px",
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "auto",
                  boxSizing: "border-box",
                  // justifyContent: "center",
                }}
              >
                {buttonsType.map((item, index) => {
                  return (
                    <Card
                      onClick={() => handleChooseType(item.value)}
                      key={index}
                      sx={{
                        width: 115,
                        margin: "15px",
                        height: 120,
                        textAlign: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                          transition: "all 0.5s ease",
                          transform: "scale(1.1)",
                          // height: 130,
                        },
                      }}
                    >
                      <CardMedia
                        sx={{ height: 36, width: 36, margin: "auto" }}
                        image={item.icon}
                        title="green iguana"
                      />
                      <CardContent sx={{ padding: "0px" }}>
                        <div style={{ marginTop: "10px" }}>{item.name}</div>
                        <div style={{ fontSize: "12px" }}>{item.p}</div>
                      </CardContent>
                    </Card>
                  );
                })}
                {}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpChooseButtonType;
