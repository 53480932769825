import { Box, Typography } from "@mui/material";
import face from "../../../assets/Image/Facebook.PNG";
import google from "../../../assets/Image/google.PNG";
import style from "../Register/Register.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Base_Url, { Apipassword } from "../../../Api/ApiUrl";

const VerifyAccountEmail = () => {
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      token: "",
    },

    onSubmit: (values) => {
      Base_Url.post("owner/verifyEmailToRegister", values, {
        headers: {
          Apipassword: Apipassword,
        },
      })
        .then((res) => {
          console.log(res);

          // localStorage.setItem("token", res.data.token.accessToken);
          localStorage.setItem("token", res.data.token);

          //   saveUser()
          navigate("/dashboard");
        })
        .catch((error) => {
          toast.error(`${error.response.data.errors.token}`);
        });
    },
  });

  console.log(formik.values.token);

  return (
    <Box
      sx={{
        maxWidth: "30%",
        alignContent: "center",
        margin: "100px auto",
        padding: "0",
        justifyContent: "center",
        // marginY: "90px",
        display: "block",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%", margin: " auto", height: "50vh" }}>
        <Typography
          component="h3"
          sx={{ fontSize: "28px", fontWeight: "700", textAlign: "center" }}
        >
          Verify Code
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              // maxWidth: "320px",
              height: "50px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              type="text"
              name="token"
              autoComplete="off"
              className={style.register}
              placeholder="Verification code"
              id="token"
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              value={formik.values.token}
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
          </Box>

          <Typography
            component="p"
            sx={{
              fontSize: "12px",
              lineHeight: "19,07px",
              color: "#ff0000",
              fontWeight: "700",
              marginTop: "15px",
              marginLeft: "7px",
            }}
          >
            {" "}
            {formik.touched.token && formik.errors.token}
          </Typography>

          <Box sx={{ height: "55px", marginTop: "30px" }}>
            <button
              type="submit"
              style={{
                background:
                  "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "10px",
                color: "white",
                fontSize: "20px",
                fontWeight: "600px",
                display: "block",
                margin: "0 auto",
              }}
            >
              Send Code
            </button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default VerifyAccountEmail;
