// ImageBlur.js (Sliders/ImageBlur.js)
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { useTheme } from "../../../../styles/ThemeContext";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function ImageBlur({ blur, onBlurChange, shade, setShade }) {
  const { currentTheme, setCurrentTheme } = useTheme();
  const handleSliderChange = async (event, newValue) => {
    onBlurChange(newValue);
    // setCurrentTheme({
    //   ...currentTheme,
    //   Container: {
    //     ...currentTheme.Container,
    //     filter: `blur(${newValue}px)`,
    //   },
    // });
  };

  const handleInputChange = (event) => {
    onBlurChange(event.target.value === "" ? 0 : Number(event.target.value));
  };

  return (
    <>
      <Box sx={{ width: 250 }}>
        <Typography id="input-slider" gutterBottom>
          Blur Image
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              value={blur}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              max={19}
            />
          </Grid>
          <Grid item>
            <Input
              value={blur}
              size="small"
              // onChange={handleInputChange}
              // inputProps={{
              //   step: 3,
              //   min: 0,
              //   max: 19,
              //   type: "number",
              //   "aria-labelledby": "input-slider",
              // }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: 250 }}>
        <Typography id="input-slider" gutterBottom>
          shade
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Slider
              value={shade}
              onChange={(e) => setShade(e.target.value)}
              aria-labelledby="input-slider"
              max={1}
              step={0.01}
            />
          </Grid>
          <Grid item>
            <Input
              value={shade}
              size="small"
              // onChange={handleInputChange}
              // inputProps={{
              //   step: 3,
              //   min: 0,
              //   max: 19,
              //   type: "number",
              //   "aria-labelledby": "input-slider",
              // }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
