import { createGlobalStyle } from "styled-components";
import * as theme from "./Theme.styled";
import firsttImage from '../../assets/Images/AppearanceThems/1stTheme.PNG'
export const GlobalStyles = createGlobalStyle`


// body {
//   background-color: ${({ theme }) => theme.colors.background};
//   color: ${({ theme }) => theme.colors.text};
//   font-family: monospace;
//   overflow-x: hidden;
// }

// theme buttons color
.light {
  background-color: red;
}

// active theme
.active{
    border: 3px solid ${({ theme }) => theme.colors.border};
}



// ================mobile-container====================

// .mobile-container  {
//   background-color: ${({ theme }) => theme.colors.screenBgc};
//   font-family: ${(props) => props.theme.FontFace.textType} ;

// }
`;
