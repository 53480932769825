import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import img from '../../assets/Image/update.webp'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const LatestUpdate = () => {
  return (
    <Box sx={{marginY:'30px'}}>
    <Grid container spacing={2}>
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
    
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative', marginTop:'20px'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
    
      <Grid xl={2.4} md={4} lg={2.4} xs={12}>
        <Box sx={{position:'relative', marginTop:'20px'}}>
          <img src={img} alt="" style={{width:'210px', borderRadius:'100px'}}/>
          <Box sx={{position:'absolute', top:'180px', left:'25px'}}>
    <Typography variant='h6' sx={{color:'white'}}> Body and Beauty...</Typography>
    <Typography component='p' sx={{fontSize:'14px', marginLeft:'20px', color:'white'}}> Body and Beauty</Typography>
    <Box display='flex'>
    <FavoriteBorderIcon sx={{color:'white', marginLeft:'25px'}}/>
    <Typography component='p' sx={{fontSize:'12px', marginLeft:'5px', color:'white', marginTop:'5px'}}> 1.190 links</Typography>
    
    </Box>
          </Box>
        </Box>
      </Grid>
    
    
    
    </Grid>
    </Box>
    
  )
}

export default LatestUpdate