import React, { useEffect, useState } from "react";
import profileAlign1 from "../../../../assets/Images/profileAlign1.PNG";
import profileAlign2 from "../../../../assets/Images/profileAlign2.PNG";
import profileAlign3 from "../../../../assets/Images/profileAlign3.PNG";
import profileAlign4 from "../../../../assets/Images/profileAlign4.PNG";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { Input } from "@mui/base";
import { useTheme } from "../../../styles/ThemeContext";

const Profile = () => {
  // ====================handel change======================
  const { currentTheme, setCurrentTheme } = useTheme();
  const [ImgSize, setImgSize] = useState();
  const [borderRadius, setBorderRadius] = useState();
  const [borderSize, setBorderSize] = useState();
  const [currentBox, setCurrentBox] = useState();

  useEffect(() => {
    if (currentTheme) {
      setImgSize(
        currentTheme?.ProfilePicture?.size
          ? currentTheme?.ProfilePicture?.size
          : 200
      );
      setBorderRadius(
        currentTheme?.ProfilePicture?.borderRadius
          ? currentTheme?.ProfilePicture?.borderRadius
          : 0
      );
      setBorderSize(
        currentTheme?.ProfilePicture?.borderSize
          ? currentTheme?.ProfilePicture.borderSize
          : 2
      );
    }
  }, []);

  const handleChangeDesign = (value) => {
    // console.log(value);
    setCurrentTheme({
      ...currentTheme,
      DivProfilePicture: {
        ...currentTheme.DivProfilePicture,
        design: value,
      },
    });
  };
  const handleChangeObject = (d) => {
    // console.log(d, currentTheme);
    // console.log("Updating Theme:", d);
    setCurrentTheme({
      ...currentTheme,
      Alignment: { ...currentTheme.Alignment, ...d.Alignment },
      Size: { ...currentTheme.Size, ...d.Size },
    });
  };

  const handleImgSizeChange = (value) => {
    setImgSize(value);
    setCurrentTheme({
      ...currentTheme,
      ProfilePicture: {
        ...currentTheme.ProfilePicture,
        size: value,
      },
    });
  };

  const handleBorderRadiusChange = (value) => {
    // console.log(value);
    setBorderRadius(value);
    setCurrentTheme({
      ...currentTheme,
      ProfilePicture: {
        ...currentTheme.ProfilePicture,
        borderRadius: value,
      },
    });
  };

  const handleBorderSizeChange = (value) => {
    setBorderSize(value);
    setCurrentTheme({
      ...currentTheme,
      ProfilePicture: {
        ...currentTheme.ProfilePicture,
        borderSize: value,
      },
    });
  };
  // ===========================

  return (
    <div>
      <div className="img-profile-con">
        <img
          src={profileAlign1}
          alt=""
          srcSet=""
          style={{ border: currentBox == 1 ? "3px solid #007aff" : "" }}
          onClick={() => {
            handleChangeDesign({
              flexDirectionD: "column",
              alignItemsD: "center",
              alignItemsT: "center",
            });
            setCurrentBox(1);
          }}
        />
        <img
          src={profileAlign2}
          alt=""
          srcSet=""
          style={{ border: currentBox == 2 ? "3px solid #007aff" : "" }}
          onClick={() => {
            handleChangeDesign({
              flexDirectionD: "column",
              alignItemsD: "start",
              alignItemsT: "start",
            });
            setCurrentBox(2);
          }}
        />
        {/* <img
          src={profileAlign2}
          alt=""
          srcSet=""
          style={{ border: currentBox == 2 ? "3px solid #007aff" : "" }}
          onClick={() => {
            handleChangeDesign({
              flexDirectionD: "column",
              lignItemsD: "start",
              alignItemsT: "start",
            });
            console.log({
              flexDirectionD: "column",
              alignItemsD: "start",
              alignItemsT: "start",
            });
            setCurrentBox(2);
          }}
        /> */}
        <div></div>
        <img
          src={profileAlign3}
          alt=""
          srcSet=""
          style={{ border: currentBox == 3 ? "3px solid #007aff" : "" }}
          onClick={() => {
            handleChangeDesign({
              flexDirectionD: "row",
              alignItemsD: "center",
              alignItemsT: "center",
            });
            setCurrentBox(3);
          }}
        />
        <img
          src={profileAlign4}
          alt=""
          srcSet=""
          style={{ border: currentBox == 4 ? "3px solid #007aff" : "" }}
          onClick={() => {
            handleChangeDesign({
              flexDirectionD: "row-reverse",
              alignItemsD: "center",
              alignItemsT: "center",
            });
            setCurrentBox(4);
          }}
        />
      </div>

      {/* =====================sliders======================= */}

      <div>
        <span>Size</span>
      </div>
      <div className="border-control">
        <input
          type="range"
          min="1"
          max="300"
          value={ImgSize}
          onChange={(e) => handleImgSizeChange(e.target.value)}
          style={{ width: "140%" }}
          className="size-slider"
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={ImgSize}
                size="small"
                onChange={(e) => handleImgSizeChange(e.target.value)}
                inputProps={{
                  step: 3,
                  min: 0,
                  max: 300,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <div>
        <span>Corner</span>
      </div>
      <div className="border-control">
        <input
          type="range"
          min="0"
          max="50"
          value={borderRadius}
          onChange={(e) => handleBorderRadiusChange(e.target.value)}
          style={{ width: "140%" }}
          className="size-slider"
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={borderRadius}
                size="small"
                onChange={(e) => handleBorderRadiusChange(e.target.value)}
                inputProps={{
                  step: 3,
                  min: 0,
                  max: 50,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <div>
        <span>OutLine</span>
      </div>
      <div className="border-control">
        <input
          type="range"
          min="0"
          max="9"
          value={borderSize}
          onChange={(e) => handleBorderSizeChange(e.target.value)}
          style={{ width: "140%" }}
          className="size-slider"
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={borderSize}
                size="small"
                onChange={(e) => handleBorderSizeChange(e.target.value)}
                inputProps={{
                  step: 3,
                  min: 0,
                  max: 9,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Profile;
