import React from "react";

import { Button, Input } from "@mui/joy";
import deleteIcon from "../../../assets/Image/delete.PNG";
import Base_Url, { Apipassword, axiosRequset } from "../../../Api/ApiUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "../../styles/ThemeContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const HeaderText = ({
  data,
  onDelete,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  // const onDelete = (event) => {
  //   event.stopPropagation();
  //   console.log("delete");
  // };
  const [values, setValues] = useState();

  useEffect(() => {
    setValues(data.title);
  }, []);
  const handleChange = (event) => {
    setValues(event.target.value);
  };
  const submitData = (event) => {
    // event.preventDefault();
    axiosRequset
      .post("/owner/Content/update", {
        id: data.id,
        title: values,
        type: data.type,
      })
      .then((res) => {
        console.log(res.data);
        setCurrentTheme({
          ...currentTheme,
          // Contans: res.data.Information.Contans,
          Contents: res.data.Information.Contents,
          // orders: res.data.Information.orders,
        });
        toast.success("Added Successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <div>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Header Text </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginX: "15px", position: "relative", width: "100%" }}>
              <Box sx={{ marginTop: "80px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "30px",
                      marginTop: "10px",
                      color: "#19609C",
                    }}
                  >
                    Headet Text
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1.5px",
                    backgroundColor: "#686868",
                    marginY: "15px",
                  }}
                ></Box>

                {/* start icons details  */}
                <Box
                  sx={{
                    marginTop: "30px",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Input
                    size="md"
                    placeholder="Create Yourself "
                    sx={{
                      marginTop: "15px",
                      boxShadow: ".5px .5px .5px .5px #19609C",
                      width: "90%",
                    }}
                    value={values}
                    onChange={handleChange}
                  />
                  {/* <Box component='img' src={deleteIcon} sx={{width:'15px', height:'25px', marginLeft:'6px', position:'absolute', right:'10px', top:'5px'}}/> */}
                </Box>
                {/* end icons details  */}
              </Box>

              {/* start save  */}

              <Button
                size="md"
                sx={{
                  width: "80%",
                  marginTop: "20px",
                  background:
                    "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                  height: "57px",
                  display: "block",
                  margin: "50px auto",
                }}
                onClick={() => submitData()}
              >
                Save
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default HeaderText;
