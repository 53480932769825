import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useTheme } from "../../../styles/ThemeContext";

const swatchStyle = {
  width: "26px",
  height: "26px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
      cyan 0%,
      palegoldenrod 16.67%,
      lime 33.33%,
      red 50%,
      blue 66.67%,
      yellow 83.33%,
      orange 100%
      )`,
  cursor: "pointer",
};

const styles = reactCSS({
  default: {
    color: swatchStyle, // Apply the modified swatch style here
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
});

// const PicheaderStyle = {
//   color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
//   background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
// };

const Fonts = () => {
  const { currentTheme, toggleTheme, setCurrentTheme } = useTheme();
  const [currentFont, setCurrentFont] = useState();
  const [currentColor, setCurrentColor] = useState();
  // ***************Picker*****************************

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // handle Change object in object
  const handleChangeObject = (d) => {
    // console.log(d, currentTheme);
    setCurrentTheme({
      ...currentTheme,
      Container: { ...currentTheme.Container, ...d },
      //   colors:{...currentTheme.colors, ...d},
    });
  };

  const handleChange = (newColor) => {
    setColor(newColor);
    // console.log(newColor);
    handleChangeColor(newColor.hex);
  };
  const toSetNewColor = (newColor) => {
    if (newColor.hex) {
      setColor(newColor.hex);
      return;
    }
    setColor(newColor);
  };
  const handleChangeColor = (newColor) => {
    // toSetNewColor(newColor)
    // setColor(newColor.rgb);
    // handleChangeObject({generalText: newColor.hex })
    console.log(newColor);
    // if (newColor.hex) {

    // setColor(newColor);
    // setCurrentTheme({
    //     ...currentTheme,
    //     Container:{...currentTheme.Container, color: newColor.hex},
    //   })
    //   return
    // }
    setCurrentTheme({
      ...currentTheme,
      Contact: { ...currentTheme.Contact, color: newColor },
    });
    console.log(currentTheme);
  };

  // ====================================================================
  const PicColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  return (
    <div className="div-fonts">
      {/* <div className="" style={{ fontSize: "20px" }}>
        Fonts
      </div> */}

      <div>
        <div className="font-type-header">basic</div>

        <span
          className="font-type"
          style={{
            fontFamily: "Young Serif",
            // fontWeight: "oblique",
            fontSize: currentFont == "Young Serif" ? "30px" : "",
            fontWeight: currentFont == "Young Serif" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "Young Serif" });
            setCurrentFont("Young Serif");
          }}
        >
          Aa
        </span>
        <span
          className="font-type"
          style={{
            fontFamily: "Roboto",
            fontSize: currentFont == "Roboto" ? "30px" : "",
            fontWeight: currentFont == "Roboto" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "Roboto" });
            setCurrentFont("Roboto");
          }}
        >
          Aa
        </span>

        <span
          className="font-type"
          style={{
            fontFamily: "Barlow",
            fontSize: currentFont == "Barlow" ? "30px" : "",
            fontWeight: currentFont == "Barlow" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "Barlow" });
            setCurrentFont("Barlow");
          }}
        >
          Aa
        </span>
        <span
          className="font-type"
          style={{
            fontFamily: " Dancing Script ",
            fontSize: currentFont == "Dancing Script" ? "30px" : "",
            fontWeight: currentFont == "Dancing Script" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: " Dancing Script, cursive" });
            setCurrentFont("Dancing Script");
          }}
        >
          Aa
        </span>
        <span
          className="font-type"
          style={{
            fontFamily: " Fjalla One ",
            fontSize: currentFont == "Fjalla One" ? "30px" : "",
            fontWeight: currentFont == "Fjalla One" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "Fjalla One, sans-serif " });
            setCurrentFont("Fjalla One");
          }}
        >
          Aa
        </span>
      </div>

      <div>
        <div className="font-type-header">unique</div>

        <span
          className="font-type"
          style={{
            fontFamily: "cursive",
            fontSize: currentFont == "cursive" ? "30px" : "",
            fontWeight: currentFont == "cursive" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "cursive" });
            setCurrentFont("cursive");
          }}
        >
          Aa
        </span>

        <span
          className="font-type"
          style={{
            fontFamily: "fantasy",
            fontSize: currentFont == "fantasy" ? "30px" : "",
            fontWeight: currentFont == "fantasy" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "fantasy" });
            setCurrentFont("fantasy");
          }}
        >
          Aa
        </span>

        <span
          className="font-type"
          style={{
            fontFamily: "sans-serif",
            fontSize: currentFont == "sans-serif" ? "30px" : "",
            fontWeight: currentFont == "sans-serif" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "sans-serif" });
            setCurrentFont("sans-serif");
          }}
        >
          Aa
        </span>

        <span
          className="font-type"
          style={{
            fontFamily: "Akronim",
            fontSize: currentFont == "Akronim" ? "30px" : "",
            fontWeight: currentFont == "Akronim" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({
              fontFamily: "Akronim",
            });
            setCurrentFont("Akronim");
          }}
        >
          Aa
        </span>

        <span
          className="font-type"
          style={{
            fontFamily: "'Aguafina Script', cursive",
            fontSize: currentFont == "'Aguafina Script', cursive" ? "30px" : "",
            fontWeight:
              currentFont == "'Aguafina Script', cursive" ? "800" : "",
          }}
          onClick={() => {
            handleChangeObject({ fontFamily: "'Aguafina Script', cursive" });
            setCurrentFont("'Aguafina Script', cursive");
          }}
        >
          Aa
        </span>
      </div>

      <div>
        <div className="font-type-header">Text Color</div>
        <div className="font-type-cir-cont">
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>

          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker color={color} onChange={handleChange} />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              background: PicColor,
              border: currentColor === 1 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor(PicColor);
              setCurrentColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              // border: "1px solid #eee",
              // border: `5px solid ${
              //   currentColor === 2 ? console.log("inherit") : "none"
              // }`,
              border:
                currentColor === 2
                  ? "3px solid rgb(0, 122, 255)"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeColor("#FFFFFF");
              setCurrentColor(2);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border: currentColor === 3 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("black");
              setCurrentColor(3);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border: currentColor === 4 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("gray");
              setCurrentColor(4);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border: currentColor === 5 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("blue");
              setCurrentColor(5);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border: currentColor === 6 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("brown");
              setCurrentColor(6);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border: currentColor === 7 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("red");
              setCurrentColor(7);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border: currentColor === 8 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("green");
              setCurrentColor(8);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border: currentColor === 9 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#FFE5C1");
              setCurrentColor(9);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border: currentColor === 10 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#E38CBA");
              setCurrentColor(10);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border: currentColor === 11 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#19609C");
              setCurrentColor(11);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border: currentColor === 12 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#51756B");
              setCurrentColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border: currentColor === 13 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#897046");
              setCurrentColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border: currentColor === 14 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#FFE5E7");
              setCurrentColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border: currentColor === 15 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeColor("#EFE5C1");
              setCurrentColor(15);
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default Fonts;
