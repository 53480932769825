import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  TextField,
} from "@mui/material";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const AddFieldPopUp = ({
  show,
  setShow,
  data,
  heandelAddField,
  values,
  setValues,
  pupUpTitle,

  currentTheme,
  setCurrentTheme,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //   const [allinputType, setallInputType] = React.useState([
  //     'text','number','date','time','textarea','select','checkbox','file'

  //   ]);
  const handleClose = () => setShow(false);
  const handleDelete = () => {
    //     axiosRequset
    //       .post(url, data)
    //       .then((res) => {
    //         toast.success("Deleted Successfully");
    //         //
    //       })
    //       .then((res) => {
    //         axiosRequset
    //           .post("/owner/Information/get", {
    //             id: currentTheme.id,
    //           })
    //           .then((res) => {
    //             console.log(res.data);
    //             setCurrentTheme({
    //               ...res.data.Information.object,
    //               public_id: res.data.link.public_id,
    //               img: res.data.Information.image,
    //               id: res.data.Information.id,
    //               Contents: res.data.Information.Contents,
    //               orders: res.data.Information.orders,
    //               data: {
    //                 title: res.data.Information.title,
    //                 desc: res.data.Information.description,
    //               },
    //             });
    //             //   setError(false);
    //             // toast.success("Added Successfully");
    //             setShow(false);
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             //   setError(true);
    //             toast.error("update went wrong");
    //           });
    //       })
    //       .catch((error) => {
    //         toast.error("Something went wrong");
    //       });
  };
  //   useEffect(() => {
  //     if (show) {
  //       setValues({});
  //     }
  //   }, [show]);
  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"> {pupUpTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "red" }}>
            <div
              style={{
                width: "400px",
                height: "150px",
              }}
            >
              <div style={{ padding: "10px" }}>
                <TextField
                  id="standard-basic"
                  label="title"
                  variant="standard"
                  style={{
                    width: "100%",
                  }}
                  value={values.title}
                  onChange={(e) =>
                    setValues({ ...values, title: e.target.value })
                  }
                />
                <br /> <br />
                {values.id == "submit" && (
                  <TextField
                    id="standard-basic"
                    label="thanks message"
                    variant="standard"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Enter thanks message"
                    value={values.message}
                    name="message"
                    onChange={(e) =>
                      setValues({ ...values, message: e.target.value })
                    }
                  />
                )}{" "}
                <br />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Required field"
                  onChange={(e) =>
                    setValues({ ...values, required: e.target.checked })
                  }
                />
              </div>
            </div>
            <Button
              size="md"
              sx={{
                width: "80%",
                marginTop: "50px",
                background:
                  "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                height: "57px",
                marginLeft: "40px",
                color: "#fff",
                fontStyle: "bold",
              }}
              onClick={() => heandelAddField(values)}
            >
              Save
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button> */}
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddFieldPopUp;
