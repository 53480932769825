import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useTheme } from "../../../../styles/ThemeContext";
const Color = () => {
  const { currentTheme, toggleTheme, setCurrentTheme } = useTheme();
  const [currentColor, setCurrentColor] = useState();
  // ========================color-picker==============================
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // handle Change object in object
  const handleChangeObject = (d) => {
    console.log(d, currentTheme);
    setCurrentTheme({
      ...currentTheme,
      Container: { ...currentTheme.Container, backgroundImage: "", ...d },
    });
  };

  const handleChange = (newColor) => {
    setColor(newColor);
    handleChangeObject({ background: newColor.hex });
  };

  const swatchStyle = {
    width: "26px",
    height: "26px",
    display: "block",
    border: "4px solid var(--gray-color-200)",
    borderRadius: "2rem",
    margin: "7px 0px 6px 10px",
    background: `conic-gradient(
      cyan 0%,
      palegoldenrod 16.67%,
      lime 33.33%,
      red 50%,
      blue 66.67%,
      yellow 83.33%,
      orange 100%
    )`,
    cursor: "pointer",
  };

  const styles = reactCSS({
    default: {
      color: swatchStyle, // Apply the modified swatch style here
      popover: {
        position: "absolute",
        zIndex: "2",
        top: "56%",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const PicheaderStyle = {
    color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  };

  const PicColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  // ===================================================

  return (
    <div>
      <div>
        <div className="font-type-header">Gradient</div>
        <div className="font-type-cir-cont">
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>

          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker color={color} onChange={handleChange} />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              ...PicheaderStyle,
              border: currentColor === 1 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: PicColor });
              setCurrentColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              border:
                currentColor === 2
                  ? "3px solid rgb(0, 122, 255)"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeObject({ background: "#ffff" });
              setCurrentColor(2);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border: currentColor === 3 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "black" });
              setCurrentColor(3);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border: currentColor === 4 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "gray" });
              setCurrentColor(4);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border: currentColor === 5 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "blue" });
              setCurrentColor(5);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border: currentColor === 6 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "brown" });
              setCurrentColor(6);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border: currentColor === 7 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "red" });
              setCurrentColor(7);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border: currentColor === 8 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "green" });
              setCurrentColor(8);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border: currentColor === 9 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#FFE5C1" });
              setCurrentColor(9);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border: currentColor === 10 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#E38CBA" });
              setCurrentColor(10);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border: currentColor === 11 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#19609C" });
              setCurrentColor(11);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border: currentColor === 12 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#51756B" });
              setCurrentColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border: currentColor === 13 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#897046" });
              setCurrentColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border: currentColor === 14 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#FFE5E7" });
              setCurrentColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border: currentColor === 15 ? "3px solid rgb(0, 122, 255)" : "",
            }}
            onClick={() => {
              handleChangeObject({ background: "#EFE5C1" });
              setCurrentColor(15);
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default Color;
