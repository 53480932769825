import React, { useState } from "react";
import HeaderT from "./HeaderT";
import ButtonsT from "./ButtonsT";
import VideoT from "./VideoT";
import SocialIcons from "./SocialIcons";
import ContactUsT from "./ContactUsT";
import YoutubSubscribe from "./YoutubSubscribe";
import FacebookPageT from "./FacebookPageT";
import PodcastT from "./PodcastT";
import RequestT from "./RequestT";
const MainTemplat = ({ componentName, data }) => {
  console.log(data);
  // Map component names to actual components
  //   const [showPopUpDelete, setShowPopUpDelete] = useState();
  //   const [dataDlt, setDataDlt] = useState({});
  // console.log(componentName, data);
  const componentMap = {
    header: HeaderT,
    buttons: ButtonsT,
    video: VideoT,
    soial: SocialIcons,
    form: ContactUsT,
    youtub: YoutubSubscribe,
    facebook: FacebookPageT,
    podcast: PodcastT,
    request: RequestT,
    // Add more dynamic components as needed
  };

  // Get the component based on the provided name
  const DynamicComponent = componentMap[componentName];

  // Render the dynamically selected component
  return DynamicComponent ? (
    <div>
      <DynamicComponent
        data={data}
        // onDelete={onDelete}
        // currentTheme={currentTheme}
        // setCurrentTheme={setCurrentTheme}
      />
    </div>
  ) : (
    <div>we are working on it</div>
  );
};

export default MainTemplat;
