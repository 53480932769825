import React from "react";
import { Button, Input } from "@mui/joy";
import twiter from "../../../assets/Image/twittersocial.PNG";
import deleteIcon from "../../../assets/Image/delete.PNG";
import Base_Url, { Apipassword, axiosRequset } from "../../../Api/ApiUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

import youtube from "../../../assets/Images/4362951_youtube_logo_social media_icon.svg";
import tiktok from "../../../assets/Images/7024783_tiktok_social media_icon.svg";
import { useTheme } from "../../styles/ThemeContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
const VideoComponent = ({
  data,
  onDelete,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  const [urlVedio, setUrlVedio] = useState();

  // const onDelete = (event) => {
  //   event.stopPropagation();
  //   console.log("delete");
  // };
  useEffect(() => {
    setUrlVedio(data.link);
  }, []);
  const handleChange = (event) => {
    setUrlVedio(event.target.value);
  };

  const handelUpdateVideo = () => {
    axiosRequset
      .post("/owner/Content/update", {
        link: urlVedio,
        id: data.id,
        type: "video",
      })
      .then((res) => {
        if (res.data) {
          setCurrentTheme({
            ...currentTheme,
            Contents: res.data.Information.Contents,
          });
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <div>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Video </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginX: "15px", position: "relative" }}>
              <Box sx={{ marginTop: "50px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "20px",
                      marginTop: "10px",
                      color: "#19609C",
                    }}
                  >
                    Video
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1.5px",
                    backgroundColor: "#787878",
                    marginY: "15px",
                  }}
                ></Box>

                <Box sx={{ marginTop: "30px" }}>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "15px",
                      marginTop: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    Video From{" "}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "10px",
                      marginLeft: "17px",
                    }}
                  >
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{ width: "30px" }}
                    />
                    <img
                      src={twiter}
                      alt="twitter"
                      style={{ width: "30px", marginLeft: "15px" }}
                    />
                    <img
                      src={tiktok}
                      alt="tiktok"
                      style={{ width: "30px", marginLeft: "15px" }}
                    />
                  </Box>

                  <Box sx={{ marginTop: "20px", marginLeft: "20px" }}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "15px", marginTop: "10px" }}
                    >
                      Video Url{" "}
                    </Typography>
                    <Input
                      size="md"
                      placeholder="https://www.youtube.com/watch?v=JkjPkX-2HQ8"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "100%",
                        // marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={handleChange}
                      value={urlVedio}

                      //   value={title}
                    />
                  </Box>
                </Box>

                <Button
                  size="md"
                  sx={{
                    width: "90%",
                    marginTop: "50px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "57px",
                    margin: "50px auto",
                    display: "block",
                  }}
                  onClick={handelUpdateVideo}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default VideoComponent;
