import React, { useEffect, useState } from "react";
import {
  Edit,
  Delete,
  NotificationImportant,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import ShowChart from "../assets/Images/svg_icons/chart-histogram.svg";
import Notification from "../assets/Images/svg_icons/cowbell.svg";
import Share from "../assets/Images/svg_icons/share.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "../components/styles/ThemeContext";
import { useMyTemplateContext } from "../components/styles/MytempletContext";
import ShareBox from "../components/MyLinks/ShareBox";
import Base_Url, { Apipassword, axiosRequset } from "../Api/ApiUrl";
import { toast } from "react-toastify";
import EditImage from "../assets/seting/edit.svg";
import DeleteImage from "../assets/seting/crach.svg";
import NotificationImage from "../assets/seting/Notifiy.svg";
import view from "../assets/seting/view.svg";
import ShareIcon from "../assets/seting/shear.svg";
import imageThierd from "../assets/seting/thierd.svg";
const MyLinksPage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [theLink, setTheLink] = useState("");
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(setIsPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  // ======================share===========================

  // const [showModal, setShowModal] = useState(false);
  const title = "Check out this amazing website!";

  // const handleShareClick = (platform) => {
  //   let shareUrl = "";

  //   switch (platform) {
  //     case "facebook":
  //       shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //         shareUrl
  //       )}`;
  //       break;
  //     case "linkedin":
  //       shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
  //         shareUrl
  //       )}`;
  //       break;
  //     case "whatsapp":
  //       shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
  //         title + " " + shareUrl
  //       )}`;
  //       break;
  //     // Add more cases for other platforms
  //     default:
  //       break;
  //   }

  //   window.open(shareUrl, "Share on " + platform, "width=600,height=400");
  //   setShowModal(false);
  // };
  // ============================================================
  // const [rows, setRows] = useState([
  //   { id: new Date().getTime(), name: "Row 1" },
  //   { id: new Date().getTime() + 1, name: "Row 2" },
  //   { id: new Date().getTime() + 2, name: "Row 3" },
  // ]);
  const [rows, setRows] = useState([]);

  // const [showEditModal, setShowEditModal] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowName, setEditRowName] = useState("");
  const [loding, setLoding] = useState(true);

  const { currentTheme, setCurrentTheme, toggleTheme } = useTheme();
  const {
    myTemplate,
    deleteTemplate,
    setTemplate,
    getTemplate,
    updateTemplate,
  } = useMyTemplateContext();
  useEffect(() => {
    axiosRequset.get("/owner/links/MyLinks").then((res) => {
      console.log(res);
      setRows(res.data.Links.data);
      setLoding(false);
    });
  }, []);

  const deleteRow = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");

        Base_Url.post(
          "/owner/Information/delete",
          {
            id,
          },
          {
            headers: {
              Apipassword: Apipassword,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
          .then((res) => {
            console.log(res);
            console.log(id);
            setRows((prevRows) => prevRows.filter((r) => r.id != id));
            toast.success("deleted Successfully");
          })
          .catch((error) => {});
        // deleteTemplate(id);
      }
    });
  };

  // ================
  const [linkOption, setlinkOption] = React.useState(10);

  const handleChange = (event) => {
    setlinkOption(event.target.value);
  };

  const handleEdeit = (item) => {
    console.log(item);
    setCurrentTheme({
      ...item.Information.object,
      public_id: item.public_id,
      id: item.id,
      img: item.Information.image,
      // Contents: item.Information.Contents,
      data: {
        title: item.Information.title ? item.Information.title : "title",
        desc: item.Information.description
          ? item.Information.description
          : "desc",
      },
    });

    navigate(`/dashboard/MyLinks/Customize/${item.id}`);
  };
  useEffect(() => {
    document.body.style.background = "#f5f7fc";
  }, []);
  return (
    <div>
      <div className="title"> Home</div>

      <div className="main-cont">
        <div className="my-links-cont">
          <div className="my-links-brief">
            <div className="right-column">
              <div className="sub-column text1">All links</div>
              <div className="sub-column links-count">{rows.length}</div>
            </div>
            <div className="center-column"></div>

            <div className="left-column">
              {/* <div className={`left-column ${isDropdownOpen ? 'dropdown' : ''}`} onClick={toggleDropdown}>
              <div className="sub-column dropdown">
                Last updates
                {isDropdownOpen && (
                  <ul className="dropdown-content">
                    <li className="drop-option">Option 1</li>
                    <li className="drop-option">Option 2</li>
                    <li className="drop-option">Option 3</li>
                  </ul>
                )}
              </div>
            </div> */}

              <FormControl fullWidth className="">
                <Select
                  value={linkOption}
                  onChange={handleChange}
                  className="option-list"
                >
                  <MenuItem value={10}>Last Update</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <Link to={"/dashboard/Templates"}>
                <button className="sub-column creat-link">Create Link</button>
              </Link>
            </div>
          </div>
        </div>

        {rows.length > 0 &&
          rows.map((row) => (
            <div className="link-card" key={row.id}>
              <div className="first-div-card">
                <img
                  src={row.Information.image}
                  // src="https://picsum.photos/300/200"
                  alt="Business consultants meeting"
                  width="300"
                  height="200"
                />
                <p>
                  {" "}
                  Link Page :<h4>{row.Information.title}</h4>
                  {"https://fastworldkw.net/"}
                  {row.public_id}
                  <br />
                  {/* linkfly.to/50907M83sDs */}{" "}
                  {row.Information.created_at.split("T")[0]}{" "}
                  {row.Information.created_at.split("T")[1].split(".")[0]}
                </p>
              </div>
              <div className="second-div-card">
                <div>{row.visits}</div>
                {/* <div>{row.visits}</div> */}
                <div>visits</div>
              </div>
              <div className="third-div-card">
                {/* <Link to={"./Customize"}> */}
                <div className="icon-card">
                  <svg
                    onClick={() => {
                      handleEdeit(row);
                    }}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      r="19.5"
                      fill="white"
                      stroke="#037F9A"
                    />
                    <path
                      d="M12.4999 28.5C13.3299 29.33 14.6699 29.33 15.4999 28.5L28.4999 15.5C29.3299 14.67 29.3299 13.33 28.4999 12.5C27.6699 11.67 26.3299 11.67 25.4999 12.5L12.4999 25.5C11.6699 26.33 11.6699 27.67 12.4999 28.5Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M27.01 16.99L24.01 13.99"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 10.44L19 10L18.56 11.5L19 13L17.5 12.56L16 13L16.44 11.5L16 10L17.5 10.44Z"
                      fill="#2C95FE"
                      stroke="#2C95FE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.5 16.44L15 16L14.56 17.5L15 19L13.5 18.56L12 19L12.44 17.5L12 16L13.5 16.44Z"
                      fill="#FF5353"
                      stroke="#FF5353"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28.5 21.44L30 21L29.56 22.5L30 24L28.5 23.56L27 24L27.44 22.5L27 21L28.5 21.44Z"
                      fill="#037F9A"
                      stroke="#037F9A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                {/* </Link> */}
                <Link to={"/dashboard/Notification/"}>
                  <div className="icon-card">
                    {/* <NotificationImportant /> */}
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#037F9A"
                      />
                      <path
                        d="M20 13.44V16.77"
                        stroke="#037F9A"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M20.0199 9C16.3399 9 13.3599 11.98 13.3599 15.66V17.76C13.3599 18.44 13.0799 19.46 12.7299 20.04L11.4599 22.16C10.6799 23.47 11.2199 24.93 12.6599 25.41C17.4399 27 22.6099 27 27.3899 25.41C28.7399 24.96 29.3199 23.38 28.5899 22.16L27.3199 20.04C26.9699 19.46 26.6899 18.43 26.6899 17.76V15.66C26.6799 12 23.6799 9 20.0199 9Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M23.3299 25.82C23.3299 27.65 21.8299 29.15 19.9999 29.15C19.0899 29.15 18.2499 28.77 17.6499 28.17C17.0499 27.57 16.6699 26.73 16.6699 25.82"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                </Link>
                <Link to={"./Insight/"}>
                  <div className="icon-card">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#037F9A"
                      />
                      <path
                        d="M10 9V26C10 27.66 11.34 29 13 29H30"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13 24L17.59 18.64C18.35 17.76 19.7 17.7 20.52 18.53L21.47 19.48C22.29 20.3 23.64 20.25 24.4 19.37L29 14"
                        stroke="url(#paint0_linear_165_143)"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_165_143"
                          x1="21"
                          y1="14"
                          x2="21"
                          y2="24"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0070FC" />
                          <stop offset="1" stop-color="#B63A3A" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </Link>
                <a
                  href={`https://fastworldkw.net/${row.public_id}`}
                  target="_blank"
                >
                  <div className="icon-card">
                    {/* <RemoveRedEyeOutlined /> */}
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#037F9A"
                      />
                      <path
                        d="M23.5799 19C23.5799 20.98 21.9799 22.58 19.9999 22.58C18.0199 22.58 16.4199 20.98 16.4199 19C16.4199 17.02 18.0199 15.42 19.9999 15.42C21.9799 15.42 23.5799 17.02 23.5799 19Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.0001 27.27C23.5301 27.27 26.8201 25.19 29.1101 21.59C30.0101 20.18 30.0101 17.81 29.1101 16.4C26.8201 12.8 23.5301 10.72 20.0001 10.72C16.4701 10.72 13.1801 12.8 10.8901 16.4C9.99009 17.81 9.99009 20.18 10.8901 21.59C13.1801 25.19 16.4701 27.27 20.0001 27.27Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </a>
                <Link to="">
                  <div className="icon-card">
                    <svg
                      onClick={() => {
                        setTheLink(`https://fastworldkw.net/${row.public_id}`);
                        setIsPopupOpen(true);
                      }}
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#037F9A"
                      />
                      <path
                        d="M24.96 13.17C26.96 14.56 28.34 16.77 28.62 19.32"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.49 19.37C11.75 16.83 13.11 14.62 15.09 13.22"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.1899 27.94C17.3499 28.53 18.6699 28.86 20.0599 28.86C21.3999 28.86 22.6599 28.56 23.7899 28.01"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.06 14.7C21.5954 14.7 22.84 13.4554 22.84 11.92C22.84 10.3846 21.5954 9.14 20.06 9.14C18.5247 9.14 17.28 10.3846 17.28 11.92C17.28 13.4554 18.5247 14.7 20.06 14.7Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.83 26.92C14.3654 26.92 15.61 25.6754 15.61 24.14C15.61 22.6046 14.3654 21.36 12.83 21.36C11.2947 21.36 10.05 22.6046 10.05 24.14C10.05 25.6754 11.2947 26.92 12.83 26.92Z"
                        stroke="#037F9A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M27.1699 26.92C28.7052 26.92 29.9499 25.6754 29.9499 24.14C29.9499 22.6046 28.7052 21.36 27.1699 21.36C25.6345 21.36 24.3899 22.6046 24.3899 24.14C24.3899 25.6754 25.6345 26.92 27.1699 26.92Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="_01_align_center" data-name="01 align center">
                        <path d="M19.333,14.667a4.66,4.66,0,0,0-3.839,2.024L8.985,13.752a4.574,4.574,0,0,0,.005-3.488l6.5-2.954a4.66,4.66,0,1,0-.827-2.643,4.633,4.633,0,0,0,.08.786L7.833,8.593a4.668,4.668,0,1,0-.015,6.827l6.928,3.128a4.736,4.736,0,0,0-.079.785,4.667,4.667,0,1,0,4.666-4.666ZM19.333,2a2.667,2.667,0,1,1-2.666,2.667A2.669,2.669,0,0,1,19.333,2ZM4.667,14.667A2.667,2.667,0,1,1,7.333,12,2.67,2.67,0,0,1,4.667,14.667ZM19.333,22A2.667,2.667,0,1,1,22,19.333,2.669,2.669,0,0,1,19.333,22Z" />
                      </g>
                    </svg>
                  </div>
                </Link>

                <div className="icon-card" onClick={() => deleteRow(row.id)}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      r="19.5"
                      fill="white"
                      stroke="#037F9A"
                    />
                    <path
                      d="M29 12.98C25.67 12.65 22.32 12.48 18.98 12.48C17 12.48 15.02 12.58 13.04 12.78L11 12.98"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.5 11.97L16.72 10.66C16.88 9.71 17 9 18.69 9H21.31C23 9 23.13 9.75 23.28 10.67L23.5 11.97"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26.8499 16.14L26.1999 26.21C26.0899 27.78 25.9999 29 23.2099 29H16.7899C13.9999 29 13.9099 27.78 13.7999 26.21L13.1499 16.14"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.3301 23.5H21.6601"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 19.5H22.5"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        {loding && <h1>Loading...</h1>}
        {!loding && rows.length == 0 && (
          <h1 style={{ textAlign: "center", marginTop: "2rem" }}>No links X</h1>
        )}
        {isPopupOpen && (
          <ShareBox
            open={isPopupOpen}
            setOpen={setIsPopupOpen}
            closePopup={closePopup}
            link={theLink}
          />
        )}
      </div>
    </div>
  );
};

export default MyLinksPage;
