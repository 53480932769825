import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { ArrowBack, ArrowRightAltRounded, KeyboardArrowDownOutlined } from '@mui/icons-material';

const data = [
  {
    date: '08-07',
    Users: 0,

  },
  {
    date: '08-08',
    Users: 1,

  },
  {
    date: '08-09',
    Users: 0.5,

  },
  {
    date: '08-10',
    Users: 0,

  },
  {
    date: '08-11',
    Users: 2,

  },
  {
    date: '08-12',
    Users: 0,

  },
  {
    date: '08-13',
    Users: 1.5,

  },
  {
    date: '08-14',
    Users: 0,

  },



];


export default function UserChart() {

  const totalUsers = data.reduce((acc, entry) => acc + entry.Users, 0);
  const lastElement = data.length - 1;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(setIsPopupOpen);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <div>

      <div className='chart-title-cont'>

        <div className='chart-title'>Users <br />{totalUsers}</div>
        <div className='chart-info' onClick={openPopup.bind(this)}>
          <span> {`${data[0].date}- ${data[lastElement].date}`}</span>
          <KeyboardArrowDownOutlined />
        </div>

      </div>

      <BarChart
        width={550}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Bar dataKey="Users" fill="var(--green-color-200)" />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
      </BarChart>


      {isPopupOpen && (
        <div className='overlay' onClick={closePopup}>
          <div className='chart-info-breif'>
            <div style={{ fontSize: '3rem' }}>Users Breif</div>
            {data.map((entry, index) => (
              <div className=''>
                <div key={index} className='info-data'>
                  {`${entry.date}: ${entry.Users}`}
                </div>
                <hr className='info-hr' />

              </div>

            ))}
          </div>
        </div>
      )}

    </div>
  );
}
