import React from 'react'
import "./hero.css"
import ClientImage from "../../../assets/client-image.png"
import laptops from "../../../assets/laptops.png"
import image1 from "../../../assets/julia-m-cameron.png"
const Hero = () => {
  return (
    <div className="hero-section">
        <div className="container">
            <div className="row d-flex justify-content-space-between">
                <div className="col-md-6 col-sm-12">
                    <div className="hero-text">
                        <h1>Everything you are. In one, simple link in Fast World.</h1>
                        <p>Combine all your content into one link. Share all your social media profiles and important links at once in a beautiful way and track its performance .</p>
                    
                        <button className="btn1">fastworld.net/yourname</button>
                        <button className="btn2">Claim Your Link</button>
                     
                    </div>
                </div>
                <div className="col-md-6  col-sm-12">
                  <div className="hero-content">
                    <div className="large-cart">
                     <div className="inner-cart">
                        <div className="info">
                            <img src={ClientImage} alt="" />
                            <h2>Mohamed A.Khaled</h2>
                            <p>Software Engineer</p>
                        </div>
                     </div>
                     <div className="outer-cart">
                      <ul className='links-outer'>
                        <li>My Articles</li>
                        <li>Certificates</li>
                        <li>My Curriculum Vitae</li>
                      </ul>
                    
                     </div>
                    </div>
                    <div className="small-cart">
                        <img src={image1} alt="" />
                        <div className="dots">
                            <span></span><span></span><span></span>
                        </div>
                           <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English .</p>
                    </div>
                    <img src={laptops} alt="" className="laptop2" />
                  </div>
                  <div className="social">
                      <i className="fa-brands fa-linkedin"></i>
                      <i className="fa-brands fa-facebook"></i>
                      <i className="fa-brands fa-square-x-twitter"></i>
                      </div>
                </div>
            </div>
        </div>


    </div>
  )
}

export default Hero