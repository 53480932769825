import { useEffect, useState } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";

import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/joy";

import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import CForm from "./CForm";
const PopUpButtonOpenForm = ({ show, setShow, handleSave, d, setText }) => {
  //   const [d, setD] = useState();
  const handleClose = () => {
    // setD();

    setShow(false);
  };
  let x1 = {
    fields: [
      { key: "input", title: "Name", required: 1 },
      { key: "email", title: "Email", required: 1 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Get on the list",
    submit: { message: "Thanks for submitting!", title: "Submit" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  let x2 = {
    fields: [
      { key: "input", title: "Full name", required: 1 },
      { key: "email", title: "Email", required: 1 },
      { key: "text", title: "Add a message...", required: 0 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Holler at us",
    submit: { message: "Thanks for submitting!", title: "Submit" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  useEffect(() => {}, []);

  //
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box className="scrollbar" sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "300px",
              }}
            >
              Add Block
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{ marginTop: "1px" }}>
            <Grid container spacing={2}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "20px",
                  overflow: "scroll",
                }}
              >
                <CForm data={d} handleSave={handleSave} setText={setText} />
              </div>
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default PopUpButtonOpenForm;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "689px",
  height: "720px",
  overflow: "scroll",
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
