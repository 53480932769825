import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";

// import icons and Image
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import { Lock } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useState } from "react";
import { axiosRequset } from "../../../Api/ApiUrl";

const DomainPopUp = ({ show, setShow, id, setLoding, loding }) => {
  const handleClose = () => setShow(false);
  const [values, setValues] = useState();
  const handleUpdate = () => {
    axiosRequset
      .post("/owner/links/update_name", { id, name: values })
      .then((res) => {
        console.log(res);
        setShow(false);
        toast.success("Updated Successfully");
        setLoding(!loding);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "30px",
              }}
            >
              Edit Link Url
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Box sx={{ display: "flex", marginTop: "40px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Fast.World.to/
            </Typography>
            <Input
              size="md"
              placeholder="50925zBwmv7 "
              sx={{
                marginTop: "0px",
                boxShadow: ".5px .5px .5px .5px #19609C",
                width: "95%",
                marginLeft: "5px",
              }}
              value={values}
              onChange={(e) => setValues(e.target.value)}
            />
          </Box>

          <Typography
            variant="h5"
            sx={{
              fontSize: "13px",
              fontWeight: "300",
              lineHeight: "27,24px",
              color: "black",
              marginLeft: "10px",
              marginTop: "17px",
            }}
          >
            https://Fast world.to/50925zBwMv7
          </Typography>

          {/* <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '300', lineHeight: '27,24px', color: 'black', marginLeft: '10px', marginTop: '26px' }}>Custom Domain</Typography>
          <Typography variant='h5' sx={{ fontSize: '16px', fontWeight: '300', lineHeight: '27,24px', color: 'black', marginLeft: '10px', marginTop: '26px' }}>Search below with a complete domain or with keywords.</Typography> */}

          {/* <Box sx={{ display: 'flex', marginTop: '30px' }}>
            <Input size="md" placeholder="50925zBwmv7" sx={{ marginTop: '3px', boxShadow: '.5px .5px .5px .5px #19609C', width: '85%', marginLeft: '5px', height: '40px' }} />
            <Box>
              <Button sx={{ width: '15%', marginTop: '5px', background: 'linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)', marginLeft: '20px', padding: '10px 20px', height: '40px', marginBottom: '10px' }}><SearchIcon sx={{ color: 'white', fontSize: '30px' }} /></Button>
            </Box>
          </Box> */}
          {/* <Typography variant='h5' sx={{ fontSize: '16px', fontWeight: '300', lineHeight: '27,24px', color: 'black', marginLeft: '10px', marginTop: '10px' }}>Already have any existing domain</Typography> */}

          {/* <Button sx={{ backgroundColor: '#B4B4B4', width: '100%', height: '45px', marginTop: '20px', textTransform: 'capitalize', color: 'white' }}>
            
            Connect Your Domain
            <Lock style={{left: '10rem' ,position: 'relative'}}/>
            </Button> */}

          {/* <Typography variant='h5' sx={{ fontSize: '16px', fontWeight: '300', lineHeight: '27,24px', color: 'black', marginLeft: '220px', marginTop: '20px' }}>Upgrade to premuim to use this feature</Typography> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "20px",
              marginTop: "30px",
              mx: "auto",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#B4B4B4",
                width: "30%",
                height: "40px",
                textTransform: "capitalize",
                color: "black",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "30%",
                background:
                  "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                // marginLeft: "20px",
                padding: "10px 20px",
                height: "40px",
                marginBottom: "10px",
                color: "white",
                textTransform: "capitalize",
              }}
              onClick={handleUpdate}
            >
              Save
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default DomainPopUp;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "500px",
  height: "540px",
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
