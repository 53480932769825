import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import done from '../../assets/Image/done.PNG'
import discount from '../../assets/Image/Capture.PNG'
const Monthly = () => {

const features1Coulom=[
    {title:'1 Link'},
    {title:'Themes customize'},
    {title:'Country & system retarigeting'},
    {title:'Thitd-party integrations'},
    {title:'Advanced link insights'},

]



const features1Coulon2=[
    {title:'PRO Themes'},
    {title:'Verified badge'},
    {title:'Scheduled link'},
    {title:'Remove Fast world logo'},
    {title:'Advanced settings'},

]



const features3Coulom=[
    {title:'10 Link'},
    {title:'Themes customize'},
    {title:'Country & system retarigeting'},
    {title:'Thitd-party integrations'},
    {title:'Advanced link insights'},

]



const features3Coulon2=[
    {title:'Affiliate ptograms'},
    {title:'Verified badge'},
    {title:'Scheduled link'},
    {title:'Remove Fast world logo'},
    {title:'Advanced settings'},

]



const [isHovering, setIsHovering] = useState(false);

const handleMouseEnter = () => {
  setIsHovering(true);
};

const handleMouseLeave = () => {
  setIsHovering(false);
};




  return (
<Box sx={{marginY:'20px', marginX:'70px'}}>

<Grid container>
<Grid item xs={12} md={12} lg={4} xl={4}>
  <Box sx={{width:'350px' , height:'293px',  boxShadow:"1px 1px 1px 1px #4192DE", borderRadius:'10px', marginLeft:'50px', marginTop:'50px', padding:'20px 20px'}}>

<Box>
<Typography component='p' sx={{fontSize:'22px' ,color:'black'}}>Free Trial</Typography>
<Typography component='p' sx={{fontSize:'20px' ,color:'black', fontWeight:'bold'}}>$0 <span style={{fontSize:'14px', fontWeight:'600', marginLeft:'10px'}}>USD/ 7day</span></Typography>

</Box>

<Box sx={{width:'100%', height:'80%'}}>

<Grid container spacing={4}>
<Grid item xs={6} md={6} lg={6} xl={6}>

  {features1Coulom.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}

</Grid>




<Grid item xs={6} md={6} lg={6} xl={6}>
{features1Coulon2.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}
</Grid>


<button   onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}
 style={{width:'112px', height:'40px',             backgroundColor: isHovering ? '#4192DE' : 'transparent',
 color: isHovering ? 'white' : '#4192DE',
border:'1px solid #4192DE', padding:'0px 10px', borderRadius:'5px', margin:'auto', marginTop:'30px', cursor:'pointer'


}}>Choose Plan</button>

</Grid>

</Box>

  </Box>

</Grid>









<Grid item xs={12} md={12} lg={4} xl={4}>

<Box sx={{width:'350px' , height:'293px',  boxShadow:"1px 1px 1px 1px #54B99B", borderRadius:'10px', marginLeft:'50px', marginTop:'50px', padding:'20px 20px'}}>

<Box>
<Typography component='p' sx={{fontSize:'22px' ,color:'black'}}>Personal Account</Typography>
<Typography component='p' sx={{fontSize:'20px' ,color:'black', fontWeight:'bold'}}>$4.99 <span style={{fontSize:'14px', fontWeight:'600', marginLeft:'10px'}}>USD/ 7day</span></Typography>

</Box>

<Box sx={{width:'100%', height:'80%'}}>

<Grid container spacing={4}>
<Grid item xs={6} md={6} lg={6} xl={6}>

  {features1Coulom.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}

</Grid>




<Grid item xs={6} md={6} lg={6} xl={6}>
{features1Coulon2.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}
</Grid>


<button onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave} style={{width:'112px', height:'40px',

backgroundColor: isHovering ? '#54B99B' : 'transparent',
color: isHovering ? 'white' : '#54B99B',

border:'1px solid #54B99B' ,padding:'0px 10px', borderRadius:'5px', margin:'auto', marginTop:'30px', cursor:'pointer'}}>Choose Plan</button>


</Grid>

</Box>

  </Box>


</Grid>




<Grid item xs={12} md={12} lg={4} xl={4}>
    <Box sx={{marginLeft:'300px'}}>
        <img src={discount} alt="" srcset="" />
    </Box>
<Box sx={{width:'350px' , height:'293px',  boxShadow:"1px 1px 1px 1px #5709EB", borderRadius:'10px', marginLeft:'50px', marginTop:'-30px', padding:'20px 20px'}}>

<Box>
<Typography component='p' sx={{fontSize:'22px' ,color:'black'}}>Company Account</Typography>
<Typography component='p' sx={{fontSize:'20px' ,color:'black', fontWeight:'bold'}}>$14.99 <span style={{fontSize:'14px', fontWeight:'600', marginLeft:'10px'}}>USD/ 7day</span></Typography>

</Box>

<Box sx={{width:'100%', height:'80%'}}>

<Grid container spacing={4}>
<Grid item xs={6} md={6} lg={6} xl={6}>

  {features3Coulom.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}

</Grid>




<Grid item xs={6} md={6} lg={6} xl={6}>
{features3Coulon2.map((item)=>(
      <Box sx={{display:'flex', marginTop:'10px'}}>
      <img src={done} alt="" style={{width:'10px'}}/>
      <Typography component='p' sx={{fontSize:'8px', marginLeft:'7px', fontWeight:'bold' }}> {item.title}</Typography>
      
          </Box>
  ))}
</Grid>


<button  onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave} style={{width:'112px', height:'40px', backgroundColor: isHovering ? '#5709EB' : 'transparent',
color: isHovering ? 'white' : '#5709EB', border:'1px solid #5709EB' ,padding:'0px 10px', borderRadius:'5px', margin:'auto', marginTop:'30px', cursor:'pointer',
}}>Choose Plan</button>

</Grid>

</Box>

  </Box>
</Grid>
</Grid>

</Box>

  )
}

export default Monthly
