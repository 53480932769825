import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import { styled } from "@mui/system";
import onlineStore1 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore1.png";
import onlineStore2 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore2.png";
import onlineStore3 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore3.png";
import onlineStore4 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore4.png";
import onlineStore6 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore6.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TemplateOnlineStore1,
  TemplateOnlineStore2,
  TemplateOnlineStore3,
  TemplateOnlineStore4,
  TemplateOnlineStore5,
} from "../styles/Theme.styled";
import { useTheme } from "../styles/ThemeContext";
import { axiosRequset } from "../../Api/ApiUrl";
import Swal from "sweetalert2";

const Template = () => {
  const [isHovering, setIsHovering] = useState(false);
  const { currentTheme, setCurrentTheme, toggleTheme } = useTheme();
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const handleMouseEnter = (id) => {
    setIsHovering(id);
  };

  const handleMouseLeave = (id) => {
    // console.log(id);
    setIsHovering(null);
  };

  // const AnimationParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const BusinessServicesParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const MusicParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const BeautyHairParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const HealthWellnessParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const FashionStyleParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const creativeParam = [
  //   { id: 1, img: creative1, x: TemplateDesign1 },
  //   { id: 2, img: creative2, x: TemplateDesign2 },
  //   { id: 3, img: creative3, x: TemplateDesign3 },
  //   { id: 4, img: creative4, x: TemplateDesign4 },
  //   { id: 5, img: creative5, x: TemplateDesign5 },
  //   { id: 6, img: creative6, x: TemplateDesign6 },
  //   { id: 7, img: creative7, x: TemplateDesign7 },
  // { id: 8, img: creative8 ,x:TemplateDesign8},
  // { id: 9, img: creative9 ,x:TemplateDesign9},
  // { id: 10, img: creative10 ,x:TemplateDesign10},
  // ];
  // const FestivalParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];

  // const TravelTourismParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  // const RestaurantsFoodParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];

  // const styleParam = [{ id: 2, img: creative2, x: TemplateDesign2 }];
  const onlineStore = [
    { id: 1, img: onlineStore1, x: TemplateOnlineStore1 },
    { id: 2, img: onlineStore2, x: TemplateOnlineStore2 },
    { id: 3, img: onlineStore3, x: TemplateOnlineStore3 },
    { id: 4, img: onlineStore4, x: TemplateOnlineStore4 },
    { id: 5, img: onlineStore6, x: TemplateOnlineStore5 },
    // { id: 6, img: onlineStore7, x: TemplateOnlineStore6 },
    // { id: 7, img: onlineStore8, x: TemplateOnlineStore7 },
    // { id: 8, img: onlineStore9, x: TemplateOnlineStore8 },
  ];
  // useEffect(() => {
  //    axiosRequset
  //   .post("/owner/Category/get", { id: 2 })
  //   .then((res) => {
  //     console.log("template", res.data.category.Designs);
  //     setTemplates(res.data.category.Designs);
  //   })
  //   .catch((error) => {});
  // }, []);
  const handleImg = async (img) => {
    let base64String = "";
    try {
      // await axios.get(img);
      // const res = await fetch(img);
      // console.log(res.blob());
      // console.log(res[0]);
      // const blob = await res.blob();

      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   base64String = reader.result.split(",")[1];
      //   // console.log(base64String);
      // };
      // base64String = res.blob();
      return base64String;
    } catch (error) {
      console.log(error);
    }
  };
  const handelClick = (item) => {
    // Base_Url.post(
    //   "/owner/Information/store",
    //   { ...item, img: handleImg(item?.img) },
    //   {
    //     headers: {
    //       Apipassword: Apipassword,
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   }
    // )
    axiosRequset
      .post("/owner/Information/store", {
        ...item,
        img: "",
        title: "title",
        description: "description",
      })
      .then((res) => {
        console.log(res);
        // console.log(res.data, "xx", res.data.Information.id);
        setCurrentTheme({
          public_id: res.data.link.public_id,
          img: res.data.Information.image,
          ...res.data.Information.object,
          data: {
            title: res.data.Information.title,
            desc: res.data.Information.description,
          },
          id: res.data.Information.id,
        });
        // console.log({ ...item, id: res.data.Information.id });
        return res.data.Information.id;
      })
      .then((id) => {
        // axiosRequset
        //   .post("/owner/Content/store", {
        //     information_id: id,
        //     type: "buttons",
        //   })
        //   .then((res) => {
        //     for (let i = 0; i <= 3; i++) {
        //       axiosRequset
        //         .post("/owner/Button/store", {
        //           content_id: res.data.Information.Contents[0].id,
        //           text: `button ${i + 1}`,
        //         })
        //         .then((res) => {})
        //         .catch((error) => {
        //           console.log(error);
        //         });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // axiosRequset
        //   .post("/owner/Content/store", {
        //     information_id: id,
        //     type: "form",
        //   })
        //   .then((res) => {})
        //   .catch((error) => {
        //     console.log(error);
        //   });
        navigate(`/dashboard/MyLinks/Customize/${id}`);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 405) {
          Swal.fire({
            title: "Subscribe in our plan",
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Let's Go",
            // denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Swal.fire("Saved!", "", "success");
              navigate("/dashboard/Settings/Subscription");
            }
            // else if (result.isDenied) {
            //   Swal.fire("Changes are not saved", "", "info");
            // }
          });
        }
      });
  };

  return (
    <Box sx={{ pb: "24px", pt: "15px" }}>
      {
        // templates.length <= 0 ? (
        //   <h1>loading...</h1>
        // ) : (
        <Box
          sx={{
            // marginTop: "24px",
            // width: "fit-content",
            boxShadow: "1px 3px 6px -1px var(--blue-color-200)",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            left: "-17px",
            // alignContent: "center",
            // alignItems: "center",
            // justifyItems: "center",
            // textAlign: "center",
            width: "90%",
            // height: "89vh",

            boxSizing: "border-box",
            flexDirection: "column",
            borderRadius: "1rem",
            marginLeft: "5rem",
            marginRight: "5rem",
            padding: "2rem 0rem 2rem 1.5rem ",
          }}
        >
          <Tabs defaultValue={1}>
            <StyledTabsList>
              {/* <StyledTab value={1}>Animation</StyledTab> */}
              <StyledTab value={1}>Online Store</StyledTab>

              {/* <StyledTab value={3}>Creative</StyledTab>
            <StyledTab value={4}>Fashion & Style</StyledTab> */}
            </StyledTabsList>
            {/* <StyledTabPanel value={1}>
            {AnimationParam.map((item) => (
              <Box
                sx={{
                  marginY: "20px",
                  marginTop: "60px",
                  position: "relative",
                  display: "inline-block",
                  maxWidth: "220px",
                }}
                key={item.id}
              >
                <Box
                  component="img"
                  chassName="hover1"
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={() => handleMouseLeave(item.id)}
                  src={item.img}
                  sx={{
                    width: "220px",
                    cursor: "pointer",
                    opacity: item.id == isHovering ? "0.4" : "",
                  }}
                ></Box>
                {item.id == isHovering ? (
                  <Button
                    variant="contained"
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={() => handleMouseLeave(item.id)}
                    sx={{
                      textTransform: "capitalize",
                      position: "absolute",
                      left: "70px",
                      top: "180px",
                    }}
                    onClick={() => {
                      // console.log("clicked",currentTheme);
                      setCurrentTheme(item.x);
                      setTemplate(item.x);
                      navigate("/dashboard/MyLinks/Customize");
                    }}
                  >
                    <Link
                      // to={`/path/${item.id}  `}
                      // to={`/dashboard/MyLinks/Customize `}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Create
                    </Link>
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </StyledTabPanel> */}

            <StyledTabPanel value={1}>
              {
                // templates.length > 0 &&
                onlineStore.map((item) => (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      borderRadius: "1rem",
                      // width: "20rem",
                    }}
                    key={item.id}
                  >
                    <Box
                      component="img"
                      chassName="hover1"
                      onMouseEnter={() => handleMouseEnter(item.id)}
                      onMouseLeave={() => handleMouseLeave(item.id)}
                      src={item.img}
                      sx={{
                        cursor: "pointer",
                        opacity: item.id == isHovering ? "0.4" : "",
                        borderRadius: "1rem",
                        width: "300px",
                        height: "321px",
                        // margin: "1rem",
                        width: "188px",
                        position: "relative",
                        margin: "0 6px 12px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    ></Box>
                    {item.id == isHovering ? (
                      <Button
                        variant="contained"
                        onMouseEnter={() => handleMouseEnter(item.id)}
                        onMouseLeave={() => handleMouseLeave(item.id)}
                        sx={{
                          textTransform: "capitalize",
                          position: "absolute",
                          left: "28%",
                          top: "45%",
                          width: "6rem",
                          fontSize: "20px",
                        }}
                        onClick={() => {
                          // console.log("clicked",currentTheme);
                          // let x = setTemplate(item.x);
                          // setCurrentTheme(x);
                          // navigate("/dashboard/MyLinks/Customize");
                          handelClick(item.x);
                          // document.getElementById("mobile-cont").style.background ="green"
                        }}
                      >
                        <Link
                          // to={`/path/${item.id}  `}
                          // to={`/dashboard/MyLinks/Customize `}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Create
                        </Link>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                ))
              }
            </StyledTabPanel>

            {/* <StyledTabPanel value={3}>
            {creativeParam.map((item) => (
              <Box
                sx={{
                  marginY: "20px",
                  marginTop: "60px",
                  position: "relative",
                  display: "inline-block",
                  maxWidth: "220px",
                }}
                key={item.id}
              >
                <Box
                  component="img"
                  chassName="hover2"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  src={item.img}
                  sx={{
                    width: "220px",
                    cursor: "pointer",
                    opacity: isHovering ? "0.4" : "",
                  }}
                ></Box>
                {isHovering ? (
                  <Button
                    variant="contained"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      textTransform: "capitalize",
                      position: "absolute",
                      left: "70px",
                      top: "180px",
                    }}
                    onClick={() => {
                      console.log("clicked", currentTheme);
                      setCurrentTheme(item.x);
                      navigate("/dashboard/MyLinks/Customize");
                    }}
                  >
                    <Link
                      // to={`/path/${item.id}  `}
                      // to={`/dashboard/MyLinks/Customize `}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Create
                    </Link>
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </StyledTabPanel>
          <StyledTabPanel value={4}>
            {FashionStyleParam.map((item) => (
              <Box
                sx={{
                  marginY: "20px",
                  marginTop: "60px",
                  position: "relative",
                  display: "inline-block",
                  maxWidth: "220px",
                }}
                key={item.id}
              >
                <Box
                  component="img"
                  chassName="hover2"
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={() => handleMouseLeave(item.id)}
                  src={item.img}
                  sx={{
                    width: "220px",
                    cursor: "pointer",
                    opacity: item.id == isHovering ? "0.4" : "",
                  }}
                ></Box>
                {item.id == isHovering ? (
                  <Button
                    variant="contained"
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={() => handleMouseLeave(item.id)}
                    sx={{
                      textTransform: "capitalize",
                      position: "absolute",
                      left: "70px",
                      top: "180px",
                    }}
                    onClick={() => {
                      console.log("clicked", currentTheme);
                      setCurrentTheme(item.x);
                      setTemplate(item.x);
                      navigate("/dashboard/MyLinks/Customize");
                    }}
                  >
                    <Link
                      // to={`/path/${item.id}  `}
                      // to={`/dashboard/MyLinks/Customize `}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Create
                    </Link>
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </StyledTabPanel> */}
          </Tabs>
        </Box>
        // )
      }
    </Box>
  );
};

export default Template;

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #19609c;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: transparent;
  width: 10%;
  line-height: 1.5;
  padding: 10px 10px;
  // padding-top: px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #19609c;
    color: white;
  }

  &:focus {
    color: #fff;
    backgroun-color: #19609c;
  }

  &.${tabClasses.selected} {
    background-color: #19609c;
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
    min-width: 400px;
    // background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    // align-content: space-between;

    `
);
