import { Android, FacebookRounded, Instagram, KeyboardArrowDownOutlined, MobileFriendly, NetworkCellRounded, NetworkPingOutlined, Twitter, WidthWide, Wifi, WindPower, Window, WindowRounded } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import androidIcon from '../../../assets/Images/android-icon.PNG'
import iosIcon from '../../../assets/Images/ios-icon.PNG'
import macIcon from '../../../assets/Images/mac-icon.PNG'
import windowsIcon from '../../../assets/Images/windows-icon.PNG'
const PieChart = () => {
  const labelsArray = ["Windows", 'Tablet', 'Mac os', 'iOS', 'Android'];
  const rate = [20, 30, 40, 45, 55]
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(setIsPopupOpen);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const data = [
    {
      date: '08-07',
      Report: 1,
  
    },
    {
      date: '08-08',
      Report: 0,
  
    },
    {
      date: '08-09',
      Report: 0.5,
  
    },
    {
      date: '08-10',
      Report: 0,
  
    },
    {
      date: '08-11',
      Report: 0,
  
    },
    {
      date: '08-12',
      Report: 0,
  
    },
    {
      date: '08-13',
      Report: 1.5,
  
    },
    {
      date: '08-14',
      Report: 0,
  
    },
  
  
  
  ];
  // const [labelsArray, setLabelsArray]= useState([]);
  // const [rate, setRate]= useState([]);

  // useEffect( ()=>{
  //  const getdata= async()=>{
  //       const labelsArraye=[];
  //       const getrate=[];

  //     const reqData= await fetch("http://localhost/reactgraphtutorial/rates"); 
  //     const resData= await reqData.json();
  //     for(let i=0; i<resData.length; i++)
  //     {
  //         labelsArray.push(resData[i].country);
  //         getrate.push(parseInt(resData[i].rates));
  //     }
  //     setLabelsArray(labelsArray);
  //     setRate(getrate);


  //  }
  //  getdata();
  // },[]);

  return (
    <React.Fragment>
      <div className='container-fluid pie-chart-cont '>
        <h1>systems</h1>
        <Chart
          type="donut"
          width={1000}
          height={550}
          series={rate}

          options={{
            labels: labelsArray,
            title: {
              text: " ",
              align: "left",
              fontSize: "30px",
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      //formatter: () => '343',
                      fontSize: 30,
                      color: 'var(--blue-color-200)',
                    }
                  }
                }
              }

            },

            dataLabels: {
              enabled: true,
            }


          }}

        />

      </div>


      <br /> <br /><br /><br /> <br /><br />



      <div>
        <table className="systems-table">
          <thead>
            <td>Systems</td> 
            <td><div className='piechart-info' onClick={openPopup.bind(this)}>
          <span> Last 7 Days</span>
          <KeyboardArrowDownOutlined className="piechart-systems-icon"/>
        </div></td> 
          
          </thead>
          

          <tbody>

            <tr>
              <td>(4)<span>Categories</span></td>
              <td>0</td>
              <td></td>

            </tr>
            <hr/>
            <tr><td>#-<span>Referrer</span></td>
              <td>Visits</td>
              <td>Percentage</td>
            </tr>
            <hr/>
            <tr>
              <td>1- <img src={ androidIcon}alt="fe"/><span>Android </span></td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr><td>2- <img src={ iosIcon}alt="fe"/><span>iOS </span></td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>3- <img src={ macIcon}alt="fe"/><span>Mac os</span></td>
              <td>0</td>
              <td>0%</td>
            </tr>
            
            <tr>
              <td>5- <img src={ windowsIcon}alt="fe"/><span>Windows</span></td>
              <td>0</td>
              <td>0%</td>
            </tr>


          </tbody>
        </table>

        {isPopupOpen && (
        <div className='overlay' onClick={closePopup}>
          <div className='chart-info-breif'>
            <div style={{ fontSize: '3rem' }}>Report Breif</div>
            {data.map((entry, index) => (
              <div className=''>
                <div key={index} className='info-data'>
                  {`${entry.date}: ${entry.Report}`}
                </div>
                <hr className='info-hr' />

              </div>

            ))}
          </div>
        </div>
      )}

      </div>

    </React.Fragment>
  );
}
export default PieChart;