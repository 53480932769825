import React, { useContext, useState } from "react";

const ThemeContext = React.createContext();

export const useMyTemplateContext = () => {
  return useContext(ThemeContext);
};

export const MyTemplateContext = ({ children }) => {
  const [myTemplate, setMyTemplate] = useState([]);

  const setTemplate = (template) => {
    // make random id by js for each template
    let randomId = Math.random().toString(36).substring(2, 15);
    // make random id by js for each template uonike
    // random id is unique
    setMyTemplate([...myTemplate, { ...template, id: randomId }]);
    console.log("myTemplate", randomId);
    console.log("myTemplate", { ...template, id: randomId });
    return { ...template, id: randomId };
  };

  const getTemplate = (id) => {
    return myTemplate.find((template) => template.id === id);
  };
  const updateTemplate = (id, updatedTemplate) => {
    setMyTemplate((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === id ? { ...template, ...updatedTemplate } : template
      )
    );
  };
  const deleteTemplate = (id) => {
    setMyTemplate((prevTemplates) =>
      prevTemplates.filter((template) => template.id !== id)
    );
  };
  const value = {
    myTemplate,
    deleteTemplate,
    setTemplate,
    getTemplate,
    updateTemplate,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
