import React, { useEffect } from "react";
import {
  BlookContainer,
  DivContact,
  Input,
  Submit,
} from "../styles/MobileScreen.styled";
import { toast } from "react-toastify";

const ContactUsT = ({ data }) => {
  const [fields, setFields] = React.useState({
    fields: [
      { id: 0, key: "Full Name", title: "Full Name", required: false },
      { id: 1, key: "email", title: "email", required: true },
    ],
    submit: {
      id: "submit",
      key: "submit",
      title: "submit",
      message: "thank you for your message",
      required: true,
    },
  });

  const handleSubmit = () => {
    toast.success(fields.submit.message);
  };
  useEffect(() => {
    console.log(data);
    if (data?.text.length > 0 && data?.text != "null") {
      setFields({ ...JSON.parse(data.text)[0] });
      // console.log(JSON.parse(data.text));
    }
    // if (data?.text.length > 0 && data?.text != null) {
    //   console.log(JSON.parse(data?.text));
    //   let fields = JSON.parse(data?.text);
    //   setFields({ ...fields[0] });
    // }
  }, []);
  return (
    <>
      <BlookContainer>
        <h3>form</h3>
        <DivContact>
          {fields?.fields?.length > 0 &&
            fields?.fields?.map((item) => {
              // console.log(item);
              // if (item) return null;
              return (
                <>
                  {/* <label
                    key={item.id}
                    style={{
                      display: "block",
                      textAlign: "start",
                      fontSize: "12px",
                    }}
                  >
                    {item?.title}
                  </label> */}
                  <Input
                    type="text"
                    placeholder={item.title}
                    key={item.id}
                    label={item.title}
                  />
                </>
              );
            })}
          {/* <Input type="text" placeholder="message" /> */}
          <Submit onClick={handleSubmit}>{fields?.submit?.title}</Submit>
        </DivContact>
      </BlookContainer>
    </>
  );
};

export default ContactUsT;
