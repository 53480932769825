import React from "react";
import { Button, Input } from "@mui/joy";
import { axiosRequset } from "../../../Api/ApiUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import PopUpAddButton from "../PopUp/PopUpAddButton";
import { Edit } from "@mui/icons-material";
import PopUpDelete from "../PopUp/PopUpDelete";
import PopUpChooseButtonType from "../PopUp/PopUpChooseButtonType";
import PopUpMap from "../PopUp/PopUpMap";
import PopUpButtonContact from "../PopUp/PopUpButtonContact";
import PopUpButtonForm from "../PopUp/PopUpButtonForm";
import PopUpButtonOpenForm from "../PopUp/PopUpButtonOpenForm";
import Switch from "@mui/material/Switch";
import ExpandIcon from "@mui/icons-material/Expand";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../Dnd/StrictModeDroppable";
const ButtonGroup = ({
  data,
  onDelete,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  console.log(data);
  const [buttonsData, setButtonsData] = useState(data?.Buttons);
  console.log(buttonsData);
  const [showPopUpAdd, setShowPopUpAdd] = useState(false);
  const [showPopUpAddUpdate, setShowPopUpAddUpdate] = useState(false);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [showPopUpEdit, setShowPopUpEdit] = useState(false);
  const [dataDlt, setDataDlt] = useState({});
  const [values, setValues] = useState({});
  const [icons, setIcons] = useState([]);
  const [myIcons, setMyIcons] = useState();
  const [showPopUpChoose, setShowPopUpChoose] = useState(false);
  const [typeChoose, setTypeChoose] = useState();
  const [showPopUpMap, setShowPopUpMap] = useState(false);
  const [showPopUpButtonContact, setShowPopUpButtonContact] = useState(false);
  const [showPopUpButtonContactEdit, setShowPopUpButtonContactEdit] =
    useState(false);
  const [contactValues, setContactValues] = useState({});
  const [showPopUpButtonForm, setShowPopUpButtonForm] = useState(false);
  const [text, setText] = useState({});
  const [showPopUpButtonOpenForm, setShowPopUpButtonOpenForm] = useState(false);
  const [showPopUpButtonFormEdit, setShowPopUpButtonFormEdit] = useState(false);
  console.log("index", index, expanded === index);
  console.log(expanded);
  let ContactUsText = {
    address1: "",
    address2: "",
    city: "",
    country: "",
    emailPrimary: "",
    emailPrimaryType: "",
    emailSecondary: "",
    emailSecondaryType: "",
    firstName: "",
    lastName: "",
    note: "",
    organization: "",
    phonePrimary: "",
    phonePrimaryType: "",
    phoneSecondary: "",
    phoneSecondaryType: "",
    position: "",
    postcode: "",
    state: "",
  };
  useEffect(() => {
    if (icons.length > 0) {
      return;
    }
    axiosRequset
      .get("/guest/Social/all")
      .then((res) => {
        setIcons(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelectIcons = (icon) => {
    console.log("line 79", values);
    setValues({
      ...values,
      // social_icon_id: icon.id,
      icon_name: icon,
    });
    setMyIcons(icon.icon);
  };
  const handleUploadIcons = (event) => {
    console.log("line 88", values);

    console.log(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);
    // setMyIcons(URL.createObjectURL(event.target.files[0]));
    // setValues({
    //   ...values,
    //   image: event.target.files[0],
    //   icon_name: "null",
    // });
    setMyIcons(URL?.createObjectURL(event?.target?.files[0]));
    setValues({
      ...values,
      image: event.target.files[0],
      icon_name: "null",
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSave = () => {
    // console.log("values", values);
    console.log("line 113", values);

    if (!values.title || !values.link) {
      toast.error("Please fill all the fields");
      return;
    }

    // const urlRegex = /(https?:\/\/[^\s]+)/g;
    // regex for valid url including https or http tell or mailto or tel or whatsapp or email or phone
    // const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g;
    // const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g;
    const urlRegex =
      /^(?:(?:https?|mailto|tel|whatsapp):|email:)(?:\/\/)?[^\s\/$.?#].[^\s]*$/;
    // const urlRegex =
    //   /^(?:(?:https?|mailto|tel|whatsapp|email|phone):|https?:\/\/|mailto:|tel:|whatsapp:|email:|phone:)[^\s\/$.?#].[^\s]*$/;

    // Examples of valid URLs:
    const isValidUrl = urlRegex.test(
      values.path ? values.path + values.link : values.link
    );
    // const isValidUrl = urlRegex.test(values.link);
    console.log("url", values.path ? values.path + values.link : values.link);

    // if (values.link == "") {
    //   toast.error("Please enter a valid URL");
    //   return;
    // }
    if (!isValidUrl) {
      toast.error("Please enter a valid URL");
      return;
    }
    console.log(values);
    console.log("upload", {
      title: values.title,
      content_id: data?.id,
      link1: values.path ? values.path + values.link : values.link,
      icon: values.image,
      type: typeChoose,
      state: 1,
      tcolor: values?.tcolor,
      path: values?.path,
      icon_name: values?.icon_name,
    });
    axiosRequset
      .post("/owner/Button/store", {
        // ...values,
        title: values.title,
        content_id: data?.id,
        // link1: values.path + values.link,
        // link1: values.link,
        link1: values.path ? values.path + values.link : values.link,
        // link1: values.path,
        icon: values.image,
        // image: values.image,
        type: typeChoose,
        state: 1,
        tcolor: values?.tcolor,
        path: values?.path,
        icon_name: values?.icon_name,
        // test
        order: buttonsData.length,
      })
      .then((res) => {
        console.log(res);
        setValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setShowPopUpAdd(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleUpdate = () => {
    // console.log(values);
    console.log("line 177", values);

    if (!values.title || !values.link) {
      toast.error("Please fill all the fields");
      return;
    }

    axiosRequset
      .post("/owner/Button/update", {
        title: values.title,
        // link: values.link,
        id: values.id,
        content_id: data?.id,
        // link1: values?.path + values.link,
        // link1: values.link,
        link1: values.path ? values.path + values.link : values.link,
        icon: values.image,
        // link1: values?.path,
        // social_icon_id: values.social_icon_id,
        tcolor: values?.tcolor,
        icon_name: values?.icon_name,
      })
      .then((res) => {
        console.log(res);
        setValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("updated Successfully");
        setShowPopUpAddUpdate(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleChooseType = (e) => {
    setShowPopUpChoose(false);
    console.log(values, e);
    setValues({ ...values, type: e });
    setTypeChoose(e);
    if (e == "google_map") {
      setShowPopUpMap(true);
      return;
    }
    if (e == "contact_details") {
      setShowPopUpButtonContact(true);
      return;
    } else if (e == "form") {
      setShowPopUpButtonForm(true);
      return;
    }
    setShowPopUpAdd(true);
  };

  const handleChangeContact = (e) => {
    setContactValues({
      ...contactValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveContact = (e) => {
    e.preventDefault();
    // console.log(Object.keys(contactValues).length);
    if (Object.keys(contactValues).length == 0) {
      toast.error("Please fill the fields");
      return;
    }
    console.log(contactValues);
    axiosRequset
      .post("/owner/Button/store", {
        text: [contactValues],
        content_id: data?.id,
        type: "contact_details",
        link1: "https://fastworldkw.net/",
        title: "Contact Details",
        state: 1,
        tcolor: values?.tcolor,
        order: buttonsData.length,
      })
      .then((res) => {
        console.log(res);
        setContactValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setShowPopUpButtonContact(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleEditContact = (e) => {
    setShowPopUpButtonContactEdit(true);
    setShowPopUpAddUpdate(false);
    setContactValues(e);
  };
  const handleEditForm = (e) => {
    setShowPopUpButtonFormEdit(true);
    setShowPopUpAddUpdate(false);
    setText(e);
  };
  // const handle = (e) => {
  //   setShowPopUpButtonOpenForm(true);
  //   setShowPopUpAddUpdate(false);
  //   setText(e);
  // };
  const handleUpdateContact = (e) => {
    e.preventDefault();
    console.log(contactValues);
    axiosRequset
      .post("/owner/Button/update", {
        text: [contactValues],
        content_id: data?.id,
        id: values.id,
        type: "contact_details",
        link1: "https://fastworldkw.net/",
        title: "Contact Details",
      })
      .then((res) => {
        // console.log(res);
        setContactValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("edited Successfully");
        setShowPopUpButtonContactEdit(false);
        setContactValues({});
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleSaveNewForm = () => {
    console.log(values);
    axiosRequset
      .post("/owner/Button/store", {
        title: values.title,
        link1: "https://fastworldkw.net/",
        content_id: data?.id,
        type: typeChoose,
        text: [text],
        state: 1,
        order: buttonsData.length,
      })
      .then((res) => {
        // console.log(res);
        setValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setShowPopUpAdd(false);
        setShowPopUpButtonOpenForm(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleUpdateButtonForm = () => {
    // console.log(values);
    axiosRequset
      .post("/owner/Button/update", {
        content_id: data?.id,
        id: values.id,
        text: [text],
      })
      .then((res) => {
        setValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setShowPopUpAdd(false);
        setShowPopUpButtonFormEdit(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleSelectFormOuter = (e) => {
    setText(e);
    setValues({ ...values, title: e.title });
    setShowPopUpButtonOpenForm(true);
  };

  const handleSwitch = (id, state) => {
    let valueSwitch;
    if (state == 0 || state == null) {
      valueSwitch = 1;
    } else {
      valueSwitch = 0;
    }
    axiosRequset
      .post("/owner/Button/update", {
        id: id,
        content_id: data?.id,
        state: valueSwitch,
        tcolor: values?.tcolor,
      })
      .then((res) => {
        console.log(res);
        setValues({});
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("updated Successfully");
        setShowPopUpAddUpdate(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };
  const handleSaveMap = () => {
    if (!values.title || !values.link) {
      toast.error("Please fill all the fields");
      return;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const isValidUrl = urlRegex.test(values.link);
    if (!isValidUrl) {
      toast.error("Please enter a valid URL");
      return;
    }
    axiosRequset
      .post("/owner/Button/store", {
        title: values.title,
        link1: values.link,
        content_id: data?.id,
        social_icon_id: "14",
        type: "google_map",
        state: 1,
        tcolor: values?.tcolor,
        order: buttonsData.length,
      })
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setShowPopUpMap(false);
        setShowPopUpAdd(false);
        setValues({});
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const getLinkAndPath = (link) => {
    if (link.includes("https://www.facebook.com/")) {
      return link.split("https://www.facebook.com/")[1];
    } else if (link.includes("https://wa.me/")) {
      return link.split("https://wa.me/")[1];
    } else if (link.includes("https://t.me/")) {
      return link.split("https://t.me/")[1];
    } else if (link.includes("mailto://")) {
      return link.split("mailto://")[1];
    } else if (link.includes("https://twitter.com")) {
      return link.split("https://twitter.com")[1];
    } else if (link.includes("https://instagram.com")) {
      return link.split("https://instagram.com")[1];
    } else if (link.includes("tel://")) {
      return link.split("tel://")[1];
    } else if (link.includes("https://linkedin.com")) {
      return link.split("https://linkedin.com")[1];
    } else {
      return link;
    }
  };
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(buttonsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(currentTheme);
    let currentButtonGroup = currentTheme.Contents.find(
      (item) => item.id === data?.id
    );
    // console.log(currentButtonGroup);
    let currentButtonGroupIndex = currentTheme.Contents.findIndex(
      (item) => item.id === data?.id
    );
    currentButtonGroup.Buttons = items;
    // console.log(currentButtonGroupIndex, currentButtonGroup);

    let newContents = [...currentTheme.Contents];
    // console.log(newContents);
    newContents[currentButtonGroupIndex] = currentButtonGroup;
    // console.log(newContents);

    // prevContent.buttons
    setCurrentTheme({ ...currentTheme, Contents: newContents });
    setButtonsData(items);

    // update the server
    items.forEach((item, index) => {
      axiosRequset
        .post("/owner/Button/update", {
          // title: values.title,
          // link: values.link,
          id: item.id,
          content_id: data?.id,
          // link1: values?.path + values.link,
          // link1: values.link,
          // link1: values?.path,
          // social_icon_id: values.social_icon_id,
          // tcolor: values?.tcolor,
          // icon_name: values?.icon_name,
          order: index,
        })
        .then((res) => {
          console.log(res);
          // setValues({});
          // setCurrentTheme({
          //   ...currentTheme,
          //   Contents: res.data.Information.Contents,
          // });
          // toast.success("updated Successfully");
          // setShowPopUpAddUpdate(false);
        })
        .catch((error) => {
          console.log(error);
          // toast.error("Something went wrong");
        });
    });
  };
  useEffect(() => {
    setButtonsData(data?.Buttons);
  }, [data]);
  return (
    <>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            draggable={true}
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography style={{ display: "block" }}>
                  Button Group{" "}
                </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <StrictModeDroppable droppableId="btn-group">
                  {(provided) => (
                    <Box
                      sx={{ marginTop: "80px" }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {/* {data?.Buttons?.map((item, index) => { */}
                      {}
                      {buttonsData?.length > 0 &&
                        buttonsData.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={String(item.id)}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    border: "1px solid #b2c2bb",
                                    padding: " 5px 10px 5px 0px",
                                    fontSize: "13px",
                                    height: "52px",
                                    lineHeight: "31px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    component="p"
                                  >
                                    <i
                                      style={{
                                        display: "inline-block",
                                        border: "1px solid #b2c2bb",
                                        height: "52px",
                                        padding: "10px 5px 10px",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <ExpandIcon />
                                    </i>{" "}
                                    <span style={{ padding: "0 5px " }}>
                                      {item.title}
                                    </span>
                                  </Typography>

                                  <Typography
                                    sx={{ display: "flex" }}
                                    component="p"
                                  >
                                    <Edit
                                      sx={{
                                        display: "inline-block",
                                        color: "#54B99B",
                                        cursor: "pointer",
                                        margin: "0 5px",
                                        ":hover": {
                                          color: "#19609C",
                                        },
                                      }}
                                      // fontSize="55px"
                                      onClick={() => {
                                        setShowPopUpAddUpdate(true);
                                        if (
                                          item.type == "contact_details" ||
                                          item.type == "form"
                                        ) {
                                          setValues({
                                            id: item.id,
                                            title: item.title,
                                            link: item.link1,
                                            type: item.type,
                                            text: item.text,
                                            icon_name: item.icon_name,
                                          });
                                        } else {
                                          setValues({
                                            id: item.id,
                                            title: item.title,
                                            link: getLinkAndPath(item.link1),
                                            type: item.type,
                                            path: item.path,
                                            icon_name: item.icon_name,
                                          });
                                        }

                                        setMyIcons(item.icon);
                                      }}
                                    />
                                    <DeleteIcon
                                      // fontSize="55px"
                                      sx={{
                                        color: "red",
                                        cursor: "pointer",
                                        margin: "0 5px",
                                        ":hover": {
                                          color: "#19609C",
                                        },
                                      }}
                                      onClick={() => {
                                        setDataDlt({
                                          id: item.id,
                                          content_id: data?.id,
                                        });
                                        setShowPopUpDelete(true);
                                      }}
                                    />
                                    <i
                                      style={{
                                        display: "inline-block",
                                      }}
                                    >
                                      <Switch
                                        // style={{ paddingBottom: "10px" }}
                                        sx={{
                                          color: "red",
                                          cursor: "pointer",
                                          margin: "0 5px",
                                          ":hover": {
                                            color: "#19609C",
                                          },
                                        }}
                                        defaultChecked
                                        checked={item.state}
                                        size="small"
                                        onChange={() =>
                                          handleSwitch(item.id, item.state)
                                        }
                                      />
                                    </i>
                                  </Typography>
                                </Box>
                              )}
                            </Draggable>
                          );
                        })}
                      {buttonsData?.length > 0 && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "1.5px",
                            backgroundColor: "#686868",
                            marginY: "15px",
                          }}
                        ></Box>
                      )}

                      <Button
                        size="md"
                        sx={{
                          width: "80%",
                          marginTop: "20px",
                          background:
                            "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                          height: "57px",
                          display: "block",
                          margin: "50px auto",
                        }}
                        onClick={() => {
                          // setShowPopUpAdd(true);
                          setShowPopUpChoose(true);
                        }}
                      >
                        +Add New Button
                      </Button>
                      {provided.placeholder}
                    </Box>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
          </AccordionDetails>
          <PopUpAddButton
            show={showPopUpAdd}
            setShow={setShowPopUpAdd}
            handleChange={handleChange}
            handleSave={handleSave}
            values={values}
            setValues={setValues}
            titele={"Add Button"}
            icons={icons}
            img={myIcons}
            handleUploadIcons={handleUploadIcons}
            handleSelectIcons={handleSelectIcons}
          />
          <PopUpAddButton
            show={showPopUpAddUpdate}
            setShow={setShowPopUpAddUpdate}
            handleChange={handleChange}
            handleSave={handleUpdate}
            values={values}
            setValues={setValues}
            titele={"Edit Button"}
            icons={icons}
            img={myIcons}
            handleUploadIcons={handleUploadIcons}
            handleSelectIcons={handleSelectIcons}
            handleEditContact={handleEditContact}
            handleEditForm={handleEditForm}
          />
          <PopUpDelete
            show={showPopUpDelete}
            setShow={setShowPopUpDelete}
            url={"/owner/Button/delete"}
            message={{ data: "Are you sure you want to delete this button?" }}
            data={dataDlt}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
          />
          <PopUpChooseButtonType
            show={showPopUpChoose}
            setShow={setShowPopUpChoose}
            handleChooseType={handleChooseType}
          />
          <PopUpMap
            show={showPopUpMap}
            setShow={setShowPopUpMap}
            handleSave={handleSaveMap}
            handleChange={handleChange}
            values={values}
          />
          <PopUpButtonContact
            show={showPopUpButtonContact}
            setShow={setShowPopUpButtonContact}
            handleChangeContact={handleChangeContact}
            handleSaveContact={handleSaveContact}
            contactValues={contactValues}
          />
          <PopUpButtonContact
            show={showPopUpButtonContactEdit}
            setShow={setShowPopUpButtonContactEdit}
            handleChangeContact={handleChangeContact}
            handleSaveContact={handleUpdateContact}
            contactValues={contactValues}
          />
          <PopUpButtonForm
            show={showPopUpButtonForm}
            setShow={setShowPopUpButtonForm}
            setText={setText}
            handleSave={handleSaveNewForm}
            handleSelectFormOuter={handleSelectFormOuter}
            // handleEditForm={handleEditForm}
          />
          <PopUpButtonOpenForm
            show={showPopUpButtonOpenForm}
            setShow={setShowPopUpButtonOpenForm}
            handleSave={handleSaveNewForm}
            d={text}
            setText={setText}
            handleEditForm={handleEditForm}
          />
          <PopUpButtonOpenForm
            show={showPopUpButtonFormEdit}
            setShow={setShowPopUpButtonFormEdit}
            handleSave={handleUpdateButtonForm}
            d={text}
            setText={setText}
          />
        </Accordion>
      )}
    </>
  );
};

export default ButtonGroup;
