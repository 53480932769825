import React from "react";

const HeaderT = ({ data }) => {
  return (
    <>
      <p
        className="text-3xl font-bold underline"
        style={{ minHeight: "100px", padding: "10px" }}
      >
        {data.title}
      </p>
    </>
  );
};

export default HeaderT;
