import { Button, Input, Slider } from "@mui/base";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTheme } from "../../../styles/ThemeContext";

const StylePage = () => {
  // Use querySelector to select the first element with the class name "online-store-btn"
  const [borderRadius, setBorderRadius] = useState(5);
  const [borderSize, setBorderSize] = useState(2);
  const { currentTheme, setCurrentTheme } = useTheme();
  const [currentBox, setCurrentBox] = useState();

  const handleChangeObject = (d) => {
    // console.log(d, currentTheme);

    const updatedTheme = {
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        ...d,
      },
    };

    setCurrentTheme(updatedTheme);
  };
  // const handleSliderChangeBorderSize = (event) => {
  //   // console.log(event.target.value, borderSize);
  //   // set data in state
  //   setBorderSize(event.target.value);
  //   // set data in contextapi
  //   setCurrentTheme({
  //     ...currentTheme,
  //     StyledButton: {
  //       ...currentTheme.StyledButton,
  //       border: ` #000 solid ${event.target.value}px`,
  //     },
  //   });
  // };
  const handleSliderChangeBorderSize = (event) => {
    const newValue = parseInt(event.target.value, 10); // or parseFloat
    // setBorderSize((prevSize) => newValue);

    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        borderSize: newValue,
      },
    });
  };

  const handleSliderChangeBorderRadius = (event) => {
    // console.log(event.target.value, borderRadius);
    // setBorderRadius(event.target.value);
    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        borderRadius: event.target.value,
      },
    });
  };
  // useEffect(() => {
  //   setCurrentTheme({
  //     ...currentTheme,
  //     StyledButton: {
  //       ...currentTheme.StyledButton,
  //       borderRadius: `${borderRadius}px`,
  //     },
  //   });
  // }, [borderRadius]);

  return (
    <div style={{ width: "95%", overflow: "hidden" }}>
      <Button
        className="btn-border-radius first-btn"
        style={{ border: currentBox == 1 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "solid",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(1);
        }}
      ></Button>

      <Button
        className="btn-border-radius second-btn"
        style={{ border: currentBox == 2 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "solid",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(2);
        }}
      ></Button>

      <Button
        className="btn-border-radius third-btn"
        style={{ border: currentBox == 3 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "groove",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(3);
        }}
      ></Button>

      <Button
        className="btn-border-radius forth-btn"
        style={{ border: currentBox == 4 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "dotted",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(4);
        }}
      ></Button>

      <Button
        className="btn-border-radius fifth-btn"
        style={{ border: currentBox == 5 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "dashed",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "0px 0px 0 0",
          });
          setCurrentBox(5);
        }}
      ></Button>

      <Button
        className="btn-border-radius six-btn"
        style={{ border: currentBox == 6 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "ridge",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(6);
        }}
      ></Button>

      <Button
        className="btn-border-radius seven-btn"
        style={{ border: currentBox == 7 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "inset",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor:
              "rgba(0, 255, 255, 0.5), rgba(255, 0, 0, 0.5), rgba(0, 255, 0, 0.5), rgba(0, 0, 255, 0.5)",
            boxShadowProperties:
              "-5px -4px 0 4px, -5px 4px 0 4px, 4px 5px 0 4px, 4px -4px 0 4px",
          });
          setCurrentBox(7);
        }}
      ></Button>

      <Button
        className="btn-border-radius last-btn"
        style={{ border: currentBox == 8 ? "3px solid rgb(0, 122, 255)" : "" }}
        onClick={() => {
          handleChangeObject({
            border: "1px solid black",
            borderColor: "black",
            borderType: "outset",
            borderSize: 5,
            borderRadius: 5,
            boxShadowColor: "#000",
            boxShadowProperties: "12px 12px 0 0",
          });
          setCurrentBox(8);
        }}
      ></Button>

      <div>
        <span>Corner</span>
      </div>
      <div className="border-control">
        <input
          type="range"
          min="0"
          max="20"
          onChange={handleSliderChangeBorderRadius}
          value={currentTheme.StyledButton.borderRadius}
          style={{ width: "140%" }}
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={currentTheme.StyledButton.borderRadius}
                size="small"
                // onChange={handleSliderChangeBorderRadius}
                // inputProps={{
                //   step: 3,
                //   min: 0,
                //   max: 19,
                //   type: "number",
                //   "aria-labelledby": "input-slider",
                // }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <div>
        <span>OutLine</span>
      </div>
      <div className="border-control">
        <input
          type="range"
          min="0"
          max="9"
          value={currentTheme.StyledButton.borderSize}
          onChange={handleSliderChangeBorderSize}
          style={{ width: "140%" }}
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={currentTheme.StyledButton.borderSize}
                size="small"
                // onChange={handleSliderChangeBorderSize}
                // inputProps={{
                //   step: 3,
                //   min: 0,
                //   max: 9,
                //   type: "number",
                //   "aria-labelledby": "input-slider",
                // }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default StylePage;
