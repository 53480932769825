import React, { useState } from "react";
import { FaTh, FaBars } from "react-icons/fa";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationIcon from "@mui/icons-material/Notifications";
// import LinkIcon from '@mui/icons-material/Link';
import LinkIcon from "../assets/Images/svg_icons/link.svg";
import Notification from "../assets/Images/svg_icons/cowbell.svg";
import Setting from "../assets/Images/svg_icons/settings.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { NavLink } from "react-router-dom";
import logo from "../assets/Images/logo.PNG";

const Sidebar = ({ children, currentPage }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const mainLinks = [
    {
      path: "/dashboard/MyLinks",
      name: "My Links",
      // icon: <LinkIcon />
      icon: (
        <svgf
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 511.904 511.904"
          style={{ width: "1em" }}
        >
          <g>
            <path d="M222.025,417.764c-33.872,35.124-89.034,38.364-126.784,7.445c-22.482-19.465-33.966-48.733-30.72-78.293   c2.811-21.794,12.997-41.97,28.864-57.173l61.355-61.397c12.492-12.496,12.492-32.752,0-45.248l0,0   c-12.496-12.492-32.752-12.492-45.248,0l-60.053,60.075C22.065,269.57,4.802,304.721,0.649,342.521   c-7.757,85.138,54.972,160.445,140.11,168.202c45.721,4.166,90.933-12.179,123.42-44.618l64.171-64.149   c12.492-12.496,12.492-32.752,0-45.248l0,0c-12.496-12.492-32.752-12.492-45.248,0L222.025,417.764z" />
            <path d="M451.358,31.289C387.651-15.517,299.186-8.179,244.062,48.484L183.667,108.9c-12.492,12.496-12.492,32.752,0,45.248l0,0   c12.496,12.492,32.752,12.492,45.248,0l61.355-61.291c33.132-34.267,86.738-38.127,124.437-8.96   c38.803,31.818,44.466,89.067,12.648,127.87c-1.862,2.271-3.833,4.45-5.907,6.53l-64.171,64.171   c-12.492,12.496-12.492,32.752,0,45.248l0,0c12.496,12.492,32.752,12.492,45.248,0l64.171-64.171   c60.413-60.606,60.257-158.711-0.349-219.124C461.638,39.727,456.631,35.341,451.358,31.289z" />
            <path d="M183.667,282.525l99.425-99.425c12.497-12.497,32.758-12.497,45.255,0l0,0c12.497,12.497,12.497,32.758,0,45.255   l-99.425,99.425c-12.497,12.497-32.758,12.497-45.255,0l0,0C171.17,315.283,171.17,295.022,183.667,282.525z" />
          </g>
        </svgf>
      ),
    },
    {
      path: "/dashboard/Notification",
      name: "Notification",
      // icon: <NotificationIcon />
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          style={{ width: "1em" }}
        >
          <path d="M23.82,21l-2.38-12.35c-.41-2.12-2.26-3.65-4.42-3.65h-.15c.08-.32,.13-.65,.13-1,0-2.21-1.79-4-4-4h-2c-2.21,0-4,1.79-4,4,0,.35,.04,.68,.13,1h-.15c-2.15,0-4.01,1.54-4.42,3.65L.18,21h7.94c.45,1.72,2.01,3,3.87,3s3.43-1.28,3.87-3h7.94ZM10,4c0-.55,.45-1,1-1h2c.55,0,1,.45,1,1s-.45,1-1,1h-2c-.55,0-1-.45-1-1Zm-4.49,5.22c.14-.71,.75-1.22,1.47-1.22h10.04c.72,0,1.34,.51,1.47,1.22l1.69,8.78H3.82l1.69-8.78Z" />
        </svg>
      ),
    },
    {
      path: "/dashboard/Templates",
      name: "Templates",
      icon: <ViewComfyOutlinedIcon />,
    },
    {
      path: "/dashboard/Settings",
      name: "Settings",
      // icon: <SettingsIcon />
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Outline"
          viewBox="0 0 24 24"
          style={{ width: "1em" }}
        >
          <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
          <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
        </svg>
      ),

      //  <img src={Setting} alt="Folder Icon" style={{width: "1em",color:" var(--gray-color-200)"}} />
    },
    {
      path: "/dashboard/Help",
      name: "Help",
      icon: <HelpOutlineIcon />,
    },
  ];

  const accountLinks = [
    {
      path: "/dashboard/MyAccount",
      name: "My Account",
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      path: "/dashboard/Logout",
      name: "Logout",
      icon: <LogoutIcon />,
    },
  ];

  return (
    <div className="cont">
      <div className="nav-home">{currentPage}</div>{" "}
      {/* Display the current page name here */}
      <div
        className="container "
        style={{
          background: "#F5F7FC",
        }}
      >
        <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
          <div className="top_section">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
              <img src={logo} alt="logo" />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            ></div>
          </div>
          {/* Main Links */}
          {mainLinks.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeClassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
          {/* Account Links */}
          <div className="account-links">
            <hr />

            {accountLinks.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeClassName="active"
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            ))}
          </div>
        </div>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
