import React from "react";

import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  /* margin-top: 18px; */
  color: white;
  position: relative;
  z-index: 15;
`;
const BackIcon = styled(KeyboardBackspace)`
  color: white;
  width: 40px;
  height: 40px;
`;

const Back = ({ link }) => {
  return (
    <BackButton to={link}>
      <BackIcon
        sx={{
          position: "relative",
          top: "3px",
        }}
      />
      Back
    </BackButton>
  );
};

export default Back;
