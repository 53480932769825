import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../App.scss";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import Appearance from "../../components/Customize/Appearance.js";
import MyPage from "../../components/Customize/MyPage.jsx";
import { Box, IconButton, Typography } from "@mui/material";
import VideoPage from "../../components/Customize/Appearance/Background/VideoPage.jsx"; // Import the VideoPage component
import EditIcon from "@mui/icons-material/Edit";

import { useTheme } from "../../components/styles/ThemeContext.js";
import { ThemeProvider } from "styled-components";
// import { GlobalStyles } from "../../components/styles/Global.js";
import profile from "../../assets/Image/imgProfile.PNG";
import { Button } from "@mui/joy";
import Base_Url, { axiosRequset } from "../../Api/ApiUrl";
import { Link, useParams } from "react-router-dom";
import facebook from "../../assets/Image/Facebook.PNG";
import instegram from "../../assets/Image/instegranimage.png";
import x from "../../assets/Image/124ba71c08f83512d0e478d29b6d16f9.png";
import MainStructureTemplate from "../../components/MainStructureTemplate.jsx";
import { useRef } from "react";
// import { Avatar, Box, IconButton, Typography } from "@mui/material";
import Logo from "../../assets/Images/logo.PNG";
import { BorderColorOutlined, KeyboardBackspace } from "@mui/icons-material";
import { ShowChart } from "@mui/icons-material";
import DomainPopUp from "../../components/Customize/PopUp/DomainPopUp.jsx";
import LinearProgress from "@mui/material/LinearProgress";
import { toast } from "react-toastify";
import DynamicIcon from "../../components/Customize/PopUp/fastworldIcons/DynamicIcon.jsx";
import { FastWorldIconsGetById } from "../../components/Customize/PopUp/fastworldIcons/index.js";
import Back from "../../components/Back.js";

const Customize = () => {
  // const [data, setData] = useState([]);
  // const token = localStorage.getItem("token");
  // const [social, setSocial] = useState([]);
  // const [id, setId] = useState("");

  // const getData = async () => {
  //   try {
  //     const res = await Base_Url.get("owner/links/MyLinks", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         Apipassword: "fastworld2023@#$fosh$",
  //         lang: "ar",
  //       },
  //     });
  //     setData(res.data.Links.Information);
  //     setSocial(res.data.Links.Information.socials);
  //     setId(res.data.Links.public_id);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const { currentTheme, setCurrentTheme } = useTheme();
  // const [videoSource, setVideoSource] = useState(""); // State to hold video source

  // =================================
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [loding, setLoding] = useState(true);
  const [update, setUpdate] = useState(false);
  const { id } = useParams();
  // =================================
  // const [isEditable, setIsEditable] = useState(false);
  // const [id, setId] = useState("your-id"); // Replace with your actual id
  // const linkRef = useRef(null);
  useEffect(() => {
    // getData();
    if (loding) {
      axiosRequset
        .post("/owner/Information/get", {
          id,
        })
        .then((res) => {
          // console.log(res.data);
          setCurrentTheme({
            ...res.data.Information.object,
            public_id: res.data.link.public_id,
            img: res.data.Information.image,
            id: res.data.Information.id,
            Contents: res.data.Information.Contents,
            orders: res.data.Information.orders,
            data: {
              title: res.data.Information.title,
              // ? res.data.Information.title
              // : "title",
              description: res.data.Information.description,
              // ? res.data.Information.description
              // : "desc",
            },
            verified: res.data.Information.verified,
            removeBrand: res.data.Information.remove_brand,
          });
          setError(false);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });

      setTimeout(() => {
        setLoding(false);
      }, 2000);
      // axiosRequset.post("/owner/links/MyLinks").then((res) => {
    }

    // })
    // console.log(currentTheme);
  }, [loding]);
  // const handleEditClick = () => {
  //   setIsEditable(true);
  // };

  const handleCopyClick = () => {
    // linkInputRef.current.select();
    // document.execCommand('copy');
    // console.log(currentTheme?.data?.title, currentTheme?.img);
    const inputField = document.createElement("input");
    inputField.value = `https://fastworldkw.net/${currentTheme.public_id}`;
    document.body.appendChild(inputField);
    inputField.select();
    document.execCommand("copy");
    document.body.removeChild(inputField);
    // alert("Link copied to clipboard");
    toast.success("Link copied to clipboard");
  };
  // const handleCopyClick = () => {
  //   // Create a temporary <input> element
  //   const inputField = document.createElement("input");
  //   inputField.value = `https://fastworldkw.net/${currentTheme.public_id}`;
  //   document.body.appendChild(inputField);
  //   inputField.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(inputField);

  //   // Add Open Graph meta tags dynamically
  //   console.log(currentTheme?.data?.title, currentTheme?.img);
  //   const metaTags = `
  //   <meta property="og:title" content="${currentTheme?.data?.title}">
  //   <meta property="og:image" content="${currentTheme?.img}">
  //   <meta property="og:url" content="https://fastworldkw.net/${currentTheme.public_id}">
  // `;
  //   const tempDiv = document.createElement("div");
  //   tempDiv.innerHTML = metaTags;
  //   document.head.appendChild(tempDiv);
  //   tempDiv.remove();

  //   // Show success message
  //   toast.success("Link copied to clipboard");
  // };
  useEffect(() => {
    document.body.style.background = "#f5f7fc";
  }, []);
  if (loding) {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>
    );
  }
  if (error) {
    return (
      <h3 className="main-cont">Unexpected Application Error! 404 Not Found</h3>
    );
  }
  // if (!currentTheme.id !== id) {
  //   return;
  // }

  return (
    <div
      className="main-cont yousef"
      style={{
        height: "100%",
        //  overflowY: "scroll",
        padding: "0px",
      }}
    >
      {/* <meta property="og:url" content={url} /> */}
      {/* <meta property="og:title" content={currentTheme?.data?.title} /> */}
      <Helmet>
        <meta property="og:title" content={currentTheme?.data?.title} />
        <meta
          property="og:description"
          content={currentTheme?.data?.description}
        />
        <meta property="og:image" content={currentTheme?.img} />
      </Helmet>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>My Title</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet> */}
      <div className="title" style={{ left: "0%" }}>
        <Back link="/dashboard/MyLinks" />
      </div>
      <div
        className="customize-cont"
        style={{
          height: "100%",
          // overflowY: "scroll"
        }}
      >
        <DynamicIcon
          componentName={FastWorldIconsGetById["60"][0]}
          style={{
            width: "40px",
            height: "30px",
            display: "none",
          }}
        />
        <div
          className="control-section"
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Box>
            <Box sx={{ marginY: "30px" }}>
              <Tabs defaultValue={0}>
                <StyledTabsList>
                  <StyledTab value={0}>MyPage</StyledTab>
                  <StyledTab value={1}>Appearance</StyledTab>
                </StyledTabsList>
                <StyledTabPanel value={0}>
                  <MyPage />
                </StyledTabPanel>
                <StyledTabPanel value={1}>
                  <Appearance />
                </StyledTabPanel>
              </Tabs>
            </Box>
          </Box>
        </div>
        <div className="preview-section">
          <div style={{ margin: "30px auto 0", width: "444px" }}>
            <div className="prev-header-container mt-5">
              <div className="logo">
                <img
                  // src={currentTheme?.img ? currentTheme?.img : Logo}
                  src={Logo}
                  alt="Logo"
                  style={{ width: "4rem" }}
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  //  marginBottom: ".5rem",
                  width: "344px",
                }}
              >
                <a
                  href={`https://fastworldkw.net/${currentTheme?.public_id}`}
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  className={`form-control readonly`}
                  rel="noopener noreferrer"
                >
                  {`https://fastworldkw.net/${currentTheme?.public_id}`}
                </a>{" "}
                <EditIcon
                  sx={{
                    fontSize: "17px",
                    marginLeft: "10px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(true)}
                />
              </Box>
              {show && (
                <DomainPopUp
                  setLoding={setLoding}
                  loding={loding}
                  id={id}
                  show={show}
                  setShow={setShow}
                />
              )}
              <div className="copy-button-container">
                <button className="btn btn-secondary" onClick={handleCopyClick}>
                  Copy
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "sticky",
              top: "10px",
              width: "344px",
              height: "fit-content",
              maxHeight: "660px",
              margin: "30px auto 0  ",
              border: "12px solid #000",
              borderRadius: "36px",
              overflow: "hidden",

              // overflow: "auto",
            }}
          >
            <div
            // style={{ overflow: "auto" }}
            >
              <MainStructureTemplate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Customize;

const blueColor = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};
const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};
const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--gray-color-200);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 45%;
  // background: ${blueColor[50]};
  padding: 20px 25px;
  // margin: 10px 30px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  &:hover {
    color: var(--blue-color-200);
  }
  &:focus {
    // color: #fff;
    // outline: 3px solid ${blueColor[200]};
  }
  &.${tabClasses.selected} {
    color: var(--blue-color-200);
  }
  &.${buttonClasses.disabled} {
    // opacity: 0.5;
    // cursor: not-allowed;
  }
`;
const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
  // width: 100%;
  width: fit-content;
  height: 100%;
  // max-height: 430px;
  overflow: auto;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  // border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  border-radius: 12px;
  // opacity: 0.6;
  margin-left: 50px
  `
);
const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  //max-width: 270px;
  width :80%;
  // background-color: ${blueColor[100]};
  // padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 1px solid white;  
  box-shadow: 1px 8px 8px 0px #c6c4c4;
  margin: 2rem 3rem 2rem 5rem;
  // box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `
);
