import React from 'react'
import Register from '../../components/Auth/Register/Register'

const RegisterPage = () => {
  return (
    <Register/>
  )
}

export default RegisterPage
