export const FastWorldIcons = {
  onlineStoreIcons: [
    "Group171",
    "Group174",
    "Group176",
    "Group178",
    "Group185",
    "Group193",
    "Group219",
    "Group221",
    "Group223",
    "Group225",
    "Group227",
    "Group229",
    "Group231",
    "Group238",
  ],
  socialMediaIcons: [
    "Group4",
    "Group18",
    "Group27",
    "Group29",
    "Group31",
    "Group33",
    "Group38",
    "Group40",
    "Group42",
    "Group44",
    "Group46",
    "Group53",
    "Group55",
    "Group89",
    "Group91",
    "Group93",
    "Group107",
    "Group111",
    "Group115",
    "Group117",
    "Group123",
    "Group125",
    "Group127",
    "Group129",
    "Group150",
    "Group152",
    "Group165",
    "Group167",
    "Group270",
    // "Group11231",
    "Group902",
    "Group903",
    "Group904",
    "Group905",
  ],
  contactIcons: [
    ["Group48", "Group489"],
    ["Group87", "Group498"],
    ["Group98", "Group502"],
    ["Group120", "Group508"],
    ["Group154", "Group515"],
    ["Group156", "Group516"],
    ["Group158", "Group517"],
    ["Group169", "Group520"],
    ["Group183", "Group525"],
    ["Group200", "Group529"],
    ["Group233", "Group537"],
    // ["Group245", "Group539"],
    ["Group247", "Group540"],
    ["Group251", "Group541"],
    ["Group253", "Group542"],
    ["Group257", "Group543"],
    ["Group260", "Group544"],
    ["Group263", "Group545"],
    ["Group265", "Group546"],
    ["Group272", "Group548"],
    ["Group900"],
    // ["Group901"],
  ],
};

export const FastWorldIconsId = {
  onlineStoreIcons: {
    1: ["Group171"],
    2: ["Group174"],
    3: ["Group176"],
    4: ["Group178"],
    5: ["Group185"],
    6: ["Group193"],
    7: ["Group219"],
    8: ["Group220"],
    9: ["Group221"],
    10: ["Group223"],
    11: ["Group225"],
    12: ["Group227"],
    13: ["Group229"],
    14: ["Group231"],
    64: ["Group238"],
    // 64: ["Group220"],
  },
  socialMediaIcons: {
    15: ["Group4"],
    16: ["Group18"],
    17: ["Group27"],
    18: ["Group29"],
    19: ["Group31"],
    20: ["Group33"],
    21: ["Group38"],
    22: ["Group40"],
    23: ["Group42"],
    24: ["Group44"],
    25: ["Group46"],
    26: ["Group53"],
    27: ["Group55"],
    28: ["Group89"],
    29: ["Group91"],
    30: ["Group93"],
    31: ["Group107"],
    32: ["Group111"],
    33: ["Group115"],
    34: ["Group117"],
    35: ["Group123"],
    36: ["Group125"],
    37: ["Group127"],
    38: ["Group129"],
    39: ["Group150"],
    40: ["Group152"],
    41: ["Group165"],
    42: ["Group167"],
    43: ["Group270"],
    // 44: ["Group11231 "],
    // 67: ["Group902", "Group904", "Group905", "Group903"],
    67: ["Group902", "Group904", "Group903", "Group905"],
    // 67: ["Group902", "Group904", "Group905", "Group903"],
    // 67: ["Group902", "Group904", "Group905", "Group903"],
  },

  contactIcons: {
    45: ["Group48", "Group489"],
    46: ["Group87", "Group498"],
    47: ["Group98", "Group502"],
    48: ["Group120", "Group508"],
    49: ["Group154", "Group515"],
    50: ["Group156", "Group516"],
    51: ["Group158", "Group517"],
    52: ["Group169", "Group520"],
    53: ["Group183", "Group525"],
    54: ["Group200", "Group529"],
    55: ["Group233", "Group537"],
    // 56: ["Group245", "Group539"],
    57: ["Group247", "Group540"],
    58: ["Group251", "Group541"],
    59: ["Group253", "Group542"],
    60: ["Group257", "Group543"],
    61: ["Group260", "Group544"],
    62: ["Group263", "Group545"],
    63: ["Group265", "Group546"],
    65: ["Group900"],
    // 66: ["Group901"],
  },
};

let x = [];
export const FastWorldIconsGetById = {
  1: ["Group171", "Group549", "Group338", "Group614"],
  2: ["Group174", "Group550", "Group339", "Group615"],
  3: ["Group176", "Group551", "Group340", "Group616"],
  4: ["Group178", "Group552", "Group341", "Group617"],
  5: ["Group185", "Group553", "Group342", "Group618"],
  6: ["Group193", "Group554", "Group343", "Group619"],
  7: ["Group219", "Group555", "Group344", "Group620"],
  8: ["Group220", "Group556", "Group345", "Group621"],
  9: ["Group221", "Group557", "Group346", "Group622"],
  10: ["Group223", "Group558", "Group347", "Group623"],
  11: ["Group225", "Group559", "Group348", "Group624"],
  12: ["Group227", "Group560", "Group349", "Group625"],
  13: ["Group229", "Group561", "Group350", "Group626"],
  14: ["Group231", "Group562", "Group351", "Group627"],
  64: ["Group238", "Group563", "Group352", "Group628"],

  15: ["Group4", "Group484", "Group273", "Group564"],
  16: ["Group18", "Group485", "Group274", "Group565"],
  17: ["Group27", "Group486", "Group275", "Group566"],
  18: ["Group29", "Group487", "Group276", "Group567"],
  19: ["Group31", "Group488", "Group277", "Group568"],
  20: ["Group33", "Group489", "Group278", "Group569"],
  21: ["Group38", "Group490", "Group279", "Group570"],
  22: ["Group40", "Group491", "Group280", "Group571"],
  23: ["Group42", "Group492", "Group281", "Group572"],
  24: ["Group44", "Group493", "Group282", "Group573"],
  25: ["Group46", "Group494", "Group283", "Group574"],
  26: ["Group53", "Group496", "Group284", "Group575"],
  27: ["Group55", "Group497", "Group285", "Group576"],
  28: ["Group89", "Group499", "Group286", "Group577"],
  29: ["Group91", "Group500", "Group287", "Group578"],
  30: ["Group93", "Group501", "Group288", "Group579"],
  31: ["Group107", "Group504", "Group289", "Group580"],
  32: ["Group111", "Group505", "Group290", "Group581"],
  33: ["Group115", "Group506", "Group291", "Group582"],
  34: ["Group117", "Group507", "Group292", "Group583"],
  35: ["Group123", "Group509", "Group293", "Group584"],
  36: ["Group125", "Group510", "Group294", "Group585"],
  37: ["Group127", "Group511", "Group295", "Group586"],
  38: ["Group129", "Group512", "Group296", "Group587"],
  39: ["Group150", "Group513", "Group297", "Group588"],
  40: ["Group152", "Group514", "Group298", "Group590"],
  41: ["Group165", "Group518", "Group299", "Group591"],
  42: ["Group167", "Group519", "Group300", "Group592"],
  43: ["Group270", "Group547", "Group301", "Group593"],
  // 44: ["Group11231 ", "Path317 ", "Group302", "Group629"],
  67: ["Group902", "Group904", "Group903", "Group905"],

  45: ["Group48", "Group489", "Group303", "Group594"],
  46: ["Group87", "Group498", "Group304", "Group595"],
  47: ["Group98", "Group502", "Group305", "Group596"],
  48: ["Group120", "Group508", "Group306", "Group597"],
  49: ["Group154", "Group515", "Group307", "Group598"],
  50: ["Group156", "Group516", "Group308", "Group599"],
  51: ["Group158", "Group517", "Group309", "Group600"],
  52: ["Group169", "Group520", "Group310", "Group601"],
  53: ["Group183", "Group525", "Group311", "Group602"],
  54: ["Group200", "Group529", "Group312", "Group603"],
  55: ["Group233", "Group537", "Group313", "Group604"],
  // 56: ["Group245", "Group539", "Group314", "Group605"],
  57: ["Group247", "Group540", "Group315", "Group606"],
  58: ["Group251", "Group541", "Group316", "Group607"],
  59: ["Group253", "Group542", "Group317", "Group608"],
  60: ["Group257", "Group543", "Group318", "Group609"],
  61: ["Group260", "Group544", "Group319", "Group610"],
  62: ["Group263", "Group545", "Group320", "Group611"],
  63: ["Group265", "Group546", "Group321", "Group612"],
  65: ["Group900"],
  // 66: ["Group901"],
};

export const FastWorldIconsGetByIdForButton = {
  1: ["Group171", "Group614"],
  2: ["Group174", "Group615"],
  3: ["Group176", "Group616"],
  4: ["Group178", "Group617"],
  5: ["Group185", "Group618"],
  6: ["Group193", "Group619"],
  7: ["Group219", "Group620"],
  8: ["Group220", "Group621"],
  9: ["Group221", "Group622"],
  10: ["Group223", "Group623"],
  11: ["Group225", "Group624"],
  12: ["Group227", "Group625"],
  13: ["Group229", "Group626"],
  14: ["Group231", "Group627"],
  64: ["Group238", "Group628"],

  15: ["Group4", "Group564"],
  16: ["Group18", "Group565"],
  17: ["Group27", "Group566"],
  18: ["Group29", "Group567"],
  19: ["Group31", "Group568"],
  20: ["Group33", "Group569"],
  21: ["Group38", "Group570"],
  22: ["Group40", "Group571"],
  23: ["Group42", "Group572"],
  24: ["Group44", "Group573"],
  25: ["Group46", "Group574"],
  26: ["Group53", "Group575"],
  27: ["Group55", "Group576"],
  28: ["Group89", "Group577"],
  29: ["Group91", "Group578"],
  30: ["Group93", "Group579"],
  31: ["Group107", "Group580"],
  32: ["Group111", "Group581"],
  33: ["Group115", "Group582"],
  34: ["Group117", "Group583"],
  35: ["Group123", "Group584"],
  36: ["Group125", "Group585"],
  37: ["Group127", "Group586"],
  38: ["Group129", "Group587"],
  39: ["Group150", "Group588"],
  40: ["Group152", "Group590"],
  41: ["Group165", "Group591"],
  42: ["Group167", "Group592"],
  43: ["Group270", "Group593"],
  // 44: ["Group11231", "Group629"],
  67: ["Group902", "Group904", "Group903", "Group905"],

  45: ["Group48", "Group594"],
  46: ["Group87", "Group595"],
  47: ["Group98", "Group596"],
  48: ["Group120", "Group597"],
  49: ["Group154", "Group598"],
  50: ["Group156", "Group599"],
  51: ["Group158", "Group600"],
  52: ["Group169", "Group601"],
  53: ["Group183", "Group602"],
  54: ["Group200", "Group603"],
  55: ["Group233", "Group604"],
  // 56: ["Group245", "Group605"],
  57: ["Group247", "Group606"],
  58: ["Group251", "Group607"],
  59: ["Group253", "Group608"],
  60: ["Group257", "Group609"],
  61: ["Group260", "Group610"],
  62: ["Group263", "Group611"],
  63: ["Group265", "Group612"],
  65: ["Group900"],
  // 66: ["Group901"],
};
