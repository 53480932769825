import React from "react";
import Table from "../components/MyAccount/Table";
const MyAccount = () => {
  return (
    <div className="main-cont">
      <div className="myAccountCont">
        <Table />
      </div>
    </div>
  );
};

export default MyAccount;
