import styled from "styled-components";
import z from "../../assets/Image/avatar1.png";
import { hexToRgb } from "../../utils/hexToRgb";
// export const MobileContainer = styled.section`
// background-color: ${({ theme }) => theme.colors.screenBgc};
//   border: 1px solid ${({ theme }) => theme.colors.borderColor};
//   height: 100%;
//   // border-radius: 10px;
//   font-family: ${({ theme }) => theme.FontFace.textStyle};
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   padding: 3rem 1rem 3rem 1rem;
// }

// `;

// export const Mobile = styled.div`
//   background-color: ${({ theme }) => theme.colors.quoteBgc};
//   border: 1px solid ${({ theme }) => theme.colors.borderColor};
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 20px;
//   border-radius: 3px;
//   max-width: 450px;
//   color :red
// `;

// export const ProfileCont = styled.div`
//   background-color: ${({ theme }) => theme.colors.quoteBgc};
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 20px;
//   // border-radius: 3px;
//   max-width: 450px;
//   display: flex;
//   // gap: 1rem;
//   align-items: center;
//   flex-direction: column;

// `;

// export const ProfileImage = styled.div`
//   background-color: ${({ theme }) => theme.colors.quoteBgc};
//   border: 1px solid ${({ theme }) => theme.colors.borderColor};
//   margin-bottom: 32px;
//   border-radius: 3px;
//   color: red;
//   width: fit-content;
// `;

// export const ProfileTitle = styled.div`
//   color: ${({ theme }) => theme.colors.generalText};

// `;
// export const ProfileDesc = styled.div`
//   color: ${({ theme }) => theme.colors.generalText};

// `;

export const StyledButton = styled.a`
  position: relative;
  width: 100%;
  margin: 10px 0;
  /* padding: 10px 2px; */
  font-size: 16px;
  color: ${({ theme }) => theme.StyledButton.color};
  /* background: ${({ theme }) => theme.StyledButton.background}; */
  /* background: ${({ theme }) =>
    `rgba(${theme.StyledButton.background}, ${theme.StyledButton.opacity})`}; */
  // border-radius: 5px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  max-width: 450px;
  position: relative;
  height: 50px;
  border-width: ${({ theme }) => theme.StyledButton.borderSize}px;
  border-style: ${({ theme }) => theme.StyledButton.borderType};
  border-color: ${({ theme }) => theme.StyledButton.borderColor};
  border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
  /* box-shadow: ${({ theme }) =>
    `${theme.StyledButton.boxShadowColor} ${theme.StyledButton.boxShadowProperties}`}; */
  /* background: rgba(255, 0, 255, 0.3); */
  /* background: ${({ theme }) => theme.StyledButton.background}; */
  ${({ theme }) => {
    if (theme?.StyledButton?.boxShadowColor?.startsWith("r")) {
      return `box-shadow: ${theme.StyledButton.boxShadowProperties} ${theme?.StyledButton?.boxShadowColor};`;
    }
    const rgbColor = theme?.StyledButton?.boxShadowColor?.startsWith("#")
      ? hexToRgb(theme.StyledButton.boxShadowColor)
      : theme.StyledButton.boxShadowColor;
    console.log(
      `box-shadow: ${theme.StyledButton.boxShadowColor} rgba(${rgbColor}, ${
        theme?.StyledButton?.opacity ?? 1
      });`
    );

    return `box-shadow: ${
      theme.StyledButton.boxShadowProperties
    } rgba(${rgbColor}, ${theme?.StyledButton?.opacity ?? 1});`; // Adjust opacity value as needed
  }}
  ${({ theme }) => {
    if (theme?.StyledButton?.background?.startsWith("r")) {
      return `background: ${theme?.StyledButton?.background};`;
    }
    const rgbColor = theme?.StyledButton?.background?.startsWith("#")
      ? hexToRgb(theme.StyledButton.background)
      : theme.StyledButton.background;
    console.log(
      `background: rgba(${rgbColor}, ${theme?.StyledButton?.opacity || 1});`
    );

    return `background: rgba(${rgbColor}, ${
      theme?.StyledButton?.opacity ?? 1
    });`; // Adjust opacity value as needed
  }}
  cursor: pointer;
  display: block;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  overflow: hidden;
  &:hover {
    transform: scale(1.08);
  }
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    height: 100%;
    padding: 10px 2px; */
    /* background: rgba(255, 0, 255, 0.3); */
    /* background: ${({ theme }) =>
      `rgba(${theme.StyledButton.background}, ${
        theme.StyledButton.opacity || 1
      })`}; */
    /* border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px; */
    /* opacity: ${({ theme }) => theme?.StyledButton?.opacity}; */
    z-index: -1;
    /* background: ${({ theme }) => theme.StyledButton.background}; */
  }
  & p {
    margin-bottom: 0;
  }
  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
    opacity: ${({ theme }) => theme?.StyledButton?.opacity};
    z-index: -1;
    background: ${({ theme }) => theme.StyledButton.background};
  } */
`;
// export const StyledButtonDiv = styled.div`
//   width: 100%;
//   margin-bottom: 10px;
//   padding: 10px 2px;
//   font-size: 16px;
//   color: ${({ theme }) => theme.StyledButton.color};
//   // border-radius: 5px;
//   transition: all 0.3s ease;
//   margin-bottom: 20px;
//   max-width: 450px;
//   position: relative;
//   height: 50px;
//   border: ${({ theme }) => theme.StyledButton.borderSize}px
//     ${({ theme }) => theme.StyledButton.borderType}
//     ${({ theme }) => theme.StyledButton.borderColor};
//   border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
//   box-shadow: ${({ theme }) =>
//     `${theme.StyledButton.boxShadowColor} ${theme.StyledButton.boxShadowProperties}`};
//   // background: ${({ theme }) => theme.StyledButton.background};
//   cursor: pointer;
//   display: block;
//   justify-content: center;
//   align-items: center;
//   font-weight: bold;
//   box-sizing: border-box;
//   &:hover {
//     transform: scale(1.08);
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: ${({ theme }) => theme?.StyledButton?.opacity};
//     z-index: -1;
//     background: ${({ theme }) => theme.StyledButton.background};
//   }
// `;
export const StyledButtonDiv = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0;
  /* padding: 10px 2px; */
  font-size: 16px;
  color: ${({ theme }) => theme.StyledButton.color};
  /* background: ${({ theme }) => theme.StyledButton.background}; */
  /* background: ${({ theme }) =>
    `rgba(${theme.StyledButton.background}, ${theme.StyledButton.opacity})`}; */
  // border-radius: 5px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  max-width: 450px;
  position: relative;
  height: 50px;
  border-width: ${({ theme }) => theme.StyledButton.borderSize}px;
  border-style: ${({ theme }) => theme.StyledButton.borderType};
  border-color: ${({ theme }) => theme.StyledButton.borderColor};
  border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
  /* box-shadow: ${({ theme }) =>
    `${theme.StyledButton.boxShadowColor} ${theme.StyledButton.boxShadowProperties}`}; */
  /* background: rgba(255, 0, 255, 0.3); */
  /* background: ${({ theme }) => theme.StyledButton.background}; */
  ${({ theme }) => {
    if (theme?.StyledButton?.boxShadowColor?.startsWith("r")) {
      return `box-shadow: ${theme.StyledButton.boxShadowProperties} ${theme?.StyledButton?.boxShadowColor};`;
    }
    const rgbColor = theme?.StyledButton?.boxShadowColor?.startsWith("#")
      ? hexToRgb(theme.StyledButton.boxShadowColor)
      : theme.StyledButton.boxShadowColor;
    console.log(
      `box-shadow: ${theme.StyledButton.boxShadowColor} rgba(${rgbColor}, ${
        theme?.StyledButton?.opacity ?? 1
      });`
    );

    return `box-shadow: ${
      theme.StyledButton.boxShadowProperties
    } rgba(${rgbColor}, ${theme?.StyledButton?.opacity ?? 1});`; // Adjust opacity value as needed
  }}
  ${({ theme }) => {
    if (theme?.StyledButton?.background?.startsWith("r")) {
      return `background: ${theme?.StyledButton?.background};`;
    }
    const rgbColor = theme?.StyledButton?.background?.startsWith("#")
      ? hexToRgb(theme.StyledButton.background)
      : theme.StyledButton.background;
    console.log(
      `background: rgba(${rgbColor}, ${theme?.StyledButton?.opacity || 1});`
    );

    return `background: rgba(${rgbColor}, ${
      theme?.StyledButton?.opacity ?? 1
    });`; // Adjust opacity value as needed
  }}
  cursor: pointer;
  display: block;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  overflow: hidden;
  &:hover {
    transform: scale(1.08);
  }
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    height: 100%;
    padding: 10px 2px; */
    /* background: rgba(255, 0, 255, 0.3); */
    /* background: ${({ theme }) =>
      `rgba(${theme.StyledButton.background}, ${
        theme.StyledButton.opacity || 1
      })`}; */
    /* border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px; */
    /* opacity: ${({ theme }) => theme?.StyledButton?.opacity}; */
    z-index: -1;
    /* background: ${({ theme }) => theme.StyledButton.background}; */
  }
  & p {
    margin-bottom: 0;
  }
  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
    opacity: ${({ theme }) => theme?.StyledButton?.opacity};
    z-index: -1;
    background: ${({ theme }) => theme.StyledButton.background};
  } */
`;
export const IconsForButton = styled.div`
  position: absolute;
  left: 10px;
  /* left: -110px; */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 25px;
  width: 25px; */
  width: 34px;
  height: 34px;
  /* width: ${({ theme }) => theme?.StyledButton.thumbnail?.size || "34px"};
  height: ${({ theme }) => theme?.StyledButton.thumbnail?.size || "34px"}; */
  /* textalign: start; */
  /* background-color: ${({ theme }) =>
    theme?.StyledButton?.thumbnail?.styleType === 0 ? "#4676ed" : "white"}; */
  /* background-color: #4676ed; */
  overflow: hidden;
  color: ${({ theme }) =>
    theme?.StyledButton.thumbnail?.color || theme.StyledButton?.color};
  // border: 3px solid
  /* ${({ theme }) =>
    theme?.StyledButton?.thumbnail?.color || theme.StyledButton?.color}; */
  border-radius: ${({ theme }) => theme?.StyledButton?.thumbnail?.borderRadius};
`;

// *********************************

export const Container = styled.div`
  position: relative;

  border: ${({ theme }) => theme.Container.border};
  /* padding: ${({ theme }) => theme.Container.padding}; */
  display: ${({ theme }) => theme.Container.display};
  color: ${({ theme }) => theme.Container.color};
  font-family: ${({ theme }) => theme.Container.fontFamily} !important;
  width: 100%;
  height: ${({ isDashboard }) => (isDashboard ? "660px" : "100%")};
  overflow: auto;
  /* height: 100%; */
  
  /* padding-bottom: 30px; */
  text-align: center;
  /* background-color: rgba(
    0,
    0,
    0,
    ${({ theme }) => (theme.Container?.shade ? theme.Container?.shade : 0)} */
  ); /* Adjust the color and opacity */

  /* min-height: 100vh; */
  box-sizing: border-box;
  // backdrop-filter: blur(
  //   ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
  // );
  // filter: blur(
  //   ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
  // );
  background-color: rgba(0,0,0, ${({ theme }) =>
    theme.Container?.shade ? theme.Container?.shade : 0});
    background: ${({ theme }) =>
      theme.Container.backgroundImage
        ? `url(${theme.Container.backgroundImage})`
        : theme.Container.background};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    /* z-index: -1; */
    // filter: blur(
    //   ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
    // );
    & > div:first-of-type {
      /* overflow: auto; */
       padding: ${({ theme }) => theme.Container.padding};
       backdrop-filter: blur(
       ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
     );
     height: 100%;
    };
  &::before {
    content: "";
    position: absolute;
    /* min-height: 660px; */
    width: 100%;
    top: 0;
    left: 0;
    /* right: 0;
    bottom: 0; */
    background-color: rgba(0,0,0, ${({ theme }) =>
      theme.Container?.shade ? theme.Container?.shade : 0});
    background: ${({ theme }) =>
      theme.Container.backgroundImage
        ? `url(${theme.Container.backgroundImage})`
        : theme.Container.background};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    /* z-index: -1; */
    /* filter: blur(
      ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
    ); */
   
      // pointer-events: none;
      // border-radius: inherit;
    }
`;
// export const DivProfile = styled.div`
//   width: 98vw;
//   height: 98vh;
//   display: ${({ theme }) =>
//     theme.DivProfilePicture?.display
//       ? theme.DivProfilePicture?.display
//       : "flex"};
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   text-align: ${({ theme }) =>
//     theme.DivProfilePicture?.textAlign
//       ? theme.DivProfilePicture?.textAlign
//       : "center"};
// `;

export const Contact = styled.div`
  max-width: ${({ theme }) => theme.Contact.maxWidth};
  // background: ${({ theme }) => theme.Contact.background};
  // border:  ${({ theme }) => theme.Contact.border};
  padding: ${({ theme }) => theme.Contact.padding};
  display: ${({ theme }) => theme.Contact.display};
  color: ${({ theme }) => theme.Contact.color};
  /* backdrop-filter: blur(
    ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
  ); */
  max-width: 850px;
  width: 90%;
  /* height: 100%; */
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  backdrop-filter: blur(
    ${({ theme }) => (theme.Container?.blur ? theme.Container?.blur : 0)}px
  );
`;
// Profile Picture Styles

export const ProfilePicture = styled.img`
  width: ${({ theme }) =>
    theme.ProfilePicture?.size ? theme.ProfilePicture?.size : 200}px;

  height: ${({ theme }) =>
    theme.ProfilePicture?.size ? theme.ProfilePicture?.size : 200}px;

  max-width: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD == "row" ||
    theme.DivProfilePicture?.design?.flexDirectionD == "row-reverse"
      ? 135
      : ""}px;
  max-height: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD == "row" ||
    theme.DivProfilePicture?.design?.flexDirectionD == "row-reverse"
      ? 135
      : ""}px;

  border-radius: ${({ theme }) =>
    theme.ProfilePicture?.borderRadius
      ? theme.ProfilePicture?.borderRadius
      : 50}%;
  border: ${({ theme }) =>
      theme.ProfilePicture?.borderSize ? theme.ProfilePicture?.borderSize : 0}px
    solid white;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-bottom: 50px;
`;
export const DivProfilePicture = styled.div`
  width: ${({ theme }) =>
    theme.ProfilePicture?.size ? theme.ProfilePicture?.size : 200}px;
  height: ${({ theme }) =>
    theme.ProfilePicture?.size ? theme.ProfilePicture?.size : 200}px;
  max-width: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD == "row" ||
    theme.DivProfilePicture?.design?.flexDirectionD == "row-reverse"
      ? 135
      : ""}px;
  max-height: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD == "row" ||
    theme.DivProfilePicture?.design?.flexDirectionD == "row-reverse"
      ? 135
      : ""}px;
  border-radius: ${({ theme }) =>
    theme.ProfilePicture?.borderRadius
      ? theme.ProfilePicture?.borderRadius
      : 50}%;
  // border: 2px solid ${({ theme }) => theme.Contact.color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  margin: 20px 0 10px;
`;
export const DivProfilePictureConatinar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD || "column"};
  align-items: ${({ theme }) =>
    theme.DivProfilePicture?.design?.alignItemsD
      ? theme.DivProfilePicture?.design?.alignItemsD
      : "center"};
  /* justify-content: space-between; */
  justify-content: center;
  gap: 23px;
  margin: 0 auto;
  /* position: relative; */
  max-width: 450px;
`;

export const DivTitleAndDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) =>
    theme.DivProfilePicture?.design?.alignItemsT
      ? theme.DivProfilePicture?.design?.alignItemsT
      : "center"};
  justify-content: center;
  /* text-align: start; */
  // margin-top: 80px;
  /* padding: 20px 0px; */
  max-width: 450px;
  // margin-bottom: 20px;
  // margin: 50px 0px/ 20px;
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  // margin: 50px auto 20px auto;
`;
export const Desc = styled.p`
  font-size: 16px;
  line-height: 1.5;
  width: ${({ theme }) =>
    theme.DivProfilePicture?.design?.flexDirectionD == "row" ||
    theme.DivProfilePicture?.design?.flexDirectionD == "row-reverse"
      ? 150
      : ""}px;
  word-wrap: break-word;
  /* margin-bottom: 10px; */
`;
export const DivButtonInfo = styled.div`
  width: 100%;
  // border: 1px solid ${({ theme }) => theme.Contact.color};
  color: ${({ theme }) => theme.Contact.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  padding: 20px 0px;
  /* margin-bottom: 20px; */
  // media queries
  @media (min-width: 768px) {
  }
`;

// export const ButtonVlog = styled.button`
//   width: 90%;
//   margin-bottom: 10px;
//   padding: 10px 20px;
//   font-size: 16px;
//   color: ${({ theme }) => theme.Contact.color};
//   border-radius: 5px;
//   border: 1px solid ${({ theme }) => theme.Contact.color};
//   cursor: pointer;
//   transition: all 0.3s ease;
//   background: transparent;
//   &:hover {
//     transform: scale(1.1);
//   }
// `
// export const ButtonAbout = styled.button`
//   width: 90%;
//   padding: 10px 20px;
//   margin-bottom: 10px;
//   color: ${({ theme }) => theme.Contact.color};
//   font-size: 16px;
//   border-radius: 5px;
//   border: 1px solid ${({ theme }) => theme.Contact.color};
//   cursor: pointer;
//   background: transparent;

//   transition: all 0.3s ease;
//   &:hover {
//     transform: scale(1.1);
//   }
// `

// contact us styles
export const DivContact = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  padding: 20px 0px;
  margin-bottom: 20px;
  // media queries
  @media (min-width: 768px) {
  }
`;
export const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.StyledButton.color};
  border: none;
  background: transparent;
  outline: none;
  border-bottom: 1px solid black;
  $:placeholder {
    color: black;
  }
`;
export const Submit = styled.button`
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid
    ${({ theme }) => theme?.StyledButton?.ActionButton?.color || "black"};
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme?.StyledButton?.ActionButton?.color || "black"};
  background: ${({ theme }) =>
    theme?.StyledButton?.ActionButton?.background || "transparent"};
  &:hover {
    transform: scale(1.1);
  }
`;

// link tree styles
export const DivLinkTree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-top: 20px; */
  /* padding: 20px 0px; */
  margin-bottom: 20px;
  // media queries
  @media (min-width: 768px) {
  }
`;
export const TheLink = styled.a`
  text-decoration: none;
  // color: black;
  display: block;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: ${({ theme }) => theme.StyledButton.border};
  border-radius: ${({ theme }) => theme.StyledButton.borderRadius};
  box-shadow: ${({ theme }) => theme.StyledButton.boxShadow};
  background: ${({ theme }) => theme.StyledButton.background};

  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

export const SocialCont = styled.div`
  padding: 10px;
  width: 100%;
  margin: 20px auto;

  // border-radius: 3px;
  max-width: 450px;
  color: blue;
  display: flex;
  gap: 15px;
  font-size: 26px;
  justify-content: ${({ theme }) =>
    theme?.Container?.social?.align || "center"};
  display: flex;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  // align-items: left;
`;

export const SocialIcon = styled.div`
  display: block;
  width: 34px;
  height: 34px;
  // border-radius: ${({ theme }) =>
    theme?.Container?.social?.borderRadius || 0}px;
  color: ${({ theme }) => theme?.Container?.social?.color || "currentColor"};
  /* background-color: ${({ theme }) =>
    theme?.Container?.social?.color || "currentColor"}; */
  overflow: hidden;
  /* border-radius: 50%; */
  & svg rect {
    /* color: ${({ theme }) =>
      theme?.Container?.social?.color || "currentColor"}; */
    /* fill: ${({ theme }) =>
      theme?.Container?.social?.color || "currentColor"}; */
  }
`;

export const BlookContainer = styled.div`
  position: relative;
  border: ${({ theme }) => theme.StyledButton.borderSize}px
    ${({ theme }) => theme.StyledButton.borderType}
    ${({ theme }) => theme.StyledButton.borderColor};

  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  margin: 20px 0px;
  color: ${({ theme }) => theme?.StyledButton?.color};
  border-radius: ${({ theme }) => theme.StyledButton.borderRadius}px;
  box-shadow: ${({ theme }) =>
    `${theme.StyledButton.boxShadowColor} ${theme.StyledButton.boxShadowProperties}`};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${({ theme }) => theme?.StyledButton?.opacity};
    z-index: -1;
    background: ${({ theme }) => theme.StyledButton?.background};
  }
`;

export const InputField = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
  transition: all 0.3s ease;
  $:placeholder {
    color: ${({ theme }) => theme.Contact.color};
  }
`;
