// Utility function to convert hex to RGB
export const hexToRgb = (hex) => {
  // Remove the hash sign if present
  hex = hex.replace(/^#/, "");

  // Ensure hex has 6 characters
  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error("Invalid hex color");
  }

  // Expand short hex (e.g., #abc to #aabbcc)
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Convert hex to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};
