import React from 'react'
import Template from '../components/Template/Template'

const Templates = () => {
  
  return (
    <div>
      <div className='title'> Templates</div>
        <div className='main-cont'>
        <Template/>
      </div>
    </div>
  )
}

export default Templates
