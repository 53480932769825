import React, { useState } from "react";
import { FaTh, Star, Delete, FaThLarge, FaGlobe } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

import { DeleteForever, LocationCity, StarBorder } from "@mui/icons-material";

const Insightbar = ({ children }) => {
  const [selectedLink, setSelectedLink] = useState(null);
  const navigate = useNavigate();

  const menuItem_v2 = [
    {
      path: "./Overview",
      name: "Overview",
      icon: <FaGlobe />,
    },
    {
      path: "./Sources",
      name: "Sources",
      icon: <StarBorder />,
    },
    {
      path: "./Links",
      name: "Links",
      icon: <DeleteForever />,
    },
    {
      path: "./Socials",
      name: "Socials",
      icon: <StarBorder />,
    },
    {
      path: "./Devices",
      name: "Devices",
      icon: <StarBorder />,
    },
    {
      path: "./Systems",
      name: "Systems",
      icon: <StarBorder />,
    },
    {
      path: "./Locations",
      name: "Locations",
      icon: <LocationCity />,
    },
  ];

  const handleLinkClick = (path) => {
    setSelectedLink(path);
    navigate(path);
  };

  return (
    <div className="container main-cont">
      <div className="Insightbar-cont" style={{ width: "25%" }}>
        {menuItem_v2.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div style={{ display: "block" }} className="link_text">
              {item.name}
            </div>
          </NavLink>
        ))}
        <main style={{ paddingBottom: "0" }}>{children}</main>
      </div>
    </div>
  );
};

export default Insightbar;
