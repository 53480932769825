import React from "react";
import { Page } from "react-facebook";

const FacebookPageT = ({ data }) => {
  return (
    <div>
      <h1>Facebook Page</h1>
      <Page
        href={data.link}
        hideCover={false}
        showFacepile={true}
        adaptContainerWidth={false}
        smallHeader={false}
        hideCTA={false}
      />
    </div>
  );
};

export default FacebookPageT;
