import React from "react";
import "./section3.css";
import earth from "../../../assets/earth-svgrepo-com 1.png";
import Product from "../product/Product";
const Section3 = () => {
  return (
    <div className="section3">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="section-text">
              <h1>Analyze your audience and keep your followers engaged</h1>
              <p>
                Track your engagement over time, monitor revenue and learn
                what’s converting your audience. Make informed updates on the
                fly to keep them coming back.
              </p>
              <button>Pricing Plan</button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="section-card">
              <h2>Best Performing</h2>
              <Product count={1} />
              <Product count={2} />
              <Product count={3} />
              <Product count={4} />
              <Product count={5} />
            </div>
            <img src={earth} className="earth" alt="" />
            <div className="chart">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
