import styled from "styled-components";

export const ThemeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  // justify-content: space;
  align-items: center;
  margin: auto;
  width: 85%;
  padding: 1px;
`;

export const ThemeButton = styled.button`
  margin: 5px 5px;
  padding: 10px;
  font-size: 0.5rem;
  /* border: 3px solid #999; */
  border: 3px solid ${(props) => (props.active ? "#007aff" : "#999")};
  border-radius: 5px;
  width: 10rem;
  height: 8rem;
  cursor: pointer;

  &:hover {
    border: 3px solid #007aff;
    box-shadow: 2px 2px 2px #007aff;
  }
`;
export const FontButton = styled.button`
  // margin: 5px 5px;
  // padding: 10px;
  // font-size: 0.5rem;
  // border: 3px solid red;
  // border-radius: 5px;
  // width: 8rem;
  // height: 8rem;
  // cursor: pointer;

  // &:hover {
  //   box-shadow: 2px 2px 2px red;
`;
