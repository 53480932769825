import React from 'react'
import "./product.css"
import image from "../../../assets/1 1.png"
const Product = (props) => {
  return (
    <div>
          <div className="product">
                        <span>{props.count}</span>
                        <img src={image} width={40} height={36} alt="" />
                        <div className="data">
                            <h3>Lipsticks</h3>
                            <p>beauty.store/lipstick</p>
                        </div>
                       </div>
    </div>
  )
}

export default Product