import React, { useEffect } from "react";
import { ReactComponent as Rectangle143 } from "./online store icons/Rectangle143.svg";
import { ReactComponent as Group171 } from "./online store icons/Group171.svg";
import { ReactComponent as Group174 } from "./online store icons/Group174.svg";
import { ReactComponent as Group176 } from "./online store icons/Group176.svg";
import { ReactComponent as Group178 } from "./online store icons/Group178.svg";
import { ReactComponent as Group185 } from "./online store icons/Group185.svg";
import { ReactComponent as Group193 } from "./online store icons/Group193.svg";
import { ReactComponent as Group219 } from "./online store icons/Group219.svg";
import { ReactComponent as Group220 } from "./online store icons/Group 220.svg";
import { ReactComponent as Group221 } from "./online store icons/Group221.svg";
import { ReactComponent as Group223 } from "./online store icons/Group223.svg";
import { ReactComponent as Group225 } from "./online store icons/Group225.svg";
import { ReactComponent as Group227 } from "./online store icons/Group227.svg";
import { ReactComponent as Group229 } from "./online store icons/Group229.svg";
import { ReactComponent as Group231 } from "./online store icons/Group231.svg";
import { ReactComponent as Group238 } from "./online store icons/Group238.svg";
import { ReactComponent as Group4 } from "./social media icons/Group4.svg";
import { ReactComponent as Group18 } from "./social media icons/Group18.svg";
import { ReactComponent as Group27 } from "./social media icons/Group27.svg";
import { ReactComponent as Group29 } from "./social media icons/Group29.svg";
import { ReactComponent as Group31 } from "./social media icons/Group31.svg";
import { ReactComponent as Group33 } from "./social media icons/Group33.svg";
import { ReactComponent as Group38 } from "./social media icons/Group38.svg";
import { ReactComponent as Group40 } from "./social media icons/Group40.svg";
import { ReactComponent as Group42 } from "./social media icons/Group42.svg";
import { ReactComponent as Group44 } from "./social media icons/Group44.svg";
import { ReactComponent as Group46 } from "./social media icons/Group46.svg";
import { ReactComponent as Group53 } from "./social media icons/Group53.svg";
import { ReactComponent as Group55 } from "./social media icons/Group55.svg";
import { ReactComponent as Group89 } from "./social media icons/Group89.svg";
import { ReactComponent as Group91 } from "./social media icons/Group91.svg";
import { ReactComponent as Group93 } from "./social media icons/Group93.svg";
import { ReactComponent as Group107 } from "./social media icons/Group107.svg";
import { ReactComponent as Group111 } from "./social media icons/Group111.svg";
import { ReactComponent as Group115 } from "./social media icons/Group115.svg";
import { ReactComponent as Group117 } from "./social media icons/Group117.svg";
import { ReactComponent as Group123 } from "./social media icons/Group123.svg";
import { ReactComponent as Group125 } from "./social media icons/Group125.svg";
import { ReactComponent as Group127 } from "./social media icons/Group127.svg";
import { ReactComponent as Group129 } from "./social media icons/Group129.svg";
import { ReactComponent as Group150 } from "./social media icons/Group150.svg";
import { ReactComponent as Group152 } from "./social media icons/Group152.svg";
import { ReactComponent as Group165 } from "./social media icons/Group165.svg";
import { ReactComponent as Group167 } from "./social media icons/Group167.svg";
import { ReactComponent as Group270 } from "./social media icons/Group270.svg";
// import { ReactComponent as Group11231 } from "./social media icons/Group11231.svg";
import { ReactComponent as Group902 } from "./social media icons/Group902.svg";

import { ReactComponent as Group48 } from "./contactIcons/Group48.svg";
import { ReactComponent as Group87 } from "./contactIcons/Group87.svg";
import { ReactComponent as Group98 } from "./contactIcons/Group98.svg";
import { ReactComponent as Group120 } from "./contactIcons/Group120.svg";
import { ReactComponent as Group154 } from "./contactIcons/Group154.svg";
import { ReactComponent as Group156 } from "./contactIcons/Group156.svg";
import { ReactComponent as Group158 } from "./contactIcons/Group158.svg";
import { ReactComponent as Group169 } from "./contactIcons/Group169.svg";
import { ReactComponent as Group183 } from "./contactIcons/Group183.svg";
import { ReactComponent as Group200 } from "./contactIcons/Group200.svg";
import { ReactComponent as Group233 } from "./contactIcons/Group233.svg";
import { ReactComponent as Group245 } from "./contactIcons/Group245.svg";
import { ReactComponent as Group247 } from "./contactIcons/Group247.svg";
import { ReactComponent as Group251 } from "./contactIcons/Group251.svg";
import { ReactComponent as Group253 } from "./contactIcons/Group253.svg";
import { ReactComponent as Group257 } from "./contactIcons/Group257.svg";
import { ReactComponent as Group260 } from "./contactIcons/Group260.svg";
import { ReactComponent as Group263 } from "./contactIcons/Group263.svg";
import { ReactComponent as Group265 } from "./contactIcons/Group265.svg";
import { ReactComponent as Group272 } from "./contactIcons/Group272.svg";
import { ReactComponent as Group900 } from "./contactIcons/Group900.svg";
// import { ReactComponent as Group901 } from "./contactIcons/Group901.svg";

import { ReactComponent as Group495 } from "./style 5/contact/Group_495.svg";
import { ReactComponent as Group498 } from "./style 5/contact/Group_498.svg";
import { ReactComponent as Group502 } from "./style 5/contact/Group_502.svg";
import { ReactComponent as Group508 } from "./style 5/contact/Group_508.svg";
import { ReactComponent as Group515 } from "./style 5/contact/Group_515.svg";
import { ReactComponent as Group516 } from "./style 5/contact/Group_516.svg";
import { ReactComponent as Group517 } from "./style 5/contact/Group_517.svg";
import { ReactComponent as Group520 } from "./style 5/contact/Group_520.svg";
import { ReactComponent as Group525 } from "./style 5/contact/Group_525.svg";
import { ReactComponent as Group529 } from "./style 5/contact/Group_529.svg";
import { ReactComponent as Group537 } from "./style 5/contact/Group_537.svg";
import { ReactComponent as Group539 } from "./style 5/contact/Group_539.svg";
import { ReactComponent as Group540 } from "./style 5/contact/Group_540.svg";
import { ReactComponent as Group541 } from "./style 5/contact/Group_541.svg";
import { ReactComponent as Group542 } from "./style 5/contact/Group_542.svg";
import { ReactComponent as Group543 } from "./style 5/contact/Group_543.svg";
import { ReactComponent as Group544 } from "./style 5/contact/Group_544.svg";
import { ReactComponent as Group545 } from "./style 5/contact/Group_545.svg";
import { ReactComponent as Group546 } from "./style 5/contact/Group_546.svg";
import { ReactComponent as Group548 } from "./style 5/contact/Group_548.svg";

import { ReactComponent as Group484 } from "./style 5/social media/Group 484.svg";
import { ReactComponent as Group485 } from "./style 5/social media/Group 485.svg";
import { ReactComponent as Group486 } from "./style 5/social media/Group 486.svg";
import { ReactComponent as Group487 } from "./style 5/social media/Group 487.svg";
import { ReactComponent as Group488 } from "./style 5/social media/Group 488.svg";
import { ReactComponent as Group489 } from "./style 5/social media/Group 489.svg";
import { ReactComponent as Group490 } from "./style 5/social media/Group 490.svg";
import { ReactComponent as Group491 } from "./style 5/social media/Group 491.svg";
import { ReactComponent as Group492 } from "./style 5/social media/Group 492.svg";
import { ReactComponent as Group493 } from "./style 5/social media/Group 493.svg";
import { ReactComponent as Group494 } from "./style 5/social media/Group 494.svg";
import { ReactComponent as Group496 } from "./style 5/social media/Group 496.svg";
import { ReactComponent as Group497 } from "./style 5/social media/Group 497.svg";
import { ReactComponent as Group499 } from "./style 5/social media/Group 499.svg";
import { ReactComponent as Group500 } from "./style 5/social media/Group 500.svg";
import { ReactComponent as Group501 } from "./style 5/social media/Group 501.svg";
import { ReactComponent as Group504 } from "./style 5/social media/Group 504.svg";
import { ReactComponent as Group505 } from "./style 5/social media/Group 505.svg";
import { ReactComponent as Group506 } from "./style 5/social media/Group 506.svg";
import { ReactComponent as Group507 } from "./style 5/social media/Group 507.svg";
import { ReactComponent as Group509 } from "./style 5/social media/Group 509.svg";
import { ReactComponent as Group510 } from "./style 5/social media/Group 510.svg";
import { ReactComponent as Group511 } from "./style 5/social media/Group 511.svg";
import { ReactComponent as Group512 } from "./style 5/social media/Group 512.svg";
import { ReactComponent as Group513 } from "./style 5/social media/Group 513.svg";
import { ReactComponent as Group514 } from "./style 5/social media/Group 514.svg";
import { ReactComponent as Group518 } from "./style 5/social media/Group 518.svg";
import { ReactComponent as Group519 } from "./style 5/social media/Group 519.svg";
import { ReactComponent as Group547 } from "./style 5/social media/Group 547.svg";
import { ReactComponent as Path317 } from "./style 5/social media/Path 317.svg";
import { ReactComponent as Group904 } from "./style 5/social media/Group 904.svg";

import { ReactComponent as Group549 } from "./style 5/online store/Group 549.svg";
import { ReactComponent as Group550 } from "./style 5/online store/Group 550.svg";
import { ReactComponent as Group551 } from "./style 5/online store/Group 551.svg";
import { ReactComponent as Group552 } from "./style 5/online store/Group 552.svg";
import { ReactComponent as Group553 } from "./style 5/online store/Group 553.svg";
import { ReactComponent as Group554 } from "./style 5/online store/Group 554.svg";
import { ReactComponent as Group555 } from "./style 5/online store/Group 555.svg";
import { ReactComponent as Group556 } from "./style 5/online store/Group 556.svg";
import { ReactComponent as Group557 } from "./style 5/online store/Group 557.svg";
import { ReactComponent as Group558 } from "./style 5/online store/Group 558.svg";
import { ReactComponent as Group559 } from "./style 5/online store/Group 559.svg";
import { ReactComponent as Group560 } from "./style 5/online store/Group 560.svg";
import { ReactComponent as Group561 } from "./style 5/online store/Group 561.svg";
import { ReactComponent as Group562 } from "./style 5/online store/Group 562.svg";
import { ReactComponent as Group563 } from "./style 5/online store/Group 563.svg";

import { ReactComponent as Group303 } from "./style 2/contact/Group 303.svg";
import { ReactComponent as Group304 } from "./style 2/contact/Group 304.svg";
import { ReactComponent as Group305 } from "./style 2/contact/Group 305.svg";
import { ReactComponent as Group306 } from "./style 2/contact/Group 306.svg";
import { ReactComponent as Group307 } from "./style 2/contact/Group 307.svg";
import { ReactComponent as Group308 } from "./style 2/contact/Group 308.svg";
import { ReactComponent as Group309 } from "./style 2/contact/Group 309.svg";
import { ReactComponent as Group310 } from "./style 2/contact/Group 310.svg";
import { ReactComponent as Group311 } from "./style 2/contact/Group 311.svg";
import { ReactComponent as Group312 } from "./style 2/contact/Group 312.svg";
import { ReactComponent as Group313 } from "./style 2/contact/Group 313.svg";
import { ReactComponent as Group314 } from "./style 2/contact/Group 314.svg";
import { ReactComponent as Group315 } from "./style 2/contact/Group 315.svg";
import { ReactComponent as Group316 } from "./style 2/contact/Group 316.svg";
import { ReactComponent as Group317 } from "./style 2/contact/Group 317.svg";
import { ReactComponent as Group318 } from "./style 2/contact/Group 318.svg";
import { ReactComponent as Group319 } from "./style 2/contact/Group 319.svg";
import { ReactComponent as Group320 } from "./style 2/contact/Group 320.svg";
import { ReactComponent as Group321 } from "./style 2/contact/Group 321.svg";
import { ReactComponent as Group322 } from "./style 2/contact/Group 322.svg";

import { ReactComponent as Group338 } from "./style 2/online store/Group 338.svg";
import { ReactComponent as Group339 } from "./style 2/online store/Group 339.svg";
import { ReactComponent as Group340 } from "./style 2/online store/Group 340.svg";
import { ReactComponent as Group341 } from "./style 2/online store/Group 341.svg";
import { ReactComponent as Group342 } from "./style 2/online store/Group 342.svg";
import { ReactComponent as Group343 } from "./style 2/online store/Group 343.svg";
import { ReactComponent as Group344 } from "./style 2/online store/Group 344.svg";
import { ReactComponent as Group345 } from "./style 2/online store/Group 345.svg";
import { ReactComponent as Group346 } from "./style 2/online store/Group 346.svg";
import { ReactComponent as Group347 } from "./style 2/online store/Group 347.svg";
import { ReactComponent as Group348 } from "./style 2/online store/Group 348.svg";
import { ReactComponent as Group349 } from "./style 2/online store/Group 349.svg";
import { ReactComponent as Group350 } from "./style 2/online store/Group 350.svg";
import { ReactComponent as Group351 } from "./style 2/online store/Group 351.svg";
import { ReactComponent as Group352 } from "./style 2/online store/Group 352.svg";

import { ReactComponent as Group273 } from "./style 2/social media/Group 273.svg";
import { ReactComponent as Group274 } from "./style 2/social media/Group 274.svg";
import { ReactComponent as Group275 } from "./style 2/social media/Group 275.svg";
import { ReactComponent as Group276 } from "./style 2/social media/Group 276.svg";
import { ReactComponent as Group277 } from "./style 2/social media/Group 277.svg";
import { ReactComponent as Group278 } from "./style 2/social media/Group 278.svg";
import { ReactComponent as Group279 } from "./style 2/social media/Group 279.svg";
import { ReactComponent as Group280 } from "./style 2/social media/Group 280.svg";
import { ReactComponent as Group281 } from "./style 2/social media/Group 281.svg";
import { ReactComponent as Group282 } from "./style 2/social media/Group 282.svg";
import { ReactComponent as Group283 } from "./style 2/social media/Group 283.svg";
import { ReactComponent as Group284 } from "./style 2/social media/Group 284.svg";
import { ReactComponent as Group285 } from "./style 2/social media/Group 285.svg";
import { ReactComponent as Group286 } from "./style 2/social media/Group 286.svg";
import { ReactComponent as Group287 } from "./style 2/social media/Group 287.svg";
import { ReactComponent as Group288 } from "./style 2/social media/Group 288.svg";
import { ReactComponent as Group289 } from "./style 2/social media/Group 289.svg";
import { ReactComponent as Group290 } from "./style 2/social media/Group 290.svg";
import { ReactComponent as Group291 } from "./style 2/social media/Group 291.svg";
import { ReactComponent as Group292 } from "./style 2/social media/Group 292.svg";
import { ReactComponent as Group293 } from "./style 2/social media/Group 293.svg";
import { ReactComponent as Group294 } from "./style 2/social media/Group 294.svg";
import { ReactComponent as Group295 } from "./style 2/social media/Group 295.svg";
import { ReactComponent as Group296 } from "./style 2/social media/Group 296.svg";
import { ReactComponent as Group297 } from "./style 2/social media/Group 297.svg";
import { ReactComponent as Group298 } from "./style 2/social media/Group 298.svg";
import { ReactComponent as Group299 } from "./style 2/social media/Group 299.svg";
import { ReactComponent as Group300 } from "./style 2/social media/Group 300.svg";
import { ReactComponent as Group301 } from "./style 2/social media/Group 301.svg";
import { ReactComponent as Group302 } from "./style 2/social media/Group 302.svg";
import { ReactComponent as Group903 } from "./style 2/social media/Group 903.svg";

import { ReactComponent as Group594 } from "./style 6/contact/Group 594.svg";
import { ReactComponent as Group595 } from "./style 6/contact/Group 595.svg";
import { ReactComponent as Group596 } from "./style 6/contact/Group 596.svg";
import { ReactComponent as Group597 } from "./style 6/contact/Group 597.svg";
import { ReactComponent as Group598 } from "./style 6/contact/Group 598.svg";
import { ReactComponent as Group599 } from "./style 6/contact/Group 599.svg";
import { ReactComponent as Group600 } from "./style 6/contact/Group 600.svg";
import { ReactComponent as Group601 } from "./style 6/contact/Group 601.svg";
import { ReactComponent as Group602 } from "./style 6/contact/Group 602.svg";
import { ReactComponent as Group603 } from "./style 6/contact/Group 603.svg";
import { ReactComponent as Group604 } from "./style 6/contact/Group 604.svg";
import { ReactComponent as Group605 } from "./style 6/contact/Group 605.svg";
import { ReactComponent as Group606 } from "./style 6/contact/Group 606.svg";
import { ReactComponent as Group607 } from "./style 6/contact/Group 607.svg";
import { ReactComponent as Group608 } from "./style 6/contact/Group 608.svg";
import { ReactComponent as Group609 } from "./style 6/contact/Group 609.svg";
import { ReactComponent as Group610 } from "./style 6/contact/Group 610.svg";
import { ReactComponent as Group611 } from "./style 6/contact/Group 611.svg";
import { ReactComponent as Group612 } from "./style 6/contact/Group 612.svg";
import { ReactComponent as Group613 } from "./style 6/contact/Group 613.svg";

// style 6 social media icons
import { ReactComponent as Group564 } from "./style 6/social media/Group 564.svg";
import { ReactComponent as Group565 } from "./style 6/social media/Group 565.svg";
import { ReactComponent as Group566 } from "./style 6/social media/Group 566.svg";
import { ReactComponent as Group567 } from "./style 6/social media/Group 567.svg";
import { ReactComponent as Group568 } from "./style 6/social media/Group 568.svg";
import { ReactComponent as Group569 } from "./style 6/social media/Group 569.svg";
import { ReactComponent as Group570 } from "./style 6/social media/Group 570.svg";
import { ReactComponent as Group571 } from "./style 6/social media/Group 571.svg";
import { ReactComponent as Group572 } from "./style 6/social media/Group 572.svg";
import { ReactComponent as Group573 } from "./style 6/social media/Group 573.svg";
import { ReactComponent as Group574 } from "./style 6/social media/Group 574.svg";
import { ReactComponent as Group575 } from "./style 6/social media/Group 575.svg";
import { ReactComponent as Group576 } from "./style 6/social media/Group 576.svg";
import { ReactComponent as Group577 } from "./style 6/social media/Group 577.svg";
import { ReactComponent as Group578 } from "./style 6/social media/Group 578.svg";
import { ReactComponent as Group579 } from "./style 6/social media/Group 579.svg";
import { ReactComponent as Group580 } from "./style 6/social media/Group 580.svg";
import { ReactComponent as Group581 } from "./style 6/social media/Group 581.svg";
import { ReactComponent as Group582 } from "./style 6/social media/Group 582.svg";
import { ReactComponent as Group583 } from "./style 6/social media/Group 583.svg";
import { ReactComponent as Group584 } from "./style 6/social media/Group 584.svg";
import { ReactComponent as Group585 } from "./style 6/social media/Group 585.svg";
import { ReactComponent as Group586 } from "./style 6/social media/Group 586.svg";
import { ReactComponent as Group587 } from "./style 6/social media/Group 587.svg";
import { ReactComponent as Group588 } from "./style 6/social media/Group 588.svg";
import { ReactComponent as Group590 } from "./style 6/social media/Group 590.svg";
import { ReactComponent as Group591 } from "./style 6/social media/Group 591.svg";
import { ReactComponent as Group592 } from "./style 6/social media/Group 592.svg";
import { ReactComponent as Group593 } from "./style 6/social media/Group 593.svg";
import { ReactComponent as Group629 } from "./style 6/social media/Group 629.svg";
import { ReactComponent as Group905 } from "./style 6/social media/Group 905.svg";

// style 6 online store
import { ReactComponent as Group614 } from "./style 6/online store/Group 614.svg";
import { ReactComponent as Group615 } from "./style 6/online store/Group 615.svg";
import { ReactComponent as Group616 } from "./style 6/online store/Group 616.svg";
import { ReactComponent as Group617 } from "./style 6/online store/Group 617.svg";
import { ReactComponent as Group618 } from "./style 6/online store/Group 618.svg";
import { ReactComponent as Group619 } from "./style 6/online store/Group 619.svg";
import { ReactComponent as Group620 } from "./style 6/online store/Group 620.svg";
import { ReactComponent as Group621 } from "./style 6/online store/Group 621.svg";
import { ReactComponent as Group622 } from "./style 6/online store/Group 622.svg";
import { ReactComponent as Group623 } from "./style 6/online store/Group 623.svg";
import { ReactComponent as Group624 } from "./style 6/online store/Group 624.svg";
import { ReactComponent as Group625 } from "./style 6/online store/Group 625.svg";
import { ReactComponent as Group626 } from "./style 6/online store/Group 626.svg";
import { ReactComponent as Group627 } from "./style 6/online store/Group 627.svg";
import { ReactComponent as Group628 } from "./style 6/online store/Group 628.svg";

import Group272zz from "./contactIcons/Group272.svg";
const DynamicIcon = ({ componentName, ...props }) => {
  const componentMap = {
    Rectangle143: Rectangle143,
    Group171: Group171,
    Group174: Group174,
    Group176: Group176,
    Group178: Group178,
    Group185: Group185,
    Group193: Group193,
    Group219: Group219,
    Group220: Group220,
    Group221: Group221,
    Group223: Group223,
    Group225: Group225,
    Group227: Group227,
    Group229: Group229,
    Group231: Group231,
    Group238: Group238,
    Group4,
    Group18,
    Group27,
    Group29,
    Group31,
    Group33,
    Group38,
    Group40,
    Group42,
    Group44,
    Group46,
    Group53,
    Group55,
    Group89,
    Group91,
    Group93,
    Group107,
    Group111,
    Group115,
    Group117,
    Group123,
    Group125,
    Group127,
    Group129,
    Group150,
    Group152,
    Group165,
    Group167,
    Group270,
    // Group11231,
    Group902,
    Group904,
    Group905,

    Group48,
    Group87,
    Group98,
    Group120,
    Group154,
    Group156,
    Group158,
    Group169,
    Group183,
    Group200,
    Group233,
    Group245,
    Group247,
    Group251,
    Group253,
    Group257,
    Group260,
    Group263,
    Group265,
    Group272,
    Group900,
    // Group901,

    Group495,
    Group498,
    Group502,
    Group508,
    Group515,
    Group516,
    Group517,
    Group520,
    Group525,
    Group529,
    Group537,
    Group539,
    Group540,
    Group541,
    Group542,
    Group543,
    Group544,
    Group545,
    Group546,
    Group548,

    Group484,
    Group485,
    Group486,
    Group487,
    Group488,
    Group489,
    Group490,
    Group491,
    Group492,
    Group493,
    Group494,
    Group496,
    Group497,
    Group499,
    Group500,
    Group501,
    Group504,
    Group505,
    Group506,
    Group507,
    Group509,
    Group510,
    Group511,
    Group512,
    Group513,
    Group514,
    Group518,
    Group519,
    Group547,
    Path317,

    Group549,
    Group550,
    Group551,
    Group552,
    Group553,
    Group554,
    Group555,
    Group556,
    Group557,
    Group558,
    Group559,
    Group560,
    Group561,
    Group562,
    Group563,

    Group303,
    Group304,
    Group305,
    Group306,
    Group307,
    Group308,
    Group309,
    Group310,
    Group311,
    Group312,
    Group313,
    Group314,
    Group315,
    Group316,
    Group317,
    Group318,
    Group319,
    Group320,
    Group321,
    Group322,

    Group338,
    Group339,
    Group340,
    Group341,
    Group342,
    Group343,
    Group344,
    Group345,
    Group346,
    Group347,
    Group348,
    Group349,
    Group350,
    Group351,
    Group352,

    Group273,
    Group274,
    Group275,
    Group276,
    Group277,
    Group278,
    Group279,
    Group280,
    Group281,
    Group282,
    Group283,
    Group284,
    Group285,
    Group286,
    Group287,
    Group288,
    Group289,
    Group290,
    Group291,
    Group292,
    Group293,
    Group294,
    Group295,
    Group296,
    Group297,
    Group298,
    Group299,
    Group300,
    Group301,
    Group302,
    Group903,
    // style 6 contact
    Group594,
    Group595,
    Group596,
    Group597,
    Group598,
    Group599,
    Group600,
    Group601,
    Group602,
    Group603,
    Group604,
    Group605,
    Group606,
    Group607,
    Group608,
    Group609,
    Group610,
    Group611,
    Group612,
    Group613,

    // style 6 social media
    Group564,
    Group565,
    Group566,
    Group567,
    Group568,
    Group569,
    Group570,
    Group571,
    Group572,
    Group573,
    Group574,
    Group575,
    Group576,
    Group577,
    Group578,
    Group579,
    Group580,
    Group581,
    Group582,
    Group583,
    Group584,
    Group585,
    Group586,
    Group587,
    Group588,
    Group590,
    Group591,
    Group592,
    Group593,
    Group629,

    // style 6 online store
    Group614,
    Group615,
    Group616,
    Group617,
    Group618,
    Group619,
    Group620,
    Group621,
    Group622,
    Group623,
    Group624,
    Group625,
    Group626,
    Group627,
    Group628,
  };

  const DynamicComponent = componentMap[componentName];
  return DynamicComponent ? (
    <>
      <DynamicComponent {...props} />
    </>
  ) : null;
};

export default DynamicIcon;
