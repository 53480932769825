import bg1 from "../../assets/Image/b17.png";
import bg2 from "../../assets/Image/b18.png";
import bg3 from "../../assets/Image/b26.png";
import bg4 from "../../assets/Image/b27.png";
import bg5 from "../../assets/Image/b28.png";
import bg6 from "../../assets/Image/b29.png";
import bg7 from "../../assets/Image/b30.png";
import x from "../../assets/Image/124ba71c08f83512d0e478d29b6d16f9.png";
import onlineStore1 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore1.png";
import onlineStore2 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore2.png";
import onlineStore3 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore3.png";
import onlineStore4 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore4.png";
import onlineStore5 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore5.png";
import onlineStore6 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore6.png";
import onlineStore7 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore7.png";
import onlineStore8 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore8.png";
import onlineStore9 from "../../assets/Images/ScreenTemplates/OnlineStore/OnlineStore9.png";

export const firstTheme = {
  // data: {
  //   title: "GOLDAN LEAF SHOP",
  //   desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  // },
  Container: {
    background: " linear-gradient(#da86b2, #7b4b64);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#fff",
    border: "6px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    background: "#da86b2",
    border: "0px solid #fff",
    borderColor: "#fff",
    borderType: "solid",
    borderSize: 4,
    borderRadius: 5,
    boxShadowColor: "#da86b2",
    boxShadowProperties: "1px 1px 1px 1px #B9B8B8",
    color: "black",
  },
};

export const secondTheme = {
  Container: {
    background: " linear-gradient(#978cf5, #cbc7e3);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "black",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    background: "#978cf5",
    border: "0px solid #fff",
    borderColor: "#fff",
    borderType: "solid",
    borderSize: 0,
    borderRadius: 5,
    boxShadowColor: "#978cf5",
    boxShadowProperties: "1px 4px 10px 1px black",
    color: "black",
  },
};
export const thirdTheme = {
  Container: {
    background: " linear-gradient(#5ab9a3, #86badc);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "red",
    color: "#fff",
    border: "6px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    background: "#5ab9a3",
    border: "0px solid #fff",
    borderColor: "#fff",
    borderType: "solid",
    borderSize: 4,
    borderRadius: 20,
    boxShadowColor: "#5ab9a3",
    boxShadowProperties: "1px 1px 1px 1px #B9B8B8",
    color: "black",
  },
};
export const forthTheme = {
  Container: {
    background: " linear-gradient(#51756b, #b9c2bf);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "black",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "0px solid #fff",
    background: "#51756b",
    borderColor: "#fff",
    borderType: "solid",
    borderSize: 0,
    borderRadius: 20,
    boxShadowColor: "#51756b",
    boxShadowProperties: "1px 4px 10px 1px black",
    color: "black",
  },
};

export const fifthTheme = {
  Container: {
    background: " linear-gradient(#c28164, #efcca9);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "black",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid #fff",
    borderColor: "#fff",
    borderType: "solid",
    background: "#c28164",
    borderSize: 0,
    borderRadius: 5,
    boxShadowColor: "#c28164",
    boxShadowProperties: "2px 2px 0 0",
    color: "black",
  },
};
export const sixTheme = {
  Container: {
    background: " linear-gradient(#492613, #9c816b);",
    color: "#fff",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "black",
    border: "1px solid #fff",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "#fff",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "0px solid #fff",
    background: "#a99079",
    borderColor: "white",
    borderType: "solid",
    borderSize: 0,
    borderRadius: 20,
    boxShadowColor: "#000",
    boxShadowProperties: "2px 2px 0 0",
    color: "black",
  },
};

export const TemplateDesign1 = {
  id: 2,
  // img: onlineStore1,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg1,
    borderColor: "black",
    Font: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign2 = {
  id: 3,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg2,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign3 = {
  id: 4,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg3,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign4 = {
  id: 5,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "black",
    color: "red",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg4,
    borderColor: "red",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "red",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign5 = {
  id: 14,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },

  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg5,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign6 = {
  id: 6,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg6,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  name: "light-theme",
};
export const TemplateDesign7 = {
  id: 7,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },

  Container: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    backgroundImage: bg6,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
  },
  Contact: {
    background: "white",
    color: "#33f8fe",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px 1px #B9B8B8",
  },
  ProfilePicture: {},
  name: "light-theme",
};

export const TemplateOnlineStore1 = {
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },

  Container: {
    background: "linear-gradient(to bottom, #EF629F, #EECDA3)",
    color: "#ffff",
    border: "",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderRadius: "30px",
    borderColor: "black",
  },
  DivProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderColor: "black",
    borderType: "solid",
    borderSize: 2,
    borderRadius: 5,
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
    color: "black",
  },
  ProfilePicture: {},
  name: "light-theme",
};

export const TemplateOnlineStore2 = {
  id: 9,
  // img: onlineStore2,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },

  Container: {
    background: "linear-gradient(to bottom,#2c3e50,#bdc3c7)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderColor: "black",
    borderType: "solid",
    borderSize: 2,
    borderRadius: 5,
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
    color: "black",
  },
  ProfilePicture: {},
  name: "light-theme",
};

export const TemplateOnlineStore3 = {
  id: 10,
  img: onlineStore3,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background:
      "linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)), url(https://fly.linkcdn.cc/theme/images/2021mc_8.png);",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "black",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderColor: "black",
    borderType: "solid",
    borderSize: 2,
    borderRadius: 5,
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
    color: "black",
  },
  ProfilePicture: {},
  name: "light-theme",
};

export const TemplateOnlineStore4 = {
  id: 11,
  img: onlineStore4,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "#c1261e",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderColor: "black",
    borderType: "solid",
    borderSize: 2,
    borderRadius: 5,
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
    color: "black",
  },
  name: "light-theme",
};

export const TemplateOnlineStore5 = {
  id: 12,
  img: onlineStore5,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "linear-gradient(to bottom,#b91d73,#f953c6)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderColor: "black",
    borderType: "solid",
    borderSize: 2,
    borderRadius: 5,
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
    color: "black",
  },
  name: "light-theme",
};
export const TemplateOnlineStore6 = {
  id: 12,
  img: onlineStore6,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "linear-gradient(to bottom,#b91d73,#f953c6)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderRadius: "5",
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
  },
  name: "light-theme",
};
export const TemplateOnlineStore7 = {
  id: 12,
  img: onlineStore7,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "linear-gradient(to bottom,#b91d73,#f953c6)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderRadius: "5",
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
  },
  name: "light-theme",
};
export const TemplateOnlineStore8 = {
  id: 12,
  img: onlineStore8,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "linear-gradient(to bottom,#b91d73,#f953c6)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderRadius: "5",
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
  },
  name: "light-theme",
};
export const TemplateOnlineStore9 = {
  id: 12,
  img: onlineStore9,
  data: {
    title: "GOLDAN LEAF SHOP",
    desc: "DISCOVER YOUR NEW ADDICTION,MINERAL BASED MAKEUP PRODUACTS SHOP NOW",
  },
  Container: {
    background: "linear-gradient(to bottom,#b91d73,#f953c6)",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    // backgroundImage:bg6,
    borderColor: "white",
    fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
    backgroundBlur: "0px",
  },
  Contact: {
    background: "white",
    color: "#ffff",
    border: "2px solid black",
    padding: "2%",
    display: "block",
    maxWidth: "100%",
    borderColor: "black",
  },
  DivProfilePicture: {},
  ProfilePicture: {},
  StyledButton: {
    border: "1px solid black",
    borderRadius: "5",
    boxShadowColor: "#000",
    boxShadowProperties: "12px 12px 0 0",
  },
  name: "light-theme",
};

// =================================font styling==========================
