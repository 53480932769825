import React, { useEffect, useState } from "react";
import ImageBlur from "./Sliders/ImageBlur";
import { useTheme } from "../../../styles/ThemeContext";
import PopUpCrop from "../../MyPageComponents/ImageUpload";

const ImagePage = () => {
  const [bgImage, setBgImage] = useState("");
  const [blur, setBlur] = useState(0); // State for blur value
  const [show, setShow] = useState(false);
  const [shade, setShade] = useState();
  const { currentTheme, setCurrentTheme } = useTheme();

  useEffect(() => {
    setBlur(currentTheme.Container.blur);
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBgImage(imageUrl);
      setShow(true);
      // setCurrentTheme({
      //   ...currentTheme,
      //   Container: {
      //     ...currentTheme.Container,
      //     backgroundImage: imageUrl,
      //   },
      // });
    }
  };
  const onSaveHandler = (x) => {
    setCurrentTheme({
      ...currentTheme,
      Container: {
        ...currentTheme.Container,
        backgroundImage: x,
      },
    });
    setShow(false);
  };
  // Handler to update blur value
  const handleBlurChange = (newValue) => {
    setBlur(newValue);
    setCurrentTheme({
      ...currentTheme,
      Container: {
        ...currentTheme.Container,
        blur: newValue,
      },
    });
  };

  const handleChangeShade = (newValue) => {
    setShade(newValue);
    setCurrentTheme({
      ...currentTheme,
      Container: {
        ...currentTheme.Container,
        shade: newValue,
      },
    });
  };
  return (
    <div>
      <h2>Background Section</h2>

      {/* Upload Image */}
      <input type="file" accept="image/*" onChange={handleImageChange} />
      <ImageBlur
        blur={blur}
        onBlurChange={handleBlurChange}
        shade={shade}
        setShade={handleChangeShade}
      />

      {/* This is where the blurred background should be displayed */}
      <div
        className="mobile-container-component"
        style={{ width: "100%", height: "500px" }}
      ></div>
      <PopUpCrop
        show={show}
        setShow={setShow}
        imgURL={bgImage}
        onSaveHandler={onSaveHandler}
      />
    </div>
  );
};

export default ImagePage;
