import {
  Backdrop,
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

// import icons and Image
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@mui/joy";
import { useRef, useState } from "react";
import iconDefault from "../../../assets/Image/defaultIcons.png";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FastWorldIconsGetById, FastWorldIconsId } from "./fastworldIcons";
import DynamicIcon from "./fastworldIcons/DynamicIcon";
import { ReactComponent as UploadIcons } from "./upload.svg";
const AnimatedstyleButton = [
  "bounce",
  "color-change",
  "jello-button",
  "shake-button",
];
// const AnimatedstyleButton = [
//   "button-scale",
//   "button-color-change",
//   "button-slide",
//   "button-rotate",
// ];
const optionLink = [
  {
    value: "",
    label: "Link URL",
    label2: "Link URL",
    placeholder: "Enter Link URL",
  },
  {
    value: "https://www.facebook.com/",
    label: "Facebook",
    label2: "Facebook id",
    placeholder: "Enter Facebook id",
  },
  {
    value: "https://wa.me/",
    label: "Whatsapp",
    label2: "Whatsapp number",
    placeholder: "Enter whatsapp number",
  },
  {
    value: "https://t.me/",
    // value: "https://web.telegram.org/k/",

    label: "Telegram",
    label2: "Telegram id",
    placeholder: "Enter Telegram id",
  },
  {
    label: "Email",
    label2: "Email",
    value: "mailto://",
    placeholder: "Enter Email",
  },
  {
    value: "https://twitter.com/",
    label: "Twitter",
    label2: "Twitter",
    placeholder: "Enter Twitter",
  },
  {
    value: "https://instagram.com/",
    label: "Instagram",
    label2: "Instagram id",
    placeholder: "Enter Instagram id ",
  },
  {
    label: "phone",
    label2: "Phone number",
    value: "tel://",
    placeholder: "Enter phone number",
  },
];
const PopUpAddButton = ({
  show,
  setShow,
  handleChange,
  handleSave,
  titele,
  values,
  setValues,
  img,
  handleUploadIcons,
  handleSelectIcons,
  icons,
  handleEditContact,
  handleEditForm,
}) => {
  const fileInputRef = useRef(null);
  const [online, setOnline] = useState(false);
  const [value, setValue] = useState("1");

  const handleChangev = (event, newValue) => {
    setValue(newValue);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleClose = () => {
    setShow(false);
    setValues({});
  };

  const getPlaseholder = (label) => {
    const option = optionLink.find((item) => item.value === label);
    if (option) {
      return option.placeholder;
    }
    return "Enter Link URL";
  };
  const getLinkLabel2 = (label) => {
    const option = optionLink.find((item) => item.value === label);
    if (option) {
      return option.label2;
    }
    return "Link Url";
  };

  const spliteLinke = (link) => {
    // setValues({ ...values, path:});
  };
  const getIcon = (name) => {
    if (FastWorldIconsGetById[`${name}`] == undefined) {
      return "Groupxx";
    } else {
      return FastWorldIconsGetById[`${name}`][0];
    }
  };
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
              // height: "760px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "30px",
              }}
            >
              {titele}
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>

          {/* Start Cards  */}
          <Box sx={{ marginTop: "40px", display: "flex" }}>
            {values?.icon_name == "null" ? (
              <img
                src={img ? img : iconDefault}
                alt=""
                srcset=""
                style={{
                  display: "block",
                  width: "120px",
                  height: "120px",
                  marginTop: "20px",
                }}
              />
            ) : (
              <div
                style={{
                  display: "block",
                  width: "120px",
                  height: "120px",
                  marginTop: "20px",
                }}
              >
                {/* {FastWorldIconsGetById[values?.icon_name] ? ( */}
                <DynamicIcon
                  componentName={getIcon(values?.icon_name)}
                  style={{
                    display: "block",
                    width: "120px",
                    height: "120px",
                    marginTop: "20px",
                  }}
                />
                {/* ) : (
                  <img
                    src={img ? img : iconDefault}
                    alt=""
                    srcset=""
                    style={{
                      display: "block",
                      width: "120px",
                      height: "120px",
                      marginTop: "20px",
                    }}
                  />
                )} */}
              </div>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "20px",
                height: "270px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: online ? "none" : "flex",
                  msFlexDirection: "column",
                }}
              >
                <Button
                  size="md"
                  sx={{
                    width: "305px",
                    marginTop: "5px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "45px",
                    color: "#FFF",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginRight: "20px",
                  }}
                  onClick={() => setOnline(!online)}
                >
                  Online Icon
                </Button>
                <Button
                  size="md"
                  sx={{
                    width: "150px",
                    marginTop: "5px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "45px",
                    color: "#FFF",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleButtonClick}
                >
                  Upload Icon
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleUploadIcons}
                />
              </Box>
              <Box
                sx={{
                  display: online ? "flex" : "none",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    width: "610px",
                    height: "300px",
                    border: "1px solid #54B99B",
                    borderRadius: "10px",
                    overflowY: "scroll",
                    padding: "1px",
                  }}
                >
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          textAlign: "center",
                          borderColor: "divider",
                          width: "100%",
                        }}
                      >
                        <TabList
                          onChange={handleChangev}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Online Store" value="1" />
                          <Tab label="Social Media" value="2" />
                          <Tab label="Contact" value="3" />
                          <Tab label="upload Icon" value="4" />
                        </TabList>
                      </Box>
                      <TabPanel
                        className="scrollbar"
                        sx={{
                          display: "flex",
                          margin: "auto",
                          textAlign: "center",
                          justifyContent: "center",
                          width: "100%",
                          padding: "0px",
                        }}
                        value="1"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            // width: "90%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            // textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.onlineStoreIcons).map(
                            (Iconxx, index) => {
                              return (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    cursor: "pointer",
                                    width: "64px",
                                    height: "64px",
                                    display: "inline-block",
                                    background: "#fff",
                                    borderRadius: "6px",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                    marginRight: "20px",
                                    marginBottom: "24px",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                    lineHeight: "64px",
                                    margin: "11px",
                                  }}
                                  onClick={() => {
                                    handleSelectIcons(Iconxx);
                                    setOnline(false);
                                  }}
                                >
                                  <DynamicIcon
                                    componentName={
                                      FastWorldIconsId.onlineStoreIcons[
                                        Iconxx
                                      ][0]
                                    }
                                    // fill="red"
                                    // color="yellow"
                                    // filter="invert(2%) sepia(100%) saturate(1352%) hue-rotate(87deg) brightness(53%) contrast(119%)"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      // marginLeft: "5px",
                                      margin: "7.5px 7.5px 7.5px 7.5px ",
                                    }}
                                  />
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        sx={{
                          display: "block",
                          marginL: "auto",
                          textAlign: "center",
                          padding: "0px",
                        }}
                        value="2"
                      >
                        <Box
                          className="scrollbar"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "90%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            // padding: "10px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.socialMediaIcons).map(
                            (icon) => (
                              <Typography
                                variant="body1"
                                sx={{
                                  cursor: "pointer",
                                  width: "64px",
                                  height: "64px",
                                  display: "inline-block",
                                  background: "#fff",
                                  borderRadius: "6px",
                                  boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                  marginRight: "20px",
                                  marginBottom: "24px",
                                  verticalAlign: "top",
                                  textAlign: "center",
                                  lineHeight: "64px",
                                }}
                                onClick={() => {
                                  handleSelectIcons(icon);
                                  setOnline(false);
                                }}
                              >
                                <DynamicIcon
                                  componentName={
                                    FastWorldIconsId.socialMediaIcons[icon][0]
                                  }
                                  // fill="black"
                                  // color="yellow"
                                  // filter="invert(2%) sepia(100%) saturate(1352%) hue-rotate(87deg) brightness(53%) contrast(119%)"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    // marginLeft: "5px",
                                    margin: "7.5px 7.5px 7.5px 7.5px ",
                                  }}
                                />
                              </Typography>
                            )
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        sx={{
                          display: "block",
                          marginL: "auto",
                          textAlign: "center",
                          padding: "0px",
                        }}
                        value="3"
                      >
                        <Box
                          className="scrollbar"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "90%",
                            height: "250px",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.contactIcons).map(
                            (Iconxx, index) => {
                              return (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    cursor: "pointer",
                                    width: "64px",
                                    height: "64px",
                                    display: "inline-block",
                                    background: "#fff",
                                    borderRadius: "6px",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                    marginRight: "20px",
                                    marginBottom: "24px",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                    lineHeight: "64px",
                                  }}
                                  onClick={() => {
                                    handleSelectIcons(Iconxx);
                                    setOnline(false);
                                  }}
                                >
                                  <DynamicIcon
                                    componentName={
                                      FastWorldIconsId.contactIcons[Iconxx][0]
                                    }
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      // marginLeft: "5px",
                                      margin: "7.5px 7.5px 7.5px 7.5px ",
                                    }}
                                  />
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        className="scrollbar"
                        sx={{
                          display: "block",
                          margin: "auto",
                          // textAlign: "center",
                          padding: "0px",
                          width: "100%",
                        }}
                        value="4"
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            cursor: "pointer",
                            width: "64px",
                            height: "64px",
                            display: "inline-block",
                            background: "#fff",
                            borderRadius: "6px",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            marginRight: "20px",
                            marginBottom: "24px",
                            verticalAlign: "top",
                            textAlign: "center",
                            lineHeight: "64px",
                          }}
                        >
                          <UploadIcons
                            style={{
                              width: "40px",
                              height: "40px",
                              // marginLeft: "5px",
                              margin: "7.5px 7.5px 7.5px 7.5px ",
                              // color: "#19609C",
                            }}
                            onClick={() => {
                              console.log("upload clicked");
                              handleButtonClick();
                            }}
                          />
                        </Typography>
                      </TabPanel>
                    </TabContext>
                  </Box>
                  {/* {icons?.map((icon, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleSelectIcons(icon);
                        setOnline(false);
                      }}
                    >
                      <img
                        src={icon.icon}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "5px",
                        }}
                      />
                    </Typography>
                  ))} */}
                </Box>
                <i
                  style={{
                    // marginLeft: "20px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    display: "block",
                    width: "20px",
                  }}
                  onClick={() => setOnline(false)}
                >
                  X
                </i>
              </Box>
            </Box>
          </Box>
          {/* button text */}
          <Box sx={{ marginTop: "15px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "27,24px",
                marginLeft: "0px",
              }}
            >
              Button Text
            </Typography>
            <Input
              size="md"
              placeholder="Enter Button Text"
              value={values.title}
              name="title"
              sx={{
                marginTop: "5px",
                boxShadow: ".5px .5px .5px .5px #19609C",
              }}
              onChange={handleChange}
            />
          </Box>
          {/* link url */}

          {values.type == "link_url" && (
            <>
              <lable style={{ marginTop: "15px", display: "block" }}>
                Link type
              </lable>
              <Select
                sx={{
                  width: "100%",
                  height: "40px",
                  lineHeight: "40px",
                  borderRadius: "4px",
                  padding: "0 12px",
                  cursor: "pointer",
                  boxShadow: ".5px .5px .5px .5px #19609C",
                }}
                name="path"
                defaultValue={optionLink[0].value}
                value={values?.path || ""}
                onChange={handleChange}
              >
                <MenuItem default value="">
                  {optionLink[0].label}
                </MenuItem>
                {optionLink.map((option, index) => {
                  if (index == 0) {
                    return null;
                  }
                  return (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  );
                })}
              </Select>
              <Box sx={{ marginTop: "15px" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "27,24px",
                    marginLeft: "0px",
                  }}
                >
                  {getLinkLabel2(values?.path)}
                </Typography>
                <Input
                  size="md"
                  placeholder={getPlaseholder(values?.path || "")}
                  onChange={handleChange}
                  name="link"
                  value={values.link}
                  sx={{
                    marginTop: "5px",
                    boxShadow: ".5px .5px .5px .5px #19609C",
                  }}
                />
                <span>
                  {" "}
                  {/* {optionLink[values?.path]?.value} */}
                  {values?.path}
                  {values?.link}{" "}
                </span>
              </Box>
            </>
          )}
          {values.type == "video" && (
            <Box sx={{ marginTop: "15px" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "27,24px",
                  marginLeft: "0px",
                }}
              >
                video Url
              </Typography>
              <Input
                size="md"
                placeholder="Enter video URL  "
                onChange={handleChange}
                name="link"
                value={values.link}
                sx={{
                  marginTop: "5px",
                  boxShadow: ".5px .5px .5px .5px #19609C",
                }}
              />
            </Box>
          )}
          {values.type == "google_map" && (
            <Box sx={{ marginTop: "15px", width: "100%" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "27,24px",
                  marginLeft: "0px",
                }}
              >
                Map Link
              </Typography>
              <Input
                size="md"
                placeholder="Enter Map Link"
                value={values?.link}
                name="link"
                sx={{
                  marginTop: "5px",
                  boxShadow: ".5px .5px .5px .5px #19609C",
                }}
                onChange={handleChange}
              />
            </Box>
          )}
          {values.type == "contact_details" && (
            <Box sx={{ marginTop: "15px" }}>
              {" "}
              <Button
                sx={{
                  boxShadow: ".5px .5px .5px .5px #19609C",
                  margin: "auto",
                  display: "block",
                  background:
                    "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                  color: "#FFF",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={() => handleEditContact(JSON.parse(values.text)[0])}
              >
                {" "}
                Edit Contact Details
              </Button>
            </Box>
          )}
          {values.type == "form" && (
            <Box sx={{ marginTop: "15px" }}>
              <Button
                sx={{
                  boxShadow: ".5px .5px .5px .5px #19609C",
                  margin: "auto",
                  display: "block",
                  background:
                    "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                  color: "#FFF",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={() => handleEditForm(JSON.parse(values.text)[0])}
              >
                {" "}
                Edit form
              </Button>
            </Box>
          )}
          <Box sx={{ marginTop: "15px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "27,24px",
                marginLeft: "0px",
              }}
            >
              Button Animation
            </Typography>
            <div style={{ display: "flex" }}>
              <label style={{ margin: "10px" }} key={"45"}>
                <input
                  size="md"
                  onChange={handleChange}
                  value={"false"}
                  sx={{
                    // marginTop: "5px",
                    display: "inline-block",
                    // boxShadow: ".5px .5px .5px .5px #19609C",
                  }}
                  type="radio"
                  checked={values.tcolor == `false`}
                  name="tcolor"
                />{" "}
                {"Not Display"}
              </label>
              {AnimatedstyleButton.map((item, index) => {
                return (
                  <>
                    <label style={{ margin: "10px" }} key={index}>
                      <input
                        size="md"
                        placeholder="Enter video URL  "
                        onChange={handleChange}
                        value={"" + item}
                        sx={{
                          // marginTop: "5px",
                          display: "inline-block",
                          // boxShadow: ".5px .5px .5px .5px #19609C",
                        }}
                        type="radio"
                        checked={values.tcolor == `${item}`}
                        name="tcolor"
                      />{" "}
                      {item}
                    </label>
                  </>
                );
              })}
            </div>
            {/* <Input
              size="md"
              placeholder="Enter video URL  "
              onChange={handleChange}
              value={values.link}
              sx={{
                marginTop: "5px",
                boxShadow: ".5px .5px .5px .5px #19609C",
              }}
              type="radio"
              checked={values.isDone == "true"}
              name="isDone"
            /> */}
          </Box>
          {/* End Cards button to save */}
          <Button
            onClick={handleSave}
            sx={{
              width: "90%",
              height: "45px",
              margin: "40px auto",
              display: "block",
              background:
                "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
              color: "#FFF",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </StyledModal>
    </div>
  );
};

export default PopUpAddButton;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "800px",
  // height: "500px",
  height: "760px",

  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
  overflowY: "auto",
});
