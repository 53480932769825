import React from "react";
import { Button } from "@mui/material";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const PopUpDelete = ({
  show,
  setShow,
  url,
  message,
  data,
  currentTheme,
  setCurrentTheme,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => setShow(false);
  const handleDelete = () => {
    axiosRequset
      .post(url, data)
      .then((res) => {
        toast.success("Deleted Successfully");
        //
      })
      .then((res) => {
        axiosRequset
          .post("/owner/Information/get", {
            id: currentTheme.id,
          })
          .then((res) => {
            console.log(res.data);
            setCurrentTheme({
              ...res.data.Information.object,
              public_id: res.data.link.public_id,
              img: res.data.Information.image,
              id: res.data.Information.id,
              Contents: res.data.Information.Contents,
              orders: res.data.Information.orders,
              data: {
                title: res.data.Information.title,
                desc: res.data.Information.description,
              },
            });
            //   setError(false);
            // toast.success("Added Successfully");
            setShow(false);
          })
          .catch((error) => {
            console.log(error);
            //   setError(true);
            toast.error("update went wrong");
          });
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "red" }}>
            {message.data}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpDelete;
