import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  TextField,
} from "@mui/material";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const country = [
  "",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo, Republic of the (Brazzaville)",
  "Congo, the Democratic Republic of the (Kinshasa)",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire, Republic of",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Islas Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern and Antarctic Lands",
  "Gabon",
  "Gambia, The",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
let ContactUsText = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  emailPrimary: "",
  emailPrimaryType: "",
  emailSecondary: "",
  emailSecondaryType: "",
  firstName: "",
  lastName: "",
  note: "",
  organization: "",
  phonePrimary: "",
  phonePrimaryType: "",
  phoneSecondary: "",
  phoneSecondaryType: "",
  position: "",
  postcode: "",
  state: "",
};
const PopUpButtonContact = ({
  show,
  setShow,
  handleSaveContact,
  handleChangeContact,
  contactValues,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //   const [allinputType, setallInputType] = React.useState([
  //     'text','number','date','time','textarea','select','checkbox','file'

  //   ]);
  const handleClose = () => setShow(false);
  const handleDelete = () => {};

  return (
    <React.Fragment>
      <BootstrapDialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">Button Contact </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText style={{ fontWeight: "bold" }}>
            <div
              style={{
                width: "550px",
                height: "650px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                padding: "20px",
                boxSizing: "border-box",
              }}
            >
              <form
                style={{
                  width: "90%",
                  margin: "auto",
                  display: "block",
                  height: "100%",
                  boxSizing: "border-box",
                }}
              >
                <div>
                  <div>
                    <label for="" class="">
                      Name
                    </label>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Input
                      sx={{ width: "45%", marginBottom: "10px" }}
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.firstName}
                    />
                    <Input
                      sx={{ width: "45%", marginBottom: "10px" }}
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.lastName}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label for="" class="">
                      Occupation
                    </label>
                  </div>
                  <Input
                    sx={{ width: "100%", marginBottom: "10px" }}
                    type="text"
                    name="Organization"
                    placeholder="Organization"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                    value={contactValues?.organization}
                  />
                  <Input
                    sx={{ width: "100%", marginBottom: "10px" }}
                    type="text"
                    name="Position"
                    placeholder="Position"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                    value={contactValues?.position}
                  />
                </div>
                {/* <div>
                  <div>
                    <label for="" class="">
                      Occupation
                    </label>
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Organization"
                    autoComplete="off"
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Position"
                    autoComplete="off"
                  />
                </div> */}
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <select
                    name="emailPrimaryType"
                    onChange={(e) => handleChangeContact(e)}
                    style={{ width: "45%" }}
                    value={contactValues?.emailPrimaryType}
                  >
                    <option value="" selected="">
                      Type
                    </option>
                    <option value="PERSONAL">Personal</option>
                    <option value="WORK">Work</option>
                    <option value="OTHER">Other</option>
                  </select>
                  <div style={{ width: "45%" }}>
                    <Input
                      sx={{ width: "100%" }}
                      type="email"
                      name="emailPrimary"
                      placeholder="Email address"
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.emailPrimary}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <select
                    name="emailSecondaryType"
                    onChange={(e) => handleChangeContact(e)}
                    style={{ width: "45%" }}
                    value={contactValues?.emailSecondaryType}
                  >
                    <option value="" selected="">
                      Type
                    </option>
                    <option value="PERSONAL">Personal</option>
                    <option value="WORK">Work</option>
                    <option value="OTHER">Other</option>
                  </select>
                  <div style={{ width: "45%" }}>
                    <Input
                      sx={{ width: "100%" }}
                      type="email"
                      name="emailSecondary"
                      placeholder="Email"
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.emailSecondary}
                    />
                  </div>
                </div>
                <div>phone</div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <select
                      name="phonePrimaryType"
                      onChange={(e) => handleChangeContact(e)}
                      style={{ width: "45%" }}
                      value={contactValues?.phonePrimaryType}
                    >
                      <option value="" selected="">
                        Type
                      </option>
                      <option value="MOBILE">Mobile</option>
                      <option value="WORK">Work</option>
                      <option value="HOME">Home</option>
                      <option value="OTHER">Other</option>
                    </select>
                    <div style={{ width: "45%" }}>
                      <Input
                        sx={{ width: "100%" }}
                        type="email"
                        name="phonePrimary"
                        placeholder="Phone number"
                        autoComplete="off"
                        onChange={(e) => handleChangeContact(e)}
                        value={contactValues?.phonePrimary}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <select
                      name="phoneSecondaryType"
                      onChange={(e) => handleChangeContact(e)}
                      style={{ width: "45%" }}
                      value={contactValues?.phoneSecondaryType}
                    >
                      <option value="" selected="">
                        Type
                      </option>
                      <option value="MOBILE">Mobile</option>
                      <option value="WORK">Work</option>
                      <option value="HOME">Home</option>
                      <option value="OTHER">Other</option>
                    </select>
                    <div style={{ width: "45%" }}>
                      <Input
                        sx={{ width: "100%" }}
                        type="email"
                        name="phoneSecondary"
                        placeholder="Phone number"
                        autoComplete="off"
                        onChange={(e) => handleChangeContact(e)}
                        value={contactValues?.phoneSecondary}
                      />
                    </div>
                  </div>
                </div>
                <div>Address</div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Input
                      sx={{ width: "45%", marginBottom: "10px" }}
                      name="address1"
                      placeholder="Street address"
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.address1}
                    />
                    <Input
                      sx={{ width: "45%", marginBottom: "10px" }}
                      name="address2"
                      placeholder="Apartment, suite, etc."
                      autoComplete="off"
                      onChange={(e) => handleChangeContact(e)}
                      value={contactValues?.address2}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      padding: "0 10px",
                    }}
                  >
                    <label>country</label>
                    <select
                      onChange={(e) => handleChangeContact(e)}
                      name="country"
                      style={{ width: "45%" }}
                      value={contactValues?.country}
                    >
                      <option value="" selected="">
                        Country
                      </option>
                      {country.map((country) => {
                        return <option value={country}>{country}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <Input
                    sx={{ width: "45%", marginBottom: "10px" }}
                    type="text"
                    name="city"
                    placeholder="City"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                  />
                  <Input
                    sx={{ width: "45%", marginBottom: "10px" }}
                    type="text"
                    name="state"
                    placeholder="State"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                    value={contactValues?.state}
                  />
                  <Input
                    sx={{ width: "45%", marginBottom: "10px" }}
                    type="text"
                    name="postcode"
                    placeholder="Zip code"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                    value={contactValues?.postcode}
                  />
                </div>
                <div>Opening Hours</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <textarea
                    sx={{ width: "98%", marginBottom: "10px" }}
                    type="text"
                    name="note"
                    placeholder="Monday-Friday, 9am-5pm"
                    autoComplete="off"
                    onChange={(e) => handleChangeContact(e)}
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "90%",
                    backgroundColor: "#f5f5f",
                    display: "flex",
                    margin: " 10px auto",
                  }}
                  onClick={(e) => handleSaveContact(e)}
                >
                  {" "}
                  Save{" "}
                </Button>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default PopUpButtonContact;
