import { Button } from "@mui/base";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BlookContainer, Submit } from "../styles/MobileScreen.styled";

const RequestT = ({ data }) => {
  const [loding, setLoding] = useState(false);
  const [values, setValues] = useState({
    options: [
      {
        id: 0,
        amount: 5,
        detail: "20-second custom video",
      },
      {
        id: 1,
        amount: 10,
        detail: "50-second custom video",
      },
    ],
    thanks: "Thank you!",
    title: "Request",
    desc: "Your request is in process. Thank you!",
  });
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    setLoding(true);
    if (data?.text.length > 0 && data?.text != "null") {
      setValues({ ...JSON.parse(data.text)[0] });
    }
    setLoding(false);
  }, [data?.text]);
  if (loding) return;

  return (
    <BlookContainer>
      <h1>{values.title}</h1> <br />
      <p>{values.desc}</p> <br />
      {values.options.map((item) => (
        <div key={item.id} style={{ textAlign: "start" }}>
          <label key={item.id}>
            <input
              type="radio"
              value={item.id}
              checked={selectedOption === item.id}
              onChange={handleOptionChange}
            />
            <span>
              {item.detail} {item.amount}
            </span>
          </label>
        </div>
      ))}
      <br />
      <Submit
        onClick={() => {
          toast.success(`${values.thanks}`);
        }}
      >
        Send
      </Submit>
    </BlookContainer>
  );
};

export default RequestT;
