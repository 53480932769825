import React, { useEffect, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useTheme } from "../../../styles/ThemeContext";
import { Slider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const swatchStyle = {
  width: "26px",
  height: "26px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
    cyan 0%,
    palegoldenrod 16.67%,
    lime 33.33%,
    red 50%,
    blue 66.67%,
    yellow 83.33%,
    orange 100%
  )`,
  cursor: "pointer",
};

const styles = reactCSS({
  default: {
    color: swatchStyle,
    popover: {
      position: "absolute",
      zIndex: "2",
      top: "30%",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
});
const swatchStyleText = {
  width: "26px",
  height: "26px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
    cyan 0%,
    palegoldenrod 16.67%,
    lime 33.33%,
    red 50%,
    blue 66.67%,
    yellow 83.33%,
    orange 100%
  )`,
  cursor: "pointer",
};

const stylesText = reactCSS({
  default: {
    color: swatchStyleText,
    popoverText: {
      position: "absolute",
      zIndex: "2",
      top: "30%",
    },
    coverText: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
});
const swatchStyleShadow = {
  width: "26px",
  height: "26px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
    cyan 0%,
    palegoldenrod 16.67%,
    lime 33.33%,
    red 50%,
    blue 66.67%,
    yellow 83.33%,
    orange 100%
  )`,
  cursor: "pointer",
};

const stylesShadow = reactCSS({
  default: {
    color: swatchStyleShadow,
    popoverShadow: {
      position: "absolute",
      zIndex: "2",
      top: "30%",
    },
    coverShadow: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
});

const swatchStyleOutline = {
  width: "26px",
  height: "26px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
    cyan 0%,
    palegoldenrod 16.67%,
    lime 33.33%,
    red 50%,
    blue 66.67%,
    yellow 83.33%,
    orange 100%
  )`,
  cursor: "pointer",
};

const stylesOutline = reactCSS({
  default: {
    color: swatchStyleOutline,
    popoverOutline: {
      position: "absolute",
      zIndex: "2",
      top: "30%",
    },
    coverOutline: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
});
const ColorPage = () => {
  const { currentTheme, setCurrentTheme } = useTheme();
  const [opacity, setOpacity] = useState(
    currentTheme?.StyledButton?.opacity || 1
  );
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState();
  const [currentTextColor, setCurrentTextColor] = useState();
  const [currentShadowColor, setCurrentShadowColor] = useState();
  const [currentOutlineColor, setCurrentOutlineColor] = useState();
  // ========================color-background-picker==============================
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // ========================color-text-picker==============================
  const [displayColorPickerText, setDisplayColorPickerText] = useState(false);
  const [colorText, setColorText] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // ========================color-Outline-picker==============================
  const [displayColorPickerOutline, setDisplayColorPickerOutline] =
    useState(false);
  const [colorOutline, setColorOutline] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // ========================color-Shadow-picker==============================
  const [displayColorPickerShadow, setDisplayColorPickerShadow] =
    useState(false);
  const [colorShadow, setColorShadow] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const handleChangeBackgroundButton = (newColor) => {
    console.log(newColor);
    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        ...newColor,
      },
    });
  };
  const handleChangeTextColor = (newColor) => {
    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        ...newColor,
      },
    });
  };
  const handleChangeObject = (d) => {
    console.log(d, currentTheme);

    if (d.hasOwnProperty("boxShadowColor")) {
      setCurrentTheme({
        ...currentTheme,
        StyledButton: {
          ...currentTheme.StyledButton,
          boxShadowColor: d.boxShadowColor,
        },
      });
    } else {
      setCurrentTheme({
        ...currentTheme,
        StyledButton: { ...currentTheme.StyledButton, ...d },
      });
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setCurrentBackgroundColor(1);
    console.log(newColor);
    setColor(newColor.rgb);
    handleChangeObject({ background: newColor.hex });
  };

  const PicheaderStyle = {
    color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  };

  const PicColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  console.log(PicColor);
  // ===================================================

  const handleClickText = () => {
    setDisplayColorPickerText(!displayColorPickerText);
  };

  const handleCloseText = () => {
    setDisplayColorPickerText(false);
  };

  const handleChangeText = (newColorText) => {
    setColorText(newColorText.rgb);
    setCurrentTextColor(1);
    handleChangeTextColor({ color: newColorText.hex });
  };

  const PicheaderStyleText = {
    color: `rgba(${colorText.r}, ${colorText.g}, ${colorText.b}, ${colorText.a})`,
    background: `rgba(${colorText.r}, ${colorText.g}, ${colorText.b}, ${colorText.a})`,
  };

  const PicColorText = `rgba(${colorText.r}, ${colorText.g}, ${colorText.b}, ${colorText.a})`;

  // ===================================================

  const handleClickShadow = () => {
    setDisplayColorPickerShadow(!displayColorPickerShadow);
  };

  const handleCloseShadow = () => {
    setDisplayColorPickerShadow(false);
  };

  const handleChangeShadow = (newColorShadow) => {
    setColorShadow(newColorShadow.rgb);
    setCurrentShadowColor(1);
    handleChangeBackgroundButton({ boxShadowColor: newColorShadow.hex });
  };

  const PicheaderStyleShadow = {
    color: `rgba(${colorShadow.r}, ${colorShadow.g}, ${colorShadow.b}, ${colorShadow.a})`,
    background: `rgba(${colorShadow.r}, ${colorShadow.g}, ${colorShadow.b}, ${colorShadow.a})`,
  };

  const PicColorShadow = `rgba(${colorShadow.r}, ${colorShadow.g}, ${colorShadow.b}, ${colorShadow.a})`;

  // ===================================================

  const handleClickOutline = () => {
    setDisplayColorPickerOutline(!displayColorPickerOutline);
  };

  const handleCloseOutline = () => {
    setDisplayColorPickerOutline(false);
  };

  const handleChangeOutline = (newColorOutline) => {
    setColorOutline(newColorOutline.rgb);
    setCurrentOutlineColor(1);
    handleChangeBackgroundButton({ borderColor: newColorOutline.hex });
  };

  const PicheaderStyleOutline = {
    color: `rgba(${colorOutline.r}, ${colorOutline.g}, ${colorOutline.b}, ${colorOutline.a})`,
    background: `rgba(${colorOutline.r}, ${colorOutline.g}, ${colorOutline.b}, ${colorOutline.a})`,
  };

  const PicColorOutline = `rgba(${colorOutline.r}, ${colorOutline.g}, ${colorOutline.b}, ${colorOutline.a})`;

  // ===================================================
  const handleOpacity = (e) => {
    setOpacity(e.target.value);
    handleChangeBackgroundButton({ opacity: e.target.value });
  };
  return (
    <div>
      <div>
        <div className="font-type-header">Background</div>
        <div className="font-type-cir-cont" style={{ position: "relative" }}>
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>

          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker color={color} onChange={handleChange} />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              ...PicheaderStyle,
              border:
                currentBackgroundColor == 1 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  PicColor.toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              console.log(PicColor);
              handleChangeBackgroundButton({ background: PicColor });
              setCurrentBackgroundColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              border:
                currentBackgroundColor == 2 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#FFFFFF".toLowerCase()
                  ? "3px solid #007aff"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#ffffff" });
              setCurrentBackgroundColor(2);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border:
                currentBackgroundColor == 3 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#000000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#000000" });
              setCurrentBackgroundColor(3);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border:
                currentBackgroundColor == 4 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#808080".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#808080" });
              setCurrentBackgroundColor(4);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border:
                currentBackgroundColor == 5 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#0000ff".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#0000ff" });
              setCurrentBackgroundColor(5);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border:
                currentBackgroundColor == 6 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#a52a2a".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#a52a2a" });
              setCurrentBackgroundColor(6);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border:
                currentBackgroundColor == 7 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#ff0000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#ff0000" });
              setCurrentBackgroundColor(7);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border:
                currentBackgroundColor == 8 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#008000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#008000" });
              setCurrentBackgroundColor(8);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border:
                currentBackgroundColor == 9 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#FFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#FFE5C1" });
              setCurrentBackgroundColor(9);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border:
                currentBackgroundColor == 10 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#E38CBA".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#E38CBA" });
              setCurrentBackgroundColor(10);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border:
                currentBackgroundColor == 11 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#19609C".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#19609C" });
              setCurrentBackgroundColor(11);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border:
                currentBackgroundColor == 12 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#51756B".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#51756B" });
              setCurrentBackgroundColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border:
                currentBackgroundColor == 13 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#897046".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#897046" });
              setCurrentBackgroundColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border:
                currentBackgroundColor == 14 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#FFE5E7".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#FFE5E7" });
              setCurrentBackgroundColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border:
                currentBackgroundColor == 15 ||
                currentTheme?.StyledButton?.background?.toLowerCase() ===
                  "#EFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ background: "#EFE5C1" });
              setCurrentBackgroundColor(15);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={
              {
                // background: "#EFE5C1",
                // border: currentBackgroundColor == 16 ? "3px solid #007aff" : "",
              }
            }
            onClick={() => {
              handleChangeBackgroundButton({ background: "transparent" });
              setCurrentBackgroundColor(16);
            }}
          >
            <CloseRoundedIcon
              style={{ color: "red" }}

              // sx={{
              //   ".MuiSvgIcon-root": {
              //     fill: "red",
              //   },
              // }}
            />
          </span>
        </div>
      </div>
      <div>
        <div>Transparency</div>
        <Slider
          min={0}
          max={1}
          step={0.01}
          value={opacity}
          style={{ width: `${Math.ceil(opacity * 100) || 100}%}` }}
          onChange={handleOpacity}
        />
        <div>{Math.ceil(opacity * 100)}%</div>
      </div>
      <div>
        <div className="font-type-header">Text</div>
        <div className="font-type-cir-cont" style={{ position: "relative" }}>
          <div style={stylesText.swatch} onClick={handleClickText}>
            <div style={stylesText.color} />
          </div>

          {displayColorPickerText ? (
            <div style={stylesText.popoverText}>
              <div style={stylesText.coverText} onClick={handleCloseText} />
              <SketchPicker color={colorText} onChange={handleChangeText} />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              ...PicheaderStyleText,
              border: currentTextColor == 1 ? "3px solid #007aff" : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: PicColorText });
              setCurrentTextColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              border:
                currentTextColor == 2 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#FFFFFF".toLowerCase()
                  ? "3px solid #007aff"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#FFFFFF" });
              setCurrentTextColor(2);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border:
                currentTextColor == 3 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#000000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#000000" });
              setCurrentTextColor(3);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border:
                currentTextColor == 4 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#808080".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#808080" });
              setCurrentTextColor(4);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border:
                currentTextColor == 5 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#0000ff".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#0000ff" });
              setCurrentTextColor(5);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border:
                currentTextColor == 6 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#a52a2a".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#a52a2a" });
              setCurrentTextColor(6);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border:
                currentTextColor == 7 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#ff0000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#ff0000" });
              setCurrentTextColor(7);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border:
                currentTextColor == 8 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#008000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#008000" });
              setCurrentTextColor(8);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border:
                currentTextColor == 9 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#FFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#FFE5C1" });
              setCurrentTextColor(9);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border:
                currentTextColor == 10 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#E38CBA".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#E38CBA" });
              setCurrentTextColor(10);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border:
                currentTextColor == 11 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#19609C".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#19609C" });
              setCurrentTextColor(11);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border:
                currentTextColor == 12 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#51756B".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#51756B" });
              setCurrentTextColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border:
                currentTextColor == 13 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#897046".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#897046" });
              setCurrentTextColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border:
                currentTextColor == 14 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#FFE5E7".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#FFE5E7" });
              setCurrentTextColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border:
                currentTextColor == 15 ||
                currentTheme?.StyledButton?.color?.toLowerCase() ===
                  "#EFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeTextColor({ color: "#EFE5C1" });
              setCurrentTextColor(15);
            }}
          ></span>
          {/* <span
            className="texxt-color-sw"
            style={
              {
                // background: "#EFE5C1",
                // border: currentBackgroundColor == 16 ? "3px solid #007aff" : "",
              }
            }
            onClick={() => {
              handleChangeTextColor({ color: "transparent" });
              setCurrentTextColor(16);
            }}
          >
            <CloseRoundedIcon
              sx={{
                ".MuiSvgIcon-root": {
                  fill: "red",
                },
              }}
            />
          </span> */}
        </div>
      </div>

      <div>
        <div className="font-type-header">Shadow</div>
        <div className="font-type-cir-cont" style={{ position: "relative" }}>
          <div style={stylesShadow.swatch} onClick={handleClickShadow}>
            <div style={stylesShadow.color} />
          </div>

          {displayColorPickerShadow ? (
            <div style={stylesShadow.popoverShadow}>
              <div
                style={stylesShadow.coverShadow}
                onClick={handleCloseShadow}
              />
              <SketchPicker color={colorShadow} onChange={handleChangeShadow} />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              ...PicheaderStyleShadow,
              border: currentShadowColor == 1 ? "3px solid #007aff" : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: PicColorShadow });
              setCurrentShadowColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              border:
                currentShadowColor == 2 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#FFFFFF".toLowerCase()
                  ? "3px solid #007aff"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#FFFFFF" });
              setCurrentShadowColor(2);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border:
                currentShadowColor == 3 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#000000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#000000" });
              setCurrentShadowColor(3);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border:
                currentShadowColor == 4 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#808080".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#808080" });
              setCurrentShadowColor(4);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border:
                currentShadowColor == 5 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#0000ff".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#0000ff" });
              setCurrentShadowColor(5);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border:
                currentShadowColor == 6 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#a52a2a".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#a52a2a" });
              setCurrentShadowColor(6);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border:
                currentShadowColor == 7 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#ff0000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#ff0000" });
              setCurrentShadowColor(7);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border:
                currentShadowColor == 8 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#008000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#008000" });
              setCurrentShadowColor(8);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border:
                currentShadowColor == 9 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#FFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#FFE5C1" });
              setCurrentShadowColor(9);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border:
                currentShadowColor == 10 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#E38CBA".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#E38CBA" });
              setCurrentShadowColor(10);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border:
                currentShadowColor == 11 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#19609C".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#19609C" });
              setCurrentShadowColor(11);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border:
                currentShadowColor == 12 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#51756B".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#51756B" });
              setCurrentShadowColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border:
                currentShadowColor == 13 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#897046".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#897046" });
              setCurrentShadowColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border:
                currentShadowColor == 14 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#FFE5E7".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#FFE5E7" });
              setCurrentShadowColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border:
                currentShadowColor == 15 ||
                currentTheme?.StyledButton?.boxShadowColor?.toLowerCase() ===
                  "#EFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "#EFE5C1" });
              setCurrentShadowColor(15);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={
              {
                // background: "#EFE5C1",
                // border: currentBackgroundColor == 16 ? "3px solid #007aff" : "",
              }
            }
            onClick={() => {
              handleChangeBackgroundButton({ boxShadowColor: "transparent" });
              setCurrentShadowColor(16);
            }}
          >
            <CloseRoundedIcon
              style={{ color: "red" }}

              // sx={{
              //   ".MuiSvgIcon-root": {
              //     fill: "red",
              //   },
              // }}
            />
          </span>
        </div>
      </div>

      <div>
        <div className="font-type-header">Outline</div>
        <div className="font-type-cir-cont" style={{ position: "relative" }}>
          <div style={stylesOutline.swatch} onClick={handleClickOutline}>
            <div style={stylesOutline.color} />
          </div>

          {displayColorPickerOutline ? (
            <div style={stylesOutline.popoverOutline}>
              <div
                style={stylesOutline.coverOutline}
                onClick={handleCloseOutline}
              />
              <SketchPicker
                color={colorOutline}
                onChange={handleChangeOutline}
              />
            </div>
          ) : null}

          <span
            className="texxt-color-sw"
            style={{
              ...PicheaderStyleOutline,
              border: currentOutlineColor == 1 ? "3px solid #007aff" : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: PicColorOutline });
              setCurrentOutlineColor(1);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFFFFF",
              border:
                currentOutlineColor == 2 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#FFFFFF".toLowerCase()
                  ? "3px solid #007aff"
                  : "3px solid #eee",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#FFFFFF" });
              setCurrentOutlineColor(2);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "black",
              border:
                currentOutlineColor == 3 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#000000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#000000" });
              setCurrentOutlineColor(3);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "gray",
              border:
                currentOutlineColor == 4 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#808080".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#808080" });
              setCurrentOutlineColor(4);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "blue",
              border:
                currentOutlineColor == 5 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#0000ff".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#0000ff" });
              setCurrentOutlineColor(5);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "brown",
              border:
                currentOutlineColor == 6 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#a52a2a".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#a52a2a" });
              setCurrentOutlineColor(6);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "red",
              border:
                currentOutlineColor == 7 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#ff0000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#ff0000" });
              setCurrentOutlineColor(7);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "green",
              border:
                currentOutlineColor == 8 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#008000".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#008000" });
              setCurrentOutlineColor(8);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5C1",
              border:
                currentOutlineColor == 9 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#FFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#FFE5C1" });
              setCurrentOutlineColor(9);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#E38CBA",
              border:
                currentOutlineColor == 10 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#E38CBA".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#E38CBA" });
              setCurrentOutlineColor(10);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#19609C",
              border:
                currentOutlineColor == 11 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#19609C".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#19609C" });
              setCurrentOutlineColor(11);
            }}
          ></span>

          <span
            className="texxt-color-sw"
            style={{
              background: "#51756B",
              border:
                currentOutlineColor == 12 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#51756B".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#51756B" });
              setCurrentOutlineColor(12);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#897046",
              border:
                currentOutlineColor == 13 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#897046".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#897046" });
              setCurrentOutlineColor(13);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#FFE5E7",
              border:
                currentOutlineColor == 14 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#FFE5E7".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#FFE5E7" });
              setCurrentOutlineColor(14);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={{
              background: "#EFE5C1",
              border:
                currentOutlineColor == 15 ||
                currentTheme.StyledButton.borderColor.toLowerCase() ===
                  "#EFE5C1".toLowerCase()
                  ? "3px solid #007aff"
                  : "",
            }}
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "#EFE5C1" });
              setCurrentOutlineColor(15);
            }}
          ></span>
          <span
            className="texxt-color-sw"
            style={
              {
                // background: "#EFE5C1",
                // border: currentBackgroundColor == 16 ? "3px solid #007aff" : "",
              }
            }
            onClick={() => {
              handleChangeBackgroundButton({ borderColor: "transparent" });
              setCurrentOutlineColor(16);
            }}
          >
            <CloseRoundedIcon
              style={{ color: "red" }}
              // sx={{
              //   ".MuiSvgIcon-root": {
              //     fill: "red",
              //     color: "red",
              //   },
              // }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ColorPage;
