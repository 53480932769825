import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import vCard from "vcf";
// import { CSVLink, CSVDownload } from "react-csv";
import FileSaver, { saveAs } from "file-saver";
import { EmailOutlined } from "@mui/icons-material";
import { useTheme } from "../../styles/ThemeContext";
import VCard from "vcard-creator";
const ContactVCFGenerator = ({ vcfContent, pid, fN }) => {
  const generateVCF = () => {
    // const card = new vCard();

    // Set properties based on the provided information
    // card.add("FN", `${firstName} ${lastName}`);
    // card.add("N", [lastName, firstName]);
    // card.add("ORG", company);
    // card.add("TITLE", jobTitle);
    // card.add("EMAIL", email);
    // card.add("TEL", phone);
    // card.add("ADR", [null, null, null, null, address]);

    // You can add more properties as needed

    // Generate the VCF string
    // const vcfContent = card.toString();

    // Create a Blob from the VCF string
    const vcfBlob = new Blob([vcfContent], {
      type: "text/vcard;charset=utf-8",
    });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(vcfBlob);
    downloadLink.download = `${pid}_${fN}.vcf`;

    // Trigger the download
    downloadLink.click();
  };
  return (
    <div>
      {/* <h2>Contact VCF Generator</h2> */}
      <button
        style={{
          width: "100%",
          height: "40px",
          border: " 1px solid #333",
          borderRadius: "6px",
          fontWeight: "600",
        }}
        onClick={generateVCF}
      >
        Save to Contacts
      </button>
    </div>
  );
};

let ContactUsText = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  emailPrimary: "",
  emailPrimaryType: "",
  emailSecondary: "",
  emailSecondaryType: "",
  firstName: "",
  lastName: "",
  note: "",
  organization: "",
  phonePrimary: "",
  phonePrimaryType: "",
  phoneSecondary: "",
  phoneSecondaryType: "",
  position: "",
  postcode: "",
  state: "",
};
const PopUpContact = ({ show, setShow, details }) => {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchResult, setSearchResult] = useState(null);
  const { currentTheme, setCurrentTheme } = useTheme();
  const handleClose = () => setShow(false);
  const handleDelete = () => {};

  useEffect(() => {
    console.log("show", show);
    console.log("details", details);
    // console.log("vCard", new vCard().parse(`eldeeb: asasa `));
    // document.body.style.overflow = "hidden";
    // return () => {
    //   document.body.style.overflow = "unset";
    // };
  }, []);

  const downloadToFile = (content, filename) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: "text/vcard" });

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
  };

  const makeVCardVersion = () => `VERSION:3.0`;
  const makeVCardInfo = (info) => `N:${info}`;
  const makeVCardName = (name) => `FN:${name}`;
  const makeVCardOrg = (org) => `ORG:${org}`;
  const makeVCardTitle = (title) => `TITLE:${title}`;
  const makeVCardPhoto = (img) => `PHOTO;TYPE=JPEG;ENCODING=b:[${img}]`;
  const makeVCardTel = (phone, type) => `TEL;TYPE=${type},VOICE:${phone}`;
  const makeVCardAdr = (address, type) => `ADR;TYPE=${type},PREF:;;${address}`;
  const makeVCardEmail = (email, type) => `EMAIL:TYPE=${type},${email}`;
  const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;
  const getVCFDataFromAPI = () => {
    let vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardInfo(`${details?.firstName} ${details?.lastName}`)}
${makeVCardName(`${details?.firstName} ${details?.lastName}`)}
${makeVCardOrg(details?.company)}
${makeVCardTitle(details?.Position)}
${makeVCardTel(details?.phonePrimary, details?.phonePrimaryType)}
${makeVCardTel(details?.phoneSecondary, details?.phoneSecondaryType)}
${makeVCardAdr(details?.address, details?.addressType)}
${makeVCardEmail(details?.emailPrimary, details?.emailPrimaryType)}
${makeVCardEmail(details?.emailSecondary, details?.emailSecondaryType)}
${makeVCardTimeStamp()}
END:VCARD`;
    downloadToFile(
      vcard,
      `${details?.firstName}${currentTheme?.public_id}.vcf`
    );
  };
  const getVCFData = () => {
    // let {
    //   firstName,
    //   lastName,
    //   email,
    //   phone,
    //   address,
    //   company,
    //   Position,
    //   Organization,
    //   emailPrimary,
    //   emailPrimaryType,
    //   emailSecondary,
    //   emailSecondaryType,
    //   phonePrimary,
    //   phonePrimaryType,
    //   phoneSecondary,
    //   phoneSecondaryType,
    // } = details;
    const card = new vCard();
    card.add("fn", `${details?.firstName} ${details?.lastName}`);
    card.add("n", [details?.lastName, details?.firstName]);
    // card.add("org", details?.company);
    // card.add("TITLE", jobTitle);
    // card.add("EMAIL", details?.email);
    // card.add("EMAIL", details?.emailPrimary, details?.emailPrimaryType);
    card.add("org", details?.Organization);
    card.add("title", details?.Position);
    card.add("email", details?.emailPrimary, {
      type: details?.emailPrimaryType,
    });
    card.add("email", details?.emailSecondary, {
      type: details?.emailSecondaryType,
    });
    card.add("tel", details?.phonePrimary, details?.phonePrimaryType);
    card.add("tel", details?.phoneSecondary, details?.phoneSecondaryType);
    // add url to card
    card.add("url", `https://fastworldkw.net/${currentTheme.public_id}`);
    // card.add("rev", `https://fastworldkw.net/${currentTheme.public_id}`);

    // Add phone numbers
    // card.add("tel", "123-456-7890", { type: "work" });
    // card.add("TEL", details?.phone);
    card.add("adr", [
      ,
      details?.country,
      details?.city,
      details?.address2,
      details?.address1,
    ]);
    // card.add('adr', ['123 Main St  reet', '', '', 'Cityville', 'CA', '12345', 'USA'], { type: 'home' });
    return card.toString();
  };
  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        // fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">contact details </DialogTitle>
        <DialogContent
          sx={{
            p: "20px",
          }}
        >
          <DialogContentText>
            <div
              style={{
                // width: "500px",
                maxWidth: "500px",
                height: "500px",
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: "space-evenly",
              }}
            >
              <div
                // style={{ width: "75%" }}
                id="sameId_as_contact_id"
              >
                <h2 style={{ textAlign: "center" }}>Contact</h2>
                <div>
                  {/* {details &&
                    Object.keys(details)?.map((key) => {
                      console.log("key", key);
                      if (key == "firstName") {
                        return (
                          <h3 style={{ padding: "10px", textAlign: "center" }}>
                            {" "}
                            {details[key]}
                          </h3>
                        );
                      } else if (key == "lastName") {
                        return (
                          <h3 style={{ padding: "10px", textAlign: "center" }}>
                            {" "}
                            {details[key]}
                          </h3>
                        );
                      } else if (key == "Position") {
                        return <p> {details[key]}</p>;
                      } else if (key == "Organization") {
                        return <p> {details[key]}</p>;
                      } else if (key == "emailPrimary") {
                        return <p> {details[key]}</p>;
                      } else if (key == "emailSecondary") {
                        return <p> {details[key]}</p>;
                      } else if (key == "phonePrimary") {
                        return (
                          <p style={{ padding: "10px" }}>
                            <PhoneInTalkIcon
                              fontSize="small"
                              style={{ margin: "0 20px 0 0" }}
                            />{" "}
                            {details["phonePrimaryType"]} {details[key]}
                          </p>
                        );
                      } else if (key == "phoneSecondary") {
                        return (
                          <p style={{ padding: "10px" }}>
                            <PhoneInTalkIcon
                              fontSize="small"
                              style={{ margin: "0 20px 0 0" }}
                            />{" "}
                            {details["phoneSecondaryType"]} {details[key]}
                          </p>
                        );
                      } else if (key == "note") {
                        return <p> {details[key]}</p>;
                      }
                    })} */}
                  <div style={{ textAlign: "center" }}>
                    {details && details["firstName"] ? (
                      <h3>{`${details["firstName"]} ${
                        details["lastName"] ? details["lastName"] : ""
                      }`}</h3>
                    ) : null}
                    {details && details["Position"] ? (
                      <div style={{ textAlign: "center" }}>
                        <p>
                          {details["Position"]}{" "}
                          {details["Organization"]
                            ? details["Organization"]
                            : ""}
                        </p>
                      </div>
                    ) : null}
                    {details && details["emailPrimary"] ? (
                      <div style={{ textAlign: "start" }}>
                        <p>
                          {" "}
                          {details["emailPrimaryType"]
                            ? details["emailPrimaryType"]
                            : ""}{" "}
                        </p>
                        <div style={{ display: "flex" }}>
                          <EmailOutlined />{" "}
                          <a href={`mailto:${details["emailPrimary"]}`}>
                            {" "}
                            {details["emailPrimary"]}{" "}
                          </a>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    {details && details["emailSecondary"] ? (
                      <div style={{ textAlign: "start" }}>
                        <p>
                          {" "}
                          {details["emailSecondaryType"]
                            ? details["emailSecondaryType"]
                            : ""}{" "}
                        </p>
                        <div style={{ display: "flex" }}>
                          <EmailOutlined />{" "}
                          <a href={`mailto:${details["emailSecondary"]}`}>
                            {" "}
                            {details["emailSecondary"]}{" "}
                          </a>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    {details && details["phonePrimary"] ? (
                      <div style={{ textAlign: "start" }}>
                        <p>
                          {" "}
                          {details["phonePrimaryType"]
                            ? details["phonePrimaryType"]
                            : ""}{" "}
                        </p>
                        <div style={{ display: "flex" }}>
                          <PhoneInTalkIcon /> <p> {details["phonePrimary"]} </p>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    {details && details["phoneSecondary"] ? (
                      <div style={{ textAlign: "start" }}>
                        <p>
                          {" "}
                          {details["phoneSecondaryType"]
                            ? details["phoneSecondaryType"]
                            : ""}{" "}
                        </p>
                        <div style={{ display: "flex" }}>
                          <PhoneInTalkIcon />{" "}
                          <p> {details["phoneSecondary"]} </p>
                        </div>
                      </div>
                    ) : null}
                    {}
                  </div>
                  <br />
                  <p style={{ padding: "10px" }}>
                    <i style={{ marginRight: "5px", display: "inline-block" }}>
                      {" "}
                      <AddLocationIcon
                        style={{ margin: "0 20px 0 0" }}
                        fontSize="small"
                      />
                    </i>
                    {}{" "}
                    {details && details["country"]
                      ? `${details["country"]} /  `
                      : ""}{" "}
                    {details && details["address1"]
                      ? `${details["address1"]} / `
                      : ""}{" "}
                    {details && details["address2"]
                      ? `${details["address2"]} / `
                      : ""}{" "}
                    {details && details["city"] ? `${details["city"]} / ` : ""}{" "}
                    {details && details["state"]
                      ? `${details["state"]} / `
                      : ""}{" "}
                    {details && details["postcode"]
                      ? `${details["postcode"]} / `
                      : ""}{" "}
                  </p>
                </div>
                <div>
                  <svg
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 48 48"
                  >
                    <path d="M24,2.67A21.33,21.33,0,1,0,45.33,24,21.33,21.33,0,0,0,24,2.67ZM19.51,27.08a1.25,1.25,0,0,1-.85.34,1.18,1.18,0,0,1-.84-.35L13,22.22A7,7,0,0,1,12,21a6.39,6.39,0,0,1-.85-2.59,6.31,6.31,0,0,1,1.55-4.89,9,9,0,0,1,1.44-1.31,6.07,6.07,0,0,1,2.38-1,6.33,6.33,0,0,1,2.75.12,6.27,6.27,0,0,1,2.44,1.32c.31.28.61.58.9.87.12.13.25.25.37.37l3.86,3.82a1.21,1.21,0,0,1,.35.89,1.1,1.1,0,0,1-.36.83,1.23,1.23,0,0,1-1.69,0l-4.89-4.84a2.92,2.92,0,0,0-.32-.28h0v0l-.17-.12a3.87,3.87,0,0,0-1.62-.62,4.18,4.18,0,0,0-1,0,4.46,4.46,0,0,0-.95.26,4.78,4.78,0,0,0-.45.23l-.22.13-.12.09,0,0c-.18.15-.36.33-.57.53l-.21.21-.21.23-.06.07h0a5.67,5.67,0,0,0-.5.86,5.68,5.68,0,0,0-.27,1,5,5,0,0,0,0,1.09,5.23,5.23,0,0,0,.28,1,4,4,0,0,0,.31.58l.16.24.07.09.1.11.27.28,4.8,4.8a1.21,1.21,0,0,1,.35.89A1.14,1.14,0,0,1,19.51,27.08Zm8.93-.47c.23.22.47.45.7.7a1.27,1.27,0,0,1,.37,1,1.17,1.17,0,0,1-.39.89,1.33,1.33,0,0,1-.91.36,1.27,1.27,0,0,1-.9-.38l-7.75-7.74-.7-.7a1.34,1.34,0,0,1-.37-1,1.22,1.22,0,0,1,.39-.89,1.31,1.31,0,0,1,1.81,0ZM35,34.78a7.41,7.41,0,0,1-1.67,1.33,6.49,6.49,0,0,1-2.6.76l-.5,0a6.27,6.27,0,0,1-4.35-1.76l-.72-.72-4.25-4.24a1.23,1.23,0,0,1-.35-.9,1.13,1.13,0,0,1,.36-.82,1.22,1.22,0,0,1,1.69,0l4.95,4.94.29.27,0,0,0,0h0l.16.11a4.94,4.94,0,0,0,.64.35,5.56,5.56,0,0,0,1,.27,5,5,0,0,0,1.09,0,5.45,5.45,0,0,0,1-.27,5.51,5.51,0,0,0,.85-.49l.09-.08.22-.2.2-.2c.21-.21.38-.39.53-.57l0,0,.08-.12a1.64,1.64,0,0,0,.14-.22,4.78,4.78,0,0,0,.23-.45,4.37,4.37,0,0,0,.26-.94,3.74,3.74,0,0,0-.26-2,5.12,5.12,0,0,0-.3-.57c0-.06-.09-.12-.13-.19l0,0h0a.35.35,0,0,0-.08-.1c0-.06-.08-.1-.12-.15l-.28-.29-4.69-4.74a1.25,1.25,0,0,1-.35-.89,1.17,1.17,0,0,1,.36-.82,1.22,1.22,0,0,1,1.69,0L35.06,26a6.14,6.14,0,0,1,0,8.8Z"></path>
                  </svg>{" "}
                  <a href={`https://fastworldkw.net/${currentTheme.public_id}`}>
                    https://fastworldkw.net/{currentTheme.public_id}
                  </a>
                </div>
                <br />
                {/* <Button
                  onClick={() => {
                    downloadContact();
                  }}
                  sx={{ marginTop: "20px", width: "100%", textAlign: "center" }}
                >
                  download
                </Button> */}
                <ContactVCFGenerator
                  // card={new vCard().parse(JSON.stringify(details))}
                  vcfContent={getVCFData()}
                  pid={currentTheme?.public_id}
                  fN={details?.firstName}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpContact;
