import { Box, Typography } from '@mui/material'
import React from 'react'
import video from '../../assets/Image/video.PNG'

const Help = () => {
  return (

<Box sx={{margin:'0 auto', alignContent:'center', alignItems:'center', display:'flex', justifyConten:'center', maxWidth:'800px'}}>

<Box>

<Typography component='h2' sx={{fontSize:'35px', fontWeight:'600', marginY:'15px', marginLeft:'80px'}}>Help</Typography>


<Box sx={{marginY:'35px', width:'80%', margin:'auto'}}>
<Typography component='h2' sx={{fontSize:'16px', lineHeight:'30px',  marginTop:'35px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px',  marginTop:'35px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span>
</Typography>
</Box>

<Box sx={{marginY:'35px', width:'80%', margin:'auto'}}>
<Typography component='h2' sx={{fontSize:'16px', lineHeight:'30px', marginTop:'35px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>

<Box sx={{marginY:'35px', width:'80%', margin:'auto' ,marginTop:'35px'}}>
<Typography component='h2' sx={{fontSize:'16px', lineHeight:'30px',  marginTop:'35px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>

<Box sx={{marginTop:'35px', marginRight:'200px'}}>
<img src={video} alt="Video help" style={{width:'140%'}}/>
</Box>

<Box sx={{ width:'60%', margin:'auto'}}>
<Typography component='h2' sx={{fontSize:'13px',marginBottom:'70px' }}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>


</Box>

</Box>
  )
}

export default Help
