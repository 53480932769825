import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Input } from "@mui/joy";

// import icons and Image
import CloseIcon from "@mui/icons-material/Close";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import iconDefault from "../../../assets/Image/defaultIcons.png";

// import { MapContainer, TileLayer } from "react-leaflet";
import { ReactLeafletSearch } from "react-leaflet-search";
import MyGoogleMap from "./MyGoogleMaps";

const PopUpMap = ({ show, setShow, values, handleSave, handleChange }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log(values);
  const [searchResult, setSearchResult] = useState(null);

  const handleSearch = (result) => {
    setSearchResult(result);
  };
  const handleClose = () => setShow(false);
  const handleDelete = () => {};

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title"> Map </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                width: "500px",
                height: "350px",
                flexWrap: "wrap",
              }}
            >
              <div>
                {/* <MapContainer center={[0, 0]} zoom={2}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />

                  <ReactLeafletSearch
                    position="topright"
                    inputPlaceholder="Search..."
                    showMarker={true}
                    closeResultsOnClick={true}
                    openSearchOnLoad={false}
                    onSearch={handleSearch}
                  />
                </MapContainer> */}
                {/* <MyGoogleMap />
                {searchResult && (
                  <div>
                    <h3>Search Result</h3>
                    <p>Location: {searchResult.display_name}</p>
                    <p>Latitude: {searchResult.latlng[0]}</p>
                    <p>Longitude: {searchResult.latlng[1]}</p>
                  </div>
                )} */}
                <Box sx={{ marginTop: "40px", display: "flex" }}>
                  <img
                    src={
                      "https://fastworldkw.net/fastworld_backend/public/uploads/social_icons/icons8-google-maps.svg" ||
                      iconDefault
                    }
                    alt=""
                    style={{
                      display: "block",
                      width: "80px",
                      height: "80px",
                      margin: "auto",
                    }}
                  />
                </Box>
                {/* button text */}
                <Box sx={{ marginTop: "50px" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      lineHeight: "27,24px",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    Button Text
                  </Typography>
                  <Input
                    size="md"
                    placeholder="Enter Button Text"
                    value={values?.title}
                    name="title"
                    sx={{
                      marginTop: "15px",
                      boxShadow: ".5px .5px .5px .5px #19609C",
                      width: "100%",
                    }}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ marginTop: "15px", width: "100%" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      lineHeight: "27,24px",
                      marginLeft: "0px",
                    }}
                  >
                    Map Link
                  </Typography>
                  <Input
                    size="md"
                    placeholder="Enter Map Link"
                    value={values?.link}
                    name="link"
                    sx={{
                      marginTop: "15px",
                      boxShadow: ".5px .5px .5px .5px #19609C",
                    }}
                    onChange={handleChange}
                  />
                </Box>
                <Button
                  size="md"
                  sx={{
                    width: "95%",
                    display: "block",
                    margin: "20px auto",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "45px",
                    color: "#FFF",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    ":hover": {
                      width: "100%",
                      fontSize: "14px",
                    },
                  }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpMap;
