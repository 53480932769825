import React, { useEffect, useState } from "react";
import MainStructureTemplate from "../components/MainStructureTemplate";
import { useTheme } from "../components/styles/ThemeContext";
import { useParams } from "react-router-dom";
import { useMyTemplateContext } from "../components/styles/MytempletContext";
import Base_Url, { Apipassword, axiosRequset } from "../Api/ApiUrl";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { Helmet } from "react-helmet";

const FullTemplate = () => {
  const { currentTheme, setCurrentTheme } = useTheme();
  const [loding, setLoding] = useState(true);
  const [error, setError] = useState(false);
  const { id } = useParams();
  console.log(currentTheme);

  useEffect(() => {
    console.log("myTemplate", currentTheme);
    if (loding) {
      axiosRequset
        .post("/guest/Link/View_Link", { public_id: id })
        .then((res) => {
          console.log(res.data.link.Information);
          // console.log(res.data.link.Information.description);
          //template
          // setTimeout(() => {
          setCurrentTheme({
            ...res.data.link.Information.object,
            public_id: res.data.link.public_id,
            img: res.data.link.Information.image,
            id: res.data.link.Information.id,
            Contents: res.data.link.Information.Contents,
            orders: res.data.link.Information.orders,
            data: {
              title: res.data.link.Information.title,
              // ? res.data.Information.title
              // : "title",
              description: res.data.link.Information.description,
              // ? res.data.Information.description
              // : "desc",
            },
            removeBrand: res.data.link.Information.remove_brand,
            verified: res.data.link.Information.verified,
          });
          setLoding(false);
          // }, 4000);
        })
        .catch((error) => {
          console.log(error);
          setLoding(false);
          setError(true);
        });
    }
  }, []);
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={currentTheme?.data?.title} />
        <meta
          property="og:description"
          content={currentTheme?.data?.description}
        />
        <meta property="og:image" content={currentTheme?.img} />
      </Helmet>
      {loding && (
        <Stack
          sx={{ width: "100%", color: "grey.500", marginTop: "2rem" }}
          spacing={2}
        >
          <LinearProgress color="secondary" />
          <LinearProgress color="success" />
          <LinearProgress color="inherit" />
        </Stack>
      )}
      {!loding && currentTheme?.public_id == id && <MainStructureTemplate />}
      {!loding && error && (
        <h1 style={{ marginTop: "5rem", textAlign: "center" }}>
          {" "}
          Unexpected Application Error! 404 Not Found
        </h1>
      )}
    </div>
  );
};

export default FullTemplate;
