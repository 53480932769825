import React from 'react'
import "./section4.css"
const Section4 = () => {
  return (
    <div className="section4">
      <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="blue"></div>
                <div className="white"></div>
                <div className="beige"></div>
            </div>
            <div className="col-md-6">
                <div className="section4-text">
                    <h1>More than 1,200,000 creators are already on Fast World</h1>
                    <button>Subscribe Now</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Section4