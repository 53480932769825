import React from 'react'
import "./card.css"
import image4 from "../../../assets/newsection/Rectangle 29.png"

const Card = (props) => {
  return (
    <div className='card'>
       <div className="image"><img src={image4} alt="" /></div>
       <div className="info-card">
        <h2>{props.name}</h2>
        <p>Senior Software Engnieer</p>
          <h3>
          Your product helped us to <br /> learn about our customers, intimately.
          </h3>
       </div>
    </div>
  )
}

export default Card