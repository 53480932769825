import  React from 'react';
import {  styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';
import Monthly from './Monthly';
import Annually from './Anuually';
import { Box, Typography } from '@mui/material';

const Pricing = () => {
  return (


<Box>
<Box sx={{textAlign:'center'}}>
<Typography component='h2' sx={{fontSize:'45px' ,color:'black'}}>Best <span style={{color:'#19609C'}}>Plans</span> For Business</Typography>
<Typography component='p' sx={{fontSize:'25px' ,color:'black'}}>No surprise fees. Choose a plan that works best for you and your team.</Typography>
</Box>


<Box sx={{marginY:'30px'}}>
<Tabs defaultValue={0}>

<StyledTabsList sx={{margin:'auto'}}>
       <StyledTab value={0}>Monthly</StyledTab>
       <StyledTab value={1}>Anuually</StyledTab>
     </StyledTabsList>
     <StyledTabPanel value={0}><Monthly/></StyledTabPanel>
     <StyledTabPanel value={1}><Annually/></StyledTabPanel>
 </Tabs>
</Box>

</Box>

)
}

export default Pricing





const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTab = styled(Tab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #54B99B;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 20%;
  padding: 20px 25px;
  margin: 10px 30px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #19609C;
    width:100px

  }

  &:focus {
    color:#fff;
    // outline: 3px solid ${blue[200]};
    width:100px
  }

  &.${tabClasses.selected} {
    background-color: #19609C;
    color: #fff;
    width:100px

    
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  // border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.6;
  `,
);

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  max-width: 270px;
  // background-color: ${blue[100]};
  // padding: 5px 15px;
  border-radius: 50px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 1px solid #91D6C2;
  // box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);