import React, { useState } from "react";

const VideoPage = () => {
  const [bgVideo, setBgVideo] = useState("");

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setBgVideo(videoUrl);

      
    }
  };

  return (
    <div>
      {/* Upload Video */}
      <input type="file" accept="video/*" onChange={handleVideoChange} />
    </div>
  );
};

export default VideoPage;
