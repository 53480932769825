import { Box, Typography } from "@mui/material";

const PopUpCard = ({ img, title, desc, onClick, marginLeft }) => {
  return (
    <Box
      sx={{
        width: "210px",
        height: "155px",
        boxShadow: "0px 4px 6px -2px #91D6C2",
        borderRadius: "20px",
        marginTop: "10px",
        cursor: "pointer",
        textAlign: "center",
       
      }}
      onClick={onClick}
    >
      <Box
        sx={{ justifyContent: "center", display: "flex", alignItems: "center"}}
      >
        <img
          src={img}
          alt="img"
          style={{ paddingTop: "30px", width: "30px" }}
        />
      </Box>

      <Box>
        <Typography
          component="h2"
          sx={{
            fontSize: "18px",
            color: "balck",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>

        <Box sx={{ width: "90%", height: "42px" }}>
          <Typography
            component="p"
            sx={{
              fontSize: "12px",
              color: "black",
              marginLeft: "30px",
              marginTop: "10px",
            }}
          >
            {desc}{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PopUpCard;
