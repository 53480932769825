import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Button, Input } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddFieldPopUp from "../PopUp/AddFieldPopUp";
import { axiosRequset } from "../../../Api/ApiUrl";
const FormComponent = ({
  data,
  onDelete,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  const [values, setValues] = useState({});
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [formContant, setFormContant] = useState({
    fields: [
      { id: 0, key: "Full Name", title: "Full Name", required: false },
      { id: 1, key: "email", title: "email", required: true },
    ],
    submit: { id: "submit", key: "submit", title: "submit", required: true },
  });

  const heandelAddField = (value) => {
    if (value.title == "" || value.title == null) {
      toast.error("Title is required");
      return;
    }
    setFormContant({
      ...formContant,
      fields: [
        ...formContant.fields,
        { id: formContant.fields.length, key: value.key, title: value.title },
      ],
    });

    setShow(false);
  };
  const heandelEditField = (value) => {
    setFormContant({
      ...formContant,
      fields: formContant.fields.map((field) => {
        if (field.id === value.id) {
          return value;
        }
        return field;
      }),
    });
    setShowEdit(false);
  };
  const heandelDeleteField = (id) => {
    setFormContant({
      ...formContant,
      fields: formContant.fields.filter((field) => field.id !== id),
    });
    toast.success("Deleted Successfully");
  };

  const handleSave = () => {
    axiosRequset
      .post("/owner/Content/update", {
        type: "form",
        id: data.id,
        text: [formContant],
      })
      .then((res) => {
        toast.success("Saved Successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleEditeSubmit = (newValue) => {
    setFormContant({
      ...formContant,
      submit: newValue,
    });
    setShowSubmit(false);
  };
  useEffect(() => {
    // let x = JSON.parse(
    //   '{"fields":[{"key":"email","required":1,"services":null,"sync":0,"title":"Email"},{"key":"input","required":0,"services":null,"sync":0,"title":"Full Name"},{"key":"checkbox","required":0,"title":"Checkboxfffff","services":["fffffffffff"]},{"key":"regions","required":1,"title":"zzzzz"},{"key":"regions","required":0,"title":"zzzzzzzzzRegions"}],"submit":{"btn_text":"Submit","thanks_text":"Thanks for submitting!"},"title":"Contact us","themes":[],"bio":{"uid":"50924fdgmn9","pro":1,"pg":1,"logoshow":1}}'
    // );
    // console.log("x", x);
    if (data?.text.length > 0 && data?.text != "null") {
      setFormContant({ ...JSON.parse(data.text)[0] });
      // console.log(JSON.parse(data.text));
    }
  }, []);
  return (
    <div>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Contact Form </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginX: "15px", position: "relative", width: "100%" }}>
              <Box sx={{ marginTop: "30px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="p"
                    sx={{ fontSize: "20px", marginTop: "10px" }}
                  >
                    Form
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1.5px",
                    backgroundColor: "#787878",
                    marginY: "15px",
                  }}
                ></Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "15px",
                      marginTop: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    Click on default text to edit{" "}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "19px",
                      marginTop: "30px",
                      marginLeft: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Contact us
                  </Typography>

                  <Box sx={{ marginTop: "35px" }}>
                    {formContant.fields?.length > 0 &&
                      formContant.fields.map(({ id, key, title, required }) => {
                        if (key == "submit") {
                          return null;
                        }
                        return (
                          <Input
                            key={id}
                            size="md"
                            placeholder={title}
                            label={key}
                            sx={{
                              marginTop: "15px",
                              boxShadow: ".5px .5px .5px .5px #19609C",
                              width: "70%",
                              marginLeft: "70px",
                              height: "45px",
                              paddingTop: "5px",
                            }}
                            endDecorator={
                              <>
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setValues({ id, key, title, required });
                                    setShowEdit(true);
                                  }}
                                />
                                <DeleteOutlineIcon
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => heandelDeleteField(id)}
                                />
                              </>
                            }
                          />
                        );
                      })}
                    <Button
                      size="md"
                      sx={{
                        width: "40%",
                        color: "white",
                        border: "1px solid #38e199",
                        height: "57px",
                        margin: "10px auto 20px",
                        display: "block",
                      }}
                      onClick={() => {
                        setShowSubmit(true);
                        setValues(formContant.submit);
                      }}
                    >
                      {formContant.submit?.title}{" "}
                      <EditIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
                <Button
                  size="md"
                  sx={{
                    width: "80%",
                    marginTop: "50px",
                    color: "#54B99B",
                    background: "white",
                    border: "1px solid #38e199",
                    height: "57px",
                    marginLeft: "40px",
                    ":hover": {
                      color: "#38e199",
                      background: "white",
                    },
                  }}
                  onClick={() => {
                    setValues({});
                    setShow(true);
                  }}
                >
                  + Add field
                </Button>
                <Button
                  size="md"
                  sx={{
                    width: "80%",
                    marginTop: "50px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "57px",
                    marginLeft: "40px",
                  }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <AddFieldPopUp
        show={show}
        setShow={setShow}
        heandelAddField={heandelAddField}
        values={values}
        setValues={setValues}
        pupUpTitle=" Add Field"
      />
      <AddFieldPopUp
        show={showEdit}
        setShow={setShowEdit}
        heandelAddField={heandelEditField}
        values={values}
        setValues={setValues}
        pupUpTitle="Edit Field"
      />
      <AddFieldPopUp
        show={showSubmit}
        setShow={setShowSubmit}
        heandelAddField={handleEditeSubmit}
        values={values}
        setValues={setValues}
        pupUpTitle="Edit submit Field"
        iSsubmit={true}
      />
    </div>
  );
};

export default FormComponent;
