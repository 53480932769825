import React, { useState } from "react";
import {
  DivLinkTree,
  IconsForButton,
  StyledButton,
  StyledButtonDiv,
} from "../styles/MobileScreen.styled";
import { Box } from "@mui/material";
import PopUpContact from "./PopUp/PopUpContact";
import PopUpFormC from "./PopUp/PopUpFormC";
import { FastWorldIconsGetById } from "../Customize/PopUp/fastworldIcons";
import { useTheme } from "../styles/ThemeContext";
import DynamicIcon from "../Customize/PopUp/fastworldIcons/DynamicIcon";

const ButtonsT = ({ data }) => {
  const [showPopUpContact, setShowPopUpContact] = useState(false);
  const [details, setDetails] = useState();
  const [showPopUpFormC, setShowPopUpFormC] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const { currentTheme } = useTheme();
  console.log(data);
  const getType = () => {
    if (currentTheme?.StyledButton.thumbnail?.styleType == 1) {
      console.log("type 1");
      return 1;
    } else if (currentTheme?.StyledButton.thumbnail?.styleType == 2) {
      console.log("type 2");
      return 2;
    } else if (currentTheme?.StyledButton.thumbnail?.styleType == 6) {
      console.log("type 6");
      return 3;
    }
    console.log("type 0x");
    return 0;
  };
  console.log(currentTheme?.StyledButton.thumbnail?.styleType == 6, "style");
  const getIcon = (name) => {
    if (FastWorldIconsGetById[`${name}`] == undefined) {
      return "Groupxx";
    } else {
      return FastWorldIconsGetById[`${name}`][getType()];
    }
  };
  return (
    <>
      <DivLinkTree>
        {data.Buttons.length > 0 &&
          data?.Buttons?.map((item) => {
            // console.log("item", item);
            if (item.state == 0) {
              return;
            }
            if (item.type == "link_url" || item.type == "google_map") {
              return (
                <StyledButton
                  className={` ${item?.tcolor}`}
                  key={item.id}
                  // href={item.path}
                  href={item.link1}
                >
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      {console.log(item.icon_name === null, "null")}
                      {console.log(item.icon_name)}
                      <IconsForButton>
                        {item.icon_name === null ? (
                          <Box
                            component="img"
                            src={item.icon}
                            // sx={{ width: "100%", height: "100%" }}
                            sx={{
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              // width: "30px",
                              // height: "30px",
                              // display: "block",
                              // borderRadius: "50%",
                              // padding: "2px",
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // borderRadius: "50%",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                              background:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 2
                                  ? currentTheme.StyledButton.thumbnail?.color
                                  : "transparent",
                            }}
                          >
                            <DynamicIcon
                              componentName={getIcon(item?.icon_name)}
                              style={{
                                // width:
                                //   currentTheme?.StyledButton.thumbnail
                                //     ?.styleType == 2 ||
                                //   currentTheme?.StyledButton.thumbnail
                                //     ?.styleType == 6
                                //     ? "21px"
                                //     : "30px",
                                width:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                height:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                // width: "25px",
                                // height: "25px",
                                display: "inline-block",
                                // height: "30px",
                                // marginBottom: "10px",
                              }}
                            />
                          </div>
                        )}
                      </IconsForButton>
                      <p
                      // style={{
                      //   textAlign: "center,",
                      //   width: "100%",
                      //   lineHeight: "25px",
                      // }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Box>
                </StyledButton>
              );
            } else if (item.type == "contact_details") {
              return (
                <StyledButtonDiv
                  key={item.id}
                  onClick={() => {
                    // console.log(item);
                    // console.log(JSON.parse(item.text)[0]);
                    setDetails(JSON.parse(item.text)[0]);
                    setShowPopUpContact(true);
                  }}
                >
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <IconsForButton>
                        {item.icon_name === null ? (
                          <Box
                            component="img"
                            src={item.icon}
                            // sx={{ width: "100%", height: "100%" }}
                            sx={{
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              // width: "30px",
                              // height: "30px",
                              // display: "block",
                              // borderRadius: "50%",
                              // padding: "2px",
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // borderRadius: "50%",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                              background:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 2
                                  ? currentTheme.StyledButton.thumbnail?.color
                                  : "transparent",
                            }}
                          >
                            <DynamicIcon
                              componentName={getIcon(item?.icon_name)}
                              style={{
                                width:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                height:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                // width: "25px",
                                // height: "25px",
                                display: "inline-block",
                                // height: "30px",
                                // marginBottom: "10px",
                              }}
                            />
                          </div>
                        )}
                      </IconsForButton>
                      <p
                        style={{
                          textAlign: "center,",
                          width: "calc(100% - 30px)",
                          lineHeight: "25px",
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Box>
                </StyledButtonDiv>
              );
            } else if (item.type == "video") {
              return (
                <StyledButton href={item.link1} key={item.id}>
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <IconsForButton>
                        {item.icon_name === null ? (
                          <Box
                            component="img"
                            src={item.icon}
                            // sx={{ width: "100%", height: "100%" }}
                            sx={{
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              // width: "30px",
                              // height: "30px",
                              // display: "block",
                              // borderRadius: "50%",
                              // padding: "2px",
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // borderRadius: "50%",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                              background:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 2
                                  ? currentTheme.StyledButton.thumbnail?.color
                                  : "transparent",
                            }}
                          >
                            <DynamicIcon
                              componentName={getIcon(item?.icon_name)}
                              style={{
                                width:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                height:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                // width: "25px",
                                // height: "25px",
                                display: "inline-block",
                                // height: "30px",
                                // marginBottom: "10px",
                              }}
                            />
                          </div>
                        )}
                      </IconsForButton>
                      <p
                        style={{
                          textAlign: "center,",
                          width: "calc(100% - 30px)",
                          lineHeight: "25px",
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Box>
                </StyledButton>
              );
            } else if (item.type == "form") {
              return (
                <StyledButtonDiv
                  onClick={() => {
                    setDataForm(JSON.parse(item.text)[0]);
                    setShowPopUpFormC(true);
                  }}
                  key={item.id}
                >
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      {/* <div
                      style={{
                        position: "absolute",
                        height: "35px",
                        left: 0,
                        width: "35px",
                        textAlign: "start",
                      }}
                    >
                      {item?.icon ? (
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            display: "inline-block",
                            // height: "30px",
                            marginBottom: "10px",
                          }}
                          src={item?.icon}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </div> */}
                      <IconsForButton>
                        {item.icon_name === null ? (
                          <Box
                            component="img"
                            src={item.icon}
                            // sx={{ width: "100%", height: "100%" }}
                            sx={{
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              // width: "30px",
                              // height: "30px",
                              // display: "block",
                              // borderRadius: "50%",
                              // padding: "2px",
                              // width: "30px",
                              // height: "30px",
                              width: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              height: currentTheme?.StyledButton.thumbnail?.size
                                ? `${currentTheme?.StyledButton.thumbnail?.size}px`
                                : "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // borderRadius: "50%",
                              borderRadius:
                                currentTheme.StyledButton.thumbnail
                                  ?.borderRadius || "50%",
                              border:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? "2px solid"
                                  : 0,
                              background:
                                currentTheme?.StyledButton.thumbnail
                                  ?.styleType == 6
                                  ? currentTheme.StyledButton.thumbnail?.color
                                  : "transparent",
                            }}
                          >
                            <DynamicIcon
                              componentName={getIcon(item?.icon_name)}
                              style={{
                                width:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                height:
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 2 ||
                                  currentTheme?.StyledButton.thumbnail
                                    ?.styleType == 6
                                    ? `${
                                        currentTheme?.StyledButton.thumbnail
                                          ?.size -
                                        0.35 *
                                          currentTheme?.StyledButton.thumbnail
                                            ?.size
                                      }px`
                                    : `${currentTheme?.StyledButton.thumbnail?.size}px`,
                                // width: "25px",
                                // height: "25px",
                                display: "inline-block",
                                // height: "30px",
                                // marginBottom: "10px",
                              }}
                            />
                          </div>
                        )}
                      </IconsForButton>
                      <p
                        style={{
                          textAlign: "center,",
                          width: "calc(100% - 30px)",
                          lineHeight: "25px",
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Box>
                </StyledButtonDiv>
              );
            }
          })}
        {/* <StyledButton src="">Facebook</StyledButton>
        <StyledButton src="">Facebook</StyledButton>
        <StyledButton src="">Facebook</StyledButton> */}
      </DivLinkTree>
      <PopUpContact
        show={showPopUpContact}
        setShow={setShowPopUpContact}
        details={details}
      />
      <PopUpFormC
        show={showPopUpFormC}
        setShow={setShowPopUpFormC}
        form={dataForm}
      />
    </>
  );
};

export default ButtonsT;
