import { React, useEffect, useState } from "react";
import Settingbar from "../components/Settingbar";
import logo from "../assets/Images/logo.PNG";
import { axiosRequset } from "../Api/ApiUrl";
import { toast } from "react-toastify";

const RemoveBrand = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loding, setLoding] = useState(false);
  console.log(isOpen ? 0 : 1);
  // const [isRunning, setIsRunning] = useState(false);
  // Function to toggle the switch
  useEffect(() => {
    setLoding(true);
    // return () => {
    axiosRequset
      .get("/owner/Setting/get")
      .then((response) => {
        console.log(response.data);
        setIsOpen(response.data.remove_brand);
        setLoding(false);
        return "";
      })
      .catch((err) => {
        setLoding(false);
        console.log(err);
      });
    setLoding(false);
    // };
  }, []);
  if (loding) {
    return <div>Loading...</div>;
  }
  const toggleSwitch = () => {
    axiosRequset
      .post("/owner/Setting/update", { remove_brand: isOpen ? 0 : 1 })
      .then((response) => {
        console.log(response);
        toast.success("Success", response.data.message);
        setIsOpen(!isOpen);
        return "";
      })
      .catch((err) => toast.error("Error", err.response.data.message));
  };

  const switchBtnClass = isOpen
    ? "logo_switch switch-btn open"
    : "logo_switch switch-btn closed";
  return (
    <div
      className="remove-brand"
      style={{
        background: "white",
      }}
    >
      <div
        className="title"
        style={{
          top: "-245px",
          left: "73%",
          justifyContent: "space-between",
        }}
      >
        {" "}
        Settings
      </div>

      <div className="remove-brand-content">
        <img src={logo} alt="logo" />
        <p>Remove Brand</p>
        <span
          className={switchBtnClass}
          data-value="1"
          onClick={toggleSwitch}
        ></span>
      </div>
    </div>
  );
};

export default RemoveBrand;
