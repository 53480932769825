import { useRef, useState } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import icons and Image
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@mui/joy";
import iconDefault from "../../../assets/Image/defaultIcons.png";
import {
  FastWorldIcons,
  FastWorldIconsGetById,
  FastWorldIconsId,
} from "./fastworldIcons";
import { ReactComponent as UploadIcons } from "./upload.svg";
import DynamicIcon from "./fastworldIcons/DynamicIcon";
import { useTheme } from "../../styles/ThemeContext";

const PopUpSocial = ({
  show,
  setShow,
  img,
  icons,
  values,
  handleChange,
  handleSave,
  handleSelectIcons,
  handleUploadIcons,
  title,
}) => {
  const { currentTheme } = useTheme();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    console.log("clicked");
  };
  const [online, setOnline] = useState(false);
  const [value, setValue] = useState("1");

  const handleChangev = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => setShow(false);

  const getStyleType = () => {
    if (currentTheme?.Container?.social?.styleType == 1) {
      return 1;
    }
    return 0;
  };
  const getIcon = (name) => {
    if (FastWorldIconsGetById[`${name}`] == undefined) {
      return "Groupxx";
    } else {
      return FastWorldIconsGetById[`${name}`][0];
    }
  };
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "30px",
              }}
            >
              {title} Thumbnail
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>

          {/* Start Cards  */}
          <Box sx={{ height: "300px", marginTop: "40px", display: "flex" }}>
            {/* <img
              src={img ? img : iconDefault}
              alt=""
              style={{
                display: "block",
                width: "120px",
                height: "120px",
                marginTop: "20px",
              }}
            /> */}
            {values?.icon_name && values?.icon_name != "null" ? (
              <DynamicIcon
                componentName={getIcon(values?.icon_name)}
                style={{ width: "120px", height: "120px", marginTop: "20px" }}
              />
            ) : (
              <img
                src={img ? img : iconDefault}
                alt=""
                style={{
                  display: "block",
                  width: "120px",
                  height: "120px",
                  marginTop: "20px",
                }}
              />
            )}
            {/* {img?.includes("/static") && (
              <img
                src={iconDefault}
                alt=""
                style={{
                  display: "block",
                  width: "120px",
                  height: "120px",
                  marginTop: "20px",
                }}
              />
            )} */}
            <Box
              sx={{
                marginLeft: "20px",
                height: "200px",
                width: "calc(100% - 100px)",
              }}
            >
              <Box
                sx={{
                  display: online ? "none" : "flex",
                  msFlexDirection: "column",
                }}
              >
                <Button
                  size="md"
                  sx={{
                    width: "150px",
                    marginTop: "5px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "45px",
                    color: "#FFF",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginRight: "20px",
                  }}
                  onClick={() => setOnline(!online)}
                >
                  Online Icon
                </Button>
                <Button
                  size="md"
                  sx={{
                    width: "150px",
                    marginTop: "5px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "45px",
                    color: "#FFF",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleButtonClick}
                >
                  Upload Icon
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleUploadIcons}
                />
              </Box>
              <Box
                sx={{
                  display: online ? "flex" : "none",
                  width: "640px",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  // background: "red",
                }}
              >
                <div>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          textAlign: "center",
                          borderColor: "divider",
                          width: "100%",
                        }}
                      >
                        <TabList
                          onChange={handleChangev}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Online Store" value="1" />
                          <Tab label="Social Media" value="2" />
                          <Tab label="Contact" value="3" />
                          <Tab label="upload Icon" value="4" />
                        </TabList>
                      </Box>
                      <TabPanel
                        className="scrollbar"
                        sx={{
                          display: "flex",
                          margin: "auto",
                          textAlign: "center",
                          justifyContent: "center",
                          width: "100%",
                          padding: "0px",
                        }}
                        value="1"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            // width: "90%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            // textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.onlineStoreIcons).map(
                            (Iconxx, index) => {
                              return (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    cursor: "pointer",
                                    width: "64px",
                                    height: "64px",
                                    display: "inline-block",
                                    background: "#fff",
                                    borderRadius: "6px",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                    marginRight: "20px",
                                    marginBottom: "24px",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                    lineHeight: "64px",
                                    margin: "11px",
                                  }}
                                  onClick={() => {
                                    handleSelectIcons(Iconxx);
                                    setOnline(false);
                                  }}
                                >
                                  <DynamicIcon
                                    componentName={
                                      FastWorldIconsId.onlineStoreIcons[
                                        Iconxx
                                      ][0]
                                    }
                                    // fill="red"
                                    // color="yellow"
                                    // filter="invert(2%) sepia(100%) saturate(1352%) hue-rotate(87deg) brightness(53%) contrast(119%)"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      // marginLeft: "5px",
                                      margin: "7.5px 7.5px 7.5px 7.5px ",
                                    }}
                                  />
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        sx={{
                          display: "flex",
                          margin: "auto",
                          textAlign: "center",
                          justifyContent: "center",
                          width: "100%",
                          padding: "0px",
                        }}
                        value="2"
                      >
                        <Box
                          className="scrollbar"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            // width: "90%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            // textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.socialMediaIcons).map(
                            (icon) => (
                              <Typography
                                variant="body1"
                                sx={{
                                  cursor: "pointer",
                                  width: "64px",
                                  height: "64px",
                                  display: "inline-block",
                                  background: "#fff",
                                  borderRadius: "6px",
                                  boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                  marginRight: "20px",
                                  marginBottom: "24px",
                                  verticalAlign: "top",
                                  textAlign: "center",
                                  lineHeight: "64px",
                                }}
                                onClick={() => {
                                  handleSelectIcons(icon);
                                  setOnline(false);
                                }}
                              >
                                <DynamicIcon
                                  componentName={
                                    FastWorldIconsId.socialMediaIcons[icon][0]
                                  }
                                  // fill="black"
                                  // color="yellow"
                                  // filter="invert(2%) sepia(100%) saturate(1352%) hue-rotate(87deg) brightness(53%) contrast(119%)"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    // marginLeft: "5px",
                                    margin: "7.5px 7.5px 7.5px 7.5px ",
                                  }}
                                />
                              </Typography>
                            )
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        sx={{
                          display: "block",
                          marginL: "auto",
                          textAlign: "center",
                          padding: "0px",
                          width: "100%",
                        }}
                        value="3"
                      >
                        <Box
                          className="scrollbar"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            // width: "90%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            // textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          {Object.keys(FastWorldIconsId.contactIcons).map(
                            (Iconxx, index) => {
                              return (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    cursor: "pointer",
                                    width: "64px",
                                    height: "64px",
                                    display: "inline-block",
                                    background: "#fff",
                                    borderRadius: "6px",
                                    boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                                    marginRight: "20px",
                                    marginBottom: "24px",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                    lineHeight: "64px",
                                  }}
                                  onClick={() => {
                                    handleSelectIcons(Iconxx);
                                    setOnline(false);
                                  }}
                                >
                                  <DynamicIcon
                                    componentName={
                                      FastWorldIconsId.contactIcons[Iconxx][0]
                                    }
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      // marginLeft: "5px",
                                      // color: "red",
                                      margin: "7.5px 7.5px 7.5px 7.5px ",
                                    }}
                                  />
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        className="scrollbar"
                        sx={{
                          display: "flex",
                          margin: "auto",
                          textAlign: "center",
                          justifyContent: "center",
                          width: "100%",
                          padding: "0px",
                        }}
                        value="4"
                      >
                        <Box
                          className="scrollbar"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            height: "250px",
                            // border: "1px solid #54B99B",
                            borderRadius: "10px",
                            overflowY: "scroll",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                            padding: "10px",
                            // textAlign: "center",
                            padding: "0px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              cursor: "pointer",
                              width: "64px",
                              height: "64px",
                              display: "inline-block",
                              background: "#fff",
                              borderRadius: "6px",
                              boxShadow: "0 0 10px 0 rgba(0,0,0,.2)",
                              marginRight: "20px",
                              marginBottom: "24px",
                              verticalAlign: "top",
                              textAlign: "center",
                              lineHeight: "64px",
                            }}
                          >
                            <UploadIcons
                              style={{
                                width: "40px",
                                height: "40px",
                                // marginLeft: "5px",
                                margin: "7.5px 7.5px 7.5px 7.5px ",
                                // color: "#19609C",
                              }}
                              onClick={() => {
                                console.log("upload clicked");
                                handleButtonClick();
                              }}
                            />
                          </Typography>
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>

                <i
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setOnline(false)}
                >
                  X
                </i>
              </Box>
            </Box>
          </Box>

          <Box
          // sx={{ marginTop: "50px" }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "27,24px",
                marginLeft: "0px",
              }}
            >
              Link Url
            </Typography>
            <Input
              size="md"
              placeholder="Enter Link URL Profile "
              // placeholder="Enter Link URL Profile, eg => https://...."
              name="link"
              value={values.link}
              // defaultValue={"https://"}
              onChange={handleChange}
              sx={{
                marginTop: "15px",
                boxShadow: ".5px .5px .5px .5px #19609C",
                "& input": {
                  textAlign: "start",
                },
              }}
            />
          </Box>
          {/* End Cards */}
          {/* buuton for submit */}
          <Box sx={{}}>
            <Button
              size="md"
              sx={{
                width: "95%",
                display: "block",
                margin: "20px auto",
                background:
                  "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                height: "45px",
                color: "#FFF",
                fontSize: "12px",
                textTransform: "capitalize",
                ":hover": {
                  width: "100%",
                  fontSize: "14px",
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default PopUpSocial;

const StyledModal = styled(Modal)`
  position: fixed;
  width: 100%;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "800px",
  height: "570px",
  borderRadius: "8px",
  padding: "20px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
