import React from "react";
import { Button } from "@mui/joy";
import face from "../../../assets/Image/socialfacebook.PNG";
import twiter from "../../../assets/Image/twittersocial.PNG";
import insta from "../../../assets/Image/socialinsta.PNG";
import intialIcon from "../../../assets/Images/128-1280406_user-icon-png.png";
import deleteIcon from "../../../assets/Image/delete.PNG";
import Base_Url, { Apipassword, axiosRequset } from "../../../Api/ApiUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PopUpSocial from "../PopUp/PopUpSocial";
import { useTheme } from "../../styles/ThemeContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import PopUpDelete from "../PopUp/PopUpDelete";
// import DynamicIcon from "../PopUp/fastworldIcons/DynamicIcon";
import { FastWorldIconsGetById } from "../PopUp/fastworldIcons";
const DynamicIcon = React.lazy(() =>
  import("../PopUp/fastworldIcons/DynamicIcon")
);
const SocialMedia = ({
  data,
  onDelete,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpUpdate, setShowPopUpUpdate] = useState(false);
  const [icons, setIcons] = useState([]);
  console.log(icons);
  const [values, setValues] = useState({});
  const [myIcons, setMyIcons] = useState();
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [dataDlt, setDataDlt] = useState({});
  console.log(currentTheme?.Contents?.Socials);
  console.log(data?.Socials);
  useEffect(() => {
    if (icons.length > 0) {
      return;
    }
    axiosRequset
      .get("/guest/Social/all")
      .then((res) => {
        // console.log(res.data);
        setIcons(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // handel change
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSave = () => {
    // console.log("values", values);
    let newValues = { ...values };
    if (!values.link.startsWith("http")) {
      newValues = { ...values, link: `https://${values.link}` };
      // console.log(newValues);
    }
    // console.log(newValues);

    axiosRequset
      // .post("/owner/Social/store", { ...values, content_id: data?.id })
      .post("/owner/Social/store", { ...newValues, content_id: data?.id })
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Added Successfully");
        setValues({});
        setMyIcons();
        setShowPopUp(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 400) {
          toast.error(error.response.data.message.link);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  const handleSelectIcons = (icon) => {
    setValues({
      ...values,
      // social_icon_id: icon.id,
      // social_icon_id: icon,
      // order: icon,
      icon_name: `${icon}`,
    });
    setMyIcons(icon);
  };
  const handleUploadIcons = (event) => {
    setMyIcons(URL?.createObjectURL(event?.target?.files[0]));
    setValues({
      ...values,
      image: event.target.files[0],
      icon_name: "null",
    });
  };
  // handle update
  const handleUpdate = (id) => {
    // Add your code here to handle the update
    let newValues = { ...values };
    if (!values.link.startsWith("http")) {
      newValues = { ...values, link: `https://${values.link}` };
      // console.log(newValues);
    }
    axiosRequset
      .post("/owner/Social/update", newValues)
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Updated Successfully");
        setValues({});
        setMyIcons();
        setShowPopUpUpdate(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 400) {
          toast.error(error.response.data.message.link);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  // handle delete
  const handleDelete = (id) => {
    setDataDlt({
      id,
      content_id: data?.id,
    });
    setShowPopUpDelete(true);
  };
  const getIcon = (name) => {
    if (FastWorldIconsGetById[`${name}`] == undefined) {
      return "Groupxx";
    } else {
      return FastWorldIconsGetById[`${name}`][0];
    }
  };
  return (
    <div>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <DynamicIcon
                componentName={getIcon("60")}
                style={{
                  width: "40px",
                  height: "30px",
                  display: "none",
                }}
              />
              <div>
                <Typography>Social Icons </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginX: "15px", position: "relative" }}>
              <Box sx={{ marginTop: "80px" }}>
                <h1 style={{ color: "#19609C" }}>Social Icons</h1>
                <Box
                  sx={{
                    width: "100%",
                    height: "1.5px",
                    backgroundColor: "#686868",
                    marginY: "15px",
                  }}
                ></Box>

                {data?.Socials?.map((icon) => (
                  // {currentTheme?.Contents?.Socials?.map((icon) => (
                  <Box
                    key={icon.id}
                    sx={{
                      position: "relative",
                      marginTop: "30px",
                      display: "flex",
                      // marginLeft: "100px",
                      justifyContent: "space-between",
                      boxShadow: "2",
                      height: "55px",
                      width: "100%",
                    }}
                  >
                    {/* {icon.icon_name !== null ||
                    (icon.icon_name !== "null" && ( */}
                    {/* <Box
                    component="img"
                    src={icon?.image}
                    sx={{
                      width: "40px",
                      height: "30px",
                      marginLeft: "10px",
                      marginTop: "12px",
                    }}
                    //   value={facebook}
                  /> */}
                    {/* ))} */}
                    {icon.icon_name === "null" ? (
                      <Box
                        component="img"
                        src={icon.image}
                        sx={{
                          width: "40px",
                          height: "30px",
                          marginLeft: "10px",
                          marginTop: "12px",
                        }}
                        //   value={facebook}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "40px",
                          height: "30px",
                          marginLeft: "10px",
                          marginTop: "12px",
                        }}
                      >
                        <DynamicIcon
                          componentName={getIcon(icon.icon_name)}
                          style={{
                            width: "40px",
                            height: "30px",
                          }}
                        />
                      </Box>
                    )}
                    <Box>
                      <EditIcon
                        sx={{
                          display: "inline-block",
                          cursor: "pointer",
                          fontSize: "30px",
                          color: "#54B99B",
                          width: "40px",
                          margin: "10px auto",
                          ":hover": {
                            color: "#19609C",
                          },
                        }}
                        onClick={() => {
                          setShowPopUpUpdate(true);
                          setMyIcons(
                            icon?.image ? icon?.image : icon?.icon_name
                          );
                          setValues({
                            content_id: icon.content_id,
                            id: icon.id,
                            // social_icon_id: icon.id,
                            link: icon.link,
                            icon_name: icon.icon_name,
                          });
                        }}
                      />
                      <DeleteIcon
                        sx={{
                          display: "inline-block",
                          cursor: "pointer",
                          fontSize: "30px",
                          color: "red",
                          width: "40px",
                          margin: "10px auto",
                          ":hover": {
                            // fontSize: "35px",
                          },
                        }}
                        onClick={() => {
                          handleDelete(icon.id);
                        }}
                      />
                    </Box>

                    {/* {socialIconsFace && (
                  <PopUpSocial
                    show={socialIconsFace}
                    setShow={setSovialIconsFace}
                    img={face}
                  />
                )} */}
                  </Box>
                ))}

                {/* <Button
                size="md"
                sx={{
                  width: "80%",
                  marginTop: "40px",
                  background:
                    "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                  height: "57px",
                  marginLeft: "70px",
                }}
                // onClick={() => submitData()}
              >
                Save
              </Button> */}
                <Button
                  size="md"
                  sx={{
                    width: "80%",
                    marginTop: "20px",
                    background:
                      "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                    height: "57px",
                    display: "block",
                    margin: "50px auto",
                  }}
                  onClick={() => {
                    setShowPopUp(true);
                    setMyIcons(intialIcon);
                    setValues({});
                  }}
                >
                  +Add More
                </Button>
                {/* {socialMore && (
                <PopUpSocial
                  show={socialMore}
                  setShow={setSocialMore}
                  img={intialIcon}
                />
              )} */}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <PopUpSocial
        show={showPopUp}
        setShow={setShowPopUp}
        icons={icons}
        values={values}
        handleChange={handleChange}
        handleSave={handleSave}
        handleSelectIcons={handleSelectIcons}
        handleUploadIcons={handleUploadIcons}
        img={myIcons}
        title="Add New "
      />
      <PopUpSocial
        show={showPopUpUpdate}
        setShow={setShowPopUpUpdate}
        icons={icons}
        values={values}
        handleChange={handleChange}
        handleSave={handleUpdate}
        handleSelectIcons={handleSelectIcons}
        handleUploadIcons={handleUploadIcons}
        img={myIcons}
        title="update "
      />
      <PopUpDelete
        show={showPopUpDelete}
        setShow={setShowPopUpDelete}
        url={"/owner/Social/delete"}
        message={{ data: "Are you sure you want to delete this social icon?" }}
        data={dataDlt}
        currentTheme={currentTheme}
        setCurrentTheme={setCurrentTheme}
      />
    </div>
  );
};

export default SocialMedia;
