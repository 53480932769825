import React from 'react'
import { Box, Typography } from '@mui/material'


const Privacypolicy = () => {
  return (
<Box sx={{maxWidth:'50%', marginLeft:'80px'}}>
<Box>
<Typography component='h2' sx={{fontSize:'35px', fontWeight:'600', marginY:'15px', width:'80%', margin:'auto'}}>Privacy policy</Typography>
</Box>

<Box sx={{marginY:'35px', width:'80%', margin:'auto'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'40px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span>
</Typography>
</Box>

<Box sx={{marginY:'35px', width:'80%', margin:'auto'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px', marginTop:'10px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>

<Box sx={{marginY:'35px', width:'80%', margin:'auto' ,marginTop:'20px'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'10px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>



<Box sx={{marginY:'35px', width:'80%', margin:'auto' ,marginTop:'20px'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'310x'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>


<Box sx={{marginY:'35px', width:'80%', margin:'auto' ,marginTop:'320x'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'10px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>


<Box sx={{marginY:'35px', width:'80%', margin:'auto' ,marginTop:'20px'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'10px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>


<Box sx={{ width:'80%', margin:'auto' ,marginY:'20px'}}>
<Typography component='h2' sx={{fontSize:'14px', lineHeight:'30px',  marginTop:'10px'}}>
Please use the form below to report a violation or an account
you think we should review. You can view our <span style={{fontWeight:'bold', marginRight:'4px'}}>Term of Service</span>
 Please  note this form is not for &eneral support requests. lf you
need help  with you own Fast world account, please contact
support on <span style={{fontWeight:'bold'}}>support@Fast world.to</span> or visit <span style={{fontWeight:'bold'}}>Help center</span></Typography>
</Box>




</Box>
  )
}

export default Privacypolicy
