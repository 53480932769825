import React from "react";
import Settingbar from "../components/Settingbar";
import Settings from "./Settings";
import myimage from "../assets/Images/settingDomainImage.PNG";

const DomainManagement = () => {
  return (
    <div
      style={{
        background: "white",
      }}
    >
      <div
        className="title"
        style={{
          top: "-245px",
          left: "73%",
          justifyContent: "space-between",
        }}
      >
        {" "}
        Settings
      </div>

      <div className="domain-content">
        <img src={myimage} alt="logo" />

        <p className="first-p">
          There are no purchased domains here yet.
          <br />
        </p>
        <p className="second-p">
          You can purchase a domain on the link url editing page.
        </p>
      </div>
    </div>
  );
};

export default DomainManagement;
