import React, { useState } from "react";
import Switch from "@mui/material/Switch";

import Color from "./ColorSection/Color";
import Gradient from "./ColorSection/Gradient";

const ToggleSwitcher = () => {
  const [isSwitchOn, setSwitchOn] = useState(false);

  const handleToggleSwitch = () => {
    // console.log(isSwitchOn);
    setSwitchOn(!isSwitchOn);
  };

  return (
    <div style={{ width: "95%", overflow: "hidden" }}>
      <div>
        Gradient
        <Switch
          checked={isSwitchOn}
          onChange={handleToggleSwitch}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      <div>{isSwitchOn ? <Gradient /> : <Color />}</div>
      {/* <Gradient /> <Color /> */}
    </div>
  );
};

export default ToggleSwitcher;
