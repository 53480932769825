import { Box, Typography } from "@mui/material";
import face from "../../../assets/Image/Facebook.PNG";
import google from "../../../assets/Image/google.PNG";
import style from "./Login.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import ResetPassword from "../ResetPassword/ResetPassword";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Base_Url, { Apipassword } from "../../../Api/ApiUrl";
import { FacebookProvider, LoginButton } from "react-facebook";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
const Login = () => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleVisible = () => {
    setVisible((visible) => !visible);
  };

  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),

    onSubmit: async (values) => {
      try {
        const res = await Base_Url.post("owner/login", values, {
          headers: {
            Apipassword: Apipassword,
          },
        });

        localStorage.setItem("token", res.data.token);
        //   saveUser()
        toast.success("Login Successfully");
        navigate("/dashboard/MyLinks");
      } catch (err) {
        toast.error(`${err.response.data.message.email}`);
      }
    },
  });

  const facebookLogin = () => {
    // window.fbAsyncInit = function () {
    //   FB.init({
    //     appId: "739374207572736",
    //     cookie: true,
    //     xfbml: true,
    //     version: "{api-version}",
    //   });
    //   FB.AppEvents.logPageView();
    // };
    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
  };
  const handleGoogleLogin = async (res) => {
    console.log(res);
    let jwtDecodex = jwtDecode(res.credential);
    console.log(jwtDecodex);
    try {
      const res = await Base_Url.post(
        "owner/sociallogin",
        {
          email: jwtDecodex.email,
          google_id: jwtDecodex.sub,
        },
        {
          headers: {
            Apipassword: Apipassword,
          },
        }
      );

      localStorage.setItem("token", res?.data?.token);
      //   saveUser()
      toast.success("Login Successfully");
      navigate("/dashboard/MyLinks");
    } catch (err) {
      toast.error(`${err.response.data.message.email}`);
    }
  };
  useEffect(() => {
    // FB.getLoginStatus(function (response) {
    // statusChangeCallback(response);
    // console.log(response);
    // });
  }, []);
  return (
    <Box
      sx={{
        alignContent: "center",
        margin: "0 auto",
        padding: "0",
        justifyContent: "center",
        marginY: "40px",
        height: "100vh",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box>
        <Typography
          component="h3"
          sx={{ fontSize: "20px", fontWeight: "400", marginLeft: "10px" }}
        >
          Log in to your account
        </Typography>
        <Box
          sx={{
            maxWidth: "330px",
            height: "50px",
            backgroundColor: "transparent",
            border: "1px solid #4192DE",
            borderRadius: "10px",
            marginTop: "20PX",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img
              src={face}
              alt=""
              srcset=""
              style={{ width: "40px", marginLeft: "10px", marginTop: "7px" }}
            />
            <Typography
              component="p"
              sx={{
                fontSize: "16px",
                color: "#4192DE",
                marginTop: "9px",
                marginLeft: "10px",
              }}
              onClick={facebookLogin}
            >
              Login with facebook
            </Typography>
            {/* <LoginButton
              scope="email"
              onError={(error) => console.log(error)}
              onSuccess={(response) => console.log(response)}
            >
              Login With Facebook
            </LoginButton> */}
          </Box>
        </Box>
        <br />
        {/* <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={(e) => console.log(e)}
        /> */}
        <Box
          sx={{
            maxWidth: "330px",
            height: "50px",
            backgroundColor: "transparent",
            border: "1px solid #E54B4B",
            borderRadius: "10px",
            marginTop: "20PX",
            padding: "7px 20px",
          }}
        >
          {/* <Box sx={{ display: "flex" }}>
            <img
              src={google}
              alt=""
              srcset=""
              style={{ width: "60px", marginLeft: "10px", marginTop: "10px" }}
            />
            <Typography
              component="p"
              sx={{
                fontSize: "19px",
                color: "#E54B4B",
                marginTop: "9px",
                marginLeft: "2px",
              }}
            >
              ***********
            </Typography>
          </Box> */}
          <GoogleLogin
            style={{
              width: "100%",
              height: "100%",
              display: "block",
              margin: "auto",
              padding: "10px",
            }}
            onSuccess={(res) => handleGoogleLogin(res)}
            onError={(e) => console.log(e)}
          />
        </Box>

        <Typography
          component="h3"
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            alignItems: "center",
            margin: "15px 130px",
          }}
        >
          OR
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              maxWidth: "330px",
              height: "50px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <input
              type="text"
              name="email"
              autoComplete="off"
              className={style.register}
              placeholder="Email Address"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              value={formik.values.email}
            />
          </Box>
          <Typography
            component="p"
            sx={{
              fontSize: "12px",
              lineHeight: "19,07px",
              color: "#ff0000",
              fontWeight: "700",
              marginTop: "15px",
              marginLeft: "7px",
            }}
          >
            {" "}
            {formik.touched.email && formik.errors.email}
          </Typography>

          <Box
            sx={{
              maxWidth: "330px",
              height: "50px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <input
              type={visible ? "text" : "password"}
              name="password"
              className={style.register}
              placeholder="Password"
              id="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <Box
              sx={{
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
                right: "10px",
                marginTop: "14px",
                fontSize: "12px",
                color: "#4192DE",
                cursor: "pointer",
              }}
              onClick={handleVisible}
            >
              {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Box>
          </Box>

          <Typography
            component="p"
            sx={{
              fontSize: "12px",
              lineHeight: "19,07px",
              color: "#ff0000",
              fontWeight: "700",
              marginTop: "15px",
              marginLeft: "7px",
            }}
          >
            {" "}
            {formik.touched.password && formik.errors.password}
          </Typography>

          <Box sx={{ marginTop: "15px" }}>
            <Link
              style={{
                fontSize: "15px",
                color: "#4192DE",
                marginLeft: "160px",
              }}
              onClick={() => setShow(true)}
            >
              {" "}
              Forgotten password
            </Link>
          </Box>

          <Box sx={{ maxWidth: "330px", height: "55px", marginTop: "30px" }}>
            <button
              type="submit"
              style={{
                background:
                  "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "10px",
                color: "white",
                fontSize: "20px",
                fontWeight: "600px",
              }}
            >
              Sign In
            </button>
          </Box>
        </form>

        <Box sx={{ display: "flex", marginTop: "40px" }}>
          <Typography
            component="h3"
            sx={{ fontSize: "14px", fontWeight: "400", marginX: "5px" }}
          >
            Don't have an account? Click here to
          </Typography>
          <Link
            style={{ marginTop: "1px", fontSize: "13px", color: "#4192DE" }}
            to="/register"
          >
            {" "}
            Sign Up
          </Link>
        </Box>
      </Box>
      {show && <ResetPassword show={show} setShow={setShow} />}
    </Box>
  );
};

export default Login;
