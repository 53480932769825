import React, { useState } from 'react'
import "./navbar.css"
import logo from "../../../assets/logo 1.png"
import {Link} from "react-router-dom"
const Navbar = () => {

    const [isActive,setIsActive]=useState(false)

    const handleClick=()=>{
        setIsActive(!isActive)
    }
  return (
    <div className="nav-bar-main">
        <div className="nav-bar">
            <div className="nav-logo">
                <img src={logo} alt="logo" />
            </div>
            <div className={`nav-links ${isActive && "active"}`}>
                <a><Link>Home</Link></a>
                <a><Link>Templates </Link></a>
                <a><Link to="/pricing"> Pricing </Link></a>
                <a><Link to="/discover">Discover </Link> </a>
                <a><Link>Learn </Link></a>
                <a><Link to="/login">Log in </Link></a>
                <Link to="/register"><button>sign up free </button></Link>

            </div>
            <i class="fa-solid fa-bars" onClick={()=>handleClick()} id='bars'></i>
        </div>
          
    </div>
  )
}

export default Navbar