import { KeyboardArrowLeft } from "@mui/icons-material";
import React from "react";
import QrContent from "./QrContent ";

const QrBox = ({ onBack, closePopup, link }) => {
  // const link = 'https://example.com'; // Replace with your actual link
  return (
    <div>
      <div style={{ padding: "20px" }} className="sharelink-header">
        <span className="qr-link">
          <KeyboardArrowLeft onClick={onBack} />
        </span>
        <button className="cross-btn" onClick={closePopup}>
          x
        </button>
      </div>

      <div className="qr-con">
        <p className="shr-title-qr"> QR Code</p>
        <QrContent link={link} />
      </div>
    </div>
  );
};

export default QrBox;
