import React from 'react'
import Notification from '../../components/Notification/Notification'

const NotificationPage = () => {
  return (
    <div>
      <div className='title'> Notification</div>
      <Notification/>

    </div>
  )
}

export default NotificationPage
