import { Box, Typography } from "@mui/material";
import React from "react";
import user from "../../assets/Image/usernotific.PNG";
import clockwhite from "../../assets/Image/clockwhite.PNG";
import clockBlue from "../../assets/Image/clockblue.PNG";
import user2 from "../../assets/Image/userblue.PNG";

const Notification = () => {
  return (
    <Box sx={{ width: "80%", margin: "auto", position: "relative" }}>
      <Box sx={{ marginTop: "25px", marginLeft: "170px" }}>
        <Typography
          component="h2"
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            marginY: "20px",
            width: "80%",
            margin: "auto",
            color: "#fff",
          }}
        >
          Notification
        </Typography>
      </Box>

      
      <Box sx={{ marginTop: "70px" }}>
        <Box
          sx={{
            maxWidth: "855px",
            minheight: "57px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0.5px 2.5px 11.5px 0.5px var(--gray-color-200)",
            padding: "7px 20px",
            borderRadius: "7px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box component="img" src={user} sx={{ width: "40px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                component="h2"
                sx={{ fontSize: "15px", color: "#DFDEDE" }}
              >
                MDNayeem - new projectcreated - test
              </Typography>
              <Box sx={{ display: "flex", marginTop: "5px" }}>
                <Box
                  component="img"
                  src={clockwhite}
                  sx={{ width: "15px", height: "17px" }}
                />
                <Typography
                  component="p"
                  sx={{
                    fontSize: "13px",
                    color: "#DFDEDE",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  months ago
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: "855px",
            minheight: "57px",
            backgroundColor: "#8CBBE3",
            boxShadow: "0.5px 2.5px 11.5px 0.5px var(--gray-color-200)",
            padding: "5px 20px",
            borderRadius: "7px",
            marginY: "40px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box component="img" src={user2} sx={{ width: "40px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                component="h2"
                sx={{ fontSize: "15px", color: "#fff" }}
              >
                MDNayeem - new projectcreated - test
              </Typography>
              <Box sx={{ display: "flex", marginTop: "5px" }}>
                <Box
                  component="img"
                  src={clockBlue}
                  sx={{ width: "15px", height: "15px" }}
                />
                <Typography
                  component="p"
                  sx={{
                    fontSize: "13px",
                    color: "#fff",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  months ago
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "855px",
            minheight: "57px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0.5px 2.5px 11.5px 0.5px var(--gray-color-200)",
            padding: "7px 20px",
            borderRadius: "7px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box component="img" src={user} sx={{ width: "40px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                component="h2"
                sx={{ fontSize: "15px", color: "#DFDEDE" }}
              >
                MDNayeem - new projectcreated - test
              </Typography>
              <Box sx={{ display: "flex", marginTop: "5px" }}>
                <Box
                  component="img"
                  src={clockwhite}
                  sx={{ width: "15px", height: "17px" }}
                />
                <Typography
                  component="p"
                  sx={{
                    fontSize: "13px",
                    color: "#DFDEDE",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  months ago
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: "855px",
            minheight: "57px",
            backgroundColor: "#8CBBE3",
            boxShadow: "0.5px 2.5px 11.5px 0.5px var(--gray-color-200)",
            padding: "5px 20px",
            borderRadius: "7px",
            marginY: "40px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box component="img" src={user2} sx={{ width: "40px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                component="h2"
                sx={{ fontSize: "15px", color: "#fff" }}
              >
                MDNayeem - new projectcreated - test
              </Typography>
              <Box sx={{ display: "flex", marginTop: "5px" }}>
                <Box
                  component="img"
                  src={clockBlue}
                  sx={{ width: "15px", height: "15px" }}
                />
                <Typography
                  component="p"
                  sx={{
                    fontSize: "13px",
                    color: "#fff",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  months ago
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Notification;
