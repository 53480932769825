import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
// import MyLinksPage from './pages/MyLinksPage';
// import Templates from './pages/Templates';
// import Notification from './pages/Notifications';
// import Help from './pages/Help';
// import Sidebar from './components/Sidebar';
// import Settingbar from './components/Settingbar';
// import DomainManagement from './pages/DomainManagement';
// import Settings from './pages/Settings';
// import Subscription from './pages/Subscription';
// import RemoveBrand from './pages/RemoveBrand';
import router from "./router/router";
import { useTheme } from "./components/styles/ThemeContext";
import { ThemeProvider } from "styled-components";
import { firstTheme } from "./components/styles/Theme.styled";
import "./fonts.css";
import { FacebookProvider } from "react-facebook";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { DragDropContext } from "react-beautiful-dnd";
const App = () => {
  const { currentTheme } = useTheme();
  function handleOnDragEnd(result) {
    console.log(result);
  }
  return (
    <>
      <GoogleOAuthProvider
        clientId={
          "311658148596-n5n1igp9b26fn3eprmbu0gv32gijsaqg.apps.googleusercontent.com"
        }
      >
        <FacebookProvider appId={739374207572736}>
          <ThemeProvider theme={currentTheme ? currentTheme : firstTheme}>
            {/* <DragDropContext onDragEnd={handleOnDragEnd}> */}
            <RouterProvider router={router} />
            {/* </DragDropContext> */}
          </ThemeProvider>
        </FacebookProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
