import Navbar from "../components/Components/navbar/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Components/footer/Footer";
function RootLayout() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}
export default RootLayout;
