import React from "react";
import PieChart from "../../components/Insight/Socials/PieChart";
import Back from "../../components/Back";

const Socials = () => {
  return (
    <div style={{ background: "white" }}>
      <div
        className="title"
        style={{
          top: "-416px",
          left: "-53%",
          // gap: 50px;
          justifyContent: "space-between",
          width: "500px",
          /* left: -158px; */
        }}
      >
        <Back link="/dashboard/MyLinks" />
        Insight
      </div>
      <div className="main-cont">
        <div className="insight-content">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default Socials;
