import React, { useEffect, useState } from "react";

const PodcastT = ({ data }) => {
  // make dinamic iframe  for embed podcast from spotify and podcast and apple podcast
  //   const [podcastUrl, setPodcastUrl] = useState();
  const [embedCode, setEmbedCode] = useState("");
  //   podcastUrl
  const generateEmbedCode = (podcastUrl) => {
    // Logic to generate the embed code based on the podcast URL
    if (!podcastUrl) {
      return;
    }
    const podcastId = extractPodcastIdFromUrl(podcastUrl);

    console.log("podcast id", podcastId);
    // Check the platform and generate the appropriate embed code
    let platform = getPodcastPlatform(podcastUrl);
    let embedCode;

    switch (platform) {
      case "spotify":
        if (podcastId.includes("si=")) {
          embedCode = `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/${podcastId}?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;
        } else {
          embedCode = `<iframe src="https://open.spotify.com/embed/show/${podcastId}" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
        }
        break;
      case "podcast":
        // Add logic for generating embed code for other hosting platforms
        // Replace this line with the actual embed code logic for the "podcast" platform
        embedCode = `<iframe src="${data.link}" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
        break;
      case "apple":
        embedCode = `<iframe src="https://embed.podcasts.apple.com/us/podcast/could-we-live-without-plastic/${podcastId}" allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="175" style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" ></iframe>`;
        // <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="175" style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/the-place-that-makes-me-happy/1702787161?i=1702787169"></iframe>
        // <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="175" style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/us/podcast/could-we-live-without-plastic/id262026947?i=1000638540627"></iframe>
        break;
      default:
        embedCode = "Unsupported podcast platform";
    }

    setEmbedCode(embedCode);
  };

  const extractPodcastIdFromUrl = (url) => {
    // Logic to extract the podcast ID from the given URL
    // This will depend on the structure of the URLs from the hosting platforms
    // For simplicity, let's assume the last segment of the URL is the podcast ID
    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  const getPodcastPlatform = (url) => {
    // Logic to determine the podcast platform based on the URL
    // You may need to adjust this based on the actual URL patterns
    if (url.includes("open.spotify.com")) {
      return "spotify";
    } else if (url.includes("podcasts.google.com")) {
      return "podcast";
    } else if (url.includes("podcasts.apple.com")) {
      return "apple";
    } else {
      return "unknown";
    }
  };

  //   let x = `<iframe src="${data?.link}" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
  //   let x = `<iframe src="https://open.spotify.com/embed/track/3UvItzqBASEeNcv1SQpXf5?utm_source=generator" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
  //   const ifram = (url) => (
  //     <iframe
  //       src={url}
  //       width="100%"
  //       height="300"
  //       frameborder="0"
  //       allowfullscreen
  //     ></iframe>
  //   );

  useEffect(() => {
    // setPodcastUrl(data?.link);
    generateEmbedCode(data?.link);
  }, [data?.link]);
  return (
    <div>
      <h1>Podcast</h1>
      {/* {x} */}
      <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      {/* <div
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://podcasts.google.com/feed/aHR0cDovL3Jzcy5jYXN0Ym94LmZtL2V2ZXJlc3QvYzRiMjliZDE1MTY1NDcyNzg5YzIzMGRmMjUwZmNmZjIueG1s" width="100%" height="232" frameborder="0" scrolling="no"></iframe>`,
        }}
        //                                                   https://open.spotify.com/track/0YpGZ3doNPRq9mMj4kgUZB?si=7dd3362466594b76
      /> */}
    </div>
  );
};

export default PodcastT;
