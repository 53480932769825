// import { Backdrop, Modal, Typography } from '@mui/material'
import React, { useState } from "react";
import { Button } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PopUpModal from "./PopUp/PopUpModal";
import { useTheme } from "../styles/ThemeContext";
import BasicInfo from "./MyPageComponents/BasicInfo";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DynamicComponentRenderer from "./MyPageComponents/DynamicComponentRenderer";

const MyPage = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { currentTheme, setCurrentTheme } = useTheme();
  const [expanded, setExpanded] = useState("");

  const handleChange = (value) => {
    console.log(value);
    setExpanded((prev) => {
      if (prev === value) {
        return "";
      } else {
        return value;
      }
    });
  };

  const getContant = (id) => {
    return currentTheme.Contents.find((item) => {
      if (item.id == id) {
        return item;
      }
    });
  };
  return (
    <div className="my-page-cont">
      {/* Top Side  */}

      {(expanded === "" || expanded === "basic") && (
        <Accordion
          expanded={expanded === "basic"}
          onChange={() => handleChange("basic")}
          sx={{
            // width: "594px",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <Typography sx={{}}>Basic Info </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BasicInfo />
          </AccordionDetails>
        </Accordion>
      )}

      {expanded !== "basic" &&
        currentTheme?.orders &&
        currentTheme?.orders.length > 0 &&
        currentTheme?.orders?.map((order, index) => (
          <DynamicComponentRenderer
            index={index}
            expanded={expanded}
            handleChange={() => handleChange(index)}
            componentName={order.split("_")[0]}
            data={getContant(
              order.split("_")[0] == "soial" ||
                order.split("_")[0] == "youtub" ||
                order.split("_")[0] == "facebook"
                ? order.split("_")[2]
                : order.split("_")[1]
            )}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
          />
        ))}

      <Box sx={{ marginX: "15px", position: "relative" }}>
        <Button
          size="md"
          sx={{
            width: "80%",
            marginTop: "80px",
            marginBottom: "50px",
            background: "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
            height: "57px",
            margin: " 80px auto",
            display: "block",
          }}
          onClick={() => setShowPopUp(true)}
        >
          +Add New Block
        </Button>
      </Box>

      {showPopUp && (
        <PopUpModal
          currentTheme={currentTheme}
          setCurrentTheme={setCurrentTheme}
          show={showPopUp}
          setShow={setShowPopUp}
        />
      )}
    </div>
  );
};

export default MyPage;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
