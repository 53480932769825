// QrContent.js
import React, { useRef, useEffect } from "react";
import QRCode from "qrcode.react";
import { useState } from "react";
import { SketchPicker } from "react-color";

const QrContent = ({ link }) => {
  const [color, setColor] = useState("#000000");
  const [bgColor, setBgColor] = useState("#ffffff");
  const qrCodeRef = useRef(null);
  const [qrLoaded, setQrLoaded] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayBgColorPicker, setDisplayBgColorPicker] = useState(false);

  const refs = useRef(null);

  // useEffect(() => {
  //   if (qrCodeRef.current) {
  //     qrCodeRef.current.toDataURL(link, (err, url) => {
  //       if (err) throw err;

  //       // Set the state to indicate that the QR code has been loaded
  //       setQrLoaded(true);
  //     });
  //   }
  // }, [link]);

  const downloadQrCode = () => {
    const canvas = document.getElementById("sameId_as_QRCode_compoent_id");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `your_name.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    // setQrLoaded(true);
  };

  // }
  // }

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  return (
    <div>
      {/* First component: Div containing the generated QR code */}
      <div>
        <QRCode
          fgColor={color}
          bgColor={bgColor}
          level="Q"
          value={link}
          ref={qrCodeRef}
          size={200}
          title="it is a QR Code"
          id="sameId_as_QRCode_compoent_id"
        />
      </div>
      <div style={{ display: "flex" }}>
        {/* <span
          style={{
            margin: "15px",
            background:
              "conic-gradient(cyan 0%, palegoldenrod 16.67%, lime 33.33%, red 50%, blue 66.67%, yellow 83.33%, orange 100%)",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
          }}
          // onClick={() => refs.current.click()}
        ></span> */}
        <span
          onClick={handleClick}
          style={{
            margin: "15px",
            background:
              "conic-gradient(cyan 0%, palegoldenrod 16.67%, lime 33.33%, red 50%, blue 66.67%, yellow 83.33%, orange 100%)",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
        ></span>

        {displayColorPicker ? (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
              top: "56%",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={handleClose}
            />
            <SketchPicker
              color={color}
              onChange={(color) => setColor(color.hex)}
            />
          </div>
        ) : null}
        <span
          style={{
            margin: "15px",
            background: "#000",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => setColor("#000")}
        ></span>
        <span
          style={{
            margin: "15px",
            background: "#b7bbc6",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => setColor("#b7bbc6")}
        ></span>
        <span
          style={{
            margin: "15px",
            background: "#2345a2",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => setColor("#2345a2")}
        ></span>
        <span
          style={{
            margin: "15px",
            background: "#07bc0c",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => setColor("#07bc0c")}
        ></span>
        <span
          style={{
            margin: "15px",
            background: "#e6e900c7",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => setColor("#e6e900c7")}
        ></span>
      </div>

      {/* Second component: Read-only link */}
      <div>
        <p>{link}</p>
      </div>

      {/* Third component: Button to download the QR code image */}
      <br />
      <div>
        <button onClick={downloadQrCode} className="download-qr-button">
          Save Image
        </button>
      </div>
    </div>
  );
};

export default QrContent;
