import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";

// import icons and Images
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/joy";
import PopUpCard from "./PopUpCard";

import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
// import social from '../../../assets/Images/face-awesome.svg'

// Array Card Insert
const ArrayCardInsert = [
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_button_icon.png",
    title: "Button Group",
    desc: "Add Links To Your Fast World Pa&se",
    marginLeft: "50px",
    type: "buttons",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_header_icon.png",
    title: "Header Text",
    desc: "Clearly &roup Your Links width a header",
    marginLeft: "60px",
    type: "header",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_video_icon.png",
    title: "Video",
    desc: "Embed Youtube , vimeo, Twitch , or TikTok",
    marginLeft: "80px",
    type: "video",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_form_icon.png",
    title: "Form ",
    desc: "Collect Your Customers Message",
    marginLeft: "80px",
    type: "form",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_social_icon.png",
    title: "Social Icons",
    desc: "Add your social paltform to your fast world",
    marginLeft: "60px",
    type: "soial_icon",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmp_ytb-sbs_icon.png",
    title: "Youtube Subscribe",
    desc: "Rapidly Growing your subscribe",
    marginLeft: "20px",
    type: "youtub_subscibe",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt-fbpage_icon.png",
    title: "facebook page",
    desc: "Rapidly Growing your facebook page",
    type: "facebook_page",
    marginLeft: "80px",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_request_icon.png",
    title: "Request",
    desc: "offer payable services your visitor for request ",
    marginLeft: "70px",
    type: "request",
  },
  {
    img: "https://fly.linkcdn.cc/images/back/cmpt_podcast_icon.png",
    title: "Podcast",
    desc: "Rapidly Growing your listeners and......",
    marginLeft: "70px",
    type: "podcast",
  },
  // {
  //   img: "https://fly.linkcdn.cc/images/back/cmpt_typeform_icon.png",
  //   title: "Typeform",
  //   desc: "Get answers forms and more ",
  //   click: setTypeForm,
  //   marginLeft: "70px",
  //   type: "typeform",
  // },
];
const PopUpModal = ({ show, setShow, currentTheme, setCurrentTheme }) => {
  const handleClose = () => setShow(false);

  const cardData = ArrayCardInsert.map(
    ({ img, title, desc, marginLeft, type }, idx) => (
      <Grid xs={4} xl={4} lg={4} md={4} key={idx}>
        <PopUpCard
          img={img}
          title={title}
          desc={desc}
          onClick={async () => {
            // click(true);
            axiosRequset
              .post("/owner/Content/store", {
                information_id: currentTheme.id,
                type,
              })
              .then((res) => {
                setCurrentTheme({
                  ...currentTheme,
                  Contents: res.data.Information.Contents,
                  orders: res.data.Information.orders,
                });
                console.log(res);
                toast.success("Added Successfully");
              })
              .catch((error) => {
                console.log(error);
              });
            handleClose();
          }}
          marginLeft={marginLeft}
        />
      </Grid>
    )
  );

  return (
    <div>
      <StyledModal 
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
       
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
           
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "300px",
              }}
            >
              Add Block
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>

          {/* Start Cards  */}
          <Box sx={{ marginTop: "1px" }}>
            <Grid container spacing={2}>
              {cardData}
            </Grid>

          
          </Box>

          {/* End Cards */}
        </Box>
      </StyledModal>
    </div>
  );
};

export default PopUpModal;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:"green"
 
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "689px",
  height: "620px",
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
