import React from "react";
import "./about.css";
import faceBookIcon from "../../../assets/social/facebook.png";
import instagramIcon from "../../../assets/social/instagram_icon 1.png";
import tikTokicon from "../../../assets/social/tiktok.png";
import webSiteIcon from "../../../assets/social/website 1.png";
import snapChaticon from "../../../assets/social/snapchat 1.png";
import youtubeIcon from "../../../assets/social/youtube-music 1.png";
import person from "../../../assets/persone-image.png"
const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
<div className="images">
    <img src={person} alt="" />
 <div className="social-images">
 <img src={faceBookIcon} alt="" className="facebook" />
    <img src={instagramIcon} className="instagram" alt="" />
    <img src={tikTokicon} className="tik-tok" alt="" />
    <img src={webSiteIcon} className="website" alt="" />
    <img src={snapChaticon} className="snapchat" alt="" />
    <img src={youtubeIcon} className="youtube" alt="" />
    <i className="fa-brands fa-square-x-twitter" id="x-twitter" style={{fontSize:"42px"}}></i>
 </div>
</div>

          </div>
          <div className="col-md-6">
            <div className="about-text">
              <h1>Create and customize your Link in minutes</h1>
              <p>
                Connect your TikTok, Instagram, Twitter, website, store, videos,
                music, podcast, events and more. It all comes together in a link
                in bio landing page designed to convert.
              </p>
              <button>Get Start</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
