import React from "react";
import { SocialCont, SocialIcon } from "../styles/MobileScreen.styled";
import DynamicIcon from "../Customize/PopUp/fastworldIcons/DynamicIcon";
import { ReactComponent as Groupxx } from "./Groupxx.svg";
// import { ReactComponent as X } from "./Group_291.svg";/
import { FastWorldIconsGetById } from "../Customize/PopUp/fastworldIcons";
import { useTheme } from "../styles/ThemeContext";
const SocialIcons = ({ data }) => {
  const { currentTheme } = useTheme();
  const getType = () => {
    if (currentTheme?.Container?.social?.styleType == 1) {
      console.log("type 1");
      return 1;
    } else if (currentTheme?.Container?.social?.styleType == 2) {
      console.log("type 2");
      return 2;
    } else if (currentTheme?.Container?.social?.styleType == 6) {
      console.log("type 6");
      return 3;
    }
    console.log("type 0x");
    return 0;
  };
  const getIcon = (name) => {
    if (FastWorldIconsGetById[`${name}`] == undefined) {
      return "Groupxx";
    } else {
      return FastWorldIconsGetById[`${name}`][getType()];
    }
  };
  return (
    <>
      <SocialCont style={{ color: "white" }} className="socil-align-con">
        {data?.Socials.length > 0 &&
          data?.Socials?.map((item) => (
            <a
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // display: "block",
                width: "34px",
                height: "34px",
                borderRadius: "50%",
                // textAlign: "center",
              }}
              href={item.link}
            >
              <SocialIcon>
                {!item?.icon_name || item?.icon_name == "null" ? (
                  <img
                    src={item.image}
                    style={{
                      // width: "30px",
                      // height: "30px",
                      width: currentTheme?.Container?.social?.size
                        ? `${currentTheme?.Container?.social?.size}px`
                        : "30px",
                      height: currentTheme?.Container?.social?.size
                        ? `${currentTheme?.Container?.social?.size}px`
                        : "30px",
                      display: "block",
                      borderRadius:
                        currentTheme?.Container?.social?.borderRadius || "50%",
                      border:
                        currentTheme?.Container?.social?.styleType == 6
                          ? "2px solid"
                          : 0,
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    style={{
                      // width: "30px",
                      // height: "30px",
                      // display: "block",
                      // borderRadius: "50%",
                      // padding: "2px",
                      // width: "30px",
                      // height: "30px",
                      width: currentTheme?.Container?.social?.size
                        ? `${currentTheme?.Container?.social?.size}px`
                        : "30px",
                      height: currentTheme?.Container?.social?.size
                        ? `${currentTheme?.Container?.social?.size}px`
                        : "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // borderRadius: "50%",
                      borderRadius:
                        currentTheme?.Container?.social?.borderRadius || "50%",
                      border:
                        currentTheme?.Container?.social?.styleType == 6
                          ? "2px solid"
                          : 0,
                      background:
                        currentTheme?.Container?.social?.styleType == 2
                          ? currentTheme?.Container?.social?.color
                          : "transparent",
                    }}
                  >
                    <DynamicIcon
                      componentName={getIcon(item?.icon_name)}
                      style={{
                        width:
                          currentTheme?.StyledButton.thumbnail?.styleType ==
                            2 ||
                          currentTheme?.StyledButton.thumbnail?.styleType == 6
                            ? `${
                                currentTheme?.Container?.social?.size -
                                0.4 * currentTheme?.Container?.social?.size
                              }px`
                            : `${currentTheme?.Container?.social?.size}px`,
                        height:
                          currentTheme?.StyledButton.thumbnail?.styleType ==
                            2 ||
                          currentTheme?.StyledButton.thumbnail?.styleType == 6
                            ? `${
                                currentTheme?.Container?.social?.size -
                                0.4 * currentTheme?.Container?.social?.size
                              }px`
                            : `${currentTheme?.Container?.social?.size}px`,
                        display: "block",
                        // borderRadius:
                        //   currentTheme?.Container?.social?.borderRadius
                        //  ||
                        // "50%",
                        // color: "black",
                      }}
                    />
                    {/* <Facebook
                      style={{
                        width: "30px",
                        height: "30px",
                        // display: "block",
                        // color: "red",
                        // fill: "yellow",
                        color: "red",
                      }}
                      // fill="yellow"
                      // fill="currentColor:Important"
                      // stroke="currentColor:Important"
                      // color="yellow"
                    /> */}
                  </div>
                )}
                {/* <img
                  src={item.image}
                  style={{ width: "30px", height: "30px", display: "block" }}
                /> */}
              </SocialIcon>
            </a>
          ))}
      </SocialCont>
    </>
  );
};

export default SocialIcons;
