import React, { useEffect, useRef } from "react";
const loadYouTubeAPI = () => {
  return new Promise((resolve) => {
    if (window.YT && window.YT.Player) {
      resolve();
    } else {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      tag.onload = () => {
        // Resolve the promise after a short delay to ensure the API is ready
        setTimeout(resolve, 500);
      };
    }
  });
};

const YouTubePlayer = ({ videoId, id }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const initPlayer = async () => {
      await loadYouTubeAPI();

      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(
          `youtube-player-${id}${videoId}`,
          {
            height: "315",
            width: "560",
            borderRadius: "12px",
            videoId: videoId,
            playerVars: {
              autoplay: 0, // Change to 1 for autoplay
            },
          }
        );
      } else {
        console.error("YouTube API or Player is not available.");
      }
    };

    initPlayer();

    return () => {
      if (playerRef.current) {
        // playerRef.current.destroy();
      }
    };
  }, [videoId]);

  return (
    <div
      style={{
        borderRadius: "12px",
        width: "90%",
        maxWidth: "560px",
        boxSizing: "border-box",
      }}
      id={`youtube-player-${id}${videoId}`}
    ></div>
  );
};

// const YouTubeVideoEmbed = ({ videoId, id }) => {
//   useEffect(() => {
//     // Load the YouTube API script dynamically
//     const tag = document.createElement("script");
//     tag.src = "https://www.youtube.com/iframe_api";
//     const firstScriptTag = document.getElementsByTagName("script")[0];
//     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//     // Define a global function called onYouTubeIframeAPIReady
//     window.onYouTubeIframeAPIReady = () => {
//       // Create the YouTube player
//       new window.YT.Player(`youtube-player-${id}`, {
//         height: "315",
//         width: "100%",
//         videoId: videoId,
//         playerVars: {
//           autoplay: 0, // Change to 1 for autoplay
//         },
//       });
//     };
//   }, [videoId]);

//   return (
//     <div>
//       <h2>Embed YouTube Video</h2>
//       <div id={`youtube-player-${id}`}></div>
//     </div>
//   );
// };
const VideoT = ({ data }) => {
  const getVideoId = () => {
    if (!data?.link) {
      return;
    } else if (data?.link?.includes("v=")) {
      return data?.link?.split("v=")[1].split("&")[0];
    } else if (data?.link?.includes("si=")) {
      return data?.link?.split("/")[3];
    }
  };
  return (
    <>
      <div className="w-100 mt-3">
        {/* <iframe
          width="95%"
          height="300"
          className="vedio"
          src={
            data?.link
              ? data.link
              : "https://www.youtube.com/embed/" + getVideoId()
          }
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe> */}
        <YouTubePlayer key={data.id} videoId={getVideoId()} id={data.id} />
      </div>
    </>
  );
};

export default VideoT;
