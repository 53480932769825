import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { CheckRounded } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Avatar from "@mui/material/Avatar";
import { Person } from "@mui/icons-material";
import intial_var_imge from "../../assets/Images/logo.PNG";
import { toast } from "react-toastify";
import Base_Url, { Apipassword, axiosRequset } from "../../Api/ApiUrl";
import { useNavigate } from "react-router-dom";
function createData(id, name, paragraph, imageUrl, header, input1, input2) {
  return {
    id,
    name,
    paragraph,
    imageUrl,
    header,
    input1,
    input2,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the input field value in the row's state
    row[name] = value;
  };

  const handleSubmit = () => {
    // Handle form submission here for the specific row.
    // You can access the values from row.input1 and row.input2.
    console.log("Submitting data for row:", row);
  };

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const user = localStorage.getItem("token");

  // send email to send codee

  const handleEmail = () => {
    try {
      Base_Url.post(
        "owner/add_second_email",
        { email },
        {
          headers: {
            Apipassword: Apipassword,
            lang: "ar",
            Authorization: `Bearer ${user}`,
          },
        }
      );
      toast.success("Please Check Email to verify code ");
    } catch (err) {
      toast.error(`${err.response.data.message.email}`);
    }
  };

  // send code

  const handleToken = () => {
    try {
      Base_Url.post(
        "owner/verify_second_email",
        { token },
        {
          headers: {
            Apipassword: Apipassword,
            Authorization: `Bearer ${user}`,
          },
        }
      );
      toast.success("SuccessFully");
    } catch (err) {
      toast.error(` something went wrong `);
    }
  };

  // console.log(user);

  // handle update password

  const handlePasswordSubmit = () => {
    try {
      Base_Url.post(
        "owner/changePassword",
        { oldPassword, password, password_confirmation },
        {
          headers: {
            Apipassword: Apipassword,
            Authorization: `Bearer ${user}`,
          },
        }
      );
      toast.success("Password Updated Successfully");
    } catch (err) {
      toast.error(`${err.response.data.message.email}`);
    }
  };

  // deleteee profile
  const navigate = useNavigate();
  const deleteProfile = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.dev}</TableCell>
        {/* <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="body2" gutterBottom>
                {row.paragraph}
              </Typography>
              {/* <form> */}
              {row.id === 1 ? (
                // <form className='account-form-row1' onSubmit={handleEmail} >
                <div className="account-form-row1">
                  <React.Fragment>
                    <input
                      type="text"
                      name="input1" // First input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="email"
                      placeholder="Email address"
                      className=""
                    />
                    <div className="VC-cont">
                      <input
                        type="text"
                        name="input2" // Second input
                        placeholder="Verification code"
                        onChange={(e) => setToken(e.target.value)}
                        value={token}
                        id="token"
                      />
                      <button
                        class="send"
                        type="submit"
                        onClick={() => handleToken()}
                      >
                        Send code
                      </button>
                    </div>

                    <button className="cancel">Cancel</button>
                    <button
                      className="bind"
                      type="submit"
                      onClick={() => handleEmail()}
                    >
                      Bind
                    </button>
                  </React.Fragment>
                  {/* // </form> */}
                </div>
              ) : row.id === 3 ? (
                // <form className='account-form-row3'>
                <div className="account-form-row3">
                  <br />
                  <p class="p_email">
                    Email:<span>example@example.com</span>
                  </p>

                  <React.Fragment>
                    <br />
                    <label>Old Password</label>
                    <input
                      type="text"
                      name="input1" // First input
                      placeholder="Please Enter Old Password"
                      className=""
                      onChange={(e) => setOldPassword(e.target.value)}
                      value={oldPassword}
                      id="oldPassword"
                    />
                    <br />
                    <label>New Password</label>
                    <input
                      type="text"
                      name="input1" // First input
                      placeholder="Please Enter New Password"
                      className=""
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      id="password"
                    />
                    <br />
                    <label>Confirm New Password</label>
                    <input
                      type="text"
                      name="input2" // Second input
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={password_confirmation}
                      id="password_confirmation"
                      placeholder="Please Enter New Password again"
                    />
                    <button className="cancel">Cancel</button>
                    <button
                      className="save"
                      onClick={() => handlePasswordSubmit()}
                      type="submit"
                    >
                      Save
                    </button>
                  </React.Fragment>
                </div>
              ) : (
                // </form>
                <div>
                  <div>
                    Deleting your account is irreversible, and all content will
                    be removed in the process, and cannot be restored.Your
                    fastworld URL will no longer be active or accessible. We
                    have no way of restoring your fastworld data or reactivating
                    your account so if you would like to use fastworld again
                    after you delete your account, you will need to signup
                    again.
                  </div>
                  <button className="delete" onClick={() => deleteProfile()}>
                    Delete Account
                  </button>
                </div>
              )}
              {/* </form> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    dev: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    input1: PropTypes.string.isRequired,
    input2: PropTypes.string.isRequired,
  }).isRequired,
};
const rows = [
  createData(
    1,
    "Bind Email",
    "Adding a current email to your fastworld account can help you log in and receive notifications as well as reset your password easily. Only you will see your email on your profile. Learn More",
    "This is the first row paragraph.",
    "",
    "",
    "",
    "Input 2"
  ),

  createData(
    3,
    "Account Password",
    "After setting password, you can log in to Fastworld through Email + Password",
    "",
    "",
    "This is the third row header.",
    "",
    ""
  ),
  createData(
    4,
    "Delete My Account",
    "",
    "",
    "",
    "This is the third row header.",
    "",
    ""
  ),
];

export default function CollapsibleTable() {
  const [profileImage, setProfileImage] = useState({ intial_var_imge });

  // Function to handle file input change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };

  const [name, setName] = useState("Omnia Arafat");
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    axiosRequset.get("/owner/getProfile").then((res) => {
      // console.log("getProfile", res);
      setName(res.data.owner.email);
      setProfileImage(res.data.owner.image);
    });
  }, []);

  return (
    <TableContainer className="table-container" component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className="profile-row">
              <div>
                <label htmlFor="profile-image-input" className="profile-label">
                  <input
                    type="file"
                    id="profile-image-input"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  <Avatar
                    alt="User Profile"
                    src={profileImage || "default-profile-image-url.jpg"}
                    className="avater"
                    sx={{
                      width: 100,
                      height: 100,
                      background: "var(--gradient-color)",
                    }}
                  >
                    <Person sx={{ height: "100px", width: "80px" }} />
                  </Avatar>
                </label>
              </div>
              <div>
                {isEditing ? (
                  <input
                    style={{ padding: "5px" }}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                ) : (
                  name
                )}

                {isEditing ? (
                  <IconButton onClick={handleSaveClick} aria-label="Save">
                    <CheckRounded />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleEditClick} aria-label="Edit">
                    <BorderColorIcon />
                  </IconButton>
                )}

                <div className="login-type"> Login by email</div>
              </div>
            </TableCell>

            <TableCell></TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
