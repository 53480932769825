import { useEffect, useState } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import styled from "@emotion/styled";

import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/joy";

import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import CForm from "./CForm";
const PopUpButtonForm = ({
  show,
  setShow,
  handleSave,
  setText,
  handleSelectFormOuter,
}) => {
  const [yourForm, setYourForm] = useState(false);
  const [viewYourForm, setViewYourForm] = useState(false);
  const [d, setD] = useState();
  const [clicked, setClicked] = useState("");
  const handleClose = () => {
    setViewYourForm(false);
    setYourForm(false);
    setClicked("");
    setD();

    setShow(false);
  };
  let x1 = {
    fields: [
      { id: 0, key: "input", title: "Name", required: 1 },
      { id: 1, key: "email", title: "Email", required: 1 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Get on the list",
    submit: { message: "Thanks for submitting!", title: "Submit" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  let x2 = {
    fields: [
      { id: 0, key: "input", title: "Full name", required: 1 },
      { id: 1, key: "email", title: "Email", required: 1 },
      { id: 2, key: "text", title: "Add a message...", required: 0 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Holler at us",
    submit: { message: "Thanks for submitting!", title: "Submit" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  let x3 = {
    fields: [
      { id: 0, key: "input", title: "Full name", required: 1 },
      { id: 1, key: "email", title: "Email", required: 1 },
      { id: 2, key: "phone", title: "Phone", required: 0 },
      { id: 3, key: "dropdown", title: "Choose an issue", required: 0 },
      {
        id: 4,
        key: "text",
        title: "Let us know what you need help with...",
        required: 0,
      },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Feedback",
    submit: { thanks_text: "Thanks for submitting!", btn_text: "Submit" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  let x4 = {
    fields: [
      { id: 0, key: "input", title: "Full name", required: 1 },
      { id: 1, key: "email", title: "Email", required: 1 },
      { id: 2, key: "phone", title: "Phone", required: 0 },
      { id: 3, key: "dropdown", title: "Position", required: 0 },
      { id: 4, key: "text", title: "Enter your message here...", required: 0 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "We're Hiring",
    submit: { thanks_text: "Thanks for submitting!", btn_text: "Apply Now" },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };

  let x5 = {
    fields: [
      { id: 0, key: "input", title: "Full name", required: 1 },
      { id: 1, key: "email", title: "Email", required: 1 },
      { id: 2, key: "phone", title: "Phone", required: 0 },
      { id: 3, key: "dropdown", title: "Select a service", required: 0 },
      { id: 4, key: "text", title: "Comments", required: 0 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Get a Quote",
    submit: {
      thanks_text: "Thanks for submitting!",
      btn_text: "Submit Request",
    },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  let x6 = {
    fields: [
      { key: "input", title: "Full name", required: 1 },
      { key: "email", title: "Email", required: 1 },
      { key: "phone", title: "Phone", required: 0 },
      { key: "text", title: "What services are you requesting?", required: 0 },
      { key: "date", title: "Preferred date", required: 1 },
      { key: "time", title: "Preferred time", required: 1 },
    ],
    themes: [
      { key: "style", value: {} },
      { key: "background", value: { opacity: "1" } },
      { key: "text", value: {} },
      { key: "submit", value: {} },
    ],
    title: "Request an appointment",
    submit: {
      thanks_text: "Thanks for submitting!",
      btn_text: "Submit Request",
    },
    bio: { uid: "50924fdgmn9", pro: 1, pg: 1, logoshow: 1 },
  };
  useEffect(() => {
    setYourForm(false);
  }, []);

  const handleSelectForm = (value) => {
    if (clicked == "x1") {
      // setD(x1);
      // setText(x1);
      handleSelectFormOuter(x1);
      // setViewYourForm(true);
      // setYourForm(false);
      handleClose();
      // setClicked("");
    } else if (clicked == "x2") {
      handleSelectFormOuter(x2);
      handleClose();
    } else if (clicked == "x3") {
      handleSelectFormOuter(x3);
      handleClose();
    } else if (clicked == "x4") {
      handleSelectFormOuter(x4);
      handleClose();
    } else if (clicked == "x5") {
      handleSelectFormOuter(x5);
      handleClose();
    } else if (clicked == "x6") {
      handleSelectFormOuter(x6);
      handleClose();
    } else {
      toast.error("please slect form");
    }
  };
  return (
    <div style={{ overflow: "overlay" }}>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={show}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box className="scrollbar" sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "27,24px",
                color: "black",
                marginLeft: "300px",
              }}
            >
              Add Block
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ width: "24px", height: "24px", color: "black" }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{ marginTop: "1px" }}>
            <Grid container spacing={2}>
              {!viewYourForm && !yourForm ? (
                <div
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    padding: "20%",
                  }}
                >
                  <img
                    src="https://fly.linkcdn.cc/images/form_blank.png"
                    alt=""
                    style={{ margin: "auto", display: "block" }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      width: "70%",
                      height: "32px",
                      borderRadius: "4px",
                      // marginTop: "20px",
                      backgroundColor: "#38e199",
                      color: "black",
                      display: "block",
                      margin: "20px auto ",
                      "&:hover": {},
                    }}
                    onClick={() => setYourForm(true)}
                  >
                    Create your first form{" "}
                  </Button>
                </div>
              ) : !viewYourForm && yourForm ? (
                <div
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "95%",
                      display: "flex",
                      padding: "40px 40px",
                      justifyContent: "space-between",
                      boxSizing: "border-box",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ width: "45%", height: "auto" }}>
                      {/* input type radio */}
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        for="formType"
                      >
                        <div>Form Type</div>
                        <div>
                          <input
                            type="radio"
                            id="formType"
                            name="formType"
                            value="x1"
                            checked={clicked == "x1"}
                            onChange={(e) => setClicked(e.target.value)}
                          />
                        </div>
                      </label>

                      <img
                        src="https://fly.linkcdn.cc/cmpt/form/subs.png"
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ width: "45%", height: "auto" }}>
                      {/* input type radio */}
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        for="formType"
                      >
                        <div>Contact us</div>
                        <div>
                          <input
                            type="radio"
                            id="formType"
                            name="formType"
                            value="x2"
                            checked={clicked == "x2"}
                            onChange={(e) => setClicked(e.target.value)}
                          />
                        </div>
                      </label>

                      <img
                        src="https://fly.linkcdn.cc/cmpt/form/contact.png"
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ width: "45%", height: "auto" }}>
                      {/* input type radio */}
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        for="formType"
                      >
                        <div>Feedback</div>
                        <div>
                          <input
                            type="radio"
                            id="formType"
                            name="formType"
                            value="x3"
                            checked={clicked == "x3"}
                            onChange={(e) => setClicked(e.target.value)}
                          />
                        </div>
                      </label>

                      <img
                        src="https://fly.linkcdn.cc/cmpt/form/feedback1.png"
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ width: "45%", height: "auto" }}>
                      {/* input type radio */}
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        for="formType"
                      >
                        <div>Job Application</div>
                        <div>
                          <input
                            type="radio"
                            id="formType"
                            name="formType"
                            value="x4"
                            checked={clicked == "x4"}
                            onChange={(e) => setClicked(e.target.value)}
                          />
                        </div>
                      </label>

                      <img
                        src="https://fly.linkcdn.cc/cmpt/form/job6-1.png"
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div style={{ width: "45%", height: "auto" }}>
                      {/* input type radio */}
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        for="formType"
                      >
                        <div>Price Quote</div>
                        <div>
                          <input
                            type="radio"
                            id="formType"
                            name="formType"
                            value="x6"
                            checked={clicked == "x6"}
                            onChange={(e) => setClicked(e.target.value)}
                          />
                        </div>
                      </label>

                      <img
                        src="https://fly.linkcdn.cc/cmpt/form/price2.png"
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <Button
                    sx={{
                      color: "black",
                      display: "block",
                      margin: "20px auto",
                      backgroundColor: "#38e199",
                      "&:hover": {
                        backgroundColor: "#38e199",
                      },
                    }}
                    disabled={!clicked}
                    onClick={handleSelectForm}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "20px",
                    overflow: "scroll",
                  }}
                >
                  {/* <CForm data={d} handleSave={handleSave} /> */}
                </div>
              )}
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
};

export default PopUpButtonForm;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: overlay;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: "689px",
  height: "720px",
  borderRadius: "8px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  overflow: "overlay",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});
