// SocialContent.js
import React from "react";
import { KeyboardArrowLeft } from "@mui/icons-material";
import facebook_var from "../../assets/Images/socialGroup1/facebook(1).png";
import twitter_var from "../../assets/Images/socialGroup1/twitter.png";
import whats_var from "../../assets/Images/socialGroup1/icons8-whatsapp-48.png";
import youtupe_var from "../../assets/Images/socialGroup1/icons8-youtube-48.png";
import tic_var from "../../assets/Images/socialGroup1/icons8-tiktok-48.png";
import insta_var from "../../assets/Images/socialGroup1/instagram (2).png";

const SocialContent = ({ onBack, closePopup, link }) => {
  const handleShare = (platform) => {
    // Function to generate the share URL for each platform
    const getShareUrl = (platform) => {
      switch (platform) {
        case "Facebook":
          return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            link
          )}`;
        case "Twitter":
          return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            link
          )}`;
        case "WhatsApp":
          return `https://api.whatsapp.com/send?text=${encodeURIComponent(
            link
          )}`;
        case "LinkedIn":
          return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
            link
          )}`;
        case "Messenger":
          return `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
            link
          )}&app_id=123456`;
        case "Instagram":
          return `https://www.instagram.com/accounts/edit/?__coig_login=${encodeURIComponent(
            link
          )}`;
        case "YouTube":
          // return `https://www.youtube.com/share?url=${encodeURIComponent(
          //   link
          // )}`;
          return "https://www.youtube.com";
        case "TikTok":
          // Assuming you have a TikTok link sharing method
          return `https://www.tiktok.com/`;
        // return "https://www.tiktok.com/share?url=" + encodeURIComponent(link);

        case "Email":
          // For email, you can use the "mailto:" scheme
          return `mailto:?body=${encodeURIComponent(link)}`;
        default:
          return "";
      }
    };

    const shareUrl = getShareUrl(platform);
    // console.log(shareUrl);
    // Open a new window or tab with the generated share URL
    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  return (
    <div>
      <div
        className="sharelink-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0  0 40px 0",
        }}
      >
        <button
          style={{ fontSize: "30px", fontFamily: "bold" }}
          onClick={closePopup}
        >
          <KeyboardArrowLeft onClick={onBack} />
        </button>
        <span className="social-link">Share my link to...</span>
        <button
          style={{ fontSize: "30px", fontFamily: "bold" }}
          onClick={closePopup}
        >
          x
        </button>
      </div>

      {/* <p className="shr-title" style={{ width: "150px" }}>
        Share my link to...
      </p> */}

      {/* Share icons for various platforms */}
      <div className="share-icons">
        <div onClick={() => handleShare("Facebook")} className="shr-soc-cont">
          {/* Use the Facebook icon or image */}
          <img src={facebook_var} alt="Facebook" className="shr-soc-icon" />
          <span> Share on Facebook</span>
        </div>
        <div onClick={() => handleShare("Twitter")} className="shr-soc-cont">
          {/* Use the Twitter icon or image */}
          <img src={twitter_var} alt="Twitter" className="shr-soc-icon" />
          <span> Share on Twitter</span>
        </div>
        <div onClick={() => handleShare("WhatsApp")} className="shr-soc-cont">
          {/* Use the WhatsApp icon or image */}
          <img src={whats_var} alt="WhatsApp" className="shr-soc-icon" />
          <span> Share on WhatsApp</span>
        </div>
        <div onClick={() => handleShare("YouTube")} className="shr-soc-cont">
          {/* Use the LinkedIn icon or image */}
          <img src={youtupe_var} alt="YouTube" className="shr-soc-icon" />
          <span> Share on YouTube</span>
        </div>
        <div onClick={() => handleShare("TikTok")} className="shr-soc-cont">
          {/* Use the Messenger icon or image */}
          <img src={tic_var} alt="tictok" className="shr-soc-icon" />
          <span> Share on Tic Tok</span>
        </div>
        <div onClick={() => handleShare("Instagram")} className="shr-soc-cont">
          {/* Use the Email icon or image */}
          <img src={insta_var} alt="instgram" className="shr-soc-icon" />
          <span> Share on instgram</span>
        </div>
      </div>
    </div>
  );
};

export default SocialContent;
