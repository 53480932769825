import React from "react";

import { Button, Checkbox, Input, Radio, RadioGroup } from "@mui/joy";
import deleteIcon from "../../../assets/Image/delete.PNG";
import Base_Url, { Apipassword, axiosRequset } from "../../../Api/ApiUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

const Request = ({
  data,
  onDelete,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange: handleExpandChange,
}) => {
  const [values, setValues] = useState({
    options: [
      {
        id: 0,
        amount: 5,
        detail: "20-second custom video",
      },
      {
        id: 1,
        amount: 10,
        detail: "50-second custom video",
      },
    ],
    thanks: "Thank you!",
    title: "Request",
    desc: "Your request is in process. Thank you!",
  });
  useEffect(() => {
    // let x =
    //   '{"success": "", "requireDetails": 0, "provider": {"id": "pp-linkfly-stripe", "merchantId": "pp-linkfly-stripe", "state": 2, "type": 3}, "options": [{"amount": 1000, "detail": "20-second custom video"}, {"amount": 3300, "detail": "333"}, {"amount": 33300, "detail": "33333333"}]}';
    // console.log(JSON.parse(x));
    if (data?.text.length > 0 && data?.text != "null") {
      setValues({ ...JSON.parse(data.text)[0] });
      // console.log(JSON.parse(data.text));
    }
  }, []);
  const removeOption = (index) => {
    setValues({
      ...values,
      options: values.options.filter((item) => item.id !== index),
    });
  };
  const addOption = () => {
    if (values.options.length >= 5) {
      toast.error("Maximum 5 options are allowed");
      return;
    }
    setValues({
      ...values,
      options: [
        ...values.options,
        { id: values.options.length + 1, amount: 0, detail: "" },
      ],
    });
  };

  const handleSave = () => {
    let cansel = false;

    values.options.map((item) => {
      if (!item.amount || !item.detail) {
        // toast.error("Please fill all the fields");
        cansel = true;
        return;
      }
    });
    if (cansel) {
      toast.error("Please fill all the fields");
      return;
    }

    axiosRequset
      .post("/owner/Content/update", {
        type: "request",
        id: data.id,
        text: [values],
      })
      .then((res) => {
        setCurrentTheme({
          ...currentTheme,
          Contents: res.data.Information.Contents,
        });
        toast.success("Saved Successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <div>
      {(expanded === "" || expanded === index) && (
        <Accordion
          expanded={expanded === index}
          onChange={handleExpandChange}
          sx={{
            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
            // borderTop: "1px solid #B9B8B8",
            // borderRadius: "10px",
            // margin: "20px 0px",
            // boxSizing: "border-box",
            border: "1px solid rgba(178, 194, 187, 1)",
            borderRadius: "5px",
            margin: "20px 0px",
            boxSizing: "border-box",
            boxShadow: "0 0 0 red",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: "relative",
              "& p": {
                fontFamily: "Anek Devanagari",
                fontSize: "18px",
                fontWeight: "300",
                // lineHeight: "60px",
                color: "#000000",
              },
              "&.Mui-expanded::after": {
                position: "absolute",
                content: "''",
                height: "1px",
                width: "calc(100% - 32px)",
                background: "#B2C2BB",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                // borderBottom: "1px solid #B2C2BB",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Request </Typography>
              </div>
              <div style={{ textAlign: "end" }}>
                <DeleteIcon
                  sx={{
                    justifyContent: "flex-end",
                    ":hover": {
                      color: "#19609C",
                    },
                  }}
                  onClick={onDelete}
                />{" "}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginX: "15px", position: "relative" }}>
              <>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{ fontSize: "20px", marginTop: "10px" }}
                    >
                      Request
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "1.5px",
                      backgroundColor: "#787878",
                      marginY: "15px",
                    }}
                  ></Box>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "19px",
                      marginTop: "10px",
                      color: "#787878",
                    }}
                  >
                    Provide various services that visitors can pay to request.
                  </Typography>

                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "20px", marginTop: "10px" }}
                    >
                      Title
                    </Typography>
                    <Input
                      size="md"
                      placeholder="Send me a request"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "70%",
                        marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={(e) => {
                        setValues({ ...values, title: e.target.value });
                      }}
                      value={values.title}
                    />
                  </Box>
                  {/* <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    component="p"
                    sx={{ fontSize: "20px", marginTop: "10px" }}
                  >
                    Button Text{" "}
                  </Typography>
                  <Input
                    size="md"
                    placeholder="Send me a request"
                    sx={{
                      marginTop: "15px",
                      boxShadow: ".5px .5px .5px .5px #19609C",
                      width: "70%",
                      marginLeft: "70px",
                      height: "45px",
                      paddingTop: "5px",
                    }}
                    onChange={(e) => {}}
                    // value={title}
                  />
                </Box> */}
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "20px", marginTop: "10px" }}
                    >
                      Description
                    </Typography>
                    <Input
                      size="md"
                      placeholder="Enter Description"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "70%",
                        marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={(e) => {
                        setValues({ ...values, desc: e.target.value });
                      }}
                      value={values.desc}
                    />
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "20px", marginTop: "10px" }}
                    >
                      Thanks message{" "}
                    </Typography>
                    <Input
                      size="md"
                      placeholder="Thanks message"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "70%",
                        marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={(e) => {
                        setValues({ ...values, thanks: e.target.value });
                      }}
                      value={values.thanks}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1.5px",
                    backgroundColor: "#787878",
                    marginY: "30px",
                  }}
                ></Box>

                <Box>
                  {/* <Typography
                  component="p"
                  sx={{ fontSize: "19px", marginTop: "10px" }}
                >
                  Payment Provider
                </Typography> */}

                  {/* <Box sx={{ marginTop: "20px" }}>
                  <RadioGroup
                    size="sm"
                    aria-labelledby="variant-label"
                    name="variant"
                    // value={variant}
                    onChange={(event) => {}}
                  >
                    <Radio label="Withdraw from Linkfly" value="solid" />
                    <Radio
                      label="Set up instant payouts"
                      value="soft"
                      sx={{ marginTop: "15px" }}
                    />
                    <Typography
                      component="p"
                      sx={{
                        fontSize: "12px",
                        marginTop: "10px",
                        marginLeft: "24px",
                      }}
                    >
                      Get paid to your local bank account automatically. No
                      minimum threshold.
                    </Typography>
                  </RadioGroup>
                </Box> */}

                  <Box sx={{ marginTop: "30px" }}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "16px", marginTop: "10px" }}
                    >
                      Request Options
                    </Typography>
                    <Typography
                      component="p"
                      sx={{ fontSize: "14px", marginTop: "7px" }}
                    >
                      You Can only add up to 5 options
                    </Typography>
                    {/* <Box sx={{ marginTop: "50px", display: "flex" }}>
                    <Input
                      size="md"
                      placeholder=" $ price"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "13%",
                        marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={(e) => {}}
                      //   value={title}
                    />
                    <Input
                      size="md"
                      placeholder="request"
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "60%",
                        marginLeft: "20px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      onChange={(e) => {}}
                      //   value={title}
                    />
                  </Box> */}
                    {values?.options?.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{ marginTop: "50px", display: "flex" }}
                        >
                          <Input
                            size="md"
                            placeholder=" $ 50"
                            type="number"
                            sx={{
                              marginTop: "15px",
                              boxShadow: ".5px .5px .5px .5px #19609C",
                              width: "13%",
                              marginLeft: "70px",
                              height: "45px",
                              paddingTop: "5px",
                            }}
                            onChange={(e) => {
                              let arr = [...values.options];
                              arr[index].amount = e.target.value;
                              setValues({ ...values, options: arr });
                            }}
                            value={item.amount}
                          />
                          <Input
                            size="md"
                            placeholder="30-minute consultation service"
                            sx={{
                              marginTop: "15px",
                              boxShadow: ".5px .5px .5px .5px #19609C",
                              width: "60%",
                              marginLeft: "20px",
                              height: "45px",
                              paddingTop: "5px",
                            }}
                            onChange={(e) => {
                              let arr = [...values.options];
                              arr[index].detail = e.target.value;
                              setValues({ ...values, options: arr });
                            }}
                            value={item.detail}
                          />
                          {index != 0 && (
                            <Box>
                              <RemoveCircleIcon
                                sx={{
                                  marginTop: "25px",
                                  marginLeft: "20px",
                                  backgroundColor: "transparent",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeOption(item.id)}
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>

                  <Box>
                    <Typography
                      component="p"
                      sx={{
                        fontSize: "16px",
                        marginTop: "45px",
                        color: "#54B99B",
                        marginLeft: "13px",
                        cursor: "pointer",
                      }}
                      onClick={() => addOption()}
                    >
                      + Add option
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1.5px",
                        backgroundColor: "#787878",
                        marginY: "30px",
                      }}
                    ></Box>
                    <Typography
                      component="p"
                      sx={{ fontSize: "19px", marginTop: "10px" }}
                    >
                      Optinal Settings
                    </Typography>

                    <Checkbox
                      label="Request fans to specify special requirements or details"
                      sx={{ marginTop: "50px" }}
                    />
                  </Box>
                  <Button
                    size="md"
                    sx={{
                      width: "80%",
                      marginTop: "30px",
                      background:
                        "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
                      height: "57px",
                      marginLeft: "70px",
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default Request;
