import React from "react";
import { useState } from "react";
import socialPer from "../../assets/Images/persons_social_icon.webp";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  QrCode2Outlined,
} from "@mui/icons-material";
import SocialContent from "./SocialContent";
import QrBox from "./QrBox";
import { useRef } from "react";
import Logo from "../../assets/Images/logo.PNG";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ShareBox = ({ open, setOpen, closePopup, link }) => {
  const count = 2;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(setIsPopupOpen);
  };
  const [activeTab, setActiveTab] = useState("main"); // 'main' is the default tab

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // ==============copy linl=============
  const textToCopy = `https://fastworldkw.net/createLink/`;

  const copyText = () => {
    //
    const range = document.createRange();
    const textElement = document.querySelector(".copy-link-text");

    // Select the text inside the element
    range.selectNode(textElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    // Execute the copy command
    document.execCommand("copy");

    // Deselect the text
    window.getSelection().removeAllRanges();

    toast.success("Text has been copied!");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "main":
        return (
          <div>
            <div className="sharelink-header">
              <span className="sharelink-title">Share Your Link</span>
              <button className="cross-btn" onClick={closePopup}>
                x
              </button>
            </div>
            <p className="sharelink-des">
              Get more visitors by sharing your link everywhere.
            </p>

            {/* <div className="soc-title">
              <img src={socialPer} alt="" style={{ marginRight: "1rem" }} />
              Add Linkfly to my socials
            </div> */}
            <div
              onClick={() => handleTabChange("social")}
              style={{ marginTop: "80px", cursor: "pointer" }}
              className="sharelink-social"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{ width: "1.5rem" }}
                onClick={() => setIsPopupOpen(true)}
              >
                <g id="_01_align_center" data-name="01 align center">
                  <path d="M19.333,14.667a4.66,4.66,0,0,0-3.839,2.024L8.985,13.752a4.574,4.574,0,0,0,.005-3.488l6.5-2.954a4.66,4.66,0,1,0-.827-2.643,4.633,4.633,0,0,0,.08.786L7.833,8.593a4.668,4.668,0,1,0-.015,6.827l6.928,3.128a4.736,4.736,0,0,0-.079.785,4.667,4.667,0,1,0,4.666-4.666ZM19.333,2a2.667,2.667,0,1,1-2.666,2.667A2.669,2.669,0,0,1,19.333,2ZM4.667,14.667A2.667,2.667,0,1,1,7.333,12,2.67,2.67,0,0,1,4.667,14.667ZM19.333,22A2.667,2.667,0,1,1,22,19.333,2.669,2.669,0,0,1,19.333,22Z" />
                </g>
              </svg>
              <span>Share my link to...</span>
              <span className="arrow-link">
                <KeyboardArrowRight onClick={() => handleTabChange("social")} />
              </span>
            </div>
            <div
              onClick={() => handleTabChange("QrBox")}
              style={{ marginTop: "80px", cursor: "pointer" }}
              className="sharelink-qr"
            >
              <QrCode2Outlined />
              <span>Share my link to...</span>
              <span className=" arrow-link">
                <KeyboardArrowRight />
              </span>
            </div>

            <div style={{ marginTop: "80px" }} className="copy-link-box">
              <div className="copy-link-logo">
                <img src={Logo} alt="Logo" className="copy-link-logo" />
              </div>
              <div className="copy-link-text">{link}</div>
              <button onClick={copyText} className="copy-link-button">
                Copy Text
              </button>
            </div>
          </div>
        );
      case "social":
        return (
          <SocialContent
            onBack={() => handleTabChange("main")}
            closePopup={closePopup}
            // link={`https://fastworldkw.net/xxx/${textToCopy}`}
            link={link}
          />
        );

      case "QrBox":
        return (
          <QrBox
            onBack={() => handleTabChange("main")}
            closePopup={closePopup}
            link={link}
          />
        );
      default:
        return null;
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ padding: "10px" }}>
          {/* <div className="popup overlay"> */}
          {/* <div className="ShareLinkCont"> */}
          {/* {activeTab !== "main" && (
            <div className="sharelink-header">
              <span className="arrow-link">
                <KeyboardArrowLeft onClick={() => handleTabChange("main")} />
              </span>
            </div>
          )} */}
          {renderContent()}
          {/* </div> */}
          {/* </div> */}
        </div>
      </Box>
    </Modal>
  );
};

export default ShareBox;
