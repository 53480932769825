import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Button, Input } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddFieldPopUp from "../PopUp/AddFieldPopUp";
import { axiosRequset } from "../../../Api/ApiUrl";
const CForm = ({ data, handleSave, setText }) => {
  const [values, setValues] = useState({});
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [formContant, setFormContant] = useState({
    fields: [
      { id: 0, key: "Full Name", title: "Full Name", required: false },
      { id: 1, key: "email", title: "email", required: true },
    ],
    submit: { id: "submit", key: "submit", title: "submit", required: true },
  });

  const heandelAddField = (value) => {
    if (value.title == "" || value.title == null) {
      toast.error("Title is required");
      return;
    }
    setText({
      ...data,
      fields: [
        ...data.fields,
        { id: data.fields.length, key: value.key, title: value.title },
      ],
    });

    setShow(false);
  };
  const heandelEditField = (value) => {
    console.log(value, "value", data);
    setText({
      ...data,
      fields: [
        ...data.fields.map((field) => {
          if (field.id === value.id) {
            return value;
          }
          return field;
        }),
      ],
    });
    setShowEdit(false);
  };
  const heandelDeleteField = (id) => {
    setText({
      ...data,
      fields: data.fields.filter((field) => field.id !== id),
    });
    toast.success("Deleted Successfully");
  };

  // const handleSave = () => {
  //   axiosRequset
  //     .post("/owner/Content/update", {
  //       type: "",
  //       id: data.id,
  //       text: [data],
  //     })
  //     .then((res) => {
  //       toast.success("Saved Successfully");
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong");
  //     });
  // };
  const handleEditeSubmit = (newValue) => {
    setText({
      ...data,
      submit: newValue,
    });
    setShowSubmit(false);
  };
  useEffect(() => {
    // let x = JSON.parse(
    //   '{"fields":[{"key":"email","required":1,"services":null,"sync":0,"title":"Email"},{"key":"input","required":0,"services":null,"sync":0,"title":"Full Name"},{"key":"checkbox","required":0,"title":"Checkboxfffff","services":["fffffffffff"]},{"key":"regions","required":1,"title":"zzzzz"},{"key":"regions","required":0,"title":"zzzzzzzzzRegions"}],"submit":{"btn_text":"Submit","thanks_text":"Thanks for submitting!"},"title":"Contact us","themes":[],"bio":{"uid":"50924fdgmn9","pro":1,"pg":1,"logoshow":1}}'
    // );
    // console.log("x", x);
    // if (data?.text.length > 0 && data?.text != "null") {
    // setText({ ...JSON.parse(data.text)[0] });
    // setText(data);
    // console.log("data", data);
    // }
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ marginX: "15px", position: "relative", width: "100%" }}>
        <Box sx={{ marginTop: "30px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              component="p"
              sx={{ fontSize: "20px", marginTop: "10px" }}
            >
              Form
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1.5px",
              backgroundColor: "#787878",
              marginY: "15px",
            }}
          ></Box>
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              component="p"
              sx={{
                fontSize: "15px",
                marginTop: "10px",
                marginLeft: "20px",
              }}
            >
              Click on default text to edit{" "}
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: "19px",
                marginTop: "30px",
                marginLeft: "20px",
                fontWeight: "bold",
              }}
            >
              Contact us
            </Typography>

            <Box sx={{ marginTop: "35px" }}>
              {data.fields?.length > 0 &&
                data.fields.map(({ id, key, title, required }) => {
                  if (key == "submit") {
                    return null;
                  }
                  return (
                    <Input
                      key={id}
                      size="md"
                      placeholder={title}
                      label={key}
                      sx={{
                        marginTop: "15px",
                        boxShadow: ".5px .5px .5px .5px #19609C",
                        width: "70%",
                        marginLeft: "70px",
                        height: "45px",
                        paddingTop: "5px",
                      }}
                      endDecorator={
                        <>
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setValues({ id, key, title, required });
                              setShowEdit(true);
                            }}
                          />
                          <DeleteOutlineIcon
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => heandelDeleteField(id)}
                          />
                        </>
                      }
                    />
                  );
                })}
              <Button
                size="md"
                sx={{
                  width: "40%",
                  color: "white",
                  border: "1px solid #38e199",
                  height: "57px",
                  margin: "10px auto 20px",
                  display: "block",
                }}
                onClick={() => {
                  setShowSubmit(true);
                  setValues(data.submit);
                }}
              >
                {data.submit?.title}{" "}
                <EditIcon
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Button>
            </Box>
          </Box>
          <Button
            size="md"
            sx={{
              width: "80%",
              marginTop: "50px",
              color: "#54B99B",
              background: "white",
              border: "1px solid #38e199",
              height: "57px",
              marginLeft: "40px",
              ":hover": {
                color: "#38e199",
                background: "white",
              },
            }}
            onClick={() => {
              setValues({});
              setShow(true);
            }}
          >
            + Add field
          </Button>
          <Button
            size="md"
            sx={{
              width: "80%",
              marginTop: "50px",
              background:
                "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
              height: "57px",
              marginLeft: "40px",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
      <AddFieldPopUp
        show={show}
        setShow={setShow}
        heandelAddField={heandelAddField}
        values={values}
        setValues={setValues}
        pupUpTitle=" Add Field"
      />
      <AddFieldPopUp
        show={showEdit}
        setShow={setShowEdit}
        heandelAddField={heandelEditField}
        values={values}
        setValues={setValues}
        pupUpTitle="Edit Field"
      />
      <AddFieldPopUp
        show={showSubmit}
        setShow={setShowSubmit}
        heandelAddField={handleEditeSubmit}
        values={values}
        setValues={setValues}
        pupUpTitle="Edit submit Field"
        iSsubmit={true}
      />
    </div>
  );
};

export default CForm;
