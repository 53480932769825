import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DivContact, Input, Submit } from "../../styles/MobileScreen.styled";

const PopUpFormC = ({ show, setShow, form }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => setShow(false);
  const handleDelete = () => {};
  const handleSubmit = () => {
    toast.success(form.submit.message);
  };
  useEffect(() => {
    // console.log("show", show);
    // console.log("details", details);
    // console.log("vCard", new vCard().parse(`eldeeb: asasa `));
    // document.body.style.overflow = "hidden";
    // return () => {
    //   document.body.style.overflow = "unset";
    // };
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">Form </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                width: "500px",
                height: "450px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: "75%" }} id="sameId_as_contact_id">
                <h2 style={{ textAlign: "center" }}>Form</h2>

                <>
                  <DivContact>
                    {form?.fields?.length > 0 &&
                      form?.fields?.map((item) => {
                        // console.log(item);
                        // if (item) return null;
                        return (
                          <Input
                            type="text"
                            placeholder={item.title}
                            key={item.id}
                            label={item.title}
                          />
                        );
                      })}
                    {/* <Input type="text" placeholder="message" /> */}
                    <Submit onClick={handleSubmit}>
                      {form?.submit?.title}
                    </Submit>
                  </DivContact>
                </>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button style={{ color: "red" }} onClick={handleDelete} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpFormC;
