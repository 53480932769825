import React from 'react'
import VerifyAccount from '../../components/Auth/VerifyAccount/VerifyAccount'

const VerifyAccountPage = () => {
  return (
  <VerifyAccount/>
  )
}

export default VerifyAccountPage
