import React from "react";
import Settingbar from "../components/Settingbar";
import profileLogo from "../assets/Images/profile-logo.PNG";
import reset from "../assets/Images/svg_icons/template.svg";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
const Subscription = () => {
  const email = "fastworldkw@gmail.com";
  const state = "Fastworld PRO";
  const currentPage = "Subscription"; // Set the current page name here
  return (
    <div className="subscription " style={{ background: "white" }}>
      <div
        className="title"
        style={{
          top: "-245px",
          left: "73%",
          justifyContent: "space-between",
        }}
      >
        {" "}
        Settings
      </div>

      <div className="subscription-content">
        <h1 className="">Your Subscription</h1>
        <div className="subscription-1st-card card">
          <div className="header">
            <p>Basic information</p>
          </div>
          <div className="body">
            <img src={profileLogo} alt="logo" />
            <p>
              Email: {email}
              <br />
              <br />
              Membership status: {state}
            </p>
          </div>
        </div>
        <div className="subscription-2nd-card card">
          <div className="header">
            <p>Billing details</p>
          </div>
          <div className="body">
            <p>
              Membership
              <br />
              <br />
              1pro
            </p>
            <p>
              Price
              <br />
              <br />
              $4.99/mon
            </p>
            <p>
              Expiry date
              <br />
              <br />
              24/08/2023
            </p>
            <p>
              {" "}
              Payment
              <br />
              <br />
              Stripe
            </p>
            <p>
              {" "}
              Action
              <br />
              <br />
              UNSUBSCRIBE
            </p>
          </div>
        </div>
        <div className="subscription-3rd-card card">
          <p className="header">Billing History</p>
          <div className="body">
            <p>Jul 24,2023</p>
            <p>USD4.99</p>
            <p>month</p>
            <p>Stripe</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              style={{
                width: "1.5rem",
                fill: " var(--blue-color-200)",
                cursor: "pointer",
              }}
            >
              <path d="M6,20h12V11H6v9Zm1-8h10v7H7v-7Zm4-4H6v-1h5v1ZM14.71,0H4.5c-1.38,0-2.5,1.12-2.5,2.5V24H22V7.29L14.71,0Zm.29,1.71l5.29,5.29h-5.29V1.71ZM3,23V2.5c0-.83,.67-1.5,1.5-1.5H14v7h7v15H3Z" />
            </svg>
            <RemoveRedEyeOutlined
              style={{ color: "var(--blue-color-200)", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
