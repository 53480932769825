import { Box, Typography } from "@mui/material";
import face from "../../../assets/Image/Facebook.PNG";
import google from "../../../assets/Image/google.PNG";
import style from "../Register/Register.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Base_Url, { Apipassword } from "../../../Api/ApiUrl";

const VerifyAccount = () => {
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    }),

    onSubmit: (values) => {
      Base_Url.post("owner/resndVerifyCode", values, {
        headers: {
          Apipassword: Apipassword,
        },
      })
        .then((res) => {
          console.log(res);

          //   localStorage.setItem('token', res.data.token.accessToken)
          //   saveUser()
          toast.success("Check Email");
          navigate("/verifyCode");
        })
        .catch((error) => {
          //  console.log(error.response.data.message);
          toast.error(`${error.response.data.message}`);
        });
    },
  });

  return (
    <Box
      sx={{
        maxWidth: "20%",
        alignContent: "center",
        margin: "0 auto",
        padding: "0",
        justifyContent: "center",
        marginY: "90px",
      }}
    >
      <Typography
        component="h3"
        sx={{ fontSize: "28px", fontWeight: "400", marginLeft: "30px" }}
      >
        Verify Your Account
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            minWidth: "320px",
            height: "50px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            type="text"
            name="email"
            autoComplete="off"
            className={style.register}
            placeholder="Verification code"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            value={formik.values.email}
          />
        </Box>

        <Typography
          component="p"
          sx={{
            fontSize: "12px",
            lineHeight: "19,07px",
            color: "#ff0000",
            fontWeight: "700",
            marginTop: "15px",
            marginLeft: "7px",
          }}
        >
          {" "}
          {formik.touched.email && formik.errors.email}
        </Typography>

        <Box sx={{ minWidth: "280px", height: "55px", marginTop: "30px" }}>
          <button
            type="submit"
            style={{
              backgroundColor: "#19609C",
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "10px",
              color: "white",
              fontSize: "20px",
              fontWeight: "600px",
            }}
          >
            Send Email
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default VerifyAccount;
