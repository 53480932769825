import { Box } from '@mui/material'
import React from 'react'
import Hero from '../components/Components/Hero/Hero'
import About from '../components/Components/about/About'
import Section3 from "../components/Components/section3/Section3"
import Section4 from '../components/Components/section4/Section4'
import Section8 from "../components/Components/New section/Section8"
const HomePage = () => {
  return (
<>

<Hero/>
<About/>
<Section3/>
<Section4/>
<Section8/> 
{/*


*/}


</>
    )
  }


    export default HomePage