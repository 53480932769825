import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../RootLayout/RootLayout";
import RootLayout_v2 from "../RootLayout/RootLayout_v2";
import HomePage from "../pages/HomePage";
import Discover from "../components/Discover/Discover";
import Pricing from "../components/Pricing/Pricing";
import MyLinksPage from "../pages/MyLinksPage";
import Notification from "../pages/Notification/NotificationPage";
import Templates from "../pages/Templates";
import DomainManagement from "../pages/DomainManagement";
import Subscription from "../pages/Subscription";
import RemoveBrand from "../pages/RemoveBrand";
import Help from "../pages/Help/HelpPage";
import MyAccount from "../pages/MyAccount";
import Logout from "../pages/Logout";
import Overview from "../pages/Insights/Overview";
import Sources from "../pages/Insights/Sources";
import Links from "../pages/Insights/Links";
import Socials from "../pages/Insights/Socials";
import Devices from "../pages/Insights/Devices";
import Systems from "../pages/Insights/Systems";
import Locations from "../pages/Insights/Locations";
import RootLayout_v3 from "../RootLayout/RootLayout_v3";
import RootLayout_v4 from "../RootLayout/RootLayout_v4";
import { Navigate, useRoutes } from "react-router-dom";
import Customize from "../pages/Customize/Customize";
import VerifyAccountPage from "../pages/Auth/VerifyAccountPage";
import VerifyAccountEmailPage from "../pages/Auth/VerifyAccountEmailPage";

import LoginPage from "../pages/Auth/LoginPage";
import RegisterPage from "../pages/Auth/RegisterPage";
import TermsPage from "../pages/Terms/Terms";
import PrivacypolicyPage from "../pages/Privacypolicy/PrivacypolicyPage";
import FullTemplate from "../pages/FullTemplate";
import Card from "../components/Components/New section/Card";
const router = createBrowserRouter([
  {
    path: "/:id",
    element: <FullTemplate />,
  },

  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "discover",
        element: <Discover />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "terms",
        element: <TermsPage />,
      },
      {
        path: "privacy",
        element: <PrivacypolicyPage />,
      },
      {
        path: "verifyAccount",
        element: <VerifyAccountPage />,
      },
      {
        path: "verifyCode",
        element: <VerifyAccountEmailPage />,
      },
    ],
  },

  {
    path: "dashboard",
    element: <RootLayout_v2 />,
    children: [
      { index: true, element: <Navigate to="MyLinks" /> },
      {
        path: "MyLinks",
        element: <MyLinksPage />,
      },

      {
        path: "MyLinks/Insight",
        element: <RootLayout_v4 />,
        children: [
          {
            element: <Navigate to="OverView" />,
            index: true,
          },

          {
            path: "Overview",
            element: <Overview />,
          },
          {
            path: "Sources",
            element: <Sources />,
          },
          {
            path: "Links",
            element: <Links />,
          },
          {
            path: "Socials",
            element: <Socials />,
          },
          {
            path: "Devices",
            element: <Devices />,
          },
          {
            path: "Systems",
            element: <Systems />,
          },
          {
            path: "Locations",
            element: <Locations />,
          },
        ],
      },

      {
        path: "MyLinks/Customize/:id",
        element: <Customize />,
      },
      {
        path: "MyLinks/Insight/Overview",
        element: <Overview />,
      },
      {
        path: "MyLinks/Insight/Sources",
        element: <Sources />,
      },
      {
        path: "MyLinks/Insight/Links",
        element: <Links />,
      },
      {
        path: "MyLinks/Insight/Socials",
        element: <Socials />,
      },
      {
        path: "MyLinks/Insight/Devices",
        element: <Devices />,
      },
      {
        path: "MyLinks/Insight/Systems",
        element: <Systems />,
      },
      {
        path: "MyLinks/Insight/Locations",
        element: <Locations />,
      },

      {
        path: "Notification",
        element: <Notification />,
      },
      {
        path: "Templates",
        element: <Templates />,
      },

      {
        // path: "Settings/DomainManagement",
        path: "Settings",
        element: <RootLayout_v3 />,
        children: [
          {
            index: true,
            element: <Navigate to="DomainManagement" />,
          },
          {
            path: "DomainManagement",
            // index: true,
            element: <DomainManagement />,
          },
          {
            path: "Subscription",
            element: <Subscription />,
          },
          {
            path: "RemoveBrand",
            element: <RemoveBrand />,
          },
        ],
      },
      {
        path: "Settings/Subscription",
        element: <Subscription />,
      },
      {
        path: "Settings/RemoveBrand",
        element: <RemoveBrand />,
      },
      {
        path: "Help",
        element: <Help />,
      },
      {
        path: "MyAccount",
        element: <MyAccount />,
      },
      {
        path: "Logout",
        element: <Logout />,
      },
      {
        path: "dashboard/MyLinks/Insight/Overview",
        element: <Overview />,
      },
    ],
  },
  // {
  //   path: 'dashboard/MyLinks/Insight/Overview' , element:<Overview/>
  // }
]);
export default router;
