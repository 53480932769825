import axios from "axios";

const ApiUrl = "https://fastworldkw.net/fastworld_backend/public";

const Base_Url = axios.create({
  baseURL: ApiUrl,
});

export default Base_Url;

export const Apipassword = "fastworld2023@#$fosh$";

export const axiosRequset = axios.create({
  baseURL: ApiUrl,
  headers: {
    Apipassword: "fastworld2023@#$fosh$",
    "Content-Type": "multipart/form-data",
    lang: "ar",
  },
});
const addAuthorizationHeader = (config, token) => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

axiosRequset.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  return addAuthorizationHeader(config, token);
});
