import React, { useEffect, useRef, useState } from "react";
import {
  ButtonAbout,
  ButtonVlog,
  Container,
  Contact,
  Desc,
  DivButtonInfo,
  DivContact,
  DivLinkTree,
  DivProfilePicture,
  DivTitleAndDesc,
  Input,
  ProfilePicture,
  StyledButton,
  Submit,
  TheLink,
  Title,
  SocialCont,
  DivProfilePictureConatinar,
} from "./styles/MobileScreen.styled";
import x from "../assets/Image/124ba71c08f83512d0e478d29b6d16f9.png";
import { useTheme } from "./styles/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation } from "react-router-dom";
import MainTemplat from "./templetComponents/MainTemplat";
// import logo from "../assets/Image/logo-removebg-preview.png";
import img1 from "../assets/Image/true.PNG";
import trueblue from "../assets/Image/trueblue.PNG";
import truegreen from "../assets/Image/truegreen.PNG";
import truebluecircle from "../assets/Image/truebluecircle.PNG";
// import icons1 from "../assets/VarificationIcons/1637326.png";
// import icons2 from "../assets/VarificationIcons/3609317.png";
// import icons3 from "../assets/VarificationIcons/icon-for-quality-15.jpg";
// import icons4 from "../assets/VarificationIcons/verified-icon-15.jpg";
// import icons from "../assets/VarificationIcons/Group1.svg";
import icons1 from "../assets/VarificationIcons/Group2.svg";
import icons2 from "../assets/VarificationIcons/Group3.svg";
import icons3 from "../assets/VarificationIcons/Group4.svg";
import icons4 from "../assets/VarificationIcons/Group1.svg";
import logo from "../assets/Image/logo-removebg-preview.png";
import logos from "../assets/Image/logos.jpg";
import { Box } from "@mui/material";
const MainStructureTemplate = () => {
  const { currentTheme } = useTheme();
  console.log(currentTheme);
  const location = useLocation();
  const imgRef = useRef();
  const iconRef = useRef();
  const [iconLeft, setIconLeft] = useState();
  const [iconTop, setIconTop] = useState();
  const [iconSize, setIconSize] = useState("22px");
  console.log(iconSize);
  const getContant = (id) => {
    return currentTheme.Contents.find((item) => {
      if (item.id == id) {
        return item;
      }
    });
  };
  const getimage = (img) => {
    // if (img == "img1") {
    //   return img1;
    // } else if (img == "img2") {
    //   return trueblue;
    // } else if (img == "img3") {
    //   return truegreen;
    // } else if (img == "img4") {
    //   return truebluecircle;
    // } else {
    //   return;
    // }
    // console.log(img);
    if (img == "icons1") {
      return icons1;
    } else if (img == "icons2") {
      return icons2;
    } else if (img == "icons3") {
      return icons3;
    } else if (img == "icons4") {
      return icons4;
    } else if (img == null) {
      return "";
    }
  };

  useEffect(() => {
    const boxRect = imgRef?.current?.getBoundingClientRect();
    const iconRect = iconRef?.current?.getBoundingClientRect();
    const boxWidth = boxRect?.width;
    const iconWidth = iconRect?.width;
    const boxHeight = boxRect?.height;
    const borderSize = +currentTheme?.ProfilePicture?.borderSize / 2;

    setIconTop(boxHeight / 2 - 10);
    setIconLeft(borderSize ? +boxWidth - 10 - +borderSize : +boxWidth - 10);

    // handle modify icon size
    const maxSize = 22; // Maximum icon size
    let minSize = 0;
    if (currentTheme?.ProfilePicture?.size) {
      if (currentTheme?.ProfilePicture?.size > 50) {
        minSize = 15;
      } else if (currentTheme?.ProfilePicture?.size > 20) {
        minSize = 10;
      } else {
        setIconSize("0px");
        return;
      }
    }
    // const minSize = 15; // Minimum icon size
    const sizeRange = maxSize - minSize; // Range of sizes

    const currentSize = currentTheme?.ProfilePicture?.size || 300; // Current icon size

    // Calculate the new icon size within the specified range
    const newSize = (currentSize / 300) * sizeRange + minSize;

    setIconSize(`${newSize}px`);
  }, [
    currentTheme?.ProfilePicture?.size,
    currentTheme?.ProfilePicture?.borderRadius,
    currentTheme?.ProfilePicture?.borderSize,
    currentTheme?.DivProfilePicture?.design?.flexDirectionD,
  ]);
  // useEffect(() => {
  //   const boxRect = imgRef.current.getBoundingClientRect();
  //   const boxWidth = boxRect.width;
  //   const boxHeight = boxRect.height;
  //   const borderSize = +currentTheme?.ProfilePicture?.borderSize / 2;

  //   setIconTop(boxHeight / 2 - 14);
  //   setIconLeft(borderSize ? +boxWidth - 14 - +borderSize : +boxWidth - 14);
  // }, [
  //   currentTheme?.ProfilePicture?.size,
  //   currentTheme?.ProfilePicture?.borderRadius,
  //   currentTheme?.ProfilePicture?.borderSize,
  //   currentTheme?.DivProfilePicture?.design?.flexDirectionD,
  // ]);
  useEffect(() => {
    // handle change icon size
    // console.log((currentTheme?.ProfilePicture?.size / 300) * 40);
    // setIconSize((prev) => {
    //   if (currentTheme?.ProfilePicture?.size > 150) {
    //     return `${(currentTheme?.ProfilePicture?.size / 300) * 22}px`;
    //   } else {
    //     console.log(currentTheme?.ProfilePicture?.size / 300);
    //     return `${(currentTheme?.ProfilePicture?.size / 300) * 22}px`;
    //   }
    // });
    // const maxSize = 22; // Maximum icon size
    // let minSize;
    // if (currentTheme?.ProfilePicture?.size > 50) {
    //   minSize = 15;
    // } else if (currentTheme?.ProfilePicture?.size > 20) {
    //   minSize = 10;
    // } else {
    //   setIconSize("0px");
    //   return;
    // }
    // // const minSize = 15; // Minimum icon size
    // const sizeRange = maxSize - minSize; // Range of sizes
    // const currentSize = currentTheme?.ProfilePicture?.size || 300; // Current icon size
    // // Calculate the new icon size within the specified range
    // const newSize = (currentSize / 300) * sizeRange + minSize;
    // setIconSize(`${newSize}px`);
  }, [currentTheme?.ProfilePicture?.size]);
  useEffect(() => {
    if (!location.pathname.startsWith("/dashboard")) {
      console.log(
        currentTheme.Container.backgroundImage
          ? `url(${currentTheme.Container.backgroundImage})`
          : `${currentTheme.Container.background}`
      );
      // document.body.style.background = currentTheme.Container.backgroundImage
      //   ? `url(${currentTheme.Container.backgroundImage})`
      //   : `${currentTheme.Container.background}`;
    }
    // document.body.style.background = "blue";
  }, []);
  return (
    <>
      <Container isDashboard={location.pathname.startsWith("/dashboard")}>
        <Box
          sx={{
            // position: "absolute",
            width: "100%",
            // height: "100%",
            // top: "0",
            // left: "0",
            // backgroundColor: "transparent",
            // backdropFilter: "blur(10px)",
            // width: "100%",
            // height: "100%",
          }}
        >
          {/* </Box> */}
          <Contact>
            <DivProfilePictureConatinar
              style={{ maxWidth: "450px", margin: "auto" }}
            >
              <DivProfilePicture>
                <ProfilePicture
                  ref={imgRef}
                  src={currentTheme?.img ? currentTheme?.img : ""}
                />
                {currentTheme?.verified && (
                  <i
                    ref={iconRef}
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      borderRadius: "50%",
                      left: `${iconLeft ? iconLeft : 200 - 15}px`,
                      // right: `${iconRight ? iconRight : 200 - 15}px`,
                      top: `${iconTop ? iconTop : 200 - 15}px`,
                    }}
                  >
                    <img
                      // style={{ width: "22px", background: "transparent" }}
                      style={{
                        width: iconSize ? iconSize : "22px",
                        background: "transparent",
                      }}
                      src={getimage(currentTheme.verified)}
                      alt=""
                    />
                  </i>
                )}
              </DivProfilePicture>
              <DivTitleAndDesc>
                <Title>{currentTheme?.data?.title}</Title>
                <Desc>{currentTheme?.data?.description}</Desc>
              </DivTitleAndDesc>
            </DivProfilePictureConatinar>

            <Box
            // sx={{
            //   overflowY: "scroll",
            //   maxHeight: "230px",
            //   "&::-webkit-scrollbar": {
            //     width: "0.1em",
            //     backgroundColor: "red",
            //   },
            //   "&::-webkit-scrollbar-thumb": {
            //     backgroundColor: "#888",
            //   },
            //   "::-webkit-scrollbar-track": {
            //     backgroundColor: "#F5F5F5",
            //   },
            // }}
            >
              {currentTheme?.orders &&
                currentTheme?.orders.length > 0 &&
                currentTheme?.orders?.map((order) => (
                  <MainTemplat
                    componentName={order.split("_")[0]}
                    data={getContant(
                      order.split("_")[0] == "soial" ||
                        order.split("_")[0] == "youtub" ||
                        order.split("_")[0] == "facebook"
                        ? order.split("_")[2]
                        : order.split("_")[1]
                    )}
                  />
                ))}
            </Box>
            {/* <div>
            {currentTheme.}
            <MainTemplat name={currentTheme?.componentName} data={currentTheme} />
          </div> */}
            {/* <SocialCont style={{ color: "white" }} className="socil-align-con">
            <FontAwesomeIcon style={{ color: "white" }} icon={faFacebook} />
            <FontAwesomeIcon icon={faInstagram} />
            <FontAwesomeIcon icon={faTwitter} />
            <FontAwesomeIcon icon={faTwitter} />
          </SocialCont> */}

            {/* <div className="w-100 mt-3">
            <iframe
              width="95%"
              height="300"
              className="vedio"
              src="https://www.youtube.com/embed/JkjPkX-2HQ8?si=OnJKpcApuMyg7TBD"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div> */}

            {/* <DivButtonInfo> */}
            {/* <h3 style={{ fontWeight: "bolder", marginBottom: "1.5rem" }}>
              WHATEVER YOU WHANT
            </h3> */}
            {/* <StyledButton>Online store</StyledButton>
            <StyledButton>Contact us</StyledButton> */}
            {/* </DivButtonInfo> */}
            {/* <DivContact>
            <Input type="email" placeholder="Email" />
            <Input type="text" placeholder="message" />
            <Submit>Submit</Submit>
          </DivContact> */}
            {/* <DivLinkTree>
            <StyledButton src="">Facebook</StyledButton>
            <StyledButton src="">Facebook</StyledButton>
            <StyledButton src="">Facebook</StyledButton>
            <StyledButton src="">Facebook</StyledButton>
          </DivLinkTree> */}
            {/* {!currentTheme?.removeBrand == 0 && ( */}
            {/* <FontAwesomeIcon icon={faTelegram} /> */}
            {!currentTheme?.removeBrand && (
              <Link to="/">
                <h1
                  style={{
                    // textAlign: "center",
                    corser: "pointer",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  {" "}
                  {/* Fast World */}
                  <img
                    src={logos}
                    alt=""
                    style={{
                      width: "80px",
                      height: "auto",
                      display: "block",
                      margin: "auto",
                      borderRadius: "50%",
                    }}
                  />
                </h1>
              </Link>
            )}
          </Contact>
        </Box>
      </Container>
    </>
  );
};

export default MainStructureTemplate;
