import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import { axiosRequset } from "../../../Api/ApiUrl";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
const PopUpCrop = ({ show, setShow, imgURL, onSaveHandler }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [size, setSize] = useState(250);
  const EditorRef = useRef(null);
  const showCroppedImage = async () => {
    if (EditorRef.current) {
      const img = EditorRef.current.getImage().toDataURL();
      return img;
    }
  };

  const handleClose = () => setShow(false);
  const handleDelete = () => {};

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title"> Map </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                width: "500px",
                height: "450px",
              }}
              className="w-full h-full flex flex-col items-center justify-center"
            >
              <AvatarEditor
                ref={EditorRef}
                image={imgURL}
                width={400}
                height={400}
                border={50}
                scale={parseFloat(size) / 250}
                color={[255, 255, 255, 0.6]}
              />
            </div>
            <div>
              <input
                type="range"
                min="250"
                max="400"
                step="5"
                value={size}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSize(e.target.value);
                }}
                style={{ width: "100%", marginTop: "100px" }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ color: "red" }}
            onClick={async () => onSaveHandler(await showCroppedImage())}
            autoFocus
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUpCrop;
