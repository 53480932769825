import {
  FacebookRounded,
  Instagram,
  KeyboardArrowDownOutlined,
  NetworkCellRounded,
  NetworkPingOutlined,
  Twitter,
  WidthWide,
  Wifi,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PieChart = () => {
  const labelsArray = ["Direct", "Facebook", "lnstagram", "Twitter", "Other"];
  const rate = [20, 30, 40, 45, 55];
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    console.log(setIsPopupOpen);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const data = [
    {
      date: "08-07",
      Report: 1,
    },
    {
      date: "08-08",
      Report: 0,
    },
    {
      date: "08-09",
      Report: 0.5,
    },
    {
      date: "08-10",
      Report: 0,
    },
    {
      date: "08-11",
      Report: 0,
    },
    {
      date: "08-12",
      Report: 0,
    },
    {
      date: "08-13",
      Report: 1.5,
    },
    {
      date: "08-14",
      Report: 0,
    },
  ];
  // const [labelsArray, setLabelsArray]= useState([]);
  // const [rate, setRate]= useState([]);

  // useEffect( ()=>{
  //  const getdata= async()=>{
  //       const labelsArraye=[];
  //       const getrate=[];

  //     const reqData= await fetch("http://localhost/reactgraphtutorial/rates");
  //     const resData= await reqData.json();
  //     for(let i=0; i<resData.length; i++)
  //     {
  //         labelsArray.push(resData[i].country);
  //         getrate.push(parseInt(resData[i].rates));
  //     }
  //     setLabelsArray(labelsArray);
  //     setRate(getrate);

  //  }
  //  getdata();
  // },[]);

  return (
    <React.Fragment>
      <div className="container-fluid pie-chart-cont ">
        <h1>Sources</h1>
        <Chart
          type="donut"
          width={1000}
          height={550}
          series={rate}
          options={{
            labels: labelsArray,
            title: {
              text: " ",
              align: "left",
              fontSize: "30px",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      //formatter: () => '343',
                      fontSize: 30,
                      color: "var(--blue-color-200)",
                    },
                  },
                },
              },
            },

            dataLabels: {
              enabled: true,
            },
          }}
        />
      </div>
      <br /> <br />
      <br />
      <br /> <br />
      <br />
      <div>
        <table className="sources-table">
          <thead>
            <td>Details</td>
            <td>
              <div className="piechart-info" onClick={openPopup.bind(this)}>
                <span> Last 7 Days</span>
                <KeyboardArrowDownOutlined className="piechart-sources-icon" />
              </div>
            </td>
          </thead>

          <tbody>
            <tr>
              <td>
                (5)<span>Referrers</span>
              </td>
              <td>0</td>
              <td></td>
            </tr>
            <hr />
            <tr>
              <td>
                #-<span>Referrer</span>
              </td>
              <td>Visits</td>
              <td>Percentage</td>
            </tr>
            <hr />
            <tr>
              <td>
                1- <FacebookRounded />
                <span>Facebook </span>
              </td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>
                2- <Instagram />
                <span>Instagram </span>
              </td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>
                3- <Twitter />
                <span>Twitter</span>
              </td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>
                4- <NetworkPingOutlined />
                <span>Other</span>
              </td>
              <td>0</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>
                5- <WidthWide />
                <span>Direct</span>
              </td>
              <td>0</td>
              <td>0%</td>
            </tr>
          </tbody>
        </table>

        {isPopupOpen && (
          <div className="overlay" onClick={closePopup}>
            <div className="chart-info-breif">
              <div style={{ fontSize: "3rem" }}>Report Breif</div>
              {data.map((entry, index) => (
                <div className="">
                  <div key={index} className="info-data">
                    {`${entry.date}: ${entry.Report}`}
                  </div>
                  <hr className="info-hr" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default PieChart;
