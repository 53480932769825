import React from "react";
import Insightbar from "../../components/Insight/Insightbar";
import UsersChart from "../../components/Insight/Overview/UserChart";
import VisitsChart from "../../components/Insight/Overview/VisitChart";
import ClicksChart from "../../components/Insight/Overview/ClicksChart";
import Report from "../../components/Insight/Overview/Report";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import Back from "../../components/Back";

// const BackButton = styled(Link)`
//   display: flex;
//   align-items: center;
//   margin-top: 8px;
// `;
// const BackIcon = styled(KeyboardBackspace)`
//   color: white;
//   width: 40px;
//   height: 40px;
// `;
const Overview = () => {
  return (
    <div style={{ background: "white" }}>
      <div
        className="title"
        style={{
          top: "-416px",
          left: "-53%",
          // gap: 50px;
          justifyContent: "space-between",
          width: "500px",
          /* left: -158px; */
        }}
      >
        {" "}
        <Back link="/dashboard/MyLinks" />
        Insight
      </div>

      <div className="main-cont">
        <div className="insight-content">
          <Report />
          <UsersChart />
          <VisitsChart />
          <ClicksChart />
        </div>
      </div>
    </div>
  );
};

export default Overview;
