import React from 'react'
import Help from '../../components/Help/Help'

const HelpPage = () => {
  return (
<div >

<div className='title'> Help</div>
<div className='main-cont'>

<Help/>
</div>
</div>

    )
}

export default HelpPage