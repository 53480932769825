import React, { useEffect, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useTheme } from "../../../../styles/ThemeContext";
const Gradient = () => {
  const { currentTheme, toggleTheme, setCurrentTheme } = useTheme();
  const [currentColor, setCurrentColor] = useState();
  // ========================color-picker==============================
  const [selectedColor1, setSelectedColor1] = useState("#000000"); // Initial color is white
  const [selectedColor2, setSelectedColor2] = useState("#ffffff"); // Initial color is white

  // ============================================================

  // handle Change object in object
  const handleChangeObject = (d) => {
    // console.log(d, currentTheme);
    setCurrentTheme({
      ...currentTheme,
      Container: { ...currentTheme.Container, backgroundImage: "", ...d },
    });
  };

  const handleColorChange1 = (e) => {
    setSelectedColor1(e.target.value);
    handleChangeObject({
      background: `linear-gradient(to bottom,${e.target.value}, ${selectedColor2})`,
    });
  };

  // ============================================================
  // ========================color-picker==============================

  const handleColorChange2 = (e) => {
    setSelectedColor2(e.target.value);
    handleChangeObject({
      background: `linear-gradient(to bottom,${selectedColor1}, ${e.target.value})`,
    });
  };
  // useEffect(() => {
  //   if (selectedColor1 || selectedColor2) {
  //     handleChangeObject({
  //       background: `linear-gradient(to bottom,${selectedColor1}, ${selectedColor2})`,
  //     });
  //   }
  // }, [selectedColor1, selectedColor2]);
  return (
    <div>
      <div>
        <div className="gradient-cir-cont">
          {/* 
          
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>

          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker color={color} onChange={handleChange} />
            </div>
          ) : null}

          <span
            className="gradient-color-sw"
            style={PicheaderStyle}
            onClick={() => {
              handleChangeObject({screenBgc:PicColor})

            }}
          ></span> */}

          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom, #EF629F,#EECDA3)",
              borderColor: currentColor === 1 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom, #EF629F,#EECDA3)",
              });
              setCurrentColor(1);
            }}
          ></span>

          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom, #F64F59,#C471ED,#12C2E9)",
              borderColor: currentColor === 2 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background:
                  "linear-gradient(to bottom, #F64F59,#C471ED,#12C2E9)",
              });
              setCurrentColor(2);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#FDBB2D,#22C1C3)",
              borderColor: currentColor === 3 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#FDBB2D,#22C1C3)",
              });
              setCurrentColor(3);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#1D2671,#C33764)",
              borderColor: currentColor === 4 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#1D2671,#C33764)",
              });
              setCurrentColor(4);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#2C3E50,#BDC3C7)",
              borderColor: currentColor === 5 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#2C3E50,#BDC3C7)",
              });
              setCurrentColor(5);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#B91D73,#F953C6)",
              borderColor: currentColor === 6 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#B91D73,#F953C6)",
              });
              setCurrentColor(6);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#0083B0,#00B4DB)",
              borderColor: currentColor === 7 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#0083B0,#00B4DB)",
              });
              setCurrentColor(7);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom, #185A9D,#43CEA2)",
              borderColor: currentColor === 8 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom, #185A9D,#43CEA2)",
              });
              setCurrentColor(8);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom, #870000,#190A05)",
              borderColor: currentColor === 9 ? "#007aff" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom, #870000,#190A05)",
              });
              setCurrentColor(9);
            }}
          ></span>
          <span
            className="gradient-color-sw"
            style={{
              background: "linear-gradient(to bottom,#EEF2F3,#8E9EAB)",
              borderColor: currentColor === 10 ? "#007aff !important" : "",
            }}
            onClick={() => {
              handleChangeObject({
                background: "linear-gradient(to bottom,#EEF2F3,#8E9EAB)",
              });
              setCurrentColor(10);
            }}
          ></span>
        </div>

        {/* =============================== */}

        {/* ================================= */}

        <h3>Select color</h3>
        <input
          type="color"
          id="colorPicker1"
          // value={selectedColor1}
          onChange={handleColorChange1}
        />

        <input
          type="color"
          id="colorPicker2"
          // value={selectedColor2}
          onChange={handleColorChange2}
        />
      </div>
    </div>
  );
};

export default Gradient;
