import React from 'react'
import Login from '../../components/Auth/Login/Login'

const LoginPage = () => {
  return (


    <Login/>

    )
}

export default LoginPage