import React from 'react'
import "./section8.css"
import image1 from "../../../assets/newsection/Ellipse 17.png"
import image2 from "../../../assets/newsection/Ellipse 16.png"
import image3 from "../../../assets/newsection/Polygon 1.png"
import image4 from "../../../assets/newsection/Rectangle 29.png"
import Card from './Card'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Section8 = () => {
  var settings = {
    className: "Slider-center-image",
    dots: false,
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect:true,
    fade:true,
    easing:"linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
      // Add more breakpoints and settings as needed
    ]
   
  };
  return (
    <div className="section8">
        <h1>Testimonial</h1>
        <div className="">
            <img src={image1} className='image-shape' alt="" />
        </div>
        <div className="circle"></div>
         <div className="card-item">
          <Card name={"Tark Ehab"}/>
         </div>
        <div className="card-slider">
        <Slider {...settings}>
     
        <div>
        <Card name={"Yousef Khaled"}/>
        </div>
        <div>
        <Card name={"hamada thabt"}/>
        </div>
        <div>
          <Card name={"abdalrahman hamed"}/>
        </div>
  
    </Slider>
        </div>
  
         <img src={image3} className='rectangle' alt="" />
         <div className="dots1">
          <span></span>
          <span></span>
          <span></span> 

         </div>
    </div>
  )
}

export default Section8