import React, { useEffect, useState } from "react";
import HeaderText from "./HeaderText";
import ButtonGroup from "./ButtonGroup";
import VideoComponent from "./VideoComponent";
import { axiosRequset } from "../../../Api/ApiUrl";
import SocialMedia from "./SocialMedia";
import PopUpDelete from "../PopUp/PopUpDelete";
import Request from "./Request";
import FormComponent from "./FormComponent";
import YoutubeSubscribe from "./YoutubeSubscribe";
import FacebookPageC from "./FacebookPageC";
import PodcastC from "./PodcastC";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const DynamicComponentRenderer = ({
  componentName,
  data,
  currentTheme,
  setCurrentTheme,
  index,
  expanded,
  handleChange,
}) => {
  // Map component names to actual components
  const [showPopUpDelete, setShowPopUpDelete] = useState();
  const [dataDlt, setDataDlt] = useState({});
  // console.log(componentName, data);
  const componentMap = {
    header: HeaderText,
    buttons: ButtonGroup,
    video: VideoComponent,
    soial: SocialMedia,
    request: Request,
    form: FormComponent,
    youtub: YoutubeSubscribe,
    facebook: FacebookPageC,
    podcast: PodcastC,
    // Add more dynamic components as needed
  };

  // Get the component based on the provided name
  const DynamicComponent = componentMap[componentName];
  // console.log("DynamicComponent", DynamicComponent, componentName);
  const onDelete = (event) => {
    event.stopPropagation();
    // console.log("delete", data);
    setDataDlt({ id: data.id });
    setShowPopUpDelete(true);
    // Handle component deletion
    // axiosRequset
    //   .post("/owner/Content/delete", {
    //     id: data.id,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  // Render the dynamically selected component


 
  const App = () => {
    const [showDynamicComponent, setShowDynamicComponent] = useState(false);
  
    const handleButtonClick = () => {
      setShowDynamicComponent(true);
    };
  
    return (
      <div>
        <button onClick={handleButtonClick}>Show Dynamic Component</button>
        {showDynamicComponent && <DynamicComponent />}
      </div>
    );
  };



















  return DynamicComponent ? (
    <>
      <DynamicComponent
        data={data}
        onDelete={onDelete}
        currentTheme={currentTheme}
        setCurrentTheme={setCurrentTheme}
        index={index}
        expanded={expanded}
        handleChange={handleChange}
      />
      <PopUpDelete
        show={showPopUpDelete}
        setShow={setShowPopUpDelete}
        url={"/owner/Content/delete"}
        message={{ data: "Are you sure you want to delete this sections?" }}
        data={dataDlt}
        currentTheme={currentTheme}
        setCurrentTheme={setCurrentTheme}
      />
    </>
  ) : (
    <div>we are working on it</div>
  );
};

export default DynamicComponentRenderer;
