import React, { useEffect, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Social1 from "../../../../assets/Images/social1_ex.png";
import Social2 from "../../../../assets/Images/social2_ex.png";
import Social3 from "../../../../assets/Images/social3_ex.png";
import Social4 from "../../../../assets/Images/social4_ex.png";
import Social5 from "../../../../assets/Images/social5_ex.png";
import Social6 from "../../../../assets/Images/social6_ex.png";
import { Image } from "react-bootstrap";
import SocialAlign1 from "../../../../assets/Images/socialAlign1.png";
import SocialAlign2 from "../../../../assets/Images/socialAlign2.png";
import SocialAlign3 from "../../../../assets/Images/socialAlign3.png";
import { useTheme } from "../../../styles/ThemeContext";
import { Box, Grid, Input } from "@mui/material";

const Thumbnail = () => {
  // ========================color-style-picker==============================
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const { currentTheme, setCurrentTheme } = useTheme();

  console.log(currentTheme?.StyledButton);
  console.log(currentTheme?.StyledButton.color);
  const [currentSocial, setCurrentSocial] = useState();
  const [currentColor, setCurrentColor] = useState();
  const [currentBox, setCurrentBox] = useState(() => {
    if (currentTheme.StyledButton.thumbnail?.borderRadius == "0px") {
      return 1;
    } else if (currentTheme.StyledButton.thumbnail?.borderRadius == "8px") {
      return 2;
    } else {
      return 3;
    }
  });
  const [iconSize, setIconSize] = useState();
  const handleIconSizeChange = (value) => {
    console.log(value);
    setIconSize(value);
    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        thumbnail: {
          ...currentTheme.StyledButton.thumbnail,
          size: value,
        },
      },
    });
  };
  const handleChangeLayout = (newValue) => {
    console.log(newValue);
    setCurrentTheme({
      ...currentTheme,
      StyledButton: {
        ...currentTheme.StyledButton,
        thumbnail: {
          ...currentTheme.StyledButton.thumbnail,
          ...newValue,
        },
      },
    });
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.rgb);
    handleChangeLayout({
      color: newColor.hex,
    });
  };

  const swatchStyle = {
    width: "26px",
    height: "26px",
    display: "block",
    border: "4px solid var(--gray-color-200)",
    borderRadius: "2rem",
    margin: "7px 0px 6px 10px",
    background: `conic-gradient(
      cyan 0%,
      palegoldenrod 16.67%,
      lime 33.33%,
      red 50%,
      blue 66.67%,
      yellow 83.33%,
      orange 100%
    )`,
    cursor: "pointer",
  };

  const styles = reactCSS({
    default: {
      color: swatchStyle,
      popover: {
        position: "absolute",
        zIndex: "2",
        top: "-100%",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const PicheaderStyle = {
    color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  };

  const PicColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  useEffect(() => {
    if (currentTheme) {
      setIconSize(
        currentTheme?.StyledButton?.thumbnail?.size
          ? currentTheme?.StyledButton?.thumbnail?.size
          : 34
      );
    }
  }, []);
  // ===================================================
  return (
    <div>
      <div className="font-type-header">Style</div>
      <div
        style={{ display: "flex", gap: "1.5rem" }}
        className="social-icons-social-block"
      >
        <Image
          src={Social1}
          style={{
            cursor: "pointer",
            width: "40px",
            borderRadius: "50%",
            border:
              currentSocial === 1 ||
              currentTheme?.StyledButton?.thumbnail?.styleType == 0
                ? "3px solid rgb(0, 122, 255)"
                : "",
          }}
          onClick={() => {
            handleChangeLayout({ styleType: 0 });
            setCurrentSocial(1);
          }}
        ></Image>
        <Image
          src={Social2}
          style={{
            cursor: "pointer",
            width: "40px",
            borderRadius: "50%",

            border:
              currentSocial == 2 ||
              currentTheme?.StyledButton?.thumbnail?.styleType == 2
                ? "3px solid #007aff"
                : "",
          }}
          onClick={() => {
            handleChangeLayout({ styleType: 2 });
            setCurrentSocial(2);
          }}
        ></Image>
        <Image
          src={Social4}
          style={{
            cursor: "pointer",
            width: "40px",
            borderRadius: "50%",
            border:
              currentSocial === 3 ||
              currentTheme?.StyledButton?.thumbnail?.styleType == 6
                ? "3px solid rgb(0, 122, 255)"
                : "",
          }}
          onClick={() => {
            handleChangeLayout({ styleType: 6 });
            setCurrentSocial(3);
          }}
        ></Image>
      </div>
      <div style={{ marginTop: "50px" }}>
        <span>Size</span>
      </div>
      {/* test  */}
      <div className="border-control">
        <input
          type="range"
          min="0"
          max="34"
          value={iconSize}
          onChange={(e) => handleIconSizeChange(e.target.value)}
          style={{ width: "140%" }}
          className="size-slider"
        />

        <Box sx={{ width: 250 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Input
                value={iconSize}
                size="small"
                onChange={(e) => handleIconSizeChange(e.target.value)}
                inputProps={{
                  step: 3,
                  min: 0,
                  max: 34,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* test  */}
      {currentTheme?.StyledButton?.thumbnail?.styleType == 2 ||
      currentTheme?.StyledButton?.thumbnail?.styleType == 6 ? (
        <>
          <div>
            <div className="font-type-header">Color</div>
            <div
              className="font-type-cir-cont"
              style={{ position: "relative" }}
            >
              <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
              </div>

              {displayColorPicker ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={handleClose} />
                  <SketchPicker color={color} onChange={handleChange} />
                </div>
              ) : null}

              <span
                className="texxt-color-sw"
                style={{
                  ...PicheaderStyle,
                  border:
                    currentColor == 1 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      PicColor.toLowerCase()
                      ? "3px solid #007aff"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: PicColor });
                  setCurrentColor(1);
                }}
              ></span>

              <span
                className="texxt-color-sw"
                style={{
                  background: "#FFFFFF",
                  border:
                    currentColor == 2 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#FFFFFF".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "3px solid #eee",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#FFFFFF" });
                  setCurrentColor(2);
                }}
              ></span>

              <span
                className="texxt-color-sw"
                style={{
                  background: "black",
                  border:
                    currentColor == 3 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#000000".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#000000" });
                  setCurrentColor(3);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "gray",
                  border:
                    currentColor == 4 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#808080".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#808080" });
                  setCurrentColor(4);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "blue",
                  border:
                    currentColor == 5 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#007aff".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#007aff" });
                  setCurrentColor(5);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "brown",
                  border:
                    currentColor == 6 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#a52a2a".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#a52a2a" });
                  setCurrentColor(6);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "red",
                  border:
                    currentColor == 7 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#ff0000".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#ff0000" });
                  setCurrentColor(7);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "green",
                  border:
                    currentColor == 8 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#008000".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#008000" });
                  setCurrentColor(8);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#FFE5C1",
                  border:
                    currentColor == 9 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#ffe5c1".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#ffe5c1" });
                  setCurrentColor(9);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#E38CBA",
                  border:
                    currentColor == 10 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#E38CBA".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#E38CBA" });
                  setCurrentColor(10);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#19609C",
                  border:
                    currentColor == 11 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#19609C".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#19609C" });
                  setCurrentColor(11);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#51756B",
                  border:
                    currentColor == 12 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#51756B".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#51756B" });
                  setCurrentColor(12);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#897046",
                  border:
                    currentColor == 13 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#897046".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#897046" });
                  setCurrentColor(13);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#FFE5E7",
                  border:
                    currentColor == 14 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#FFE5E7".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#FFE5E7" });
                  setCurrentColor(14);
                }}
              ></span>
              <span
                className="texxt-color-sw"
                style={{
                  background: "#EFE5C1",
                  border:
                    currentColor == 15 ||
                    currentTheme?.StyledButton.thumbnail.color?.toLowerCase() ===
                      "#EFE5C1".toLowerCase()
                      ? "3px solid rgb(0, 122, 255)"
                      : "",
                }}
                onClick={() => {
                  handleChangeLayout({ color: "#EFE5C1" });
                  setCurrentColor(15);
                }}
              ></span>
            </div>
          </div>
        </>
      ) : null}
      <div className="font-type-header"></div>
      <div
        className="social-algin-con"
        style={{ display: "flex", gap: "3rem", marginTop: "50px" }}
      >
        <div
          style={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
            border:
              currentBox == 1
                ? "4px solid rgb(0, 122, 255)"
                : "4px solid #626262",
          }}
          onClick={() => {
            handleChangeLayout({ borderRadius: "0px" });
            setCurrentBox(1);
          }}
        ></div>
        <div
          style={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
            borderRadius: "8px",
            border:
              currentBox == 2
                ? "4px solid rgb(0, 122, 255)"
                : "4px solid #626262",
          }}
          onClick={() => {
            handleChangeLayout({ borderRadius: "8px" });
            setCurrentBox(2);
          }}
        ></div>
        <div
          style={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border:
              currentBox == 3
                ? "4px solid rgb(0, 122, 255)"
                : "4px solid #626262",
          }}
          onClick={() => {
            handleChangeLayout({ borderRadius: "50%" });
            setCurrentBox(3);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Thumbnail;
