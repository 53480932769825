import React, { useContext, useState } from "react";

const ThemeContext = React.createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProviders = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState();

  const toggleTheme = (theme) => {
    setCurrentTheme(theme);
  };

  const value = { currentTheme, setCurrentTheme, toggleTheme };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
