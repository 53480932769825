import React from 'react'
import Privacypolicy from '../../components/Privacypolicy/Privacypolicy'

const PrivacypolicyPage = () => {
  return (

    <Privacypolicy/>

    )
}

export default PrivacypolicyPage