import React, { useEffect, useState } from "react";
import "./footer.css";
import logo from "../../../assets/logo 1.png";
import arrow from "../../../assets/arrow-down-circle.png"
import $ from 'jquery';
const Footer = () => {
    const handleClick=()=>{
        $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
    }
  return (
    <div className="footer">
      <div className="all-footer">
        <div className="first">
          <img src={logo} alt="" />
          <div className="text">
           <p>
           "Fast World: Your go-to solution for effortless link management.
            Simplify sharing, optimize branding, and collaborate seamlessly.
            Join us in revolutionizing the way you handle links online."
           </p>
          </div>
          <div className="social-footer">
          <i class="fa-brands fa-square-whatsapp"></i>
          <i class="fa-brands fa-square-facebook"></i>
          <i class="fa-brands fa-youtube"></i>
          <i class="fa-brands fa-linkedin"></i>
          <i class="fa-brands fa-twitter"></i>
          </div>
        </div>
        <div className="two">
            <h2>Pricing</h2>
            <h2>Free</h2>
            <h2>Pro</h2>
            <h2>Free Trial</h2>
        </div>
        <div className="three">
            <h2>Support</h2>
            <h2>Help Center</h2>
            <h2>Report a violation</h2>
        </div>
        <div className="four">
            <h2>Legal</h2>
            <h2>Terms & Conditions</h2>
            <h2>Privacy Policy</h2>
    
        </div>
        <img src={arrow} className="arrow" onClick={()=>handleClick()} alt="" />
      </div>
      <p className="copyRight">© 2024 Fast World Kuwait . All rights reserved.</p>
    </div>
  );
};

export default Footer;
