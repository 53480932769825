import React, { useState, useEffect } from "react";
import { ThemeButton, ThemeContainer } from "../styles/ThemeSwitching.styled";
import {
  secondTheme,
  firstTheme,
  sixTheme,
  forthTheme,
  thirdTheme,
  fifthTheme,
} from "../styles/Theme.styled";
import { useTheme } from "../styles/ThemeContext"; // Correct the import
import { Link, useNavigate } from "react-router-dom";
import reactCSS from "reactcss";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "styled-components";
import Profile from "./Appearance/More/Profile";
import Social from "./Appearance/More/Social";
import ColorPage from "./Appearance/Background/ColorPage";
import ImagePage from "./Appearance/Background/ImagePage";
import VideoPage from "./Appearance/Background/VideoPage.jsx";
import Thumbnail from "./Appearance/Block/Thumbnail";
import ActionButton from "./Appearance/Block/ActionButton";
import ColorPageBlock from "./Appearance/Block/ColorPage";
import StylePage from "./Appearance/Block/StylePage";
import Fonts from "./Appearance/Fonts/Fonts";
import { useMyTemplateContext } from "../styles/MytempletContext.js";
import firsttImage from "../../assets/Images/AppearanceThems/1stTheme.PNG";
import { GlobalStyles } from "../styles/Global.js";
import Base_Url, { Apipassword, axiosRequset } from "../../Api/ApiUrl.js";
import { toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../Dnd/StrictModeDroppable.jsx";

const swatchStyle = {
  width: "50px",
  height: "50px",
  display: "block",
  border: "4px solid var(--gray-color-200)",
  borderRadius: "2rem",
  margin: "7px 0px 6px 10px",
  background: `conic-gradient(
    cyan 0%,
    palegoldenrod 16.67%,
    lime 33.33%,
    red 50%,
    blue 66.67%,
    yellow 83.33%,
    orange 100%
  )`,
  cursor: "pointer",
};

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--gray-color-200);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 20%;
  padding: 20px 25px;
  &:hover {
    color: var(--blue-color-200);
  }
  &:focus {
    // color: #fff;
    // outline: 3px solid #80BFFF;
    // width: 100px;
  }
  &.${tabClasses.selected} {
    color: var(--blue-color-200);
  }
  &.${buttonClasses.disabled} {
    // opacity: 0.5;
    cursor: not-allowed;
  }
`;
const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
// width: 104%;
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
padding: 20px 12px;
border-radius: 12px;
// opacity: 0.6;
`
);
const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
margin-bottom: 16px;
display: -webkit-flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
-webkit-justify-content: center;
-webkit-align-content: center;
`
);
// accordion data
// const accordionData = [
//   {
//     id: 0,
//     key: "themes",
//     title: "Themes :",
//     content: (
//       <div className="theme-cont">
//         <ThemeContainer>
//           <ThemeButton
//             onClick={() => {
//               handelChangeTheme(firstTheme);
//               setCurrentThemeBtn(1);
//             }}
//             active={currentThemeBtn == 1 ? true : false}
//             className={`firstTheme `}
//           ></ThemeButton>
//           <ThemeButton
//             onClick={() => {
//               handelChangeTheme(secondTheme);
//               setCurrentThemeBtn(2);
//             }}
//             active={currentThemeBtn == 2 ? true : false}
//             className={`secondTheme`}
//           ></ThemeButton>

//           <ThemeButton
//             onClick={() => {
//               handelChangeTheme(thirdTheme);
//               setCurrentThemeBtn(3);
//             }}
//             active={currentThemeBtn == 3 ? true : false}
//             className={`thirdTheme `}
//           ></ThemeButton>

//           <ThemeButton
//             onClick={() => {
//               handelChangeTheme(forthTheme);
//               setCurrentThemeBtn(4);
//             }}
//             active={currentThemeBtn == 4 ? true : false}
//             className={`forthTheme `}
//           ></ThemeButton>
//           <ThemeButton
//             onClick={() => {
//               handelChangeTheme(fifthTheme);
//               setCurrentThemeBtn(5);
//             }}
//             active={currentThemeBtn == 5 ? true : false}
//             className={`fifthTheme `}
//           ></ThemeButton>

//           <ThemeButton
//             sx={{
//               width: "500px",
//             }}
//             onClick={() => {
//               handelChangeTheme(sixTheme);
//               setCurrentThemeBtn(6);
//             }}
//             active={currentThemeBtn == 6 ? true : false}
//             className={`sixTheme `}
//           ></ThemeButton>
//           <ThemeButton
//             onClick={() => {
//               navigate("/dashboard/dashboard/Templates");
//             }}
//             className="more-tmp-link"
//             style={{ fontSize: "22px" }}
//           >
//             {" "}
//             More
//           </ThemeButton>
//         </ThemeContainer>
//         {/* <Link to={"/dashboard/dashboard/Templates"} >
//             {" "}
//             <div>More</div>
//           </Link> */}
//       </div>
//     ),
//   },
//   {
//     id: 1,
//     key: "fonts",
//     title: "Fonts",
//     content: <Fonts />,
//   },
//   {
//     id: 2,

//     key: "background",
//     title: "Background",
//     content: (
//       <div className="Background-style-block">
//         <div className="tabs">
//           <Box>
//             <Box sx={{ marginY: "30px" }}>
//               <Tabs defaultValue={0}>
//                 <StyledTabsList sx={{ margin: "auto" }}>
//                   <StyledTab value={0}>Color</StyledTab>
//                   <StyledTab value={1}>Image </StyledTab>
//                   <StyledTab value={2}>Video</StyledTab>
//                 </StyledTabsList>
//                 <StyledTabPanel value={0}>
//                   <ColorPage />
//                 </StyledTabPanel>
//                 <StyledTabPanel value={1}>
//                   <ImagePage />
//                 </StyledTabPanel>
//                 <StyledTabPanel value={2}>
//                   <VideoPage />
//                 </StyledTabPanel>
//               </Tabs>
//             </Box>
//           </Box>
//         </div>
//       </div>
//     ),
//   },
//   {
//     id: 3,

//     key: "block",
//     title: "Block",
//     content: (
//       <div className="Block-style-block">
//         <Box>
//           <Box sx={{ marginY: "30px" }}>
//             <Tabs defaultValue={0}>
//               <StyledTabsList sx={{ margin: "auto" }}>
//                 <StyledTab value={0}>Style</StyledTab>
//                 <StyledTab value={1}>Color </StyledTab>
//                 <StyledTab value={2}>Thumbnail</StyledTab>
//                 <StyledTab value={3}>Action Button</StyledTab>
//               </StyledTabsList>
//               <StyledTabPanel value={0}>
//                 <StylePage />
//               </StyledTabPanel>
//               <StyledTabPanel value={1}>
//                 <ColorPageBlock />
//               </StyledTabPanel>
//               <StyledTabPanel value={2}>
//                 <Thumbnail />
//               </StyledTabPanel>
//               <StyledTabPanel value={3}>
//                 <ActionButton />
//               </StyledTabPanel>
//             </Tabs>
//           </Box>
//         </Box>
//       </div>
//     ),
//   },
//   {
//     id: 4,

//     key: "more",
//     title: "More",
//     content: (
//       <div className="more-style-block" style={{ fontSize: "16px" }}>
//         <Box>
//           <Box sx={{ marginY: "30px" }}>
//             <Tabs defaultValue={0}>
//               <StyledTabsList sx={{ margin: "auto" }}>
//                 <StyledTab value={0}>Profile</StyledTab>
//                 <StyledTab value={1}>Social </StyledTab>
//               </StyledTabsList>
//               <StyledTabPanel value={0}>
//                 <Profile />
//               </StyledTabPanel>
//               <StyledTabPanel value={1}>
//                 <Social />
//               </StyledTabPanel>
//             </Tabs>
//           </Box>
//         </Box>
//       </div>
//     ),
//   },
// ];
const Appearance = () => {
  // =====================theme switcher with scss======================
  const [colorTheme, setColorTheme] = useState("theme-white");
  const navigate = useNavigate();
  const [currentThemeBtn, setCurrentThemeBtn] = useState(0);
  // useEffect(() => {
  //   const currentThemeColor = localStorage.getItem("theme-color");

  //   if (currentThemeColor) {
  //     setColorTheme(currentThemeColor);
  //   }
  // }, []);

  // const handelClickTheme = (theme) => {
  //   setColorTheme(theme);
  // };

  const { currentTheme, toggleTheme } = useTheme();
  // console.log(currentTheme);

  // const { updateTemplate } = useMyTemplateContext();

  // const [selectedTheme, setSelectedTheme] = useState(firstTheme);

  const handleRefreshClick = () => {
    axiosRequset
      .post("/owner/Information/update", {
        ...currentTheme,
        img: "",
        imgtwo: "",
      })
      .then((res) => {
        console.log(res);
        toast.success("updated Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // ====================================================================
  // change theme

  const handelChangeTheme = (theme) => {
    toggleTheme({
      ...currentTheme,
      ...theme,
    });
  };
  // accordin
  const [expanded, setExpanded] = useState("");

  const handleChange = (value) => {
    console.log(value);
    setExpanded((prev) => {
      if (prev === value) {
        return "";
      } else {
        return value;
      }
    });
  };
  const [accordionData, setAccordionData] = useState(() => {
    const appearanceData = [
      {
        id: 0,
        key: "themes",
        title: "Themes :",
        content: (
          <div className="theme-cont">
            <ThemeContainer>
              <ThemeButton
                onClick={() => {
                  handelChangeTheme(firstTheme);
                  setCurrentThemeBtn(1);
                }}
                active={currentThemeBtn == 1 ? true : false}
                className={`firstTheme `}
              ></ThemeButton>
              <ThemeButton
                onClick={() => {
                  handelChangeTheme(secondTheme);
                  setCurrentThemeBtn(2);
                }}
                active={currentThemeBtn == 2 ? true : false}
                className={`secondTheme`}
              ></ThemeButton>

              <ThemeButton
                onClick={() => {
                  handelChangeTheme(thirdTheme);
                  setCurrentThemeBtn(3);
                }}
                active={currentThemeBtn == 3 ? true : false}
                className={`thirdTheme `}
              ></ThemeButton>

              <ThemeButton
                onClick={() => {
                  handelChangeTheme(forthTheme);
                  setCurrentThemeBtn(4);
                }}
                active={currentThemeBtn == 4 ? true : false}
                className={`forthTheme `}
              ></ThemeButton>
              <ThemeButton
                onClick={() => {
                  handelChangeTheme(fifthTheme);
                  setCurrentThemeBtn(5);
                }}
                active={currentThemeBtn == 5 ? true : false}
                className={`fifthTheme `}
              ></ThemeButton>

              <ThemeButton
                sx={{
                  width: "500px",
                }}
                onClick={() => {
                  handelChangeTheme(sixTheme);
                  setCurrentThemeBtn(6);
                }}
                active={currentThemeBtn == 6 ? true : false}
                className={`sixTheme `}
              ></ThemeButton>
              <ThemeButton
                onClick={() => {
                  navigate("/dashboard/dashboard/Templates");
                }}
                className="more-tmp-link"
                style={{ fontSize: "22px" }}
              >
                {" "}
                More
              </ThemeButton>
            </ThemeContainer>
            {/* <Link to={"/dashboard/dashboard/Templates"} >
          {" "}
          <div>More</div>
        </Link> */}
          </div>
        ),
      },
      {
        id: 1,
        key: "fonts",
        title: "Fonts",
        content: <Fonts />,
      },
      {
        id: 2,

        key: "background",
        title: "Background",
        content: (
          <div className="Background-style-block">
            <div className="tabs">
              <Box>
                <Box sx={{ marginY: "30px" }}>
                  <Tabs defaultValue={0}>
                    <StyledTabsList sx={{ margin: "auto" }}>
                      <StyledTab value={0}>Color</StyledTab>
                      <StyledTab value={1}>Image </StyledTab>
                      <StyledTab value={2}>Video</StyledTab>
                    </StyledTabsList>
                    <StyledTabPanel value={0}>
                      <ColorPage />
                    </StyledTabPanel>
                    <StyledTabPanel value={1}>
                      <ImagePage />
                    </StyledTabPanel>
                    <StyledTabPanel value={2}>
                      <VideoPage />
                    </StyledTabPanel>
                  </Tabs>
                </Box>
              </Box>
            </div>
          </div>
        ),
      },
      {
        id: 3,

        key: "block",
        title: "Block",
        content: (
          <div className="Block-style-block">
            <Box>
              <Box sx={{ marginY: "30px" }}>
                <Tabs defaultValue={0}>
                  <StyledTabsList sx={{ margin: "auto" }}>
                    <StyledTab value={0}>Style</StyledTab>
                    <StyledTab value={1}>Color </StyledTab>
                    <StyledTab value={2}>Thumbnail</StyledTab>
                    <StyledTab value={3}>Action Button</StyledTab>
                  </StyledTabsList>
                  <StyledTabPanel value={0}>
                    <StylePage />
                  </StyledTabPanel>
                  <StyledTabPanel value={1}>
                    <ColorPageBlock />
                  </StyledTabPanel>
                  <StyledTabPanel value={2}>
                    <Thumbnail />
                  </StyledTabPanel>
                  <StyledTabPanel value={3}>
                    <ActionButton />
                  </StyledTabPanel>
                </Tabs>
              </Box>
            </Box>
          </div>
        ),
      },
      {
        id: 4,

        key: "more",
        title: "More",
        content: (
          <div className="more-style-block" style={{ fontSize: "16px" }}>
            <Box>
              <Box sx={{ marginY: "30px" }}>
                <Tabs defaultValue={0}>
                  <StyledTabsList sx={{ margin: "auto" }}>
                    <StyledTab value={0}>Profile</StyledTab>
                    <StyledTab value={1}>Social </StyledTab>
                  </StyledTabsList>
                  <StyledTabPanel value={0}>
                    <Profile />
                  </StyledTabPanel>
                  <StyledTabPanel value={1}>
                    <Social />
                  </StyledTabPanel>
                </Tabs>
              </Box>
            </Box>
          </div>
        ),
      },
    ];
    const appearance = localStorage.getItem("appearance");
    if (appearance) {
      const appearanceIds = JSON.parse(appearance);
      const sorted = appearanceIds.map((id) =>
        appearanceData.find((item) => +item.id === +id)
      );
      return sorted;
    } else {
      return appearanceData;
    }
  });
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(accordionData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // localStorage.setItem("appearance", JSON.stringify(items));
    console.log(items);
    const appearance = items.map((item) => item.id);

    localStorage.setItem("appearance", JSON.stringify(appearance));
    setAccordionData(items);
  };
  return (
    <>
      <div className="appearance-cont">
        <div className="appearance-header">
          <Box
            sx={{
              fontFamily: "Anek Devanagari",
              fontSize: "20px",
              fontWeight: "300",
              lineHeight: "60px",
            }}
          >
            Appearance
          </Box>
          <button className="apply-btn" onClick={handleRefreshClick}>
            Apply
          </button>{" "}
          {/* Use <button> instead of <Button> */}
        </div>
        {/*  */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <StrictModeDroppable droppableId="appearance">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {accordionData.map((accordionItem, index) => (
                  <Draggable
                    key={accordionItem.id}
                    draggableId={accordionItem.key}
                    index={index}
                    isDragDisabled={expanded === accordionItem.key && true}
                  >
                    {(provided) =>
                      (expanded === "" || expanded === accordionItem.key) && (
                        <Accordion
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={accordionItem.key}
                          expanded={expanded === accordionItem.key}
                          onChange={() => handleChange(accordionItem.key)}
                          sx={{
                            // boxShadow: "#B9B8B8 1px 1px 1px 1px",
                            // borderTop: "1px solid #B9B8B8",
                            // borderRadius: "10px",
                            // margin: "20px 0px",
                            // boxSizing: "border-box",
                            border: "1px solid rgba(178, 194, 187, 1)",
                            borderRadius: "5px",
                            margin: "20px 0px",
                            boxSizing: "border-box",
                            boxShadow: "0 0 0 red",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              position: "relative",
                              "& p": {
                                fontFamily: "Anek Devanagari",
                                fontSize: "18px",
                                fontWeight: "300",
                                // lineHeight: "60px",
                                color: "#000000",
                              },
                              "&.Mui-expanded::after": {
                                position: "absolute",
                                content: "''",
                                height: "1px",
                                width: "calc(100% - 32px)",
                                background: "#B2C2BB",
                                bottom: "0",
                                left: "50%",
                                transform: "translateX(-50%)",
                                // borderBottom: "1px solid #B2C2BB",
                              },
                            }}
                          >
                            {/* <Typography style={{ fontSize: "16px" }}> */}
                            <Typography>{accordionItem.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {accordionItem.content}
                          </AccordionDetails>
                        </Accordion>
                      )
                    }
                  </Draggable>
                ))}{" "}
                {provided.placeholder}
              </Box>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default Appearance;
