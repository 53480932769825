import { Box, Typography } from '@mui/material'
import face from '../../../assets/Image/Facebook.PNG'
import google from '../../../assets/Image/google.PNG'
import style from './Register.module.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import Base_Url, { Apipassword } from '../../../Api/ApiUrl'


const Register = () => {


    
    

  const [visible,setVisible]=useState(false)

  const handleVisible=()=>{
      setVisible((visible)=> !visible)
  }


  let navigate=useNavigate()

  const formik = useFormik({
      initialValues:{
        email:"",
        password:"",
        password_confirmation:""
      },
  
      validationSchema: Yup.object({
        email: Yup.string().email('Invalid email format').required("Email is required"),
        password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
        password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),        }),
  
      onSubmit:(values)=>{
        Base_Url.post("owner/register", values,{headers:{
          Apipassword:Apipassword
        }}).then((res)=>{    
         
          //   localStorage.setItem('token', res.data.token.accessToken)
          //   saveUser()
            toast.success("Please Check Email To Verify Account");
            navigate('/verifyCode')          
          }
       ).catch((error)=>{
      //  console.log(error.response.data.message);
      toast.error(`${error.response.data.message.email}`)
       })
               }
    })
  


  return (

<Box sx={{maxWidth:'20%', alignContent:'center', margin:'0 auto', padding:'0', justifyContent:'center', marginY:'40px',alignItems:'center', display:'flex'}}>
<Box>
<Typography component='h3' sx={{fontSize:'28px', fontWeight:'400', marginLeft:'10px'}}>Log in to your account</Typography>
<Box sx={{maxWidth:'400px',height:'50px', backgroundColor:'transparent', border:'1px solid #4192DE', borderRadius:'10px', marginTop:'20PX'}}>
<Box sx={{display:'flex'}}>
<img src={face} alt="" srcset="" style={{maxWidth:'30px', marginLeft:'10px', marginTop:'7px'}}/>
<Typography component='p' sx={{fontSize:'15px', color:'#4192DE', marginTop:'9px', marginLeft:'10px'}}>Login With Facebook</Typography>
</Box>
</Box>


<Box sx={{maxWidth:'330px', height:'50px', backgroundColor:'transparent', border:'1px solid #E54B4B', borderRadius:'10px', marginTop:'20PX'}}>
<Box sx={{display:'flex'}}>
<img src={google} alt="" srcset="" style={{width:'60px', marginLeft:'10px', marginTop:'10px'}}/>
<Typography component='p' sx={{fontSize:'19px', color:'#E54B4B', marginTop:'9px', marginLeft:'2px'}}>***********</Typography>
</Box>
</Box>


<Typography component='h3' sx={{fontSize:'16px', fontWeight:'300',  alignItems:'center', margin:'15px 130px'}}>XOR</Typography>

<form onSubmit={formik.handleSubmit}> 

<Box sx={{maxWidth:'330px', height:'50px', marginTop:'20px', display:'flex', justifyContent:'space-between', position:'relative'}}>
<input type="text" name="email" autocomplete="off" className={style.register} placeholder="Email Address" id='email' onChange={formik.handleChange} onBlur={formik.handleChange} value={formik.values.email} />
</Box>
<Typography component='p'  sx={{fontSize:"12px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px',marginLeft:'7px' }}>    {formik.touched.email &&formik.errors.email}</Typography>
{/* <Box sx={{maxWidth:'300px', height:'50px', marginTop:'20px', display:'flex', justifyContent:'space-between', position:'relative'}}>
<input type="text" name="email" autocomplete="off" className={style.register} placeholder="Verification code"/>
<button style={{backgroundColor:'transparent', border:'none', position:'absolute', right:'10px', marginTop:'19px', fontSize:'12px', color:'#4192DE', cursor:'pointer'}}>Send Code</button>
</Box> */}

<Box sx={{maxWidth:'330px', height:'50px', marginTop:'20px', display:'flex', justifyContent:'space-between', position:'relative'}}>
<input type={visible?"text":"password"} name="password"  className={style.register} placeholder="Password" id='password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}/>
<Box sx={{backgroundColor:'transparent', border:'none', position:'absolute', right:'10px', marginTop:'14px', fontSize:'12px', color:'#4192DE', cursor:'pointer'}} onClick={handleVisible}>{visible? <VisibilityIcon/>:<VisibilityOffIcon/>}</Box>
</Box>
<Typography component='p'  sx={{fontSize:"12px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px',marginLeft:'7px' }}>    {formik.touched.password &&formik.errors.password}</Typography>


<Box sx={{maxWidth:'330px', height:'50px', marginTop:'20px', display:'flex', justifyContent:'space-between', position:'relative'}}>
<input type={visible?"text":"password"} name="password_confirmation"  className={style.register} placeholder="Confirm Password" id='password_confirmation' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password_confirmation}/>
<Box sx={{backgroundColor:'transparent', border:'none', position:'absolute', right:'10px', marginTop:'14px', fontSize:'12px', color:'#4192DE', cursor:'pointer'}} onClick={handleVisible}>{visible? <VisibilityIcon/>:<VisibilityOffIcon/>}</Box>
</Box>
<Typography component='p'  sx={{fontSize:"12px", lineHeight:'19,07px', color:'#ff0000', fontWeight:'700', marginTop:'15px',marginLeft:'7px' }}>    {formik.touched.password_confirmation &&formik.errors.password_confirmation}</Typography>



<Box sx={{maxWidth:'330px', height:'55px', marginTop:'30px'}}>
<button type='submit' style={{background: 'linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)', width:"100%", height:'100%', border:'none', borderRadius:'10px', color:'white', fontSize:'20px', fontWeight:'600px'}}>
Sign up
</button>
</Box>

</form>


<Box sx={{display:'flex', marginTop:'40px'}}>
<Typography component='h3' sx={{fontSize:'18px', fontWeight:'400', marginX:'5px'}}>Already have an account?</Typography>
<Link style={{ marginTop:'1px', fontSize:'17px', color:'#4192DE'}} to='/login'>  Log in.</Link>
</Box>

</Box>
</Box>
    )
}

export default Register