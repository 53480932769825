import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import Recommend from './Recommend'
import Hot from './Hot'
import LastedUpdate from './LatestUpdate'
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';





const Discover = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
<Box sx={{width:'95%', marginX:'80px'}}>

<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
<Typography component='h2' sx={{fontSize:'35px', fontWeight:'600', marginTop:'15px'}}>Discover</Typography>

<Box className="input-wrapper" style={{ width: '430px', marginTop: '6px', marginRight:'280px', position:'relative'}}>
<Box sx={{marginTop:'30px'}}>
<Input placeholder="Search......."   startDecorator={<SearchIcon />} sx={{backgroundColor:'#FFFFFF',boxShadow:1, outline:'none', border:'none', paddingLeft:'-100px'}}/>               
<button style={{color:'white', right:'-120px', position:'absolute',top:'30px',  padding:'12px 30px', background: 'linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)',  borderRadius:'10px', border:'none',}}>Search</button>

</Box>

</Box>
</Box>



<Box sx={{ width: '100%', typography: 'body1', marginTop:'50px' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Recommend" value="1" sx={{textTransform:'capitalize'}}/>
              <Tab label="Hot" value="2" sx={{textTransform:'capitalize'}} />
              <Tab label="Latest Update" value="3" sx={{textTransform:'capitalize'}} />
            </TabList>
          </Box>
          <TabPanel value="1"><Recommend/></TabPanel>
          <TabPanel value="2"><Hot/></TabPanel>
          <TabPanel value="3"><LastedUpdate/></TabPanel>
        </TabContext>
      </Box>
</Box>

  )
}

export default Discover