import React, { useEffect, useState } from "react";
import profile from "../../../assets/Image/imgProfile.PNG";
import img from "../../../assets/Image/mauns.PNG";
import img1 from "../../../assets/Image/true.PNG";
import img2 from "../../../assets/Image/trueblue.PNG";
import img3 from "../../../assets/Image/truegreen.PNG";
import img4 from "../../../assets/Image/truebluecircle.PNG";
import { Button, CardOverflow, Input, Textarea } from "@mui/joy";
import Base_Url, { Apipassword, axiosRequset } from "../../../Api/ApiUrl";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import { useTheme } from "../../styles/ThemeContext";
import icons40 from "../../../assets/Group 40.png";

// import icons2 from "../../../assets/VarificationIcons/3609317.png";
// import icons3 from "../../../assets/VarificationIcons/icon-for-quality-15.jpg";
// import icons4 from "../../../assets/VarificationIcons/verified-icon-15.jpg";
import icons from "../../../assets/VarificationIcons/Group5.svg";
import icons1 from "../../../assets/VarificationIcons/Group2.svg";
import icons2 from "../../../assets/VarificationIcons/Group3.svg";
import icons3 from "../../../assets/VarificationIcons/Group4.svg";
import icons4 from "../../../assets/VarificationIcons/Group1.svg";
import ImageCropper from "./ImageUpload";
import PopUpCrop from "./ImageUpload";
import { Delete } from "@mui/icons-material";
const varifications = [
  {
    name: "icons1",
    img: icons1,
  },
  {
    name: "icons4",
    img: icons4,
  },
  {
    name: "icons2",
    img: icons2,
  },
  {
    name: "icons3",
    img: icons3,
  },
];
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const BasicInfo = () => {
  const [tilteAndDescAndImg, setTilteAndDescAndImg] = useState({});
  console.log(tilteAndDescAndImg);
  const { currentTheme, setCurrentTheme } = useTheme();
  const [profileImage, setProfileImage] = useState(profile);
  const [varificationIcons, setVarificationIcons] = useState();
  const [show, setShow] = useState(false);
  const [currentIcon, setCurrentIcon] = useState();
  console.log(currentTheme);

  useEffect(() => {
    setCurrentIcon(currentTheme?.verified);
    setTilteAndDescAndImg({
      title: currentTheme.data.title,
      description: currentTheme.data.description,
    });
    setProfileImage(currentTheme?.img);
    axiosRequset
      .get("/guest/getVarificationIcons")
      .then((res) => {
        // console.log(res.data);
        setVarificationIcons(res.data["Varification-Icon"].data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setProfileImage(currentTheme?.img);
  }, [currentTheme?.img]);
  const imgProfile = (event) => {
    setTilteAndDescAndImg({
      ...tilteAndDescAndImg,
    });
    setShow(true);
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  const handelChangeTitle = (event) => {
    // console.log(event.target.name);

    setTilteAndDescAndImg({
      ...tilteAndDescAndImg,
      [event.target.name]: event.target.value,
    });
    // handle change title and description in live preview
    setCurrentTheme((current) => {
      let themeData = { ...current.data };
      themeData = {
        ...themeData,
        [event.target.name]: event.target.value,
      };
      return { ...current, data: themeData };
    });
    // console.log(currentTheme);
  };
  const handelTitleAndDescription = (event) => {
    event.preventDefault();
    console.log({ ...tilteAndDescAndImg });
    axiosRequset
      .post("/owner/Information/update", {
        id: currentTheme.id,
        ...tilteAndDescAndImg,
      })
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...res.data.Information.object,
          public_id: res.data.link.public_id,
          img: res.data.Information.image,
          id: res.data.Information.id,
          Contents: res.data.Information.Contents,
          orders: res.data.Information.orders,
          data: {
            title: res.data.Information.title,
            description: res.data.Information.description,
          },
          verified: res.data.Information.verified,
        });

        toast.success("Added Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const handleCropSubmit = (croppedImageUrl) => {
    let zz = dataURLtoFile(croppedImageUrl, "croppedImage.png");
    axiosRequset
      .post("/owner/Information/update", {
        id: currentTheme.id,
        ...tilteAndDescAndImg,
        image: zz,
      })
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...res.data.Information.object,
          public_id: res.data.link.public_id,
          img: res.data.Information.image,
          id: res.data.Information.id,
          Contents: res.data.Information.Contents,
          orders: res.data.Information.orders,
          data: {
            title: res.data.Information.title,
            description: res.data.Information.description,
          },
          verified: res.data.Information.verified,
        });

        toast.success("Added Successfully");
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // });
  };
  const handleDeleteProfileImg = () => {
    console.log({
      id: currentTheme.id,
      // ...tilteAndDescAndImg,
      cover: "delete",
    });
    // setProfileImage(profile);
    axiosRequset
      .post("/owner/Information/update", {
        id: currentTheme.id,
        // ...tilteAndDescAndImg,
        cover: "delete",
      })
      .then((res) => {
        console.log(res);
        setCurrentTheme({
          ...res.data.Information.object,
          public_id: res.data.link.public_id,
          img: res.data.Information.image,
          id: res.data.Information.id,
          Contents: res.data.Information.Contents,
          orders: res.data.Information.orders,
          data: {
            title: res.data.Information.title,
            description: res.data.Information.description,
          },
          verified: res.data.Information.verified,
        });

        toast.success("Deleted Successfully");
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // });
  };

  return (
    <div>
      {/* <Typography component="h1" className="title-info" >
        Basic Info
      </Typography> */}
      {/* <Box
        sx={{
          width: "515px",
          height: "1.5px",
          backgroundColor: "rgba(178, 194, 187, 1)",
          marginTop: "15px",
        }}
      ></Box> */}
      {/* <ImageCropper imageUrl={profileImage} onCropComplete={handleCropSubmit} /> */}
      <Box
        sx={{
          // marginLeft: "-15px",
          // width: "594px",
          // height: "559px",
          position: "relative",
          borderRadius: "5px",
        }}
      >
        <Typography
          component="p"
          sx={{
            lineHeight: "60px",
            color: "rgba(51, 51, 51, 1)",
            fontFamily: "Anek Devanagari",
            fontWeight: "500",
            fontSize: "20px",
            // marginTop: "10px",
          }}
        >
          Profile Picture
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "140px",
              height: "140px",
              borderRadius: "50%",
            }}
          >
            <Button
              component="label"
              variant="contained"
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                borderRadius: "50%",
              }}
              // startIcon={<CloudUploadIcon sx={{ color: "blue" }} />}
            >
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => imgProfile(e)}
              />
            </Button>
            <Box
              component="img"
              src={profileImage}
              sx={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
            {/* delete profile img  */}
            <IconButton
              aria-label="delete"
              sx={{
                position: "absolute",
                bottom: "-5px",
                right: "-20px",
              }}
              onClick={handleDeleteProfileImg}
            >
              <Delete />
            </IconButton>
          </Box>

          <Stack
            sx={{
              position: "relative",
              top: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Anek Devanagari",
                fontSize: "16px",
                fontWeight: "400",
                height: "50px",
                color: "#333333",
                lineHeight: "60px",
              }}
            >
              Verified Badge:
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                gap: "101px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  gap: "3px",
                  position: "relative",
                  ml: "10px",
                  // left: "-115px",
                  // top: "32px",
                }}
              >
                <Box
                  sx={{
                    width: "35px",
                    height: "35px",
                    // border: "solid 1px rgba(3, 127, 154, 1)",
                    border: `${
                      "icon.name" == currentIcon
                        ? "1px solid rgba(3, 127, 154, 1)"
                        : ""
                    }`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "0.3s",
                  }}
                >
                  <Box
                    component="img"
                    src={icons}
                    sx={{
                      width: "22px",
                      height: "22px",
                      cursor: "pointer",
                      // marginTop: "-28px",
                      // marginLeft: "6px",
                    }}
                    onClick={() => {
                      setCurrentIcon("icon.name");
                      setTilteAndDescAndImg({
                        ...tilteAndDescAndImg,
                        verified: "false",
                      });
                      setCurrentTheme((current) => ({
                        ...current,
                        verified: "",
                      }));
                    }}
                  />
                </Box>
                {varifications.map((varification) => (
                  // <div
                  //   style={{
                  //     display: "inline-block",
                  //     width: "22px",
                  //     height: "22px",
                  //   }}
                  // >
                  <Box
                    sx={{
                      width: "35px",
                      height: "35px",
                      // border: "solid 1px rgba(3, 127, 154, 1)",
                      border: `${
                        varification.name == currentIcon
                          ? "1px solid rgba(3, 127, 154, 1)"
                          : ""
                      }`,
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "0.3s",
                    }}
                  >
                    <Box
                      component="img"
                      src={varification?.img}
                      sx={{
                        width: "22px",
                        height: "22px",
                        // marginLeft: "6px",
                        cursor: "pointer",
                        // border: `${
                        //   varification.name == currentIcon
                        //     ? "2px solid #54b99b"
                        //     : ""
                        // }`,
                        // borderRadius: "50%",
                        // p: "2px",
                      }}
                      onClick={() => {
                        setCurrentIcon(varification.name);
                        setTilteAndDescAndImg({
                          ...tilteAndDescAndImg,
                          verified: varification.name,
                        });
                        setCurrentTheme((current) => ({
                          ...current,
                          verified: varification.name,
                        }));
                      }}
                    />
                  </Box>
                  // </div>
                ))}
                {/* <div
              className="pro"
              style={{
                width: "40px",
                height: "20px",
                background: "rgba(3, 127, 154, 1)",
                borderRadius: "50%",
                backgroundSize: "cover",
                position: "relative",
                left: "115px",
                top: "26px",
              }}
            >
              <h2
                style={{
                  fontSize: "12px",
                  fontFamily: "Anek Devanagari",
                  color: "#fff",
                  fontWeight: "600",
                  marginLeft: "10px",
                  marginTop: "6px",
                }}
              >
                PRO
              </h2>
            </div> */}
              </Box>
              <Typography
                sx={{
                  width: "40px",
                  height: "20px",
                  backgroundColor: "#037F9A",
                  borderRadius: "50px",
                  fontFamily: "Anek Devanagari",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "60px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  pt: "3px",
                }}
              >
                Pro
              </Typography>
            </Box>
          </Stack>
        </Box>
        {/* Title */}
        <Box sx={{ marginTop: "28px" }}>
          <Typography
            component="p"
            sx={{
              fontFamily: "Anek Devanagari",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            Title
          </Typography>
          <TextField
            name="title"
            placeholder="Business Consultants"
            onChange={handelChangeTitle}
            value={tilteAndDescAndImg.title}
            sx={{
              height: "35px",
              width: "100%",
              "& fieldset": {
                borderRadius: "0",
                // width: "100%",
              },
              "& input": {
                width: "100%",
                fontFamily: "Anek Devanagari",
                fontSize: "14px",
                fontWeight: "400",
                p: "7px",
              },
            }}
          />
          {/* <Input
            size="md"
            placeholder="Business Consultants"
            sx={{
              // marginTop: "15px",
              height: "35px",
              textAlign: "start",
              width: "85%",
              marginLeft: "10px",
              borderRadius: "5px",
              border: "solid 1px #rgba(178, 194, 187, 1)",
            }}
            name="title"
            onChange={handelChangeTitle}
            value={tilteAndDescAndImg.title}
          /> */}
        </Box>

        {/* Descrition  */}
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            component="p"
            sx={{
              fontFamily: "Anek Devanagari",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Description
          </Typography>
          <TextField
            placeholder="Taking Your Business to the Next Level"
            minRows={2}
            sx={{
              height: "35px",
              width: "100%",
              "& fieldset": {
                borderRadius: "0",
                // width: "100%",
              },
              "& input": {
                width: "100%",
                fontFamily: "Anek Devanagari",
                fontSize: "14px",
                fontWeight: "400",
                p: "7px",
              },
            }}
            name="description"
            onChange={handelChangeTitle}
            value={tilteAndDescAndImg.description}
          />
        </Box>
        {/* save  */}
        <Button
          size="md"
          sx={{
            display: "block",
            width: "86%",
            marginTop: "20px",
            background: "linear-gradient(45deg, #19609C 30%,  #54B99B 90% 90%)",
            height: "57px",
            mx: "auto",
          }}
          onClick={handelTitleAndDescription}
        >
          Save
        </Button>
      </Box>
      <PopUpCrop
        show={show}
        setShow={setShow}
        imgURL={profileImage}
        onSaveHandler={handleCropSubmit}
      />
    </div>
  );
};

export default BasicInfo;
